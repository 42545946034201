import Popover, { PopoverOrigin } from '@mui/material/Popover';
import * as React from 'react';

interface CustomPopoverProps {
  children: React.ReactNode;
  /**
   * The DOM element used to set the position of the popover.
   */
  anchorEl: HTMLElement | null;
  /**
   * The anchor origin of the popover.
   */
  anchorOrigin?: PopoverOrigin;
  /**
   * The function that handles the closing of the popover.
   */
  handleClose: () => void;
}

const CustomPopover: React.FC<CustomPopoverProps> = ({
  anchorEl,
  anchorOrigin,
  handleClose,
  children,
}) => {
  const open = Boolean(anchorEl);
  const id = open ? 'simple-popover' : undefined;

  return (
    <Popover
      id={id}
      open={open}
      anchorEl={anchorEl}
      onClose={handleClose}
      anchorOrigin={anchorOrigin}
    >
      {children}
    </Popover>
  );
};

export default CustomPopover;
