import { ExportContainer } from './base/ExportContainer';

/**
 * The Export page for the travel itinerary, hotels, and flights.
 */
const ExportPage = () => {
  return (
    // Calculated height since the Toolbar is sticky
    <div className="min-h-[calc(100vh-7rem)]">
      <ExportContainer />
    </div>
  );
};

export default ExportPage;
