import React from 'react';
import { Outlet } from "react-router-dom";
import { v4 as uuidv4 } from 'uuid';
import NavbarRequestDemo from '../../components/navbar/requestDemoNavbar';

type RequestDemoContainerProps = {}

const RequestDemoContainer: React.FC<RequestDemoContainerProps> = () => {
  const userId = localStorage.getItem("userId") || uuidv4();
  localStorage.setItem("userId", userId);
  return (
    <div className="relative z-10">
      <NavbarRequestDemo />
      <Outlet />
    </div>
  );
}


export default RequestDemoContainer;