import { Grid, Stack, Typography } from "@mui/joy";
import CardBook from "./cardBook";
import { gridLayout } from "../../utils/const";
import Quotation from "./quotation";

type BookProps = {}

const cardBookList = [
  {
    title: 'Cafe Origin',
    date: '17/3/2025 11:30 am',
    link: '',
    cost: '$200',
  },
  {
    title: 'Pose cafe',
    date: '17/3/2025 11:30 am',
    link: '',
    cost: '$200',
  },
  {
    title: 'Restaurant Origin',
    date: '17/3/2025 11:30 am',
    link: '',
    cost: '$200',
  },
  {
    title: 'Cafe Origin',
    date: '17/3/2025 11:30 am',
    link: '',
    cost: '$200',
  },
  {
    title: 'Pose cafe',
    date: '17/3/2025 11:30 am',
    link: '',
    cost: '$200',
  },
  {
    title: 'Restaurant Origin',
    date: '17/3/2025 11:30 am',
    link: '',
    cost: '$200',
  },
]

const cardFlight = {
  title: 'Tunisia -> Istanbul',
  date: '17/3/2025 11:30 am',
  link: '',
  cost: '$200',
}
const cardHotel = {
  title: 'Pyramid Hotel',
  date: '17/3/2025 11:30 am',
  link: '',
  cost: '$200',
}

// Mock data for quotation
const mockQuotationData = {
  clientName: "John Doe",
  clientAddress: "123 Travel Street",
  clientPostcode: "12345",
  siteAddress: "456 Destination Ave",
  sitePostcode: "67890",
  items: [
    {
      title: "Flight Ticket",
      description: "Tunisia -> Istanbul",
      subTotal: 180.00,
      tax: 20.00
    },
    {
      title: "Hotel Stay",
      description: "Pyramid Hotel - 3 nights",
      subTotal: 300.00,
      tax: 30.00
    },
    {
      title: "Activities Package",
      description: "City Tours and Experiences",
      subTotal: 250.00,
      tax: 25.00
    }
  ]
};

const Book: React.FC<BookProps> = () => {
  return (
    <Stack spacing={4} sx={{ padding: { lg: 4, xs: 1 } }}>
      <Typography sx={{ fontSize: '2rem', fontWeight: '700' }} level="h2">Bookings</Typography>
      {/* Added Quotation Component */}
      <Quotation {...mockQuotationData} />
    </Stack>
  );
}


export default Book;