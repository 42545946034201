import { Variant1 } from './Variant1';

/**
 * Export all templates
 */
export const Templates = {
  Variant1,
};

/**
 * The keys of the templates. Used for the select dropdown.
 */
export type TemplateKey = keyof typeof Templates;
