import Paper from "@mui/material/Paper";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TablePagination from "@mui/material/TablePagination";
import TableRow from "@mui/material/TableRow";
import * as React from "react";
import { Link } from "react-router-dom";
import { useGetFeedbackQuery } from "../../../redux/features/adminSlice";
import { FeedbackSchema } from "../../../utils/types";
import { formatDateTime } from "../../../utils/functions";

interface Column {
  id: "_id" | "email" | "rating" | "improve" | "date" | "country" | "requestId" | 'type' | 'comment';
  label: string;
  minWidth?: number;
  align?: "right" | "left" | "center";
  link?: (requestId: string) => JSX.Element;
}

const columns: readonly Column[] = [
  { id: "_id", label: "id", minWidth: 70 },
  { id: "email", label: "Email", minWidth: 100 },
  {
    id: "date",
    label: "Date",
    minWidth: 170,
    align: "left",
  },
  {
    id: "rating",
    label: "Rating",
    minWidth: 170,
    align: "left",
  },
  {
    id: "improve",
    label: "Improve",
    minWidth: 200,
    align: "left",
  },
  {
    id: "country",
    label: "Location by IP",
    minWidth: 170,
    align: "left",
  },
  {
    id: "type",
    label: "Type",
    minWidth: 100,
    align: "left",
  },
  {
    id: "comment",
    label: "Comment",
    minWidth: 200,
    align: "left",
  },
  {
    id: "requestId",
    label: "RequestId",
    minWidth: 170,
    align: "left",
    link: (value: string) => <Link to={`/travel/flight/${value}`}>Show result</Link>,
  },
];

const createData = (
  _id: string,
  email: string,
  rating: string,
  improve: string,
  date: string,
  country: any,
  type: string,
  comment: string,
  requestId: string
): FeedbackSchema => {
  return { _id, email, rating, improve, date, country, requestId, type, comment };
}

export default function FeedbackTable() {
  const [page, setPage] = React.useState(0);
  const [rowsPerPage, setRowsPerPage] = React.useState(10);
  const [rows, setRows] = React.useState<FeedbackSchema[]>([]);


  const { data, isLoading, isSuccess, isFetching, isError, error } = useGetFeedbackQuery({});
  const handleChangePage = (event: unknown, newPage: number) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (
    event: React.ChangeEvent<HTMLInputElement>
  ) => {
    setRowsPerPage(+event.target.value);
    setPage(0);
  };

  React.useEffect(() => {
    if (data) {

      const rows = data.responseObject?.map((item: any, index: number) =>
        createData(
          (index + 1).toString(),
          item?.email,
          item?.rating,
          item?.improve,
          formatDateTime(item?.date),
          item?.country,
          item?.type,
          item?.comment,
          item?.requestId,
        )
      );
      setRows(rows);
    }

  }, [data, isLoading, isFetching, isSuccess, isError, error]);

  return (
    <Paper sx={{ width: "100%", overflow: "hidden" }}>
      <TableContainer sx={{ maxHeight: 440 }}>
        <Table stickyHeader aria-label="sticky table">
          <TableHead>
            <TableRow>
              {columns.map((column) => (
                <TableCell
                  key={column.id}
                  align={column.align}
                  style={{ minWidth: column.minWidth }}
                >
                  {column.label}
                </TableCell>
              ))}
            </TableRow>
          </TableHead>
          <TableBody>
            {rows
              ?.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
              ?.map((row: FeedbackSchema) => {
                return (
                  <TableRow
                    hover
                    role="checkbox"
                    tabIndex={-1}
                    key={row._id as React.Key}
                  >
                    {columns.map((column) => {
                      const value = row[column.id];
                      return (
                        <TableCell key={column.id} align={column.align}>
                          {column.link && typeof value === "string"
                            ? column.link(value)
                            : column.id === "country" ? `${value?.name || ''}, ${value?.country?.city || ''}` : value}
                        </TableCell>
                      );
                    })}
                  </TableRow>
                );
              })}
          </TableBody>
        </Table>
      </TableContainer>
      <TablePagination
        rowsPerPageOptions={[10, 25, 100]}
        component="div"
        count={rows.length}
        rowsPerPage={rowsPerPage}
        page={page}
        onPageChange={handleChangePage}
        onRowsPerPageChange={handleChangeRowsPerPage}
      />
    </Paper>
  );
}
