import { serverUrl } from '../../utils/env'
import { BookingDetailsResponse, BookingsDetailsResponse, CancelBookingResponse, HotelDetailsResponse, HotelPreBookingResponse, HotelRatesResponse, NuiteeRateResponse } from '../../utils/types'
import { apiSlice } from './apiSlice'

export const nuiteeHotelApi = apiSlice?.injectEndpoints({
  endpoints: builder => ({
    searchHotels: builder.query({
      query: (params) => ({
        url: `${serverUrl}/nuitee/hotels/search`,
        method: 'get',
        params:{...params}
      }),
      transformResponse: (response: NuiteeRateResponse) => response
    }),
    getHotelDetailsNuitee: builder.query({
      query: (hotelId) => ({
        url: `${serverUrl}/nuitee/hotels/${hotelId}`,
        method: 'get'
      }),
      transformResponse: (response: HotelDetailsResponse) => response
    }),
    getHotelRates: builder.query({
      query: ({ hotelId, ...params }) => ({
        url: `${serverUrl}/nuitee/hotels/${hotelId}/rates`,
        method: 'get',
        params
      }),
      transformResponse: (response: HotelRatesResponse) => response
    }),
    preBookHotel: builder.mutation({
      query: (data) => ({
        url: `${serverUrl}/nuitee/prebook`,
        method: 'post',
        body: data
      }),
      transformResponse: (response: HotelPreBookingResponse) => response
    }),
    bookHotel: builder.mutation({
      query: (data) => ({
        url: `${serverUrl}/nuitee/bookings`,
        method: 'post',
        body: data
      }),
      transformResponse: (response: BookingDetailsResponse) => response
    }),
    getBookingsDetails: builder.query({
      query: (clientReference) => ({
        url: `${serverUrl}/nuitee/bookings/all/${clientReference}`,
        method: 'get'
      }),
      transformResponse: (response: BookingsDetailsResponse) => response
    }),
    getBookingDetails: builder.query({
      query: (bookingId) => ({
        url: `/bookings/${bookingId}`,
        method: 'get'
      }),
    }),
    cancelBooking: builder.mutation({
      query: (bookingId) => ({
        url: `${serverUrl}/nuitee/bookings/${bookingId}`,
        method: 'delete'
      }),
      transformResponse: (response: CancelBookingResponse) => response
    }),
  }),
})

export const {
  useSearchHotelsQuery,
  useLazySearchHotelsQuery,
  useGetHotelDetailsNuiteeQuery,
  useLazyGetHotelDetailsNuiteeQuery,
  useGetHotelRatesQuery,
  usePreBookHotelMutation,
  useBookHotelMutation,
  useGetBookingsDetailsQuery,
  useLazyGetBookingsDetailsQuery,
  useGetBookingDetailsQuery,
  useCancelBookingMutation
} = nuiteeHotelApi