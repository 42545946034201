import { FlightBookingResult, FlightSearchResult, GenericResponse, PersistedFlightResponse, ReturnFlightResult } from '../../utils/types'
import { getFlightBookingQuery, getFlightQuery, getFlightsQuery, getReturnFlightQuery, persistFlightsMutation, getPersistedFlightsQuery } from '../queries/flights.query'
import { apiSlice } from './apiSlice'

export const flightsApi = apiSlice.injectEndpoints({
  endpoints: (builder) => ({
    getFlights: builder.query({
      query: (data) => ({
        url: '/',
        method: 'POST',
        body: {
          query: getFlightsQuery,
          variables: { data }
        }
      }),
      transformResponse: (response: { data: { getFlights: FlightSearchResult } }) => response.data.getFlights
    }),
    getFlight: builder.query({
      query: (data) => ({
        url: '/',
        method: 'POST',
        body: {
          query: getFlightQuery,
          variables: { data }
        }
      }),
      transformResponse: (response: { data: { getFlight: FlightSearchResult } }) => response.data.getFlight
    }),
    getReturnFlights: builder.query({
      query: ({ departure_id, arrival_id, outbound_date, return_date, token }) => ({
        url: '/',
        method: 'POST',
        body: {
          query: getReturnFlightQuery,
          variables: { data: { departure_id, arrival_id, outbound_date, return_date, token } }
        }
      }),
      transformResponse: (response: { data: { getReturnedFlights: ReturnFlightResult } }) => response.data.getReturnedFlights
    }),
    getFlightBookingOptions: builder.query({
      query: ({ departure_id, arrival_id, outbound_date, return_date, token }) => ({
        url: '/',
        method: 'POST',
        body: {
          query: getFlightBookingQuery,
          variables: { data: { departure_id, arrival_id, outbound_date, return_date, token } }
        }
      }),
      transformResponse: (response: { data: { getFlightsBooking: FlightBookingResult } }) => response.data.getFlightsBooking
    }),
    persistFlights: builder.mutation({
      query: params => ({
        url: '/',
        method: 'POST',
        body: {
          query: persistFlightsMutation,
          variables: { data: params }
        }
      }),
      transformResponse: (response: { data: { persistFlights: GenericResponse} }) => response.data.persistFlights
    }),
    getPersistedFlights: builder.query({
      query: params => ({
        url: '/',
        method: 'POST',
        body: {
          query: getPersistedFlightsQuery,
          variables: { data: params }
        }
      }),
      transformResponse: (response: { data: { getPersistedFlights: PersistedFlightResponse } }) => response.data.getPersistedFlights
    }),
  }),
})

export const {
  useLazyGetFlightsQuery,
  useLazyGetReturnFlightsQuery,
  useLazyGetFlightBookingOptionsQuery,
  useGetFlightQuery,
  usePersistFlightsMutation,
  useGetPersistedFlightsQuery,
} = flightsApi
