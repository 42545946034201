import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
import KeyboardArrowUpIcon from '@mui/icons-material/KeyboardArrowUp';
import TrendingFlatIcon from '@mui/icons-material/TrendingFlat';
import { Button } from '@mui/joy';
import { IconButton, TableCell, TableRow } from '@mui/material';
import Grid from '@mui/material/Unstable_Grid2';
import { RowTableFlight } from '../../../utils/types';
import AutoAwesomeIcon from '@mui/icons-material/AutoAwesome';
type FlightCardProps = {
  row: RowTableFlight;
  subFlight?: boolean;
  open: boolean;
  setOpen: (open: boolean) => void;
  openSelectFlight: boolean;
  setOpenSelectFlight: (openSelectFlight: boolean) => void;
  openBooking: boolean;
  setOpenBooking: (openBooking: boolean) => void;
  loadingBooking: boolean;
  isLoading: boolean;
  handleBookingFlight: any
  handleSelectFlight: any
  index: number;
  indexFils: number;
}

const FlightCard: React.FC<FlightCardProps> = ({ row, subFlight, open, setOpen, openBooking, openSelectFlight, setOpenBooking, setOpenSelectFlight, loadingBooking, isLoading, handleBookingFlight, handleSelectFlight, index, indexFils }) => {

  const firstFlight = row.flights?.[0];
  const lastFlight = row.flights?.[row.flights.length - 1];
  return (
    <TableRow role="checkbox" tabIndex={-1} className={index === 0 && (subFlight ? indexFils === 0 : true) ? "border-yellow-600 border-2" : ""}>
      <TableCell padding={'none'} sx={{ paddingY: 1 }}>
        <Grid container direction={'column'}>
          {index === 0 && (subFlight ? indexFils === 0 : true) && <div className='absolute top-0 left-0 text-yellow-600 z-10'>
            <AutoAwesomeIcon />
          </div>}
          <Grid container gap={1}>
            <Grid xs={'auto'}>
              <img alt="logo" src={row.photo} loading='lazy' width={32} />
            </Grid>
            <Grid container direction={'column'} flex={1} gap={1}>
              <Grid flex={1} container>
                <Grid container xs={'auto'} direction={'column'}>
                  <Grid flex={1} container xs='auto' >
                    <Grid xs={4} alignItems={'center'} display={'flex'} >
                      <span className='text-sm poppins-semibold' >{row.time.split('-')[0]}</span>
                    </Grid>
                    <Grid xs={4} >
                      <IconButton aria-label="expand row" size="small" onClick={() => setOpen(!open)}>
                        <TrendingFlatIcon />
                      </IconButton>
                    </Grid>
                    <Grid xs={4} alignItems={'center'} display={'flex'}>
                      <span className='text-sm poppins-semibold' >
                        {row.time.split('-')[1]}
                      </span>
                    </Grid>
                  </Grid>
                  <Grid flex={1} container>
                    <Grid xs={4}><span className='text-xs poppins-medium' >{firstFlight.departure_airport.id}</span> </Grid>
                    <Grid xs={4} xsOffset={4}><span className='text-xs poppins-medium' >{lastFlight.arrival_airport.id}</span></Grid>
                  </Grid>
                </Grid>
                <Grid container xs={'auto'} flex={1} justifyContent={'flex-end'}>
                  <Grid container direction={'column'}>
                    <Grid flex={1} height={34} display={'flex'} alignItems={'center'}><span className='text-sm poppins-semibold' >{row.cost}</span></Grid>
                    <Grid flex={1}><span className='text-xs poppins-medium' >{row.type}</span></Grid>
                  </Grid>
                </Grid>
              </Grid>

            </Grid>
            <Grid xs={'auto'} container direction={'column'}>
              <Grid>
                <IconButton aria-label="expand row" size="small" onClick={() => setOpen(!open)}>
                  {open ? <KeyboardArrowUpIcon /> : <KeyboardArrowDownIcon />}
                </IconButton>
              </Grid>
            </Grid>
          </Grid>
          <Grid container columnGap={{ xs: 1, md: 2 }} display={'flex'} alignItems={'center'} >
            <Grid>
              <span className='text-xs poppins-medium' >{row.flights.length > 1 ? row.flights.length - 1 + ' stops' : 'Non stop'}</span>
            </Grid>
            <Grid>
              <span className='text-xs poppins-medium' >{row.duration}</span>
            </Grid>
            <Grid>
              <span className='text-xs poppins-medium' >{row.name}</span>
            </Grid>
            <Grid marginLeft={'auto'}>
              {subFlight ?
                <Button
                  sx={{ fontSize: '0.7rem', paddingBlock: '0', paddingInline: '0.75rem' }}
                  loading={loadingBooking}
                  disabled={loadingBooking}
                  onClick={() => handleBookingFlight(row['action'])}
                  variant="outlined"
                  color='neutral'
                  className="rounded-lg" >Book flight</Button> :
                <Button
                  sx={{ fontSize: '0.7rem', paddingBlock: '0', paddingInline: '0.75rem' }}
                  loading={isLoading}
                  disabled={isLoading}
                  onClick={() => handleSelectFlight(row['action'])}
                  variant="outlined"
                  color='neutral'
                  className="rounded-lg"
                >{openSelectFlight ? 'Unselect' : 'Select flight'}</Button>
              }
            </Grid>
          </Grid>
        </Grid>
      </TableCell>
    </TableRow >
  );
}


export default FlightCard;