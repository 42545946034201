import HomeIcon from '@mui/icons-material/Home';
import LoginIcon from '@mui/icons-material/Login';
import { Box } from "@mui/joy";
import { List, ListItem, ListItemButton, ListItemIcon, ListItemText, Toolbar } from "@mui/material";
import { useNavigate } from "react-router-dom";
import logo from "../../assets/images/logo/white.svg";
type RequestDemoSidebarProps = {
  setMobileOpen: (open: boolean) => void
  setEmailOpen?: (open: boolean) => void
}

const RequestDemoSidebar: React.FC<RequestDemoSidebarProps> = ({ setMobileOpen }) => {
  const navigate = useNavigate()
  return (
    <Box className='h-full flex flex-col'>
      <Toolbar className='pt-4 pb-7' >
        <img src={logo} alt="logo" className="mx-auto w-16 cursor-pointer" />
      </Toolbar>
      <List >
        <ListItem >
          <ListItemButton
            onClick={() => {
              setMobileOpen(false)
              navigate('/')
            }}
            sx={theme => ({
              color: 'white',
              '--mui-palette-action-active': 'white',
              '&:hover': {
                color: theme.palette.navoy.red,
                '--mui-palette-action-active': theme.palette.navoy.red
              }
            })}
          >
            <ListItemIcon sx={{ minWidth: 40 }}>
              <HomeIcon />
            </ListItemIcon>
            <ListItemText primary={'Home'} />
          </ListItemButton>
        </ListItem>
        <ListItem >
          <ListItemButton
            onClick={() => {
              setMobileOpen(false)
              navigate('/auth/login')
            }}
            sx={theme => ({
              color: 'white',
              '--mui-palette-action-active': 'white',
              '&:hover': {
                color: theme.palette.navoy.red,
                '--mui-palette-action-active': theme.palette.navoy.red
              }
            })}
          >
            <ListItemIcon sx={{ minWidth: 40 }}>
              <LoginIcon />
            </ListItemIcon>
            <ListItemText primary={'Login'} />
          </ListItemButton>
        </ListItem>
      </List>
    </Box>
  );
}


export default RequestDemoSidebar;