export const enterpriseForm = `
mutation AddEnterpriseForm($data: EnterpriseFormInput) {
  addEnterpriseForm(data: $data) {
    success
    message
    responseObject {
      message
      success
    }
    statusCode
  }
}
`