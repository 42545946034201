import { Stack, Typography } from "@mui/joy";
import { FlightSearchResult } from "../../../utils/types";
import TableFlight from "./tableFlight";
import { useParams } from "react-router-dom";
import { useAppSelector } from "../../../redux/hooks";
import { useGetFlightQuery } from "../../../redux/features/flights.api";
import { useEffect } from "react";


type BodyProps = {
  flightsData: FlightSearchResult | undefined
}

const Body: React.FC<BodyProps> = ({ flightsData }) => {
  const params = useParams();
  const requestId = params.requestId;
  const subscriptionId = params.subscriptionId;

  const user = useAppSelector((state) => state.auth.user);
  const { data, refetch, isFetching } = useGetFlightQuery({ id: requestId, subscription_id: subscriptionId, user: { id: user?.id } })
  
  useEffect(() => {
    if (!data && !isFetching) {
      refetch()
    }
  }, [data, isFetching, refetch])

  if (!flightsData?.data) return null
  return (
    <Stack spacing={4} p={{ xs: 2, md: 3 }} bgcolor={'#fff'} borderRadius={'12px'} boxShadow={'0px 10px 35px rgba(0, 0, 0, 0.03)'} >
      <Typography sx={theme => ({ color: theme.vars.palette.navoy.blue, fontSize: 20, fontFamily: 'Poppins', fontWeight: 500 })} >Suggested Flights</Typography>
      <TableFlight flightsData={flightsData} />
    </Stack >
  );
}


export default Body;