/**
 * Generates a color variation based on the input number and a gradient between two colors.
 *
 * @param {string} startColor - The starting color in hex format.
 * @param {string} endColor - The ending color in hex format.
 * @param {number} variation - The number to base the color variation on (1 to maxVariation). If set to 1, the start color is returned. If set to maxVariation, the end color is returned.
 * @param {number} maxVariation - The maximum value for the variation. Defaults to 10.
 *
 * @returns {string} - The color variation in hex format.
 */
export function getColorVariation(startColor: string, endColor: string, variation: number, maxVariation: number = 10): string {
  if (!startColor.startsWith('#') || !endColor.startsWith('#')) {
    throw new Error('Invalid color provided. Should be in hex format.');
  }

  if (variation < 1 || variation > maxVariation) {
    variation = Math.min(maxVariation, Math.max(1, variation)); // Clamp the variation value between 1 and maxVariation
  }

  const startColorHex = parseInt(startColor.replace('#', ''), 16); // Convert start color to hex number
  const endColorHex = parseInt(endColor.replace('#', ''), 16); // Convert end color to hex number

  // Extract RGB components from the start color
  const startR = (startColorHex >> 16) & 0xff;
  const startG = (startColorHex >> 8) & 0xff;
  const startB = startColorHex & 0xff;

  // Extract RGB components from the end color
  const endR = (endColorHex >> 16) & 0xff;
  const endG = (endColorHex >> 8) & 0xff;
  const endB = endColorHex & 0xff;

  // Calculate the interpolation factor
  const factor = (variation - 1) / (maxVariation - 1); // Variation is from 1 to maxVariation, so factor is from 0 to 1

  // Calculate the new RGB values by interpolating between the start and end colors
  const newR = Math.round(startR + factor * (endR - startR));
  const newG = Math.round(startG + factor * (endG - startG));
  const newB = Math.round(startB + factor * (endB - startB));

  // Combine the new RGB values into a single hex color
  const newColor = (newR << 16) | (newG << 8) | newB;

  // Convert the color to a hex string and return it
  return `#${newColor.toString(16).padStart(6, '0')}`;
}
