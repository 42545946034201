import { useCallback, useEffect, useRef, useState } from 'react';
import { createRoot } from 'react-dom/client';
// eslint-disable-next-line import/no-webpack-loader-syntax
import mapboxgl from '!mapbox-gl';
import DirectionsService from '@mapbox/mapbox-sdk/services/directions';
import polyline from '@mapbox/polyline';

import { mapBoxToken } from '../../../../utils/env';
import { toFixedFloat } from '../../../../utils/numbers';

import { getColorVariation } from '../../../../utils/colorUtils';
import { useMapActivitiesStore } from '../../../../zustand';
import Marker from './Marker';
import { MapActivity } from './types';

mapboxgl.accessToken = mapBoxToken || '';

const directionsService = DirectionsService({ accessToken: mapBoxToken || '' });

interface UseInitializeMapProps {
  defaultLng: number;
  defaultLat: number;
  defaultZoom: number;
  onMarkerClick: (e: React.MouseEvent<HTMLElement>, feature: MapActivity) => void;
}

/**
 * This hook initializes the map and adds markers and routes to the map.
 *
 * @param defaultLat - Default latitude of the map.
 * @param defaultLng - Default longitude of the map.
 * @param defaultZoom - Default zoom level of the map.
 * @param onMarkerClick - Callback to handle marker click.
 *
 */
const useInitializeMap = ({ defaultLng, defaultLat, defaultZoom, onMarkerClick }: UseInitializeMapProps) => {
  const mapRef = useRef<mapboxgl.Map | null>(null); // This will be set to the store to allow other components to access the map instance
  const mapContainerRef = useRef<HTMLDivElement | null>(null);
  const markersRef = useRef<mapboxgl.Marker[]>([]);
  const routesRef = useRef<string[]>([]);

  // ========================= State =========================
  const [mapLoaded, setMapLoaded] = useState(false);
  const [lng, setLng] = useState(defaultLng);
  const [lat, setLat] = useState(defaultLat);
  const [zoom, setZoom] = useState(defaultZoom);

  const { mapActivities, setMapRef } = useMapActivitiesStore();

  // ========================= Handlers =========================
  /**
   * Called on `load` event of the map. Adds markers and routes to the map.
   */
  const onLoad = useCallback(async () => {
    console.debug('onLoad: mapActivities -', mapActivities);
    if (!mapLoaded) setMapLoaded(true);

    // Remove existing markers
    markersRef.current.forEach((marker) => marker.remove());
    markersRef.current = [];

    // Remove existing routes
    routesRef.current.forEach((routeId) => {
      if (mapRef.current?.getSource(routeId)) {
        mapRef.current?.removeLayer(routeId);
        mapRef.current?.removeSource(routeId);
      }
    });
    routesRef.current = [];

    // Group activities by day_id
    const activitiesByDay: { [key: string]: MapActivity[] } = {};
    mapActivities.forEach((activity) => {
      if (!activitiesByDay[activity.dayId]) {
        activitiesByDay[activity.dayId] = [];
      }
      activitiesByDay[activity.dayId].push(activity);
    });

    const fullBounds = new mapboxgl.LngLatBounds();
    const daysNum = Object.keys(activitiesByDay).length;

    // Iterate over each group of activities
    for (const dayId in activitiesByDay) {
      const activities = activitiesByDay[dayId];
      const coordinates: [number, number][] = [];

      // Add markers for each activity in the group
      activities
        .filter((activity) => activity.visible)
        .forEach((activity) => {
          const markerDiv = document.createElement('div');
          createRoot(markerDiv).render(<Marker onClick={onMarkerClick} feature={activity} />);
          const marker = new mapboxgl.Marker(markerDiv)
            .setLngLat([activity.longitude, activity.latitude])
            .addTo(mapRef.current as mapboxgl.Map);

          markersRef.current.push(marker);
          coordinates.push([activity.longitude, activity.latitude]);
          fullBounds.extend([activity.longitude, activity.latitude]);
        });

      // Add route between activities in the group
      if (coordinates.length > 1) {
        const waypoints = coordinates.map((coord) => ({
          coordinates: coord,
        }));

        try {
          const response = await directionsService
            .getDirections({
              profile: 'walking',
              waypoints: waypoints,
            })
            .send();

          const data = response.body.routes[0];
          const route = polyline.decode(data.geometry);

          const routeId = `route-${dayId}`;
          routesRef.current.push(routeId);

          if (mapRef.current?.getSource(routeId)) {
            mapRef.current?.removeLayer(routeId);
            mapRef.current?.removeSource(routeId);
          }

          mapRef.current?.addSource(routeId, {
            type: 'geojson',
            data: {
              type: 'Feature',
              properties: {},
              geometry: {
                type: 'LineString',
                coordinates: route.map((coord) => [coord[1], coord[0]]), // Ensure coordinates are in [lng, lat] format
              },
            },
          });

          mapRef.current?.addLayer({
            id: routeId,
            type: 'line',
            source: routeId,
            layout: {
              'line-join': 'round',
              'line-cap': 'round',
            },
            paint: {
              'line-color': getColorVariation('#63bdb5', '#004D40', parseInt(dayId, 10), daysNum),
              'line-width': 6,
            },
          });
        } catch (error) {
          console.error('Error fetching directions:', error);
        }
      }
    }

    // Fit the map to the bounds of the markers
    if (fullBounds.getNorthEast()?.lng && fullBounds.getNorthEast()?.lat) {
      console.debug('onLoad: fitBounds -', fullBounds);
      mapRef.current?.fitBounds(fullBounds, { padding: 50, speed: 1 });
    }
  }, [mapRef, mapActivities, mapLoaded, onMarkerClick]);

  /**
   * Called on `move` event of the map. Updates the state with the current map center and zoom level.
   */
  const onMove = useCallback(() => {
    if (!mapRef.current) return;
    const center = mapRef.current.getCenter();
    setLng(toFixedFloat(center.lng, 4));
    setLat(toFixedFloat(center.lat, 4));
    setZoom(toFixedFloat(mapRef.current.getZoom(), 2));
  }, [mapRef]);

  /**
   * Creates a new map instance and initializes it.
   */
  const initializeMap = useCallback(async () => {
    if (!mapContainerRef.current) return;
    if (mapRef.current) return; // Prevent reinitialization if map already exists

    mapRef.current = new mapboxgl.Map({
      container: mapContainerRef.current,
      style: 'mapbox://styles/mapbox/streets-v12',
      center: [lng, lat],
      zoom: zoom,
    });

    mapRef.current.on('load', onLoad); // Needed for the first load, even if there are no activities
    mapRef.current.on('move', onMove);

    // Set the map ref using setMapRef to allow other components to access the map instance
    setMapRef(mapRef.current);
  }, [lng, lat, zoom, onLoad, onMove, setMapRef]);

  // ========================= Effects =========================
  useEffect(() => {
    initializeMap();
  }, [initializeMap]);

  /**
   * Side effect to reload the map activities when they change.
   *
   * Be extremely careful with this effect, as it can cause very undesirable
   * re-renders. For example, one use case happened when the `onMarkerClick`
   * was not memoized, and it caused `onLoad` function to be re-created whenever
   * the button was clicked and hence breaking the zoom functionality.
   *
   * The solution was to put the `useCallback` hook around the `onMarkerClick`
   * and on all other functions that relied on the `onMarkerClick` function.
   * This ensured that the `onMarkerClick` function was memoized and did not
   * change on re-renders.
   *
   * NOTE: If any issues persist with the map, then you can debug this effect
   * by using the `useEffectDebugger` hook, which will log exactly what
   * dependencies are causing the effect to run.
   */
  useEffect(
    // useEffectDebugger(
    () => {
      console.debug('useInitializeMap: mapActivities -', mapActivities);

      // Only update the map if it has been loaded, and the activities have changed
      if (mapLoaded) {
        onLoad();
      }

      // return () => {
      //   if (mapRef.current) {
      //     mapRef.current.remove();
      //     mapRef.current = null;
      //   }
      // };
    },
    [mapLoaded, mapActivities, onLoad]
    // ['mapLoaded', 'mapActivities', 'onLoad']
  );

  return { mapContainerRef, mapLoaded, lng, lat, zoom };
};

export default useInitializeMap;
