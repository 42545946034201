import { yupResolver } from "@hookform/resolvers/yup";
import LockIcon from "@mui/icons-material/Lock";
import VisibilityIcon from "@mui/icons-material/Visibility";
import VisibilityOffIcon from "@mui/icons-material/VisibilityOff";
import { Box, Button, FormControl, FormHelperText, FormLabel, Input, Typography } from "@mui/joy";
import { useState } from "react";
import { Controller, useForm } from "react-hook-form";
import { Link, useNavigate, useParams } from "react-router-dom";
import { toast } from "react-toastify";
import * as yup from "yup";
import { useResetPasswordMutation } from "../../redux/features/authSlice";
import Logo from "./components/logo";

const validationSchema = yup.object().shape({
  password: yup.string().required("Password is required"),
});

type ResetPasswordProps = {};

type Form = { password: string };

const ResetPassword: React.FC<ResetPasswordProps> = () => {
  const [resetPassword, { isLoading }] = useResetPasswordMutation();
  const { handleSubmit, control, formState: { errors }, setError } = useForm({
    resolver: yupResolver(validationSchema),
    defaultValues: { password: "" },
  });

  const [visible, setVisible] = useState(true);
  const navigate = useNavigate();
  const { token } = useParams();

  const onSubmit = async (data: Form) => {
    try {
      const result = await resetPassword({ newPassword: data.password, token }).unwrap();
      if (result.success) {
        if (result.responseObject.error) {
          toast.warn(result.responseObject.message)
          setError('password', { type: "manual", message: result.responseObject.message })
        } else {
          toast.info(result.responseObject.message)
          navigate('/auth/login');
        }
      } else {
        setError('password', { type: "manual", message: result.responseObject.message })
        toast.error(result.responseObject.message)
      }
    } catch (error) {
      toast.error("An error occurred. Please try again.");
    }
  };

  return (
    <Box className="flex flex-col flex-1 justify-center items-center gap-10">
      <Logo />
      <Box className="min-w-[70%] flex flex-col gap-4 w-full md:w-auto">
        <form onSubmit={handleSubmit(onSubmit)} className="flex flex-col gap-3">
          <FormControl>
            <FormLabel
              sx={(theme) => ({
                "--FormLabel-color": theme.vars.palette.neutral[700],
              })}
            >
              New Password
            </FormLabel>
            <Controller
              name="password"
              control={control}
              rules={{ required: true }}
              render={({ field }) => (
                <Input
                  {...field}
                  sx={(theme) => ({
                    fontSize: "0.75rem",
                    "--Input-minHeight": "3rem",
                    "&:focus-within:before": {
                      "--Input-focused": 0,
                    },
                    background: "#fff",
                  })}
                  placeholder="Enter new password"
                  type={visible ? "password" : "text"}
                  error={!!errors.password?.message}
                  size="sm"
                  startDecorator={
                    <LockIcon
                      sx={(theme) => ({ color: theme.palette.navoy.blue })}
                      fontSize="small"
                    />
                  }
                  endDecorator={
                    !visible ? (
                      <VisibilityIcon
                        sx={(theme) => ({ color: "#999" })}
                        fontSize="small"
                        onClick={() => setVisible((prev) => !prev)}
                      />
                    ) : (
                      <VisibilityOffIcon
                        sx={(theme) => ({ color: "#999" })}
                        fontSize="small"
                        onClick={() => setVisible((prev) => !prev)}
                      />
                    )
                  }
                />
              )}
            />
            {!!errors.password?.message && (
              <FormHelperText
                sx={(theme) => ({
                  color: theme.vars.palette.danger[400],
                  fontSize: "xs",
                })}
              >
                {errors.password?.message}
              </FormHelperText>
            )}
          </FormControl>
          <Box className="flex justify-end">
            <Link to={"/auth/forgot-password"} className="text-sm text-navoy-blue font-medium">
              Forgot Password!
            </Link>
          </Box>
          <Box className="mt-4">
            <Button
              fullWidth
              sx={(theme) => ({
                background: theme.vars.palette.navoy.blue,
                "&:hover": { background: theme.vars.palette.navoy.blue },
                paddingY: 2,
              })}
              type="submit"
              loading={isLoading}
            >
              Reset Password
            </Button>
          </Box>
        </form>
        <Typography className="text-center pt-[3px]" fontSize="sm">
          Remember your password?{" "}
          <Link to="/auth/login" className="text-navoy-blue font-bold">
            Login
          </Link>
        </Typography>
      </Box>
    </Box>
  );
};

export default ResetPassword;
