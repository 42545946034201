import { yupResolver } from "@hookform/resolvers/yup";
import { Button, Card, CardContent, FormControl, FormHelperText, FormLabel, Grid, Input, LinearProgress } from "@mui/joy";
import { Controller, useForm } from "react-hook-form";
import { useGetSocialMediaQuery, useUpdateSocialMediaMutation } from "../../redux/features/authSlice";
import { useAppSelector } from "../../redux/hooks";
import { validationSchemaCardSocialMedia } from "../../utils/formYupValidation";
import { useEffect } from "react";

type CardSocialMediaProps = {}

const CardSocialMedia: React.FC<CardSocialMediaProps> = () => {
  const user = useAppSelector(state => state.auth.user)
  const [updateSocialMedia, { isLoading: updateSocialMediaIsLoading }] = useUpdateSocialMediaMutation()
  const { data: dataSocialMedia, isFetching } = useGetSocialMediaQuery({ id: user?.id })

  const { handleSubmit, control, formState: { errors }, setValue } = useForm({
    resolver: yupResolver(validationSchemaCardSocialMedia),
    defaultValues: {
      x: '',
      instagram: '',
      linkedin: '',
      pinterest: '',
      tiktok: ''
    },
  });

  useEffect(() => {
    if (dataSocialMedia?.responseObject) {
      setValue('x', dataSocialMedia?.responseObject?.x)
      setValue('instagram', dataSocialMedia?.responseObject?.instagram)
      setValue('linkedin', dataSocialMedia?.responseObject?.linkedin)
      setValue('pinterest', dataSocialMedia?.responseObject?.pinterest)
      setValue('tiktok', dataSocialMedia?.responseObject?.tiktok)
    }
  }, [dataSocialMedia, setValue])

  const onSubmit = async (data: any) => {
    
    updateSocialMedia({ ...data, id: user?.id })
  };
  const TikTokIcon = ({ color = "#000000" }) => {
    return (
      <svg
        fill={color}
        xmlns="http://www.w3.org/2000/svg"
        viewBox="0 0 50 50"
        width="20px"
        height="20px"
      >
        <path d="M41,4H9C6.243,4,4,6.243,4,9v32c0,2.757,2.243,5,5,5h32c2.757,0,5-2.243,5-5V9C46,6.243,43.757,4,41,4z M37.006,22.323 c-0.227,0.021-0.457,0.035-0.69,0.035c-2.623,0-4.928-1.349-6.269-3.388c0,5.349,0,11.435,0,11.537c0,4.709-3.818,8.527-8.527,8.527 s-8.527-3.818-8.527-8.527s3.818-8.527,8.527-8.527c0.178,0,0.352,0.016,0.527,0.027v4.202c-0.175-0.021-0.347-0.053-0.527-0.053 c-2.404,0-4.352,1.948-4.352,4.352s1.948,4.352,4.352,4.352s4.527-1.894,4.527-4.298c0-0.095,0.042-19.594,0.042-19.594h4.016 c0.378,3.591,3.277,6.425,6.901,6.685V22.323z" />
      </svg>
    );
  };

  return (
    <Card variant="outlined" size="lg" className="me-auto w-full" >
      <CardContent >
        <form onSubmit={handleSubmit(onSubmit)} className="flex flex-col gap-4">
          <Grid container spacing={2} sx={{ flexGrow: 1 }}>
            {isFetching && <Grid xs={12}> <LinearProgress /></Grid>}
            <Grid md={6} xs={12}>
              <FormControl>
                <FormLabel
                  sx={(theme) => ({
                    "--FormLabel-color": theme.vars.palette.neutral[700],
                  })}
                >
                  X
                </FormLabel>
                <Controller
                  name="x"
                  control={control}
                  rules={{ required: true }}
                  render={({ field }) => (
                    <Input
                      {...field}
                      sx={(theme) => ({
                        fontSize: " 0.75rem",
                        "--Input-minHeight": "3rem",
                        "&:focus-within:before": {
                          "--Input-focused": 0,
                        },

                      })}
                      placeholder="username"
                      type={"text"}
                      error={!!errors.x?.message}
                      size="sm"
                      startDecorator={
                        <div >
                          {/* <XIcon
                            sx={(theme) => ({ color: theme.palette.navoy.blue })}
                            fontSize="small"
                          /> */}
                          https://x.com/
                        </div>

                      }
                    />
                  )}
                />
                {!!errors.x?.message && (
                  <FormHelperText
                    sx={(theme) => ({
                      color: theme.vars.palette.danger[400],
                      fontSize: "xs",
                    })}
                  >
                    {errors.x?.message}
                  </FormHelperText>
                )}
              </FormControl>
            </Grid>
            <Grid md={6} xs={12}>
              <FormControl>
                <FormLabel
                  sx={(theme) => ({
                    "--FormLabel-color": theme.vars.palette.neutral[700],
                  })}
                >
                  Pinterest
                </FormLabel>
                <Controller
                  name="pinterest"
                  control={control}
                  rules={{ required: true }}
                  render={({ field }) => (
                    <Input
                      {...field}
                      sx={(theme) => ({
                        fontSize: " 0.75rem",
                        "--Input-minHeight": "3rem",
                        "&:focus-within:before": {
                          "--Input-focused": 0,
                        },

                      })}
                      placeholder="username"
                      type={"text"}
                      error={!!errors.pinterest?.message}
                      size="sm"
                      startDecorator={
                        // <PinterestIcon
                        //   sx={(theme) => ({ color: theme.palette.navoy.blue })}
                        //   fontSize="small"
                        // />
                        <div>https://www.pinterest.com/</div>
                      }
                    />
                  )}
                />
                {!!errors.pinterest?.message && (
                  <FormHelperText
                    sx={(theme) => ({
                      color: theme.vars.palette.danger[400],
                      fontSize: "xs",
                    })}
                  >
                    {errors.pinterest?.message}
                  </FormHelperText>
                )}
              </FormControl>
            </Grid>
            <Grid md={6} xs={12}>
              <FormControl>
                <FormLabel
                  sx={(theme) => ({
                    "--FormLabel-color": theme.vars.palette.neutral[700],
                  })}
                >
                  LinkedIn
                </FormLabel>
                <Controller
                  name="linkedin"
                  control={control}
                  rules={{ required: true }}
                  render={({ field }) => (
                    <Input
                      {...field}
                      sx={(theme) => ({
                        fontSize: " 0.75rem",
                        "--Input-minHeight": "3rem",
                        "&:focus-within:before": {
                          "--Input-focused": 0,
                        },

                      })}
                      placeholder="username"
                      type={"text"}
                      error={!!errors.linkedin?.message}
                      size="sm"
                      startDecorator={
                        // <LinkedInIcon
                        //   sx={(theme) => ({ color: theme.palette.navoy.blue })}
                        //   fontSize="small"
                        // />
                        <div>https://www.linkedin.com/in/</div>
                      }
                    />
                  )}
                />
                {!!errors.linkedin?.message && (
                  <FormHelperText
                    sx={(theme) => ({
                      color: theme.vars.palette.danger[400],
                      fontSize: "xs",
                    })}
                  >
                    {errors.linkedin?.message}
                  </FormHelperText>
                )}
              </FormControl>
            </Grid>
            <Grid md={6} xs={12}>
              <FormControl>
                <FormLabel
                  sx={(theme) => ({
                    "--FormLabel-color": theme.vars.palette.neutral[700],
                  })}
                >
                  Tiktok
                </FormLabel>
                <Controller
                  name="tiktok"
                  control={control}
                  rules={{ required: true }}
                  render={({ field }) => (
                    <Input
                      {...field}
                      sx={(theme) => ({
                        fontSize: " 0.75rem",
                        "--Input-minHeight": "3rem",
                        "&:focus-within:before": {
                          "--Input-focused": 0,
                        },

                      })}
                      placeholder="username"
                      type={"text"}
                      error={!!errors.tiktok?.message}
                      size="sm"
                      startDecorator={
                        // <TikTokIcon />
                        <div>https://www.tiktok.com/@</div>
                      }
                    />
                  )}
                />
                {!!errors.tiktok?.message && (
                  <FormHelperText
                    sx={(theme) => ({
                      color: theme.vars.palette.danger[400],
                      fontSize: "xs",
                    })}
                  >
                    {errors.tiktok?.message}
                  </FormHelperText>
                )}
              </FormControl>
            </Grid>
            <Grid md={6} xs={12}>
              <FormControl>
                <FormLabel
                  sx={(theme) => ({
                    "--FormLabel-color": theme.vars.palette.neutral[700],
                  })}
                >
                  Instagram
                </FormLabel>
                <Controller
                  name="instagram"
                  control={control}
                  rules={{ required: true }}
                  render={({ field }) => (
                    <Input
                      {...field}
                      sx={(theme) => ({
                        fontSize: " 0.75rem",
                        "--Input-minHeight": "3rem",
                        "&:focus-within:before": {
                          "--Input-focused": 0,
                        },

                      })}
                      placeholder="username"
                      type={"text"}
                      error={!!errors.instagram?.message}
                      size="sm"
                      startDecorator={
                        // <InstagramIcon
                        //   sx={(theme) => ({ color: theme.palette.navoy.blue })}
                        //   fontSize="small"
                        // />
                        <div>https://www.instagram.com/</div>
                      }
                    />
                  )}
                />
                {!!errors.instagram?.message && (
                  <FormHelperText
                    sx={(theme) => ({
                      color: theme.vars.palette.danger[400],
                      fontSize: "xs",
                    })}
                  >
                    {errors.instagram?.message}
                  </FormHelperText>
                )}
              </FormControl>
            </Grid>
          </Grid>
          <div>
            <Button type="submit" loading={updateSocialMediaIsLoading} disabled={updateSocialMediaIsLoading} >Submit</Button>
          </div>
        </form>
      </CardContent>
    </Card>
  );
}


export default CardSocialMedia;