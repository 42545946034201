import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import { Box, Grid, LinearProgress, Typography } from '@mui/joy';
import { Button, Paper } from '@mui/material';
import React from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import { useGetBookingsDetailsQuery } from '../../../../../redux/features/nuiteeHotel.api';
import CardBook from './cardBook';

const Bookings: React.FC = () => {
  const navigate = useNavigate();
  const { requestId, subscriptionId } = useParams();
  const { data: bookings, isLoading, refetch } = useGetBookingsDetailsQuery(subscriptionId);


  return (
    <Box>
      <Box sx={{ mb: 2 }}>
        <Button
          startIcon={<ArrowBackIcon />}
          onClick={() => navigate(`/dashboard/travel/${requestId}/${subscriptionId}/hotel`)}
          sx={{
            textTransform: 'none',
            color: 'text.secondary'
          }}
        >
          Back to Hotels
        </Button>
      </Box>

      {isLoading && <LinearProgress />}


      <Typography level="h3" sx={{ mb: 3 }}>My Hotel Bookings</Typography>

      <Grid container spacing={3}>
        {bookings?.responseObject?.data?.map((booking) => (
          <CardBook key={booking.bookingId} booking={booking} refetch={refetch} />
        )).reverse()}
      </Grid>
    </Box>
  );
};

export default Bookings;
