import { create } from 'zustand';
import { MapActivity } from '../pages/travelTabs/itinerary/map/types';
// eslint-disable-next-line import/no-webpack-loader-syntax
import mapboxgl from '!mapbox-gl';

type MapActivitiesState = {
  /**
   * Reference to the map instance. See https://github.com/pmndrs/zustand/discussions/1140#discussioncomment-3237306 for
   * a ref in the store.
   */
  mapRef: React.MutableRefObject<mapboxgl.Map | null> | null;
  /**
   * List of activities to be shown on the map.
   */
  mapActivities: MapActivity[];
  /**
   * Update the activities store with the given activities.
   * @param activities - The activities to be stored.
   */
  setMapActivities: (activities: MapActivity[]) => void;
  /**
   * Toggles the visibility of the activities with the given dayId.
   * @param dayId - The unique identifier of the day.
   */
  toggleVisibility: (dayId: string) => void;
  /**
   * Navigates to the location of the activities with the given dayId.
   * @param dayId - The unique identifier of the day.
   */
  locateOnMap: (dayId: string) => void;
  /**
   * Sets the map reference.
   * @param ref - The map reference to be set.
   */
  setMapRef: (ref: mapboxgl.Map | null) => void;
};

/**
 * Store for map activities. We use this store to manage the activities shown on the map.
 */
const useMapActivitiesStore = create<MapActivitiesState>((set, get) => ({
  mapRef: null,
  mapActivities: [],
  setMapActivities: (activities) => set({ mapActivities: activities }),
  toggleVisibility: (dayId) =>
    set((state) => ({
      mapActivities: state.mapActivities.map((activity) => {
        if (activity.dayId === dayId) {
          return { ...activity, visible: !activity.visible };
        }
        return activity;
      }),
    })),
  locateOnMap: (dayId) => {
    const state = get();
    if (!state.mapRef) return;
    const fullBounds = new mapboxgl.LngLatBounds();
    state.mapActivities.forEach((activity) => {
      if (activity.dayId === dayId) {
        fullBounds.extend([activity.longitude, activity.latitude]);
      }
      return activity;
    });
    state.mapRef.current?.fitBounds(fullBounds, { padding: 100 });
  },
  setMapRef: (ref) => set(() => ({ mapRef: { current: ref } })),
}));

export default useMapActivitiesStore;
