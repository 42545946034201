import Paper from "@mui/material/Paper";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TablePagination from "@mui/material/TablePagination";
import TableRow from "@mui/material/TableRow";
import * as React from "react";
import { useGetSearchQuery } from "../../../redux/features/adminSlice";
import { SearchSchema } from "../../../utils/types";
import { formatDate, formatDateTime } from "../../../utils/functions";
import { Link } from "react-router-dom";

interface Column {
  id: "id" | "email" | "source" | "target" | "startDate" | "endDate" | "mode" | "budget" | "address" | "userId" | "requestId" | 'date' | 'countryInfo';
  label: string;
  minWidth?: number;
  align?: "right" | "left" | "center";
  link?: (requestId: string) => JSX.Element;
}

const columns: readonly Column[] = [
  { id: "id", label: "id", minWidth: 70 },
  { id: "email", label: "Email", minWidth: 100 },
  {
    id: "date",
    label: "Date",
    minWidth: 170,
    align: "left",
  },
  {
    id: "address",
    label: "Location by navigator",
    minWidth: 170,
    align: "left",
  },
  {
    id: "countryInfo",
    label: "Location by ip",
    minWidth: 170,
    align: "left",
  },
  {
    id: "source",
    label: "Source",
    minWidth: 170,
    align: "left",
  },
  {
    id: "target",
    label: "Target",
    minWidth: 170,
    align: "left",
  },
  {
    id: "startDate",
    label: "Start Date",
    minWidth: 120,
    align: "left",
  },
  {
    id: "endDate",
    label: "End Date",
    minWidth: 120,
    align: "left",
  },

  {
    id: "budget",
    label: "Budget",
    minWidth: 100,
    align: "left",
  },
  {
    id: "mode",
    label: "Mode",
    minWidth: 120,
    align: "left",
  },

  {
    id: "requestId",
    label: "RequestId",
    minWidth: 120,
    align: "left",
  },
  {
    id: "userId",
    label: "UserId",
    minWidth: 300,
    align: "left",
  }
];

const createData = (
  id: string,
  email: string,
  source: string,
  target: string,
  startDate: string,
  endDate: string,
  date: string,
  mode: string,
  budget: number,
  address: any,
  userId: string,
  requestId: string,
  countryInfo: any

): SearchSchema => {
  return {
    id,
    email,
    source,
    target,
    startDate,
    endDate,
    date,
    mode,
    budget,
    address,
    userId,
    requestId,
    countryInfo
  };
}

export default function FeedbackTable() {
  const [page, setPage] = React.useState(0);
  const [rowsPerPage, setRowsPerPage] = React.useState(10);
  const [rows, setRows] = React.useState<SearchSchema[]>([]);


  const { data, isLoading, isSuccess, isFetching, isError, error } = useGetSearchQuery({});
  const handleChangePage = (event: unknown, newPage: number) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (
    event: React.ChangeEvent<HTMLInputElement>
  ) => {
    setRowsPerPage(+event.target.value);
    setPage(0);
  };

  React.useEffect(() => {
    if (data?.success) {

      const rows = data.responseObject?.map((item: any, index: number) =>
        createData(
          (index + 1).toString(),
          item?.email,
          item?.source,
          item?.target,
          formatDate(item?.startDate),
          formatDate(item?.endDate),
          formatDateTime(item?.date),
          item?.mode,
          item?.budget,
          item?.address,
          item?.userId,
          item?.requestId,
          item?.countryInfo
        )
      );
      setRows(rows);
    }

  }, [data, isLoading, isFetching, isSuccess, isError, error]);

  return (
    <Paper sx={{ width: "100%", overflow: "hidden" }}>
      <TableContainer sx={{ maxHeight: 440 }}>
        <Table stickyHeader aria-label="sticky table">
          <TableHead>
            <TableRow>
              {columns.map((column) => (
                <TableCell
                  key={column.id}
                  align={column.align}
                  style={{ minWidth: column.minWidth }}
                >
                  {column.label}
                </TableCell>
              ))}
            </TableRow>
          </TableHead>
          <TableBody>
            {rows
              ?.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
              ?.map((row: SearchSchema) => {
                return (
                  <TableRow
                    hover
                    role="checkbox"
                    tabIndex={-1}
                    key={row.id as React.Key}
                  >
                    {columns.map((column) => {
                      const value = row[column.id];
                      return (
                        <TableCell key={column.id} align={column.align}>
                          {
                            column.id === 'requestId' && value ? <Link to={`/travel/flight/${value}`}>Show result</Link> :
                              column.id === 'address' ? `${value?.city || ''}, ${value?.state || ''}, ${value?.country || ''}` :
                                column.id === 'countryInfo' ? `${value?.name || ''}, ${value?.country?.city || ''}` :
                                  value
                          }
                        </TableCell>
                      );
                    })}
                  </TableRow>
                );
              })}
          </TableBody>
        </Table>
      </TableContainer>
      <TablePagination
        rowsPerPageOptions={[10, 25, 100]}
        component="div"
        count={rows.length}
        rowsPerPage={rowsPerPage}
        page={page}
        onPageChange={handleChangePage}
        onRowsPerPageChange={handleChangeRowsPerPage}
      />
    </Paper>
  );
}
