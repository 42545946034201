export const getUserSubscriptionData = `
query GetUserSubscriptionData($data: DataGenerateId) {
  getUserSubscriptionData(data: $data) {
    success
    message
    responseObject {
      ... on GetUserSubscriptionData {
        current_period_end
        current_period_start
        created
        amount
        brand
        last4
        status
        canceled_at
      }
      ... on AuthError {
        error
        message
      }
    }
    statusCode
  }
}
`

export const cancelUserSubscription = `
mutation CancelUserSubscription($data: cancelUserSubscriptionInput) {
  cancelUserSubscription(data: $data) {
    success
    message
    responseObject {
      ... on AuthError {
        error
        message
      }
    }
    statusCode
  }
}
`

export const updateTerms = `
mutation UpdateUserTerms($data: updateUserTermsInput) {
  updateUserTerms(data: $data) {
    success
    message
    responseObject {
      ... on AuthError {
        error
        message
      }
    }
    statusCode
  }
}
`

export const getEnterpriseLink = `
query GetEnterpriseLink($link: String) {
  getEnterpriseLink(link: $link) {
    success
    message
    responseObject {
      ... on EnterpriseLinkData {
        email
        id
        agencyName
      }
      ... on AuthError {
        error
        message
      }
    }
    statusCode
  }
}
`