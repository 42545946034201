import "react-datepicker/dist/react-datepicker.css";

import DatePicker from "react-datepicker";
import { FormHelperText, FormLabel } from "@mui/material";
import { FormControl } from "@mui/joy";

import DateRangeStyle from "./dateRange.styles";
import { useCallback, useEffect, useState } from "react";

interface DateProps {
  field: any;
  setValue: (range: [Date | null, Date | null]) => void;
  matches: boolean;
  errors: any;
  label: string;
  id?: string;
  minDate?: Date;
  maxDate?: Date;
}

const DateRange: React.FC<DateProps> = ({ field, matches, setValue, errors, label, id, minDate, maxDate }) => {
  const [inputValue, setInputValue] = useState("");

  /**
   * Handle raw input change to validate and format the date range. This makes
   * it possible to input a date range directly as a string.
   * @param event
   */
  const handleChangeRaw = (event: React.FocusEvent<HTMLInputElement>) => {
    setInputValue(event.currentTarget.value);
  };

  /**
   * Debounced validation and state update for raw input.
   */
  const validateAndSetDate = useCallback(() => {
    if (!inputValue) return;

    const dateParts = inputValue.split(" - ");

    if (dateParts.length === 2) {
      const startDate = new Date(dateParts[0]);
      const endDate = new Date(dateParts[1]);

      if (
        startDate instanceof Date &&
        !isNaN(startDate.getTime()) &&
        endDate instanceof Date &&
        !isNaN(endDate.getTime()) &&
        (!minDate || startDate >= minDate) &&
        (!maxDate || endDate <= maxDate)
      ) {
        setValue([startDate, endDate] as [Date | null, Date | null]);
        field.onChange([startDate, endDate]);
      }
    }
  }, [inputValue, minDate, maxDate, setValue, field]);

  useEffect(() => {
    const handler = setTimeout(() => {
      validateAndSetDate();
    }, 500);

    return () => {
      clearTimeout(handler);
    };
  }, [inputValue, validateAndSetDate]);

  return (
    <FormControl className="gap-2">
      {/* <Typography id="rank" level="body-sm" fontWeight="lg" sx={{ mb: 1.5 }}>
        {label}
      </Typography> */}
      <FormLabel htmlFor={label}>{label}</FormLabel>
      <DateRangeStyle>
        <DatePicker
          placeholderText="yyyy-MM-dd - yyyy-MM-dd"
          id={id}
          // className="border-none w-full py-1 pr-2 pl-0 text-gray-600 focus:outline-none"
          className="MuiOutlinedInput-input"
          minDate={minDate}
          selected={field.value?.[0]}
          onChange={(range) => {
            setValue(range)
            field.onChange(range)
          }}
          onChangeRaw={handleChangeRaw}
          startDate={field.value?.[0]}
          endDate={field.value?.[1]}
          selectsRange
          withPortal={matches}
          maxDate={maxDate}
          dateFormat="yyyy-MM-dd"
          disabledKeyboardNavigation
        />
      </DateRangeStyle>
      {errors.length > 0 && <FormHelperText error={Array.isArray(errors) && errors.length > 0 ? true : false}>{Array.isArray(errors) && errors.length > 0 && errors?.[0]?.message && 'Start date required'}</FormHelperText>}
      {errors.length > 1 && <FormHelperText error={Array.isArray(errors) && errors.length > 1 ? true : false}>{Array.isArray(errors) && errors.length > 1 && errors?.[1]?.message && 'End date required'}</FormHelperText>}
    </FormControl>
  );
}

export default DateRange;
