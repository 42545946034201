import { configureStore } from '@reduxjs/toolkit';

// Define your reducers here (replace with your actual reducers)
import { authReducer, messageReducer, notificationReducer } from '../features';
import { apiSlice } from '../features/apiSlice';
import { setupListeners } from '@reduxjs/toolkit/query'

const store = configureStore({
  reducer: {
    auth: authReducer,
    message: messageReducer,
    notification: notificationReducer,
    [apiSlice.reducerPath]: apiSlice.reducer
  },
  middleware: getDefaultMiddleware => getDefaultMiddleware().concat(apiSlice.middleware)
});

export default store

export type AppDispatch = typeof store.dispatch
export type RootState = ReturnType<typeof store.getState>

setupListeners(store.dispatch)