import { yupResolver } from '@hookform/resolvers/yup';
import { Container, FormHelperText, Textarea } from '@mui/joy';
import Autocomplete from '@mui/joy/Autocomplete';
import Button from '@mui/joy/Button';
import Card from '@mui/joy/Card';
import CardActions from '@mui/joy/CardActions';
import CardContent from '@mui/joy/CardContent';
import FormControl from '@mui/joy/FormControl';
import FormLabel from '@mui/joy/FormLabel';
import Input from '@mui/joy/Input';
import Typography from '@mui/joy/Typography';
import * as React from 'react';
import { Controller, useForm } from "react-hook-form";
import { toast } from 'react-toastify';
import { v4 as uuidv4 } from 'uuid';
import { useAddRequestDemoMutation } from '../../redux/features/requestDemo.api';
import { validationSchemaRequestDemo } from '../../utils/formYupValidation';
import { Country } from '../../utils/types';
import countriesData from './countries_cities.json';

interface FormData {
  firstName: string;
  lastName: string;
  email: string;
  country: string;
  city: string;
  additionalComments?: string;
}

const RequestDemo: React.FC = () => {
  const userId = localStorage.getItem("userId") || uuidv4();
  localStorage.setItem("userId", userId);
  const { handleSubmit, control, formState: { errors }, setError } = useForm<FormData>({
    resolver: yupResolver(validationSchemaRequestDemo),
    defaultValues: {
      firstName: '',
      lastName: '',
      email: '',
      country: '',
      city: '',
      additionalComments: ''
    },
  });
  const [createRequestDemo, { isLoading }] = useAddRequestDemoMutation();

  const [countries, setCountries] = React.useState<{ name: string, id: number }[]>([]);
  const [filteredCities, setFilteredCities] = React.useState<{ name: string, id: number }[]>([]);

  const handleCountryChange = (value: string) => {
    const countriesDatas = countriesData as Country[];
    const country: Country | undefined = countriesDatas.find(data => data.name === value)
    if (country) {
      const cities = country.cities
      const citiesName = cities.map(city => ({ name: city.name, id: city.id }))
        .filter((city, index, self) => self.findIndex(c => c.name === city.name) === index);
      setFilteredCities(citiesName);
    } else setFilteredCities([]);
  };

  React.useEffect(() => {
    const countriesDatas = countriesData as Country[];
    const countryList = countriesDatas.map(countries => ({ name: countries.name, id: countries.id }));
    setCountries(countryList);
  }, []);

  const onSubmit = async (data: FormData) => {
    console.log('data: ', data);
    const result = await createRequestDemo({ ...data }).unwrap();
    if (result?.success) {
      if (!result?.responseObject.success) {
        toast.warn(result?.responseObject?.message)
        if (result?.responseObject.message?.includes('email'))
          setError('email', { type: "manual", message: result?.responseObject.message })
      } else {
        toast.info(result?.responseObject?.message)
      }
    } else {
      toast.error(result?.responseObject?.message)
    }
  };

  return (
    <Container maxWidth="lg" className="min-h-screen   pt-36 laptop:pt-40 pb-8  relative">
      <Card
        variant="outlined"
        sx={{
          maxHeight: 'max-content',
          maxWidth: '100%',
          mx: 'auto',
        }}
      >
        <Typography level="h4" component="h1" sx={{ mb: 2 }}>
          Request a Demo
        </Typography>
        <form onSubmit={handleSubmit(onSubmit)}>
          <CardContent
            sx={{
              display: 'grid',
              gridTemplateColumns: 'repeat(2, minmax(80px, 1fr))',
              gap: 1.5,
            }}
          >
            <FormControl>
              <FormLabel>First Name</FormLabel>
              <Controller
                name="firstName"
                control={control}
                rules={{ required: true }}
                render={({ field }) => (
                  <Input placeholder='First name' {...field} />
                )}
              />
              {!!errors.firstName?.message && (
                <FormHelperText
                  sx={(theme) => ({
                    color: theme.vars.palette.danger[400],
                    fontSize: "xs",
                  })}
                >
                  {errors.firstName?.message}
                </FormHelperText>
              )}
            </FormControl>
            <FormControl>
              <FormLabel>Last Name</FormLabel>
              <Controller
                name="lastName"
                control={control}
                rules={{ required: true }}
                render={({ field }) => (
                  <Input placeholder='Last name' {...field} />
                )}
              />
              {!!errors.lastName?.message && (
                <FormHelperText
                  sx={(theme) => ({
                    color: theme.vars.palette.danger[400],
                    fontSize: "xs",
                  })}
                >
                  {errors.lastName?.message}
                </FormHelperText>
              )}
            </FormControl>
            <FormControl sx={{ gridColumn: '1/-1' }}>
              <FormLabel>Email Address</FormLabel>
              <Controller
                name="email"
                control={control}
                rules={{ required: true }}
                render={({ field }) => (
                  <Input placeholder='Email' {...field} />
                )}
              />
              {!!errors.email?.message && (
                <FormHelperText
                  sx={(theme) => ({
                    color: theme.vars.palette.danger[400],
                    fontSize: "xs",
                  })}
                >
                  {errors.email?.message}
                </FormHelperText>
              )}
            </FormControl>
            <FormControl>
              <FormLabel>Country</FormLabel>
              <Controller
                name="country"
                control={control}
                rules={{ required: true }}
                render={({ field }) => (
                  <Autocomplete
                    placeholder="Select country"
                    options={countries.map(country => country.name)}
                    value={field.value}
                    onChange={(_, value) => {
                      field.onChange(value);
                      handleCountryChange(value as string);
                    }}
                  />
                )}
              />
              {!!errors.country?.message && (
                <FormHelperText
                  sx={(theme) => ({
                    color: theme.vars.palette.danger[400],
                    fontSize: "xs",
                  })}
                >
                  {errors.country?.message}
                </FormHelperText>
              )}
            </FormControl>
            <FormControl>
              <FormLabel>City</FormLabel>
              <Controller
                name="city"
                control={control}
                rules={{ required: true }}
                render={({ field }) => (
                  <Autocomplete
                    placeholder="Select city"
                    options={filteredCities.map(city => city.name)}
                    value={field.value}
                    onChange={(_, value) => {
                      field.onChange(value);
                    }}
                  />
                )}
              />
              {!!errors.city?.message && (
                <FormHelperText
                  sx={(theme) => ({
                    color: theme.vars.palette.danger[400],
                    fontSize: "xs",
                  })}
                >
                  {errors.city?.message}
                </FormHelperText>
              )}
            </FormControl>
            <FormControl sx={{ gridColumn: '1/-1' }}>
              <FormLabel>Additional Comments</FormLabel>
              <Controller
                name="additionalComments"
                control={control}
                render={({ field }) => (
                  <Textarea placeholder="Type anything…" minRows={4} {...field} />
                )}
              />
              {!!errors.additionalComments?.message && (
                <FormHelperText
                  sx={(theme) => ({
                    color: theme.vars.palette.danger[400],
                    fontSize: "xs",
                  })}
                >
                  {errors.additionalComments?.message}
                </FormHelperText>
              )}
            </FormControl>
            <CardActions sx={{ gridColumn: '1/-1' }}>

              <Button loading={isLoading} type='submit' variant="solid" color="primary" className='!bg-navoy_blue-950'>
                Submit
              </Button>
            </CardActions>
          </CardContent>
        </form>
      </Card>
    </Container>
  );
}
export default RequestDemo;
