export const refreshToken = `
mutation RefreshToken($token: String) {
  refreshToken(token: $token) {
    success
    message
    responseObject {
      accessToken
      refreshToken
      message
      code
    }
    statusCode
  }
}
`