import { Navigate, useLocation } from "react-router-dom";
import { useAppSelector } from "../redux/hooks";

type RouteType = 'auth' | 'private' | 'dashboard';

interface ProtectedRouteProps {
  children: JSX.Element;
  type: RouteType;
}

const ProtectedRoute: React.FC<ProtectedRouteProps> = ({ children, type }) => {
  const user = useAppSelector((state) => state.auth.user);
  const location = useLocation();

  switch (type) {
    case 'auth':
      // Routes accessible only when logged out (login, signup, etc.)
      return !user ? children : <Navigate to="/dashboard" replace state={{ from: location }} />;
    
    case 'private':
    case 'dashboard':
      // Routes requiring authentication
      return user ? children : <Navigate to="/auth/login" replace state={{ from: location }} />;
    
    default:
      return children;
  }
};

export default ProtectedRoute;