
import HotelDetails from './hotelDetails';

const HotelDetailsPage = () => {


  return (
    <HotelDetails />
  );
};
export default HotelDetailsPage;
