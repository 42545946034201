export const getBlogs = `
query GetBlogs($data: BlogsInput) {
  getBlogs(data: $data) {
    data {
      id
      documentId
      title
      createdAt
      updatedAt
      publishedAt
      description
      slug
      ckeditor
      image {
        id
        documentId
        name
        alternativeText
        caption
        width
        height
        formats {
          thumbnail {
            name
            hash
            ext
            mime
            path
            width
            height
            size
            sizeInBytes
            url
          }
          small {
            name
            hash
            ext
            mime
            path
            width
            height
            size
            sizeInBytes
            url
          }
          medium {
            name
            hash
            ext
            mime
            path
            width
            height
            size
            sizeInBytes
            url
          }
          large {
            name
            hash
            ext
            mime
            path
            width
            height
            size
            sizeInBytes
            url
          }
          
        }
        hash
        ext
        mime
        size
        url
        previewUrl
        provider
        provider_metadata
        createdAt
        updatedAt
        publishedAt
        locale
        
      }
    }
    meta {
      page
      pageSize
      pageCount
      total
    }
  }
}
`

export const getBlog = `
query GetBlog($documentId: String) {
  getBlog(documentId: $documentId) {
    data {
      id
      documentId
      title
      createdAt
      updatedAt
      publishedAt
      description
      slug
      ckeditor
      image {
        id
        documentId
        name
        alternativeText
        caption
        width
        height
        formats {
          thumbnail {
            name
            hash
            ext
            mime
            path
            width
            height
            size
            sizeInBytes
            url
          }
          small {
            name
            hash
            ext
            mime
            path
            width
            height
            size
            sizeInBytes
            url
          }
          medium {
            name
            hash
            ext
            mime
            path
            width
            height
            size
            sizeInBytes
            url
          }
          large {
            name
            hash
            ext
            mime
            path
            width
            height
            size
            sizeInBytes
            url
          }
        }
        hash
        ext
        mime
        size
        url
        previewUrl
        provider
        provider_metadata
        createdAt
        updatedAt
        publishedAt
        locale
      }
    }
    meta {
      page
      pageSize
      pageCount
      total
    }
  }
}
`