import { FormControl, FormHelperText, FormLabel } from '@mui/material';
import { styled } from '@mui/material/styles';
import React, { useEffect, useRef } from "react";


const TextInput = styled('input')(({ theme }) => ({
  ...theme.typography.body1,
  borderRadius: 4,
  position: 'relative',
  backgroundColor: theme.palette.background.paper,
  border: '1px solid #ced4da',
  fontSize: '1rem',
  width: '100%',
  padding: '16.5px 14px',
  transition: theme.transitions.create(['border-color', 'box-shadow']),
  '&:focus': {
    borderColor: theme.palette.primary.main,
    boxShadow: `0 0 0 0.1rem ${theme.palette.primary.main}`,
    outline: 'none',
  },
}));

type SearchGoogleMapProps = {
  id?: string;
  label: string;
  placeholder: string;
  field: any;
  error: string
  disabled?: boolean;
  onLocationChange?: (location: { lat: number; lng: number }) => void;
}

const SearchGoogleMap: React.FC<SearchGoogleMapProps> = ({ id, label, placeholder, field, error, disabled, onLocationChange }) => {
  const searchRef = useRef<HTMLInputElement>(null)

  useEffect(() => {
    if (!window.google?.maps?.places) {
      console.error('Google Maps Places API is not loaded');
      return;
    }

    const searchInput = searchRef.current;
    if (!searchInput) return;

    const autocomplete = new window.google.maps.places.Autocomplete(
        searchRef.current, { fields: ['formatted_address','address_components','geometry'], types: ["(regions)"] }
      );
      autocomplete.addListener('place_changed', () => {
        const place = autocomplete.getPlace();
        
        if (searchRef.current) {
          searchRef.current.value = place.formatted_address;
          field.onChange(place.formatted_address)
          
          if (place.geometry?.location && onLocationChange) {
            onLocationChange({
              lat: place.geometry.location.lat(),
              lng: place.geometry.location.lng()
            });
          }
        }
    });
  }, [field, onLocationChange])

  return (
    <FormControl fullWidth error={!!error} className="gap-2">
      <FormLabel htmlFor={id}>{label}</FormLabel>
      <TextInput
        id={id}
        className='MuiTextField-root'
        {...field}
        placeholder={placeholder}
        ref={searchRef}
        variant="outlined"
        disabled={disabled}
      />
      {error && <FormHelperText error={!!error}>{error}</FormHelperText>}
    </FormControl>
  );
}


export default SearchGoogleMap;