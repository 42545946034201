import { AddEnterpriseFormResponse } from '../../utils/types';
import { enterpriseForm } from '../queries/enterprise.query';
import { apiSlice } from './apiSlice';

export const enterpriseApi = apiSlice.injectEndpoints({
  endpoints: (builder) => ({
    addEnterprise: builder.mutation({
      query: (data) => ({
        url: '/',
        method: 'POST',
        body: {
          query: enterpriseForm,
          variables: { data },
        },
      }),
      transformResponse: (response: { data: { addEnterpriseForm: AddEnterpriseFormResponse } }) => response.data.addEnterpriseForm,
    }),
  }),
});

export const { useAddEnterpriseMutation } = enterpriseApi;
