import { Text, View } from '@react-pdf/renderer';
import React from 'react';
import { createTw } from 'react-pdf-tailwind';

const tw = createTw({});

interface ActivityItemProps {
  name: string;
  date: string;
  time: string;
  costUsd: number;
  description: string;
  primaryColor: string;
  secondaryColor: string;
  isFirst: boolean;
}

const ActivityItem: React.FC<ActivityItemProps> = (props) => {
  return (
    <View wrap={false} style={props.isFirst ? tw('border-t') : undefined}>
      <View style={tw('flex flex-row')}>
        <Text style={tw('flex-1 text-xs p-2')}>{props.name}</Text>
        <Text style={tw('flex-1 text-xs p-2')}>{props.date}</Text>
        <Text style={tw('flex-1 text-xs p-2')}>{props.time}</Text>
        <Text style={tw('flex-1 text-xs p-2')}>
          {props.costUsd.toLocaleString('en-US', { style: 'currency', currency: 'USD' })}
        </Text>
      </View>
      <View style={[tw('p-4 border-t mx-auto'), { borderColor: props.secondaryColor }]}>
        <Text style={tw('text-xs leading-normal')}>{props.description}</Text>
      </View>
    </View>
  );
};

export default ActivityItem;
