import { ReportAccommodation, ReportActivity, ReportFlight } from '../types';

/**
 * Dummy data for the export page. This is used as a sample data for users
 * whishing to have a preview.
 */
export const DUMMY_DATA: {
  title: string;
  description: string;
  flights: ReportFlight[];
  accommodations: ReportAccommodation[];
  activities: ReportActivity[];
} = {
  title: 'Romantic Honeymoon in Paris',
  description:
    "Experience the romance of Paris with a fresh and modern twist. This honeymoon itinerary is designed for couples seeking intimate moments and unforgettable experiences. From romantic strolls along the Seine to exquisite dining experiences, each day is crafted to celebrate love in the City of Lights. Enjoy the charm of Parisian streets, indulge in gourmet meals, and create lasting memories in one of the world's most romantic cities.",
  flights: [
    {
      name: 'Flight 1',
      time: '09:00 AM',
      duration: '7h 15m',
      stops: 'Non-stop',
      type: 'First Class',
      costUsd: 1500,
      departureTime: '09:00',
      departureAirport: 'John F. Kennedy International Airport (JFK)',
      arrivalTime: '16:15',
      arrivalAirport: 'Charles de Gaulle Airport (CDG)',
      airline: 'Air France . First Class . Airbus A380 . AF 007',
      amenities: ['Wi-Fi', 'USB', 'Video', 'Legroom', 'Gourmet Meals', 'Lounge Access'],
    },
  ],
  accommodations: [
    {
      name: 'Le Bristol Paris',
      type: 'Hotel',
      checkIn: '2023-06-01',
      checkOut: '2023-06-07',
      costPerNightUsd: 800,
      address: '112 Rue du Faubourg Saint-Honoré, 75008 Paris, France',
    },
  ],
  activities: [
    {
      name: 'Seine River Dinner Cruise',
      date: '2023-06-01',
      time: '7:00 PM',
      costUsd: 200,
      description:
        'Enjoy a romantic dinner cruise along the Seine River, with stunning views of Paris landmarks illuminated at night.',
    },
    {
      name: 'Private Eiffel Tower Tour',
      date: '2023-06-02',
      time: '10:00 AM',
      costUsd: 150,
      description:
        'Experience a private tour of the Eiffel Tower, including access to the summit for breathtaking views of Paris.',
    },
    {
      name: 'Couples Cooking Class',
      date: '2023-06-03',
      time: '11:00 AM',
      costUsd: 120,
      description:
        'Learn to cook classic French dishes together in a hands-on cooking class led by a professional chef.',
    },
    {
      name: 'Montmartre Wine Tasting',
      date: '2023-06-04',
      time: '3:00 PM',
      costUsd: 100,
      description: 'Explore the charming Montmartre neighborhood and enjoy a wine tasting session at a local wine bar.',
    },
    {
      name: 'Versailles Palace and Gardens Tour',
      date: '2023-06-05',
      time: '9:00 AM',
      costUsd: 180,
      description:
        'Take a guided tour of the opulent Palace of Versailles and its stunning gardens, a UNESCO World Heritage site.',
    },
    {
      name: 'Romantic Picnic at Luxembourg Gardens',
      date: '2023-06-06',
      time: '12:00 PM',
      costUsd: 80,
      description:
        'Enjoy a romantic picnic in the beautiful Luxembourg Gardens, with a gourmet picnic basket prepared by a local deli.',
    },
  ],
};
