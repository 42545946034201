import { PersistedHotelSchema } from '../../../utils/types';

export interface ReportAccommodation {
  name: string;
  type: string;
  checkIn: string;
  checkOut: string;
  costPerNightUsd: number;
  address: string;
}

export function mapAccommodation(accommodation: PersistedHotelSchema): ReportAccommodation {
  return {
    name: accommodation.name,
    type: accommodation.type,
    checkIn: accommodation.check_in_date,
    checkOut: accommodation.checkout_date,
    costPerNightUsd: accommodation.price_per_night,
    address: `${accommodation.gps_coordinates.latitude.toFixed(2)}, ${accommodation.gps_coordinates.longitude.toFixed(
      2
    )}`,
  };
}
