import { GetTripOfPlan } from "../../utils/types"
import { getTripOfPlan, userTrips, userTripsInfo } from "../queries/userTrip.query"
import { apiSlice } from "./apiSlice"


export const userTripApiSlice = apiSlice.injectEndpoints({

  endpoints: builder => ({
    getUserTripsInfo: builder.query({
      query: params => ({
        method: 'post',
        url: `/`,
        body: {
          query: userTripsInfo,
          variables: { data: params }
        }
      }),
      transformResponse: (response: { data: { getUserTripsInfo: any } }) => response.data.getUserTripsInfo
    }),
    getUserTrips: builder.query({
      query: params => ({
        method: 'post',
        url: `/`,
        body: {
          query: userTrips,
          variables: { data: params }
        }
      }),
      transformResponse: (response: { data: { getUserTrips: any } }) => response.data.getUserTrips
    }),
    getTripOfPlan: builder.query({
      query: data => ({
        method: 'post',
        url: `/`,
        body: {
          query: getTripOfPlan,
          variables: { data }
        }
      }),
      transformResponse: (response: { data: { getTripOfPlan: GetTripOfPlan } }) => response.data.getTripOfPlan
    })
  }),
})

export const { useGetUserTripsInfoQuery, useGetUserTripsQuery, useGetTripOfPlanQuery  } = userTripApiSlice