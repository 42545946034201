import PlaylistAddCheckCircleRoundedIcon from "@mui/icons-material/PlaylistAddCheckCircleRounded";
import { Alert, Box } from "@mui/joy";

type AlertSuccessProps = {
  message: string;
};

const AlertSuccess: React.FC<AlertSuccessProps> = ({ message }) => {
  return (
    <Box
      sx={{ display: "flex", flexDirection: "column", gap: 2, width: "100%" }}
    >
      <Alert
        variant="soft"
        color="success"
        startDecorator={<PlaylistAddCheckCircleRoundedIcon />}
      >
        {message}
      </Alert>
    </Box>
  );
};

export default AlertSuccess;
