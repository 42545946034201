import { useEffect, useState } from 'react';

/**
 * Returns a debounced state value and setter, along with the debounced value itself.
 * This can be useful for input fields that require a delay before updating the state
 * to prevent unnecessary re-renders.
 *
 * @example
 * const [value, setValue, debouncedValue] = useDebouncedState<string>('initial value', 1000);
 *
 * @param initialValue the initial value of the state
 * @param delay the delay in milliseconds
 * @returns an array containing the state value, the setter, and the debounced value
 */
const useDebouncedState = <T>(initialValue: T, delay: number) => {
  const [value, setValue] = useState<T>(initialValue);
  const [debouncedValue, setDebouncedValue] = useState<T>(initialValue);

  useEffect(() => {
    const handler = setTimeout(() => {
      setDebouncedValue(value);
    }, delay);

    return () => {
      clearTimeout(handler);
    };
  }, [value, delay]);

  return [value, setValue, debouncedValue] as const;
};

export default useDebouncedState;
