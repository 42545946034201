import { useCallback, useState } from 'react';
/**
 * This hook provides a popover state and functions to open, close, and toggle the popover.
 * You can use this hook to quickly define the popover state and functions.
 *
 * @example
 * ```tsx
 * const { anchorEl, open, close, toggle } = usePopover();
 *
 * return (
 *   <div>
 *     <button onClick={toggle}>Toggle Popover</button>
 *     <CustomPopover
 *       anchorEl={anchorEl}
 *       handleClose={close}
 *     >
 *       <Typography variant="subtitle1">Popover</Typography>
 *       <Typography variant="body2">
 *         This is a popover. You can put any content here.
 *       </Typography>
 *     </CustomPopover>
 *   </div>
 * );
 * ```
 *
 * @returns an object.
 */
const usePopover = () => {
  const [anchorEl, setAnchorEl] = useState<HTMLElement | null>(null);

  /**
   * This function opens the popover.
   * @param event - Click event.
   */
  const open = useCallback((event: React.MouseEvent<HTMLElement>) => {
    setAnchorEl(event.currentTarget);
  }, []);

  /**
 /**
   * This function closes the popover.
   */
  const close = useCallback(() => {
    setAnchorEl(null);
  }, []);

  /**
   * This function toggles the popover.
   * @param event - Click event.
   */
  const toggle = useCallback(
    (event: React.MouseEvent<HTMLElement>) => {
      if (anchorEl) {
        close();
      } else {
        open(event);
      }
    },
    [anchorEl, close, open]
  );

  // /**
  //  * TODO: Can work better
  //  * This function forces the popover to update its position.
  //  */
  // const forceUpdate = () => {
  //   setAnchorEl((prev) => (prev ? { ...prev } : null));
  // };

  return {
    anchorEl,
    open,
    close,
    toggle,
  };
};

export default usePopover;
