import 'mapbox-gl/dist/mapbox-gl.css';

import { MapActivity } from './types';
import useInitializeMap from './useInitializeMap';

interface MapProps {
  defaultLng: number;
  defaultLat: number;
  defaultZoom: number;
  /**
   * Callback to handle marker click.
   * @param e - Click event.
   * @param feature - Activity of the day.
   */
  onMarkerClick: (e: React.MouseEvent<HTMLElement>, feature: MapActivity) => void;
}

const Map = ({ defaultLng, defaultLat, defaultZoom, onMarkerClick }: MapProps) => {
  const { mapContainerRef, mapLoaded } = useInitializeMap({
    defaultLat,
    defaultLng,
    defaultZoom,
    onMarkerClick,
  });

  return (
    <section className="relative w-full h-full">
      {!mapLoaded && <article className="w-full h-full animate-pulse bg-blue-gray-100" />}
      <article
        ref={mapContainerRef}
        className="w-full h-full"
        // Deal with edge case where the map is loaded but loader is still visible
        style={{ visibility: !mapLoaded ? 'hidden' : 'visible' }}
      />
      {/* <article className="absolute top-0 left-0">
        <div className="inline-block px-4 py-2 m-2 text-white rounded-full bg-blue-gray-500">
          Longitude: {lng} | Latitude: {lat} | Zoom: {zoom}
        </div>
      </article> */}
    </section>
  );
};

export default Map;
