import { yupResolver } from "@hookform/resolvers/yup";
import MailIcon from "@mui/icons-material/Mail";
import { Alert, Box, Button, Divider, FormControl, FormHelperText, FormLabel, Input, Typography } from "@mui/joy";
import { useEffect, useState } from "react";
import { Controller, useForm } from "react-hook-form";
import { Link } from "react-router-dom";
import * as yup from "yup";
import Logo from "./components/logo";
import { useForgetPasswordMutation } from "../../redux/features/authSlice";
import { toast } from "react-toastify";
const validationSchema = yup.object().shape({
  email: yup.string().email("Email is invalid").required("Email is required"),
});

type ForgotPasswordProps = {};

type Form = { email: string }

const ForgotPassword: React.FC<ForgotPasswordProps> = () => {

  const [forgotPassword, { data, isLoading }] = useForgetPasswordMutation()
  const { handleSubmit, control, reset, formState: { errors }, setError } = useForm({
    resolver: yupResolver(validationSchema),
    defaultValues: { email: "" },
  });

  const [success, setSuccess] = useState(false);
  const onSubmit = (data: Form) => {
    console.log('data: ', data);
    setSuccess(false)
    forgotPassword({ email: data.email })
  };

  useEffect(() => {
    if (data) {
      if (data.success) {
        if (data.responseObject.error) {
          toast.warn(data.responseObject.message)
          setError('email', { type: "manual", message: data.responseObject.message })
        } else {
          toast.info(data.responseObject.message)
          setSuccess(true)
          reset()
        }
      } else {
        toast.error(data.responseObject.message)
        setError('email', { type: "manual", message: data.responseObject.message })
      }
    }
  }, [data, reset])

  return (
    <Box className="flex flex-col flex-1 justify-center items-center gap-10">
      <Logo />
      <Box className="min-w-[70%] flex flex-col gap-4  w-full md:w-auto">
        <form onSubmit={handleSubmit(onSubmit)} className="flex flex-col gap-3">
          <FormControl>
            <FormLabel
              sx={(theme) => ({
                "--FormLabel-color": theme.vars.palette.neutral[700],
              })}
            >
              Email
            </FormLabel>
            <Controller
              name="email"
              control={control}
              rules={{ required: true }}
              defaultValue=""
              render={({ field }) => (
                <Input
                  {...field}
                  sx={(theme) => ({
                    fontSize: " 0.75rem",
                    "--Input-minHeight": "3rem",
                    "&:focus-within:before": {
                      "--Input-focused": 0,
                    },
                    boxShadow: theme.shadow.lg,
                    background: "#fff",
                  })}
                  placeholder="Enter your email"
                  type="email"
                  error={!!errors.email?.message}
                  size="sm"
                  startDecorator={
                    <MailIcon
                      sx={(theme) => ({ color: theme.palette.navoy.blue })}
                      fontSize="small"
                    />
                  }
                />
              )}
            />
            {!!errors.email?.message && (
              <FormHelperText
                sx={(theme) => ({
                  color: theme.vars.palette.danger[400],
                  fontSize: "xs",
                })}
              >
                {errors.email?.message}
              </FormHelperText>
            )}
          </FormControl>
          {success && (
            <Alert variant={"soft"} color="success">
              You will receive a link to create a new password via email.
            </Alert>
          )}
          <Box className="mt-4">
            <Button
              fullWidth
              sx={(theme) => ({
                background: theme.vars.palette.navoy.blue,
                "&:hover": { background: theme.vars.palette.navoy.blue },
                paddingY: 2,
              })}
              type="submit"
              loading={isLoading}
            >
              Send
            </Button>
          </Box>
        </form>
        <Divider />
        <Typography className="text-center  pt-[3px]" fontSize="sm">
          Already have an account?{" "}
          <Link to="/auth/login" className=" text-navoy-blue font-bold">
            Login
          </Link>
        </Typography>
      </Box>
    </Box>
  );
};

export default ForgotPassword;
