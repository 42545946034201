import { StyleSheet, Text, View } from '@react-pdf/renderer';
import { useMemo } from 'react';
import { createTw } from 'react-pdf-tailwind';
import { ReportAccommodation, ReportActivity, ReportFlight } from '../../../types';

const tw = createTw({});

const styles = StyleSheet.create({
  bold: {
    fontFamily: 'Helvetica-Bold',
    fontWeight: 'bold',
  },
});

interface TotalCostsProps {
  flights: ReportFlight[];
  accommodations: ReportAccommodation[];
  activities: ReportActivity[];
  primaryColor: string;
  secondaryColor: string;
}

export const TotalCosts: React.FC<TotalCostsProps> = (props) => {
  const flightsTotal = useMemo(() => {
    return props.flights.reduce((total, acc) => total + acc.costUsd, 0);
  }, [props.flights]);

  const accommodationsTotal = useMemo(() => {
    return props.accommodations.reduce((total, acc) => total + acc.costPerNightUsd, 0);
  }, [props.accommodations]);

  const activitiesTotal = useMemo(() => {
    return props.activities.reduce((total, acc) => total + acc.costUsd, 0);
  }, [props.activities]);

  const totalCost = useMemo(() => {
    return flightsTotal + accommodationsTotal + activitiesTotal;
  }, [flightsTotal, accommodationsTotal, activitiesTotal]);

  return (
    <View style={tw('mt-4')} wrap={false}>
      {/* <View style={[tw('p-3 border flex items-center mb-2 rounded-sm'), { backgroundColor: props.primaryColor }]}>
          <Text style={[tw('text-lg leading-none'), styles.bold]}>Overall Costs</Text>
        </View> */}
      <View style={[tw('border border-black'), { backgroundColor: props.secondaryColor }]}>
        {/* Individual Costs */}
        <View style={tw('mb-2 py-1')}>
          <View style={[tw('flex flex-row p-2 px-4')]}>
            <Text style={[tw('text-xs leading-none text-gray-900'), styles.bold]}>Flights</Text>
            <View style={tw('border-dotted border-b border-black w-full mx-6')} />
            <Text style={[tw('text-xs leading-none text-gray-900'), styles.bold]}>
              {flightsTotal.toLocaleString('en-US', { style: 'currency', currency: 'USD' })}
            </Text>
          </View>
          <View style={[tw('flex flex-row p-2 px-4')]}>
            <Text style={[tw('text-xs leading-none text-gray-900'), styles.bold]}>Accommodation</Text>
            <View style={tw('border-dotted border-b border-black w-full mx-6')} />
            <Text style={[tw('text-xs leading-none text-gray-900'), styles.bold]}>
              {accommodationsTotal.toLocaleString('en-US', { style: 'currency', currency: 'USD' })}
            </Text>
          </View>
          <View style={[tw('flex flex-row p-2 px-4')]}>
            <Text style={[tw('text-xs leading-none text-gray-900'), styles.bold]}>Activities</Text>
            <View style={tw('border-dotted border-b border-black w-full mx-6')} />
            <Text style={[tw('text-xs leading-none text-gray-900'), styles.bold]}>
              {activitiesTotal.toLocaleString('en-US', { style: 'currency', currency: 'USD' })}
            </Text>
          </View>
        </View>
        {/* Global Total */}
        <View style={[tw('flex flex-row p-2 px-4 border-t justify-between')]}>
          <Text style={[tw('text-sm leading-none'), styles.bold]}>Total (approx.)</Text>
          {/* <View style={tw('border-dotted border-b border-black w-full mx-6')} /> */}
          <Text style={[tw('text-sm leading-none'), styles.bold]}>
            {totalCost.toLocaleString('en-US', { style: 'currency', currency: 'USD' })}
          </Text>
        </View>
      </View>
    </View>
  );
};
