import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import { Accordion, AccordionDetails } from '@mui/joy';
import { Box, Button, Card, CardContent, IconButton, Typography } from '@mui/material';
import { useEffect, useState } from 'react';
import { clientIdUnsplash } from '../../../utils/env';
import RegenerateForm from './regenerateForm';

type OverviewProps = {
  target: string;
  title: string;
  description: string;
  expanded: boolean;
  setExpanded: (b: boolean) => void;
}

const Overview: React.FC<OverviewProps> = ({ title, description, expanded, setExpanded, target }) => {

  const [bg, setBg] = useState("")

  useEffect(() => {
    const fetchData = async () => {
      try {
        const response = await fetch(`https://api.unsplash.com/search/photos?query=${target}&client_id=${clientIdUnsplash}`)
        if (!response.ok) {
          throw new Error('Network response was not ok')
        }
        const data = await response.json()
        setBg(data?.results[0]?.urls?.regular)
      } catch (error) {
        console.error('Error fetching itinerary data:', error)
      }
    }
    if (target) fetchData()
  }, [target])


  const handleToggle = () => {
    setExpanded(!expanded);
  };


  return (
    <Card >
      <Box
        sx={theme => ({
          position: 'relative',
          minHeight: 240,
          backgroundImage: `url(${bg})`,
          backgroundSize: 'cover',
          backgroundPosition: 'center',
          backgroundColor: theme.palette.navoy_bg
        })}
      >
        <Box className='absolute left-0 bottom-0 right-0 top-0 bg-[#00000050]'></Box>
        <Box className='absolute left-0 bottom-0 right-0 flex justify-between px-4 pb-2 flex-wrap gap-3'>
          <Typography
            variant="h4"
            sx={{
              color: 'white',
              fontWeight: 'bold',
              marginTop: 'auto',
              maxWidth: 350,
              fontSize: { lg: 24, xs: 18, md: 20 }
            }}
          >
            {title}
          </Typography>
          <Box sx={{ display: 'flex', justifyContent: 'flex-end', gap: 1, marginTop: 'auto' }}>
            <Button variant='outlined' onClick={() => setExpanded(!expanded)} className='!rounded-full !bg-white'>
              Regenerate
            </Button>
          </Box>
        </Box>
      </Box>
      <CardContent>
        <Typography variant="body1" color="text.secondary" className='!text-sm md:!text-lg' >
          {description}
        </Typography>
        <Box display="flex" justifyContent="flex-end" mb={1}>
          <IconButton onClick={handleToggle}>
            <ExpandMoreIcon style={{ transform: expanded ? 'rotate(180deg)' : 'rotate(0deg)' }} />
          </IconButton>
        </Box>
        <Accordion expanded={expanded}>
          <AccordionDetails>
            <RegenerateForm expanded={expanded} setExpanded={(b) => setExpanded(b)} />
          </AccordionDetails>
        </Accordion>
      </CardContent>
    </Card>
  );
}


export default Overview;