import { Box, Button, Container } from "@mui/joy";
import SearchTable from './table'
import { useGetSearchQuery } from "../../../redux/features/adminSlice";

type SearchProps = {}

const Search: React.FC<SearchProps> = () => {

  const { refetch, isLoading } = useGetSearchQuery({});
  const handleRefetch = () => refetch();

  return (
    <Box
      component="section"
      sx={theme => ({
        paddingTop: 16,
        paddingBottom: 16,
        background: theme.vars.palette.navoy.bg,
        minHeight: "100vh",
      })}
    >
      <Container maxWidth="lg">
        <Box className="flex justify-between items-center mb-4">
          <h2 className="text-2xl font-bold mb-2">Users Search</h2>
          <Button loading={isLoading} className="bg-navoy-red" size="sm" onClick={handleRefetch}>
            Refetch
          </Button>
        </Box>
        <SearchTable />
      </Container>
    </Box>
  );
}


export default Search;