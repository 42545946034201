import PriceSection from "../home/price";

type PaymentPageProps = {}
 
const PaymentPage:React.FC<PaymentPageProps> = () => {
  return (
    <PriceSection />
  );
}
 
 
export default PaymentPage;