export const geocodeJson = process.env.REACT_APP_GEOCODEJSON_URL;
export const apiKey = process.env.REACT_APP_API_KEY_GEO;
export const mapApiJs = process.env.REACT_APP_MAP_API_JS;
export const mapBoxToken = process.env.REACT_APP_MAPBOX_TOKEN;

export const googleClientId = process.env.REACT_APP_GOOGLE_CLIENT_ID || ''
export const googleClientSecret = process.env.REACT_APP_GOOGLE_CLIENT_SECRET || ''

export const navoyGraphqlUrl = process.env.REACT_APP_NAVOY_GATEWAY_URL;
// export const navoyGraphqlUrl = "http://localhost:4000/graphql";

export const tripGeneratorUrl = process.env.REACT_APP_TRIP_GENERATOR_URL;
// export const tripGeneratorUrl = "http://localhost:8000";

export const xClientId = process.env.REACT_APP_X_CLIENT_ID || ''
export const xClientSecret = process.env.REACT_APP_X_CLIENT_SECRET || ''
export const xBearerToken = process.env.REACT_APP_X_BEARER_TOKEN || ''
export const xAccessTokenSecret = process.env.REACT_APP_X_ACCESS_TOKEN_SECRET || ''
export const xApiKey = process.env.REACT_APP_X_API_KEY || ''
export const xApiSecretKey = process.env.REACT_APP_X_API_SECRET_KEY || ''
export const xRootUrl = process.env.REACT_APP_X_ROOT_URL || ''
export const xRedirectUri = process.env.REACT_APP_X_REDIRECT_URI || ''
// export const xRedirectUri =  "http://www.localhost:3000/auth/login"
export const xState = process.env.REACT_APP_X_STATE || ''

export const stripFreeTrial  = process.env.REACT_APP_STRIP_FREE_TRIAL || ''
export const stripBasicMonth = process.env.REACT_APP_STRIP_BASIC_MONTH || ''
export const stripBasicYear = process.env.REACT_APP_STRIP_BASIC_YEAR || ''
export const stripProMonth = process.env.REACT_APP_STRIP_PRO_MONTH || ''
export const stripProYear = process.env.REACT_APP_STRIP_PRO_YEAR || ''
export const stripPremiumMonth = process.env.REACT_APP_STRIP_PREMIUM_MONTH || ''
export const stripPremiumYear = process.env.REACT_APP_STRIP_PREMIUM_YEAR || ''

export const clientIdUnsplash = process.env.REACT_APP_CLIENT_ID_UNSPLASH || ''
