export const registerQuery = `
mutation Register($data: RegisterInput) {
  register(data: $data) {
    success
    message
    responseObject {
      ... on MessageResponse {
        message
      }
      ... on AuthError {
        error
        message
      }
    }
    statusCode
  }
}
`
 
export const loginQuery = `
mutation Login($data: LoginInput) {
  login(data: $data) {
    success
    message
    responseObject {
      ... on LoginResponse {
        email
        username
        name
        surName
        picture
        token
        id
        refreshToken
        subscription {
          currentSubscription {
            plan
            tripNumber
            modificationNumber
            eventId
            startDate
            endDate
            subscriptionId
            status
          }
          daysUsed
          daysRemaining
          isActive
        }
      }
      ... on AuthError {
        error
        message
      }
    }
    statusCode
  }
}
`

export const googleAuthQuery = `
mutation LoginGoogle($data: GoogleParamsInput) {
  loginGoogle(data: $data) {
    success
    message
    responseObject {
      ... on LoginResponse {
        email
        username
        name
        surName
        picture
        token
        id
        refreshToken
        terms
        subscription {
          currentSubscription {
            plan
            tripNumber
            modificationNumber
            eventId
            startDate
            endDate
            subscriptionId
            status
          }
          daysUsed
          daysRemaining
          isActive
        }
      }
      ... on AuthError {
        error
        message
      }
    }
    statusCode
  }
}
`

export const updateUserInfo = `
mutation UserInfo($data: UserInfoInput) {
  userInfo(data: $data) {
    message
    responseObject {
      ... on MessageResponse {
        message
      }
      ... on AuthError {
        error
        message
      }
    }
    statusCode
    success
  }
}
`

export const getUserInfo = `
query GetUserInfo($data: GetUserInfoInput) {
  getUserInfo(data: $data) {
    success
    message
    statusCode
    responseObject {
      ... on UserInfoData {
        agencyName
        description
        name
        surName
        website
        id
      }
      ... on AuthError {
        error
        message
      }
    }
  }
}
`

export const updateSocialMedia = `
mutation UpdateSocialMedia($data: UpdateSocialMediaInput) {
  updateSocialMedia(data: $data) {
    success
    message
    responseObject {
      ... on MessageResponse {
        message
      }
      ... on AuthError {
        error
        message
      }
    }
    statusCode
  }
}
`

export const getUserSocialMediaInfo = `
query GetUserSocialMediaInfo($data: GetUserInfoInput) {
  getUserSocialMediaInfo(data: $data) {
    success
    message
    statusCode
    responseObject {
      ... on GetUserSocialMediaInfoData {
        x
        instagram
        linkedin
        pinterest
        tiktok
        id
      }
      ... on AuthError {
        error
        message
      }
    }
  }
}
`

export const uploadImage = `
mutation UploadImage( $file: Upload, $id:String) {
  uploadImage( file: $file, id:$id) {
    success
    message
    responseObject {
      ... on MessageResponse {
        message
      }
      ... on AuthError {
        error
        message
      }
    }
    statusCode
  }
}
`

export const getImage =`
query GetImage($data: GetUserInfoInput) {
  getImage(data: $data) {
    success
    message
    statusCode
    responseObject {
      ... on GetImageData {
        picture
      }
      ... on AuthError {
        error
        message
      }
    }
  }
}
` 

export const updateUserSubscription = `
mutation UpdateUserSubscription($data: SubscriptionParamsInput) {
  updateUserSubscription(data: $data) {
    success
    message
    responseObject {
      ... on AuthError {
        error
        message
      }
      ... on MessageResponse {
        message
      }
    }
    statusCode
  }
}
`

export const getUserSubscriptionStatus = `
query GetUserSubscriptionStatus($data: GetUserInfoInput) {
  getUserSubscriptionStatus(data: $data) {
    success
    message
    responseObject {
      ... on AuthError {
        error
        message
      }
      ... on UserSubscriptionStatusData {
        daysUsed
        daysRemaining
        isActive
        currentSubscription {
          plan
          tripNumber
          modificationNumber
          eventId
          startDate
          endDate
          subscriptionId
          status
        }
      }
    }
    statusCode
  }
}
`

export const forgetPasswordQuery = `
mutation ForgotPassword($data: ForgotPasswordInput) {
  forgotPassword(data: $data) {
    success
    message
    responseObject {
      ... on MessageResponse {
        message
      }
      ... on AuthError {
        error
        message
      }
    }
    statusCode
  }
}
`

export const resetPasswordQuery =`
mutation ResetPassword($data: ResetPasswordInput) {
  resetPassword(data: $data) {
    success
    message
    responseObject {
      ... on MessageResponse {
        message
      }
      ... on AuthError {
        error
        message
      }
    }
    statusCode
  }
}
`