import { Divider } from '@mui/joy';
import DialogTitle from '@mui/joy/DialogTitle';
import List from '@mui/joy/List';
import Modal from '@mui/joy/Modal';
import ModalClose from '@mui/joy/ModalClose';
import ModalDialog from '@mui/joy/ModalDialog';
import * as React from 'react';
import { BookingOptionFlight, Prices } from '../../utils/types';

type DialogVerticalScrollProps = {
  open: boolean,
  setOpen: (value: boolean) => void,
  title: string,
  option: Prices[] | BookingOptionFlight[],
  Content: React.JSXElementConstructor<any>,
  select: string,
  setSelect: (s: string) => void
  text: string
  subText: string
}

const DialogVerticalScroll: React.FC<DialogVerticalScrollProps> = ({ open, setOpen, title, option, Content, select, setSelect, text, subText }) => {

  return (
    <Modal open={open} onClose={() => { setOpen(false) }}      >
      <ModalDialog layout={'center'}>
        <ModalClose />
        <DialogTitle>{title}</DialogTitle>
        <div>
          <div className='text-md text-navoy-blue'>{text}</div>
          <div className='text-sm text-navoy-blue'>{subText}</div>
        </div>
        <List
          sx={{
            overflow: 'auto',
            mx: 'calc(-1 * var(--ModalDialog-padding))',
            px: 'var(--ModalDialog-padding)',
          }}
        >
          {option.map((item, index) => (
            <div key={index}>
              <Content item={item} select={select} setSelect={(s: string) => setSelect(s)} />
              {index < option.length - 1 && <Divider orientation='horizontal' />}
            </div>
          ))}
        </List>
      </ModalDialog>
    </Modal>
  );
}

export default DialogVerticalScroll;