import React from 'react';
import { Dialog, DialogTitle, DialogContent, DialogActions, Button, Box, Typography, Divider } from '@mui/material';
import { Room, HotelRate } from '../../../../../utils/types';

interface BookingDialogProps {
  open: boolean;
  onClose: () => void;
  room: Room | null;
  selectedRate: HotelRate | null;
  onConfirm: () => void;
}

const BookingDialog: React.FC<BookingDialogProps> = ({
  open,
  onClose,
  room,
  selectedRate,
  onConfirm
}) => {
  if (!room || !selectedRate) return null;

  const BookingDetails: React.FC<{
    label?: string;
    value?: string;
    variant?: 'body1' | 'body2' | 'h6' | 'subtitle1' | 'subtitle2' | 'caption' | 'overline';
    color?: 'text.primary' | 'text.secondary' | 'text.disabled';
    sx?: object;
    gutterBottom?: boolean
  }> = ({ label = '', value = '', variant = "body2", color = "text.secondary", sx = {} }) => (
    <Typography variant={variant} color={color} sx={sx} gutterBottom>
      {label && `${label}: `}{value}
    </Typography>
  );

  const discount = Math.round(
    ((selectedRate.retailRate.total[0].amount - selectedRate.retailRate.suggestedSellingPrice[0].amount) / 
    selectedRate.retailRate.total[0].amount) * 100
  );

  return (
    <Dialog open={open} onClose={onClose} maxWidth="sm" fullWidth>
      <DialogTitle>Confirm Booking</DialogTitle>
      <DialogContent>
        <Box sx={{ py: 2 }}>
          {/* Room Details */}
          <BookingDetails value={room.roomName} variant="h6" gutterBottom />
          <BookingDetails label="Rate Type" value={selectedRate.boardName} variant="body1" gutterBottom />
          
          {/* Pricing */}
          <Box sx={{ my: 2, p: 2, bgcolor: 'grey.50', borderRadius: 1 }}>
            <BookingDetails 
              label="Total" 
              value={`$${selectedRate.retailRate.total[0].amount}`} 
              variant="h6" 
              color="text.primary" 
            />
            <BookingDetails 
              label="Original Price" 
              value={`$${selectedRate.retailRate.suggestedSellingPrice[0].amount}`} 
              variant="body2" 
              color="text.secondary" 
              sx={{ textDecoration: 'line-through' }} 
            />
            {discount > 0 && (
              <Typography variant="body2" color="success.main" sx={{ fontWeight: 'bold' }}>
                {discount}% OFF
              </Typography>
            )}
          </Box>

          {/* Room Specifications */}
          <Box sx={{ mb: 2 }}>
            <BookingDetails label="Maximum Occupancy" value={`${room.maxOccupancy} guests`} />
            <BookingDetails label="Room Size" value={`${room.roomSizeSquare} ${room.roomSizeUnit}`} />
          </Box>

          {/* Cancellation Policies */}
          <Divider sx={{ my: 2 }} />
          <Typography variant="subtitle1" gutterBottom>Cancellation Policy</Typography>
          {selectedRate.cancellationPolicies.refundableTag === 'NRFN' ? (
            <Box sx={{ mb: 1 }}>
              <Typography variant="body2" color="error.main">
                Non-refundable booking
              </Typography>
            </Box>
          ) : (
            selectedRate.cancellationPolicies.cancelPolicyInfos.map((policy, index) => {
              const cancellationDate = new Date(policy.cancelTime);
              const isPastCancellationDate = new Date() > cancellationDate;
              
              return (
                <Box key={index} sx={{ mb: 1 }}>
                  <Typography 
                    variant="body2" 
                    color={isPastCancellationDate ? "error.main" : "text.secondary"}
                  >
                    {policy.type === 'FREE_CANCELLATION' ? 'Free cancellation until:' : 'Cancellation fee after:'} {cancellationDate.toLocaleDateString()}
                    {isPastCancellationDate && ' (Passed)'}
                  </Typography>
                  {policy.amount > 0 && (
                    <Typography 
                      variant="body2" 
                      color={isPastCancellationDate ? "error.main" : "text.secondary"}
                    >
                      Fee: ${policy.amount}
                    </Typography>
                  )}
                </Box>
              );
            })
          )}
          {selectedRate.cancellationPolicies.hotelRemarks.map((remark, index) => (
            <Typography key={index} variant="body2" color="text.secondary" sx={{ mt: 1 }}>
              {remark}
            </Typography>
          ))}
        </Box>
      </DialogContent>
      <DialogActions>
        <Button onClick={onClose}>Cancel</Button>
        <Button variant="contained" onClick={onConfirm} color="primary">
          Confirm Booking
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default BookingDialog;
