export const getFlightsQuery = `
query GetFlights($data: GetFlightInput) {
  getFlights(data: $data) {
    status
    data {
      selected_flight {
        flight_id
        price
        airline_logo
        total_duration
        airline
        token
        type
        flights {
          departure_airport {
            name
            id
            time
          }
          arrival_airport {
            name
            id
            time
          }
          duration
          airline
          airline_logo
          airplane
          travel_class
          flight_number
          extensions
        }
        search_parameters {
          departure_id
          arrival_id
          outbound_date
          return_date
        }
      }
      suggestions {
        flight_id
        price
        airline_logo
        total_duration
        airline
        token
        type
        flights {
          departure_airport {
            name
            id
            time
          }
          arrival_airport {
            name
            id
            time
          }
          duration
          airline
          airline_logo
          airplane
          travel_class
          flight_number
          extensions
        }
        search_parameters {
          departure_id
          arrival_id
          outbound_date
          return_date
        }
      }
      
    }
  }
}
`

export const getReturnFlightQuery = `
query GetReturnedFlights($data: FlightSearchInput) {
  getReturnedFlights(data: $data) {
    status
    data {
      flight_id
      price
      airline_logo
      total_duration
      airline
      token
      type
      flights {
        departure_airport {
          name
          id
          time
        }
        arrival_airport {
          name
          id
          time
        }
        duration
        airline
        airline_logo
        airplane
        travel_class
        flight_number
        extensions
      }
      search_parameters {
        departure_id
        arrival_id
        outbound_date
        return_date
      }
    }   
  }
}
`

export const getFlightBookingQuery = `
query GetFlightsBooking($data: FlightSearchInput) {
  getFlightsBooking(data: $data) {
    status
    data {
      selected_flights {
        flight_id
        price
        airline_logo
        total_duration
        airline
        token
        type
        flights {
          departure_airport {
            name
            id
            time
          }
          arrival_airport {
            name
            id
            time
          }
          duration
          airline
          airline_logo
          airplane
          travel_class
          flight_number
          extensions
        }
        search_parameters {
          departure_id
          arrival_id
          outbound_date
          return_date
        }
      }
      booking_options {
        book_with
        airline_logos
        marketed_as
        price
        baggage_prices
        local_prices {
          currency
          price
        }
        booking_request {
          url
          post_data
        }
      }
      price_insights {
        lowest_price
        price_level
        typical_price_range
        currency
      }
      baggage_prices
    }
  }
}
`

export const getFlightQuery = `
query GetFlight($data: TripIdInput) {
  getFlight(data: $data) {
    status
    data {
      selected_flight {
        flight_id
        price
        airline_logo
        total_duration
        airline
        token
        type
        flights {
          departure_airport {
            name
            id
            time
          }
          arrival_airport {
            name
            id
            time
          }
          duration
          airline
          airline_logo
          airplane
          travel_class
          flight_number
          extensions
        }
        search_parameters {
          departure_id
          arrival_id
          outbound_date
          return_date
        }
      }
      suggestions {
        flight_id
        price
        airline_logo
        total_duration
        airline
        token
        type
        flights {
          departure_airport {
            name
            id
            time
          }
          arrival_airport {
            name
            id
            time
          }
          duration
          airline
          airline_logo
          airplane
          travel_class
          flight_number
          extensions
        }
        search_parameters {
          departure_id
          arrival_id
          outbound_date
          return_date
        }
      }
    }
  }
}
`

export const persistFlightsMutation = `
  mutation PersistFlights($data: PersistFlightsInput!) {
    persistFlights(data: $data) {
      status
      data
    }
  }
`

export const getPersistedFlightsQuery = `
  query GetPersistedFlights($data: DataGenerateId) {
    getPersistedFlights(data: $data) {
      status
      data {
        flight_id
        price
        airline_logo
        total_duration
        airline
        token
        type
        flights {
          departure_airport {
            name
            id
            time
          }
          arrival_airport {
            name
            id
            time
          }
          duration
          airline
          airline_logo
          airplane
          travel_class
          flight_number
          extensions
        }
        search_parameters {
          departure_id
          arrival_id
          outbound_date
          return_date
        }
      }
    }
  }
`
