import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import ArrowForwardIcon from '@mui/icons-material/ArrowForward';
import { Alert, Box, Card, CardContent, Grid, LinearProgress, Typography } from '@mui/joy';
import { Button, Paper } from '@mui/material';
import React, { useEffect } from 'react';
import { useNavigate, useParams, useSearchParams } from 'react-router-dom';
import { useBookHotelMutation, useGetHotelDetailsNuiteeQuery } from '../../../../../redux/features/nuiteeHotel.api';
import { useAppSelector } from '../../../../../redux/hooks';

interface BookingDetailsProps {
}

const BookingDetails: React.FC<BookingDetailsProps> = () => {
  const navigate = useNavigate()
  const user = useAppSelector((state) => state.auth.user);
  const [bookHotel, { data, isLoading }] = useBookHotelMutation();
  const { requestId, subscriptionId, hotelId } = useParams();
  const { data: hotel, isLoading: loadingHotel } = useGetHotelDetailsNuiteeQuery(hotelId);
  const [searchParams] = useSearchParams();

  useEffect(() => {
    const prebookId = searchParams.get('prebookId');
    const transactionId = searchParams.get('transactionId');
    if (prebookId && transactionId) {
      bookHotel({
        "guestInfo": {
          "firstName": user?.name,
          "lastName": user?.surName,
          "email": user?.email,
        },
        "paymentDetails": {
          "transactionId": transactionId,
        },
        "prebookId": prebookId,
        clientReference: subscriptionId
      });
    }
  }, [bookHotel, searchParams, user?.email, user?.name, user?.surName, subscriptionId]);

  const bookingDetails = data?.responseObject?.data;
  const hotelDetails = hotel?.responseObject?.data;

  const formatDate = (dateString: string) => {
    return new Date(dateString).toLocaleDateString('en-US', {
      weekday: 'long',
      year: 'numeric',
      month: 'long',
      day: 'numeric'
    });
  };

  return (
    <Box>
      <Box sx={{ mb: 2, display: 'flex', justifyContent: 'space-between' }} >
        <Button
          startIcon={<ArrowBackIcon />}
          onClick={() => navigate(`/dashboard/travel/${requestId}/${subscriptionId}/hotel`)}
          sx={{
            textTransform: 'none',
            color: 'text.secondary'
          }}
        >
          Back to Hotels
        </Button>
        <Button
          onClick={() => navigate(`/dashboard/travel/${requestId}/${subscriptionId}/hotel/booking`)}
          sx={{
            textTransform: 'none',
            color: 'text.secondary'
          }}
          endIcon={<ArrowForwardIcon />}
        >
          My Hotel Bookings
        </Button>
      </Box>

      {(isLoading || loadingHotel) && <LinearProgress />}

      <Paper elevation={3} sx={{ p: 4, borderRadius: 2 }}>
        <Box sx={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center', mb: 3 }}>
          <Typography level="h3">Booking Confirmation</Typography>
          <Typography
            level="body-lg"
            sx={{
              px: 2,
              py: 1,
              borderRadius: 'sm',
              bgcolor: bookingDetails?.status === 'CONFIRMED' ? 'success.softBg' : 'warning.softBg',
              color: bookingDetails?.status === 'CONFIRMED' ? 'success.solidBg' : 'warning.solidBg'
            }}
          >
            {bookingDetails?.status}
          </Typography>
        </Box>

        <Grid container spacing={4}>
          {/* Hotel Information */}
          <Grid xs={12} md={6}>
            <Card>
              <CardContent>
                <Typography level="body-lg" sx={{ mb: 2 }}>Hotel Details</Typography>
                <Box sx={{ display: 'flex', flexDirection: 'column', gap: 1.5 }}>
                  <Typography level="body-lg">
                    <strong>Hotel:</strong> {hotelDetails?.name}
                  </Typography>
                  <Typography level="body-lg">
                    <strong>Address:</strong> {hotelDetails?.address}
                  </Typography>
                  <Typography level="body-lg">
                    <strong>Check-in:</strong> {formatDate(bookingDetails?.checkin || '')}
                  </Typography>
                  <Typography level="body-lg">
                    <strong>Check-out:</strong> {formatDate(bookingDetails?.checkout || '')}
                  </Typography>
                  <Typography level="body-lg">
                    <strong>Hotel Confirmation:</strong> {bookingDetails?.hotelConfirmationCode}
                  </Typography>
                </Box>
              </CardContent>
            </Card>
          </Grid>

          {/* Guest Information */}
          <Grid xs={12} md={6}>
            <Card>
              <CardContent>
                <Typography level="body-lg" sx={{ mb: 2 }}>Guest Information</Typography>
                <Box sx={{ display: 'flex', flexDirection: 'column', gap: 1.5 }}>
                  <Typography level="body-lg">
                    <strong>Guest Name:</strong> {bookingDetails?.holder?.firstName} {bookingDetails?.holder?.lastName}
                  </Typography>
                  <Typography level="body-lg">
                    <strong>Email:</strong> {bookingDetails?.holder?.email}
                  </Typography>
                  <Typography level="body-lg">
                    <strong>Phone:</strong> {bookingDetails?.holder?.phone || 'Not provided'}
                  </Typography>
                </Box>
              </CardContent>
            </Card>
          </Grid>

          {/* Room Details */}
          <Grid xs={12} md={6}>
            <Card>
              <CardContent>
                <Typography level="body-lg" sx={{ mb: 2 }}>Room Details</Typography>
                <Box sx={{ display: 'flex', flexDirection: 'column', gap: 1.5 }}>
                  <Typography level="body-lg">
                    <strong>Room Type:</strong> {bookingDetails?.bookedRooms?.[0]?.roomType?.name}
                  </Typography>
                  <Typography level="body-lg">
                    <strong>Adults:</strong> {bookingDetails?.bookedRooms?.[0]?.adults || 0}
                  </Typography>
                  <Typography level="body-lg">
                    <strong>Children:</strong> {bookingDetails?.bookedRooms?.[0]?.children || 0}
                  </Typography>
                  <Typography level="body-lg">
                    <strong>Board Type:</strong> {bookingDetails?.bookedRooms?.[0]?.boardType || 'Not specified'}
                  </Typography>
                </Box>
              </CardContent>
            </Card>
          </Grid>

          {/* Payment Information */}
          <Grid xs={12} md={6}>
            <Card>
              <CardContent>
                <Typography level="body-lg" sx={{ mb: 2 }}>Payment Details</Typography>
                <Box sx={{ display: 'flex', flexDirection: 'column', gap: 1.5 }}>
                  <Typography level="body-lg">
                    <strong>Total Amount:</strong> {bookingDetails?.currency} {bookingDetails?.price}
                  </Typography>
                  <Typography level="body-lg">
                    <strong>Booking ID:</strong> {bookingDetails?.bookingId}
                  </Typography>
                  <Typography level="body-lg">
                    <strong>Supplier Reference:</strong> {bookingDetails?.supplierBookingId}
                  </Typography>
                  <Typography level="body-lg">
                    <strong>Booking Date:</strong> {formatDate(bookingDetails?.createdAt || '')}
                  </Typography>
                </Box>
              </CardContent>
            </Card>
          </Grid>

          {/* Cancellation Policy */}
          <Grid xs={12}>
            <Card>
              <CardContent>
                <Typography level="body-lg" sx={{ mb: 2 }}>Cancellation Policy</Typography>
                {bookingDetails?.cancellationPolicies?.refundableTag === 'NRFN' ? (
                  <Alert color="warning" sx={{ mb: 2 }}>
                    This booking is non-refundable
                  </Alert>
                ) : (
                  <Box sx={{ display: 'flex', flexDirection: 'column', gap: 2 }}>
                    {bookingDetails?.cancellationPolicies?.cancelPolicyInfos?.map((policy, index) => (
                      <Box key={index}>
                        <Typography level="body-lg">
                          <strong>{policy.type === 'FREE_CANCELLATION' ? 'Free cancellation until:' : 'Cancellation fee after:'}</strong>
                          {' '}{formatDate(policy.cancelTime)}
                        </Typography>
                        {policy.amount > 0 && (
                          <Typography level="body-lg">
                            Fee: ${policy.amount}
                          </Typography>
                        )}
                      </Box>
                    ))}
                  </Box>
                )}
                {bookingDetails?.cancellationPolicies?.hotelRemarks?.map((remark, index) => (
                  <Typography key={index} level="body-sm" sx={{ mt: 1, color: 'neutral.500' }}>
                    {remark}
                  </Typography>
                ))}
              </CardContent>
            </Card>
          </Grid>
        </Grid>
      </Paper>
    </Box>
  );
};

export default BookingDetails;
