import { lazy } from "react";
import { createBrowserRouter } from "react-router-dom";
import Feedback from '../pages/admin/feedback/feedback';
import Search from '../pages/admin/search/search';
import ForgotPassword from '../pages/auth/forgotPassword';
import Login from '../pages/auth/login';
import ResetPassword from '../pages/auth/resetPassword';
import Signup from '../pages/auth/signup';
import BlogPage from "../pages/blog";
import Blogs from "../pages/blogs";
import Book from '../pages/book';
import Bookmarks from '../pages/bookmarks';
import DashboardPage from '../pages/dashboard';
import Error404 from '../pages/error/404';
import ExportPage from "../pages/export";
import FormSearch from '../pages/form';
import Home from '../pages/home';
import PaymentPage from "../pages/payment";
import PaymentSuccess from "../pages/paymentSuccess";
import Profile from '../pages/profile';
import RequestDemo from "../pages/requestDemo";
import TermsPage from "../pages/terms";
import TravelTabs from '../pages/travelTabs';
import Flight from "../pages/travelTabs/flight";
import BookingRoom from "../pages/travelTabs/hotel/nuitee/booking";
import BookingDetails from "../pages/travelTabs/hotel/nuitee/bookingDetails";
import Bookings from "../pages/travelTabs/hotel/nuitee/bookings";
import HotelDetailsPage from "../pages/travelTabs/hotel/nuitee/details";
import ItineraryContent from "../pages/travelTabs/itinerary";
import ProductDetails from "../pages/travelTabs/itinerary/productDetails";
import ProtectedRoute from './ProtectedRoute';
const RequestDemoContainer = lazy(() => import("../containers/requestDemo"));
const EnterpriseForm = lazy(() => import("../pages/enterpriseForm"));
const NuiteeHotels = lazy(() => import("../pages/travelTabs/hotel/nuitee"));

// lazy loading
const AdminContent = lazy(() => import('../containers/admin'))
const Auth = lazy(() => import('../containers/auth'))
const Dashboard = lazy(() => import('../containers/dashboard'))
const ErrorContainer = lazy(() => import('../containers/error'))
const HomeContainer = lazy(() => import('../containers/home'))
const PaymentContainer = lazy(() => import("../containers/payment"));

const router = createBrowserRouter([
  {
    path: "/",
    element: <HomeContainer />,
    children: [
      {
        path: "",
        element: <Home />,
      },

    ],
    errorElement: <ErrorContainer />
  },
  {
    path: "/",
    element: <RequestDemoContainer />,
    children: [
      {
        path: "/requestDemo",
        element: <RequestDemo />
      },
      {
        path: "/enterprisePlan",
        element: <EnterpriseForm />
      },
      {
        path: "/terms",
        element: <TermsPage />
      },
      {
        path: "/blogs",
        element: <Blogs />
      },
      {
        path: "/blog/:blogId",
        element: <BlogPage />
      }
    ]
  },
  {
    path: "/404",
    element: <Error404 />,
  },
  {
    path: '/dashboard',
    element: (
      <ProtectedRoute type="private">
        <Dashboard />
      </ProtectedRoute>
    ),
    errorElement: <ErrorContainer />,
    children: [
      {
        path: "/dashboard",
        element: <DashboardPage />,
      },
      {
        path: "/dashboard/profile",
        element: <Profile />
      },
      {
        path: "/dashboard/form",
        element: <FormSearch />
      },
      {
        path: "/dashboard/bookmarks",
        element: <Bookmarks />,
      },
      {
        path: "/dashboard/book/:requestId",
        element: <Book />
      },
      {
        path: "/dashboard/export/:requestId/:subscriptionId",
        element: <ExportPage />
      },
      {
        path: "/dashboard/travel/:requestId/:subscriptionId",
        element: <TravelTabs />,
        children: [
          {
            index: true,
            element: <ItineraryContent />
          },
          {
            path: "hotel",
            children: [
              {
                index: true,
                element: <NuiteeHotels />
              },
              {
                path: ":hotelId",
                element: <HotelDetailsPage />
              },
              {
                path: ":hotelId/booking/:offerId",
                element: <BookingRoom />
              },
              {
                path: ":hotelId/booking/:offerId/book",
                element: <BookingDetails />
              },
              {
                path: "booking",
                element: <Bookings />
              },

            ]
          },
          {
            path: "flight",
            element: <Flight />
          },
          {
            path: "itinerary/:productId",
            element: <ProductDetails />
          }
        ]
      },
    ]
  },
  {
    path: "/auth",
    element: <Auth />,
    children: [
      {
        path: "/auth/login",
        element: <Login />,
      },
      {
        path: "/auth/signup",
        element: (
          <ProtectedRoute type="auth">
            <Signup />
          </ProtectedRoute>
        ),
      },
      {
        path: "/auth/forgot-password",
        element: <ProtectedRoute type="auth"><ForgotPassword /></ProtectedRoute>,
      },
      {
        path: "/auth/reset-password/:token",
        element: <ProtectedRoute type="auth"><ResetPassword /></ProtectedRoute>,
      },
    ],
    errorElement: <ErrorContainer />
  },
  {
    path: "/admin",
    element: <AdminContent />,
    children: [
      {
        path: "/admin/userssearch",
        element: <Search />,
      },
      {
        path: "/admin/usersfeedback",
        element: <Feedback />,
      },
    ],
    errorElement: <ErrorContainer />
  },
  {
    path: "/payment",
    element: (
      <ProtectedRoute type="dashboard">
        <PaymentContainer />
      </ProtectedRoute>
    ),
    children: [
      {
        path: "/payment",
        element: <PaymentPage />
      },
      {
        path: "/payment/success",
        element: <PaymentSuccess />
      },
    ],
    errorElement: <ErrorContainer />
  }
]);

export default router;