import LocationOnIcon from '@mui/icons-material/LocationOn';
import { Text, View } from '@react-pdf/renderer';
import React from 'react';
import { createTw } from 'react-pdf-tailwind';
import { MaterialIcon } from '../../shared/MaterialIcon';

const tw = createTw({});

interface AccommodationItemProps {
  name: string;
  type: string;
  checkIn: string;
  checkOut: string;
  costPerNightUsd: number;
  address: string;
  primaryColor: string;
  secondaryColor: string;
  isFirst: boolean;
}

const AccommodationItem: React.FC<AccommodationItemProps> = (props) => {
  return (
    <View wrap={false} style={props.isFirst ? tw('border-t') : undefined}>
      <View style={tw('flex flex-row')}>
        <Text style={tw('flex-1 text-xs p-2')}>{props.name}</Text>
        <Text style={tw('flex-1 text-xs p-2')}>{props.type}</Text>
        <Text style={tw('flex-1 text-xs p-2')}>{props.checkIn}</Text>
        <Text style={tw('flex-1 text-xs p-2')}>{props.checkOut}</Text>
        <Text style={tw('flex-1 text-xs p-2')}>
          {props.costPerNightUsd.toLocaleString('en-US', { style: 'currency', currency: 'USD' })}
        </Text>
      </View>
      <View style={[tw('p-2 border-t flex flex-col gap-y-2 mx-4'), { borderColor: props.secondaryColor }]}>
        <View style={tw('flex flex-row items-center mx-auto')}>
          <MaterialIcon icon={LocationOnIcon} className="w-4 h-4 mr-2" style={{ color: props.primaryColor }} />
          <Text style={tw('text-xs')}>{props.address}</Text>
        </View>
      </View>
    </View>
  );
};

export default AccommodationItem;
