import { yupResolver } from "@hookform/resolvers/yup";
import AddIcon from '@mui/icons-material/Add';
import { Box, Button, Card, CardActions, CardContent, FormHelperText, FormLabel, Input, LinearProgress, Textarea, Typography } from "@mui/joy";
import { FormControl } from "@mui/material";
import { useEffect, useState } from "react";
import { Controller, useForm } from "react-hook-form";
import { useParams } from "react-router-dom";
import { toast } from "react-toastify";
import { InputReactNumberFormatUSD } from "../../../components/inputs/input";
import TimeSelector from "../../../components/inputs/time";
import { useAddActivityByDayMutation, useGetActivitiesByDayQuery, useLazyGetActivitiesByDayQuery } from "../../../redux/features/itineraryApi";
import { useAppSelector } from "../../../redux/hooks";
import { validationSchemaAddActivity } from "../../../utils/formYupValidation";
import { convertTime } from "../../../utils/functions";
import { ActivityOfDay } from "../../../utils/types";

type CardAddActivityProps = {
  setShowAddActivity: (close: boolean) => void;
  number: string
}


type Activity = {
  title: string;
  description?: string;
  type: string;
  web_url?: string;
  budget: string;
  scheduled_startTime: string;
  scheduled_endTime: string;
}

const CardAddActivity: React.FC<CardAddActivityProps> = ({ setShowAddActivity, number }) => {
  const user = useAppSelector((state) => state.auth.user)
  const params = useParams()
  const requestId = params.requestId
  const subscriptionId = params.subscriptionId;
  const [getActivityByDay] = useLazyGetActivitiesByDayQuery()
  const [addActivity, { isLoading }] = useAddActivityByDayMutation()
  const { data: dataActivityByDay } = useGetActivitiesByDayQuery({ id: requestId, subscription_id: subscriptionId, user: { id: user?.id }, number })
  const [startTime1, setStartTime] = useState<any>(null)

  const { handleSubmit, control, formState: { errors }, getValues, watch, setError } = useForm({
    resolver: yupResolver(validationSchemaAddActivity),
    defaultValues: {
      budget: '',
      description: '',
      scheduled_startTime: '',
      scheduled_endTime: '',
      title: '',
      type: '',
      web_url: ''
    },
  });

  useEffect(() => {
    if (watch('scheduled_startTime')) {
      setStartTime(watch('scheduled_startTime'))
    }

  }, [watch('scheduled_startTime')])

  const onSubmit = async (data: Activity) => {
    if (user) {

      if (data.scheduled_startTime < data.scheduled_endTime) {
        setError('scheduled_endTime', { type: "manual", message: '' })
      } else {
        setError('scheduled_endTime', { type: "manual", message: 'The end time must be greater than start time' })
        return;
      }
  
      const activity = {
        scheduled_time: convertTime(new Date(data.scheduled_startTime).toString()) + ' - ' + convertTime(new Date(data.scheduled_endTime).toString()),
        title: data.title,
        description: data.description,
        type: data.type,
        web_url: data.web_url,
        budget: data.budget,
      }
      const dataInput = {
        trip_id: { id: requestId, subscription_id: subscriptionId, user: { id: user.id } },
        activity,
        number
      }
      const activityResponse = await addActivity({ ...dataInput }).unwrap()
      const { success } = activityResponse?.data ?? {};
      const isSuccess = success === true;
      if(activityResponse?.status === 401){
        toast.warn(activityResponse?.message)
        return;
      }
  
      if (isSuccess) {
        await getActivityByDay({ id: requestId, subscription_id: subscriptionId, user: { id: user.id }, number }).unwrap()
        toast.success("Your activity was added successfully");
      } else {
        toast.error("Failed to add activity. Please try again later");
      }
  
      if (isSuccess) {
        setShowAddActivity(false);
      }
    }
  };

  const excludedTimes = () => {
    return dataActivityByDay?.data?.map((item: ActivityOfDay) => {
      return { time: item.scheduled_time }
    }) || []
  }

  return (
    <Card variant="outlined">
      {isLoading && <LinearProgress />}
      <form onSubmit={handleSubmit(onSubmit)}>
        <CardContent className='!gap-4'>
          <Box className='flex gap-2 items-center'>
            <AddIcon fontSize="medium" />
            <Typography level="body-lg" className="!text-base md:!text-xl">Add Activity</Typography>
          </Box>
          <Box className='flex gap-3 flex-wrap items-start'>
            <FormControl sx={{ minWidth: { md: 380, xs: '100%' } }} className="gap-2" >
              <FormLabel >Activity title</FormLabel>
              <Controller
                name="title"
                control={control}
                rules={{ required: true }}
                render={({ field }) => (
                  <Input  {...field} placeholder="Activity title" variant="outlined" sx={{ minWidth: { md: 380, xs: '100%' }, minHeight: 56 }} />
                )}
              />
              {!!errors.title?.message && (
                <FormHelperText
                  sx={(theme) => ({
                    color: theme.vars.palette.danger[400],
                    fontSize: "xs",
                  })}
                >
                  {errors.title?.message}
                </FormHelperText>
              )}
            </FormControl  >
            <FormControl sx={{ minWidth: { md: 190, xs: '100%' } }}>
              <Controller
                name="budget"
                control={control}
                render={({ field }) => (
                  <InputReactNumberFormatUSD
                    field={field}
                    label='Budget'
                    error={errors?.budget?.message?.toString() || ''}
                    placeholder='Activity cost'
                    sx={{ minWidth: { md: 190, xs: '100%' } }}
                  />
                )}
              />
            </FormControl>
          </Box>
          <Box className='flex gap-3  flex-wrap items-start'>
            <FormControl sx={{ minWidth: { md: 185, xs: '100%' } }} className="gap-2" >
              <FormLabel >Start time</FormLabel>
              <Controller
                name="scheduled_startTime"
                control={control}
                rules={{ required: true }}
                render={({ field }) => (
                  <TimeSelector field={field} excludedTimes={excludedTimes()} />
                )}
              />
              {!!errors.scheduled_startTime?.message && (
                <FormHelperText
                  sx={(theme) => ({
                    color: theme.vars.palette.danger[400],
                    fontSize: "xs",
                  })}
                >
                  {errors.scheduled_startTime?.message}
                </FormHelperText>
              )}
            </FormControl>
            <FormControl sx={{ minWidth: { md: 185, xs: '100%' } }} className="gap-2" >
              <FormLabel >End time</FormLabel>
              <Controller
                name="scheduled_endTime"
                control={control}
                rules={{ required: true }}
                render={({ field }) => (
                  <TimeSelector field={field} excludedTimes={excludedTimes()} startTime={getValues('scheduled_startTime')} />
                )}
              />
              {!!errors.scheduled_endTime?.message && (
                <FormHelperText
                  sx={(theme) => ({
                    color: theme.vars.palette.danger[400],
                    fontSize: "xs",
                  })}
                >
                  {errors.scheduled_endTime?.message}
                </FormHelperText>
              )}
            </FormControl>
            <FormControl sx={{ minWidth: { md: 180, xs: '100%' } }} className="gap-2" >
              <FormLabel >Type of activity</FormLabel>
              <Controller
                name="type"
                control={control}
                rules={{ required: true }}
                render={({ field }) => (
                  <Input  {...field} type="text" placeholder="ex: Cafe, Restaurant ..." variant="outlined" sx={{ minWidth: { md: 170, xs: '100%' }, minHeight: 56 }} />
                )}
              />
              {!!errors.type?.message && (
                <FormHelperText
                  sx={(theme) => ({
                    color: theme.vars.palette.danger[400],
                    fontSize: "xs",
                  })}
                >
                  {errors.type?.message}
                </FormHelperText>
              )}
            </FormControl>
          </Box>
          <Box className='flex gap-3 items-center flex-wrap'>
            <FormControl sx={{ minWidth: '100%' }} className="gap-2" >
              <FormLabel >Web site of activity</FormLabel>
              <Controller
                name="web_url"
                control={control}
                rules={{ required: true }}
                render={({ field }) => (
                  <Input  {...field} type="url" placeholder="Web site of activity" variant="outlined" sx={{ minWidth: '100%', minHeight: 56 }} />
                )}
              />
              {!!errors.web_url?.message && (
                <FormHelperText
                  sx={(theme) => ({
                    color: theme.vars.palette.danger[400],
                    fontSize: "xs",
                  })}
                >
                  {errors.web_url?.message}
                </FormHelperText>
              )}
            </FormControl>
          </Box>
          <Box className='flex gap-3 items-center flex-wrap'>
            <FormControl sx={{ minWidth: '100%' }} className="gap-2" >
              <FormLabel >Description of activity</FormLabel>
              <Controller
                name="description"
                control={control}
                rules={{ required: true }}
                render={({ field }) => (
                  <Textarea  {...field} placeholder="Description of activity" variant="outlined" sx={{ minWidth: '100%', minHeight: 56 }} />
                )}
              />
              {!!errors.description?.message && (
                <FormHelperText
                  sx={(theme) => ({
                    color: theme.vars.palette.danger[400],
                    fontSize: "xs",
                  })}
                >
                  {errors.description?.message}
                </FormHelperText>
              )}
            </FormControl>
          </Box>

        </CardContent>
        <CardActions className='justify-center'>
          <Button disabled={isLoading} variant="outlined" size="md" onClick={() => setShowAddActivity(false)}>
            Discard
          </Button>
          <Button loading={isLoading} type="submit" variant="solid" size="md">
            Save
          </Button>
        </CardActions>
      </form>
    </Card>
  );
}


export default CardAddActivity;