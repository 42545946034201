import { Container } from '@mui/joy';
import React from 'react';

const TermsPage: React.FC = () => {
  return (
    <Container maxWidth='lg'>
      <div className="container mx-auto py-8 pt-32">
        <h2 className="text-2xl font-bold mb-4">Navoy Terms of Use</h2>
        <div className='mb-4'><span className='text-lg font-semibold'>Last Updated</span>: <span className='text-lg font-medium'>September 3rd, 2024</span></div>

        {/* Existing sections */}
        <div className='text-base font-medium mb-4'>1 Acceptance of Terms</div>
        <div className='text-base font-normal mb-4'>
          By accessing or using Navoy's services, website, or mobile application (collectively, the
          "Services"), you agree to be bound by these Terms of Use ("Terms"). If you do not agree to
          these Terms, please do not use the Services
        </div>

        <div className='text-base font-medium mb-4'>
          2. Description of Services
        </div>
        <div className='text-base font-normal mb-4'>
          Navoy provides an AI-powered travel platform that offers personalized itineraries and travel
          planning services. The Services may include booking options, travel recommendations, and
          other features designed to enhance your travel experience.
        </div>
        <div className='text-base font-normal mb-4'>
          Please note that you may be accessing a beta version of our platform, which is still under
          development. While we strive to provide a seamless experience, there may be bugs or
          incomplete features. Your feedback is valuable in helping us improve the final product.
        </div>

        {/* New sections */}
        <div className='text-base font-medium mb-4'>3. Eligibility</div>
        <div className='text-base font-normal mb-4'>
          You must be at least 18 years old to use the Services. By using the Services, you represent
          and warrant that you have the legal capacity to enter into this agreement.
        </div>

        <div className='text-base font-medium mb-4'>4. Account Registration</div>
        <div className='text-base font-normal mb-4'>
          To access certain features of the Services, you may be required to create an account. You
          agree to provide accurate, current, and complete information during the registration process
          and to update such information as needed. You are responsible for maintaining the
          confidentiality of your account credentials and for all activities that occur under your account.
        </div>

        <div className='text-base font-medium mb-4'>5. User Conduct</div>
        <div className='text-base font-normal mb-4'>
          You agree not to use the Services for any unlawful or prohibited purpose. You agree not to
          engage in any activity that could damage, disable, or impair the Services or interfere with
          any other party's use of the Services.
        </div>

        <div className='text-base font-medium mb-4'>6. Intellectual Property</div>
        <div className='text-base font-normal mb-4'>
          All content, trademarks, and other intellectual property available through the Services are the
          property of Navoy or its licensors. You may not use, reproduce, distribute, or create
          derivative works from any content provided through the Services without explicit permission
          from Navoy.
        </div>

        <div className='text-base font-medium mb-4'>7. Privacy Policy</div>
        <div className='text-base font-normal mb-4'>
          Your use of the Services is also governed by our Privacy Policy, which is incorporated by
          reference into these Terms. Please review our Privacy Policy to understand our practices.
        </div>
        <div className='text-base font-medium mb-4'>8. Payment Terms</div>
        <div className='text-base font-normal mb-4'>
          Certain features of the Services may be subject to fees. By using these features, you agree
          to pay all applicable fees and taxes. Navoy reserves the right to change its pricing and fees
          at any time.
        </div>

        <div className='text-base font-medium mb-4'>9. Cancellations and Refunds</div>
        <div className='text-base font-normal mb-4'>
          All purchases made through the Services are final and non-refundable, unless otherwise
          stated in our cancellation policy. Please review specific terms for bookings or purchases
          made through our platform.
        </div>

        <div className='text-base font-medium mb-4'>10. Limitation of Liability</div>
        <div className='text-base font-normal mb-4'>
          Navoy provides the Services "as is" and makes no warranties, whether express, implied, or
          statutory, including warranties of merchantability, fitness for a particular purpose, and
          non-infringement. Navoy shall not be liable for any indirect, incidental, special,
          consequential, or punitive damages arising out of or related to your use of the Services.
        </div>

        <div className='text-base font-medium mb-4'>11. Indemnification</div>
        <div className='text-base font-normal mb-4'>
          You agree to indemnify and hold harmless Navoy, its affiliates, and their respective directors,
          officers, employees, and agents from and against any claims, liabilities, damages, losses,
          and expenses, including reasonable attorney's fees, arising out of or in any way connected
          with your access to or use of the Services.
        </div>

        <div className='text-base font-medium mb-4'>12. Termination</div>
        <div className='text-base font-normal mb-4'>
          Navoy reserves the right to suspend or terminate your account or access to the Services at
          any time, without notice, for conduct that Navoy believes violates these Terms or is harmful
          to other users of the Services, Navoy, or third parties.
        </div>

        <div className='text-base font-medium mb-4'>13. Governing Law</div>
        <div className='text-base font-normal mb-4'>
          These Terms shall be governed by and construed in accordance with the laws of the State of
          New York, without regard to its conflict of law principles. Any legal action or proceeding
          arising under these Terms shall be brought exclusively in the courts located in New York.
        </div>

        <div className='text-base font-medium mb-4'>14. Changes to Terms</div>
        <div className='text-base font-normal mb-4'>
          Navoy may update these Terms from time to time. We will notify you of any material changes
          by posting the new Terms on our website. Your continued use of the Services after such
          changes have been made will constitute your acceptance of the new Terms.
        </div>

        <div className='text-base font-medium mb-4'>15. Contact Information</div>
        <div className='text-base font-normal mb-12'>
          If you have any questions about these Terms, please contact us at [contact@navoy.io].
        </div>
        <div className='text-base font-semibold'>Navoy Inc.</div>
        <div className='text-base font-normal'>548 Market St,</div>
        <div className='text-base font-normal'>San Francisco, California, 94104</div>
        <div className='text-base font-normal'>Email: [contact@navoy.io]</div>
      </div>
    </Container>
  );
};

export default TermsPage;
