import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { INotification } from "../../utils/types";

const initialState: INotification = { message: '', color: 'warning', open: false }
const notificationSlice = createSlice({
  name: 'notification',
  initialState,
  reducers: {
    setNotification(state, action: PayloadAction<INotification>) {
      const { message, color, open } = action.payload
      state.message = message
      state.color = color
      state.open = open
    }
  }
})

export const { setNotification } = notificationSlice.actions
export default notificationSlice.reducer