import { CircularProgress, Modal } from "@mui/joy";

type LoadingModalProps = {
  openLoading: boolean,
  setOpenLoading: (value: boolean) => void
}

const LoadingModal: React.FC<LoadingModalProps> = ({ openLoading, setOpenLoading }) => {
  return (
    <Modal
      aria-labelledby="modal-title"
      aria-describedby="modal-desc"
      open={openLoading}
      onClose={() => setOpenLoading(false)}
      sx={{ display: "flex", justifyContent: "center", alignItems: "center" }}
    >
      <CircularProgress variant="soft" color="danger" />
    </Modal>
  );
}


export default LoadingModal;