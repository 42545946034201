import React from 'react';
import { Card, CardContent, CardMedia, Typography, Button, CardActions } from '@mui/material';
import ShareIcon from '@mui/icons-material/Share';
import { Blog } from '../../utils/types';
import { Link } from 'react-router-dom';
import { BLOG_URL } from '../../utils/env';


interface BlogCardProps {
  blog: Blog;
}

const BlogCard: React.FC<BlogCardProps> = ({ blog }) => {
  const handleShare = () => {
    // Implement share functionality here
    console.log('Sharing blog:', blog.title);
  };

  return (
    <Card sx={{ height: '100%', display: 'flex', flexDirection: 'column' }}>
      <CardMedia
        component="img"
        sx={{ height: 200 }}
        image={BLOG_URL + blog.image.formats.medium.url}
        alt={blog.title}
      />
      <CardContent sx={{ flexGrow: 1 }}>
        <Typography gutterBottom variant="h5" component="h2">
          {blog.title}
        </Typography>
        <Typography>
          {blog.description}
        </Typography>
      </CardContent>
      <CardActions sx={{ justifyContent: 'space-between', px: 2, pb: 2 }}>
        <Button component={Link} to={`/blog/${blog.slug}`} size="small">Read More</Button>
        <Button size="small" startIcon={<ShareIcon />} onClick={handleShare}>
          Share
        </Button>
      </CardActions>
    </Card>
  );
};

export default BlogCard;