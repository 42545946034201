import { Column, SortOption } from "./types";

export const marksBudget = [
  { value: 100, label: "100 USD" },
  { value: 1000000, label: "1M USD" },
];

export const modeList = [
  { value: "", label: "Travel purpose ?" },
  { value: "Business", label: "Business" },
  { value: "Leisure", label: "Leisure" },
  { value: "Honeymoon", label: "Honeymoon" },
  { value: "Cultural", label: "Cultural inclusion" },
  { value: "Adventure", label: "Adventure" },
  { value: "Shopping", label: "Shopping" },
];

export const tripPurpose = [
  { value: "Business", label: "Business" },
  { value: "Leisure", label: "Leisure" },
  { value: "Honeymoon", label: "Honeymoon" },
  { value: "Cultural", label: "Cultural inclusion" },
  { value: "Adventure", label: "Adventure" },
  { value: "Shopping", label: "Shopping" },
  { value: "Multi_purpose", label: "Multi-purpose" },
]

export const activitiesList = [
  'Outdoors',
  'Big family',
  'Vegan',
  'Pet friendly',
  'Sight Seeing',
  'Music',
  'Opera',
  'Festivals',
  'Michelin Starred',
  'Ski',
  'Sea Diving',
  'Beach vibes',
  'Sports',
  'Formula One',
  'Business events',
  'Luxury Shopping',
  'Wholesale places'
]

export const preferredAccommodationList = [
  { value: "hotel", label: "Hotel" },
  { value: "airbnb", label: "Airbnb" },
  { value: "private_villa", label: "Private Villa" },
]

export const travelerStyleList = [
  { value: "minimalist", label: "Minimalist" },
  { value: "modern", label: "Modern" },
  { value: "traditional", label: "Traditional" },
  { value: "artistic", label: "Artistic" },
  { value: "geeky", label: "Geeky" },
]

export const numberActivitiesList = [
  { value: '1', label: '1' },
  { value: '2', label: '2' },
  { value: '3', label: '3' },
  { value: '4', label: '4' },
  { value: '5', label: '5' },
  { value: '6', label: '6' },
  { value: '7', label: '7' },
  { value: '8', label: '8' },
]

export const ratingList: { [index: string]: string } = {
  1: "Useless",
  2: "Average",
  3: "Above average",
  4: "Useful",
  5: "Very useful",
};

export const feedBackImproveList = [
  "Reduce execution time",
  "Present the results in a better way",
  "Make the results more accurate/relevant",
];

export const drawerWidth = 250;

export const maxAddDays = 6

export const gridLayout = {
  spacing: { xs: 2, md: 3 },
  columns: { xs: 4, md: 8, lg: 12, xl: 12 },
  item: { xs: 4 }
}

export const columnsFlight: readonly Column[] = [
  { id: 'icon', label: '', minWidth: 50 },
  { id: 'name', label: 'Name', minWidth: 170 },
  { id: 'time', label: 'Time', minWidth: 140 },
  { id: 'duration', label: 'Duration', minWidth: 130 },
  { id: 'stops', label: 'Stops', minWidth: 120 },
  { id: 'cost', label: 'Cost', minWidth: 80 },
  { id: 'type', label: 'Type', minWidth: 150 },
  { id: 'action', label: 'Action', minWidth: 160 },
]

export const sortListBookmarks: SortOption[] = [
  { id: 'name', label: 'Trip name' },
  { id: 'start_date', label: 'Date' },
  { id: 'given_budget', label: 'Estimated cost', },
  { id: 'last_edited', label: 'Last edited', }
]