import MenuIcon from '@mui/icons-material/Menu';
import { Button } from '@mui/joy';
import { Container } from "@mui/material";
import IconButton from "@mui/material/IconButton";
import React, { useState } from "react";
import { Link } from "react-router-dom";
import logoBlue from '../../assets/images/logo/blue_host.png';

import Sidebar from '../sidebar';
import RequestDemoSidebar from '../sidebar/requestDemo';


const NavbarRequestDemo: React.FC<any> = () => {
  const [mobileOpen, setMobileOpen] = useState<boolean>(false);

  const [isClosing, setIsClosing] = useState(false);

  const handleDrawerToggle = () => {
    if (!isClosing) {
      setMobileOpen(prev => !prev);
    }
  };


  return (
    <div
      className={"fixed top-0 duration-300 z-10 flex justify-end items-center laptop:h-20 h-16 w-full mx-auto font-semibold text-lg "}
    >
      <Container maxWidth="lg">
        <div className="flex justify-end items-center">
          <ul className="hidden laptop:flex text-navoy_blue-950 items-center flex-1 gap-2">

            <Link to="/" className="block">
              <img src={logoBlue} alt="logo" className="h-[2rem]" />
            </Link>

            <Link to={'/auth/login'} className='ms-auto'>
              <Button id="login" size='lg' className="!bg-navoy_blue-950   !text-lg">Login</Button>
            </Link>
          </ul>

          <Link to="/" className="block me-auto">
            <img src={logoBlue} alt="logo" className="w-[6rem] laptop:hidden" />
          </Link>
          <IconButton
            color="inherit"
            aria-label="open drawer"
            edge="start"
            onClick={handleDrawerToggle}
            sx={{ mr: 2, display: { laptop: "none" } }}
          >
            <MenuIcon sx={theme => ({ color: theme.palette.navoy_blue[950] })} />
          </IconButton>
        </div>
      </Container>
      <Sidebar
        desktopOpen={false}
        setIsClosing={setIsClosing}
        setMobileOpen={setMobileOpen}
        mobileOpen={mobileOpen}
        drawer={<RequestDemoSidebar setMobileOpen={(b: boolean) => setMobileOpen(b)} />}
      />
    </div>
  );
};

export default NavbarRequestDemo;
