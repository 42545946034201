import { Box } from "@mui/joy";
import logo from "../../../assets/images/logo/blue_2.png";
import { Link } from "react-router-dom";

type LogoProps = {};

const Logo: React.FC<LogoProps> = () => {
  return (
    <Box className="mb-6 md:mb-8">
      <Link to="/">
        <img src={logo} alt="Logo" className="mx-auto mb-1 w-28 " />
      </Link>
    </Box>
  );
};

export default Logo;
