import CheckCircleIcon from '@mui/icons-material/CheckCircle';
import KeyboardArrowRightIcon from '@mui/icons-material/KeyboardArrowRight';
import RadioButtonUncheckedIcon from '@mui/icons-material/RadioButtonUnchecked';
import { Button, IconButton, useTheme } from "@mui/joy";
import { useMediaQuery } from "@mui/material";
import { toast } from 'react-toastify';
import { BookingOptionFlight } from "../../../utils/types";
type BookingFlightProps = {
  item: BookingOptionFlight;
  select: string
  setSelect: (select: string) => void
}

const BookingFlight: React.FC<BookingFlightProps> = ({ item, select, setSelect }) => {
  const theme = useTheme();
  const matches = useMediaQuery(theme.breakpoints.up("laptop"));

  const handleLinkClick = (event: any) => {
    if (!item.booking_request.url) {
      event.preventDefault(); 
      toast.warn('Something went wrong, please try an other flight!')
    }
  };
  return (
    <div className='flex items-center'>
      <div>
        {select === item.book_with ?
          <IconButton onClick={() => setSelect("")}><CheckCircleIcon className='text-green-500' /></IconButton> :
          <IconButton onClick={() => setSelect(item.book_with)}><RadioButtonUncheckedIcon /></IconButton>}
      </div>
      <a
        href={item.booking_request.url ? item.booking_request.url + '?' + item.booking_request?.post_data : '/'}
        target="_blank" rel="noreferrer"
        onClick={handleLinkClick}
        className='flex-1'
      >
        <div className="flex items-center px-6 py-3 pe-1 flex-nowrap gap-2">
          <div className="flex flex-grow items-center gap-3">
            <div className="flex flex-shrink-0">
              <img src={item.airline_logos[0]} alt="logo" className="h-9 w-9" />
            </div>
            <div className="flex-shrink py-4 me-auto">
              <div className="items-baseline flex">
                <div className='text-navoy_bg-700 text-md poppins-medium'>
                  {item.book_with}
                </div>
              </div>
            </div>
            <div className="ms-auto flex-col flex items-center text-right text-md text-navoy_bg-700 poppins-medium">
              <div><span className="text-green-700">US${item.price}</span></div>
              <div><span className="poppins-regular text-sm">{item.local_prices?.[0]?.currency}{item.local_prices?.[0]?.price}</span></div>
            </div>
          </div>
          <div className="flex-shrink-0 justify-center ps-2  flex-initial flex">
            {
              matches ?
                <Button
                  variant="outlined"
                  color="primary"
                  className=" whitespace-nowrap rounded-2xl px-6 py-1"
                  endDecorator={<KeyboardArrowRightIcon />}
                >
                  Visit site
                </Button> :
                <KeyboardArrowRightIcon />
            }
          </div>
        </div>
      </a>
      
    </div>
  );
}


export default BookingFlight;