
import { Box, Container, LinearProgress, Typography } from '@mui/joy';
import { useParams } from 'react-router-dom';
import { useGetBlogQuery } from '../../redux/features/blog.api';
import { BLOG_URL } from '../../utils/env';

const BlogPage = () => {
  const { blogId } = useParams();
  const { data, isLoading } = useGetBlogQuery(blogId)
  // const [loading, setLoading] = useState(true);


  if (isLoading) return <LinearProgress />;

  return (
    <Container maxWidth="lg" className="pt-8">
      <Box className="py-8">
        <Typography level="h1" className="!mb-4">
          {data?.data?.[0].title}
        </Typography>

        <img
          loading='lazy'
          src={BLOG_URL + data?.data?.[0].image.url}
          alt={BLOG_URL + data?.data?.[0].image.alternativeText}
          className="w-full h-[400px] object-cover rounded-lg mb-6"
        />

        <Typography className="!text-gray-600 !mb-4">
          Published on: {data?.data?.[0].createdAt && new Date(data?.data?.[0].createdAt).toLocaleDateString()}
        </Typography>

        {data?.data?.[0].ckeditor && <div
          className="prose max-w-none"
          dangerouslySetInnerHTML={{ __html: data?.data?.[0].ckeditor }}
        />}
      </Box>
    </Container>
  );
};

export default BlogPage;
