import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import { Box, LinearProgress } from "@mui/joy";
import { Button } from "@mui/material";
import { useEffect } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { usePreBookHotelMutation } from "../../../../../redux/features/nuiteeHotel.api";
import { useAppSelector } from "../../../../../redux/hooks";

type BookingRoomProps = {}

const BookingRoom: React.FC<BookingRoomProps> = () => {
  const navigate = useNavigate();
  const { offerId, requestId, subscriptionId, hotelId } = useParams();
  const [preBookHotel, { data, isLoading: preBookLoading }] = usePreBookHotelMutation();
  const user = useAppSelector((state) => state.auth.user);

  useEffect(() => {
    if (offerId) {
      preBookHotel({ offerId, usePaymentSdk: true });
    }
  }, [offerId, preBookHotel])


  useEffect(() => {
    const handlePayment = () => {

      if (window.LiteAPIPayment) {
        const publicKey = process.env.REACT_APP_LITE_API_PUBLIC_KEY || 'sandbox';
        const clientSecret = data?.responseObject.data.secretKey || '';
        const prebookId = data?.responseObject.data.prebookId || '';
        const transactionId = data?.responseObject.data.transactionId || '';
        const guestFirstName = user?.name || '';
        const guestLastName = user?.surName || '';
        const guestEmail = user?.email || '';
        const environment = process.env.REACT_APP_LITE_API_PUBLIC_KEY || 'sandbox';
        const liteAPIConfig = {
          publicKey: publicKey,
          appearance: { theme: 'flat' },
          options: { business: { name: 'LiteAPI' } },
          targetElement: '#pe',
          secretKey: clientSecret,
          returnUrl: `${window.location.origin}/dashboard/travel/${requestId}/${subscriptionId}/hotel/${hotelId}/booking/${offerId}/book?prebookId=${prebookId}&transactionId=${transactionId}&guestFirstName=${encodeURIComponent(
            guestFirstName
          )}&guestLastName=${encodeURIComponent(guestLastName)}&guestEmail=${encodeURIComponent(
            guestEmail
          )}&environment=${encodeURIComponent(environment)}`,
        };

        const liteAPIPayment = new window.LiteAPIPayment(liteAPIConfig);
        liteAPIPayment.handlePayment();
      } else {
        console.error('LiteAPIPayment library not loaded yet.');
      }
    };
    if (data?.responseObject?.data?.secretKey) {
      handlePayment()
    }
  }, [data?.responseObject.data.prebookId, data?.responseObject.data.secretKey, data?.responseObject.data.transactionId, hotelId, offerId, requestId, subscriptionId, user?.email, user?.name, user?.surName])



  if (preBookLoading) {
    return <LinearProgress />;
  }

  return (
    <div>
      <Box sx={{ mb: 2 }}>
        <Button
          startIcon={<ArrowBackIcon />}
          onClick={() => navigate(-1)}
          sx={{
            textTransform: 'none',
            color: 'text.secondary'
          }}
        >
          Back to Hotels
        </Button>
      </Box>
      {preBookLoading && <LinearProgress />}
      <div id="pe"></div>
    </div>
  );
}


export default BookingRoom;