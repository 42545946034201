
import React from 'react';
import Carousel from 'react-multi-carousel';
import 'react-multi-carousel/lib/styles.css';

interface MultiCarouselProps {
  children: React.ReactNode;
  deviceType?: string;
  ssr?: boolean;
  infinite?: boolean;
  autoPlay?: boolean;
  autoPlaySpeed?: number;
  keyBoardControl?: boolean;
  customTransition?: string;
  transitionDuration?: number;
  containerClass?: string;
  sliderClass?: string;
  itemClass?: string;
  dotListClass?: string;
  focusOnSelect?: boolean;
  centerMode?: boolean;
  additionalTransfrom?: number;
}

const responsive = {
  desktop: {
    breakpoint: { max: 3000, min: 1024 },
    items: 3,
    slidesToSlide: 3,
  },
  tablet: {
    breakpoint: { max: 1024, min: 464 },
    items: 2,
    slidesToSlide: 2,
  },
  mobile: {
    breakpoint: { max: 464, min: 0 },
    items: 1,
    slidesToSlide: 1,
  },
};

const MultiCarousel: React.FC<MultiCarouselProps> = ({
  additionalTransfrom = 0,
  autoPlay = false,
  autoPlaySpeed = 3000,
  centerMode = false,
  children,
  containerClass = '',
  customTransition = 'all .5s ease-in-out',
  deviceType,
  dotListClass = '',
  focusOnSelect = false,
  infinite = true,
  itemClass = '',
  keyBoardControl = true,
  sliderClass = '',
  ssr = true,
  transitionDuration = 500,
}) => {
  return (
    <Carousel
      additionalTransfrom={additionalTransfrom}
      arrows
      autoPlay={autoPlay}
      autoPlaySpeed={autoPlaySpeed}
      centerMode={centerMode}
      containerClass={containerClass}
      customTransition={customTransition}
      deviceType={deviceType}
      dotListClass={dotListClass}
      draggable
      focusOnSelect={focusOnSelect}
      infinite={infinite}
      itemClass={itemClass}
      keyBoardControl={keyBoardControl}
      responsive={{
        desktop: {
          breakpoint: { max: 3000, min: 1024 },
          items: 3,
          slidesToSlide: 1,
        },
        tablet: {
          breakpoint: { max: 1024, min: 464 },
          items: 2,
          slidesToSlide: 1,
        },
        mobile: {
          breakpoint: { max: 464, min: 0 },
          items: 1,
          slidesToSlide: 1,
        },
      }}
      sliderClass={sliderClass}
      ssr={ssr}
      swipeable
      transitionDuration={transitionDuration}
    >
      {children}
    </Carousel>
  );
};

export default MultiCarousel;
