import AccessTimeIcon from '@mui/icons-material/AccessTime';
import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import HotelIcon from '@mui/icons-material/Hotel';
import LocalParkingIcon from '@mui/icons-material/LocalParking';
import LocationOnIcon from '@mui/icons-material/LocationOn';
import StarIcon from '@mui/icons-material/Star';
import WifiIcon from '@mui/icons-material/Wifi';
import { LinearProgress } from '@mui/joy';
import { Box, Button, Divider, Grid, Paper, Typography } from '@mui/material';
import { useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import { ReactCarousel } from '../../../../../components/carousel';
import { useGetHotelDetailsNuiteeQuery, useGetHotelRatesQuery } from '../../../../../redux/features/nuiteeHotel.api';
import { useGetSearchParametersQuery } from '../../../../../redux/features/tripApi';
import { useAppSelector } from '../../../../../redux/hooks';
import HotelRoom from './hotelRoom';

const HotelDetails = () => {
  const { hotelId, requestId, subscriptionId } = useParams();
  const [showAll, setShowAll] = useState(false);
  const [showFullDescription, setShowFullDescription] = useState(false);
  const user = useAppSelector((state) => state.auth.user);
  const navigate = useNavigate()
  const { data: searchData } = useGetSearchParametersQuery({
    id: requestId,
    subscription_id: subscriptionId,
    user: { id: user?.id }
  });
  const { data: ratesData, isLoading: ratesLoading } = useGetHotelRatesQuery({
    hotelId,
    checkin: searchData?.data?.dateRange[0],
    checkout: searchData?.data?.dateRange[1],
    adults: searchData?.data?.nbrTravelers,
    currency: 'USD',
    guestNationality: navigator.language.split('-')[1] || 'US'
  });
  const { data, isLoading } = useGetHotelDetailsNuiteeQuery(hotelId);
  if (!data) return null;
  const largeImages = (data?.responseObject.data.hotelImages.map((image) => image.url) || []).slice(0, 20);


  const facilities = data?.responseObject.data.hotelFacilities;
  const displayedFacilities = showAll ? facilities : facilities.slice(0, 20);
  const hotelData = data?.responseObject.data;

  // Get room rates from ratesData
  const roomRates = ratesData?.responseObject?.data?.data?.[0]?.roomTypes || [];

  // Check if any rooms have rates available
  const hasAvailableRooms = hotelData.rooms.some(room =>
    roomRates.some(rate => rate.rates.some(r => r.mappedRoomId === room.id))
  );

  const handleBookRoom = (offerId: string) => {
    navigate(`/dashboard/travel/${requestId}/${subscriptionId}/hotel/${hotelId}/booking/${offerId}`)
  };

  return (
    <Box >
      <Box sx={{ mb: 2 }}>
        <Button
          startIcon={<ArrowBackIcon />}
          onClick={() => navigate(-1)}
          sx={{
            textTransform: 'none',
            color: 'text.secondary'
          }}
        >
          Back to Hotels
        </Button>
      </Box>
      {isLoading && <LinearProgress />}

      <Paper elevation={3} sx={{ padding: 3, marginBottom: 3 }}>
        <Typography variant="h4" gutterBottom sx={{ fontWeight: 'bold', color: '#1a237e' }}>
          {hotelData.name}
        </Typography>
        <Box sx={{ display: 'flex', alignItems: 'center', marginBottom: 2 }}>
          <LocationOnIcon sx={{ marginRight: 1, color: '#1976d2' }} />
          <Typography variant="h6">
            {hotelData.city}, {hotelData.country}
          </Typography>
          <Box sx={{ display: 'flex', alignItems: 'center', marginLeft: 2 }}>
            {[...Array(hotelData.starRating)].map((_, index) => (
              <StarIcon key={index} sx={{ color: '#ffc107' }} />
            ))}
          </Box>
        </Box>
      </Paper>

      <Box sx={{ marginBottom: 4 }}>
        <ReactCarousel images={largeImages} />
      </Box>


      <Grid container spacing={3}>
        <Grid item xs={12}>
          <Paper elevation={3} sx={{ padding: 3 }}>
            <Typography variant="h5" gutterBottom sx={{ fontWeight: 'bold' }}>
              Available Rooms
            </Typography>

            <Divider sx={{ marginBottom: 2 }} />
            {hasAvailableRooms ? (
              hotelData.rooms.map((room, index) => {
                const roomRatesForRoom = roomRates.filter(rate =>
                  rate.rates.some(r => r.mappedRoomId === room.id)
                );
                if (roomRatesForRoom.length === 0) return null;
                return (
                  <HotelRoom
                    key={index}
                    room={room}
                    rates={roomRatesForRoom}
                    isLoading={ratesLoading}
                    onBookRoom={handleBookRoom}
                  />
                );
              })
            ) : (
              <Box sx={{ textAlign: 'center', p: 4 }}>
                <Typography variant="h6" color="error">
                  No rooms available for the selected dates
                </Typography>
              </Box>
            )}
          </Paper>
        </Grid>
        <Grid item xs={12} md={8}>
          <Paper elevation={3} sx={{ padding: 3, marginBottom: 3 }}>
            <Typography variant="h5" gutterBottom sx={{ fontWeight: 'bold' }}>
              Hotel Description
            </Typography>
            <Typography variant="body1">
              <div dangerouslySetInnerHTML={{
                __html: showFullDescription
                  ? hotelData.hotelDescription
                  : hotelData.hotelDescription.slice(0, 300) + '...'
              }} />
            </Typography>
            <Button
              onClick={() => setShowFullDescription(!showFullDescription)}
              sx={{ marginTop: 2 }}
            >
              {showFullDescription ? 'Show Less' : 'Read More'}
            </Button>
          </Paper>

          <Paper elevation={3} sx={{ padding: 3 }}>
            <Typography variant="h5" gutterBottom sx={{ fontWeight: 'bold' }}>
              Important Information
            </Typography>
            <Typography variant="body1" sx={{ whiteSpace: 'pre-line' }}>
              {hotelData.hotelImportantInformation}
            </Typography>
          </Paper>
        </Grid>

        <Grid item xs={12} md={4}>
          <Paper elevation={3} sx={{ padding: 3, marginBottom: 3 }}>
            <Box sx={{ display: 'flex', alignItems: 'center', marginBottom: 2 }}>
              <AccessTimeIcon sx={{ marginRight: 1, color: '#1976d2' }} />
              <Typography variant="h6" gutterBottom>Check-in/Check-out</Typography>
            </Box>
            <Typography>Check-in: {hotelData.checkinCheckoutTimes.checkin}</Typography>
            <Typography>Check-out: {hotelData.checkinCheckoutTimes.checkout}</Typography>
          </Paper>

          <Paper elevation={3} sx={{ padding: 3, marginBottom: 3 }}>
            <Typography variant="h6" gutterBottom>Quick Facts</Typography>
            <Box sx={{ display: 'flex', alignItems: 'center', marginY: 1 }}>
              <WifiIcon sx={{ marginRight: 1, color: '#1976d2' }} />
              <Typography>{hotelData.facilities.find(f => f.name === "WiFi available")?.name || "WiFi Available"}</Typography>
            </Box>
            <Box sx={{ display: 'flex', alignItems: 'center', marginY: 1 }}>
              <LocalParkingIcon sx={{ marginRight: 1, color: '#1976d2' }} />
              <Typography>Parking: {hotelData.parking}</Typography>
            </Box>
            <Box sx={{ display: 'flex', alignItems: 'center', marginY: 1 }}>
              <HotelIcon sx={{ marginRight: 1, color: '#1976d2' }} />
              <Typography>Type: {hotelData.hotelType}</Typography>
            </Box>
          </Paper>

          <Paper elevation={3} sx={{ padding: 3 }}>
            <Typography variant="h6" gutterBottom>Rating & Reviews</Typography>
            <Typography variant="h3" color="primary" sx={{ fontWeight: 'bold' }}>
              {hotelData.rating}/10
            </Typography>
            <Typography>Based on {hotelData.reviewCount} reviews</Typography>
          </Paper>
        </Grid>

        <Grid item xs={12}>
          <Paper elevation={3} sx={{ padding: 3 }}>
            <Typography variant="h5" gutterBottom sx={{ fontWeight: 'bold' }}>
              Hotel Facilities
            </Typography>
            <Divider sx={{ marginBottom: 2 }} />
            <Grid container spacing={1}>
              {displayedFacilities.map((facility, index) => (
                <Grid item key={index}>
                  <Box sx={{
                    backgroundColor: '#f5f5f5',
                    padding: '8px 16px',
                    borderRadius: '20px',
                    margin: '4px',
                    border: '1px solid #e0e0e0'
                  }}>
                    <Typography variant="body2">{facility}</Typography>
                  </Box>
                </Grid>
              ))}
            </Grid>
            {facilities.length > 20 && (
              <Button
                variant="outlined"
                onClick={() => setShowAll(!showAll)}
                sx={{ mt: 2 }}
              >
                {showAll ? 'Show Less' : 'Show More Facilities'}
              </Button>
            )}
          </Paper>
        </Grid>


      </Grid>
    </Box>
  );
};

export default HotelDetails;