/**
 * Round a number to a specified number of decimal places and return as a float.
 *
 * @param num - The number to round.
 * @param decimals - The number of decimal places.
 * @param base - The base to round to. Default is 10.
 *
 * @returns The rounded number as a float.
 */
export const toFixedFloat = (
  num: number,
  decimals: number,
  base = 10
): number => {
  const factor = Math.pow(base, decimals);
  return Math.round(num * factor) / factor;
};
