import { StyleSheet, Text, View } from '@react-pdf/renderer';
import React from 'react';
import { createTw } from 'react-pdf-tailwind';

const tw = createTw({});

const styles = StyleSheet.create({
  bold: {
    fontFamily: 'Helvetica-Bold',
    fontWeight: 'bold',
  },
});

interface OverviewProps {
  title: string;
  description: string;
  primaryColor: string;
}

const Overview: React.FC<OverviewProps> = (props) => {
  return (
    <View>
      <View style={[tw('p-4 border border-black flex items-center'), { backgroundColor: props.primaryColor }]}>
        <Text style={[tw('text-center text-xl leading-none'), styles.bold]}>{props.title}</Text>
      </View>
      <View style={tw('p-4 border border-black border-t-0')}>
        <Text style={tw('text-xs leading-normal')}>{props.description}</Text>
      </View>
    </View>
  );
};

export default Overview;
