import { createApi, fetchBaseQuery } from '@reduxjs/toolkit/query/react';
import { refreshToken } from '../queries/refreshtoken.query';
import { RootState } from '../store';
import { navoyGraphqlUrl } from './../../utils/env';
import { login, logout } from './authSlice';
import { toast } from 'react-toastify';

const baseQuery = fetchBaseQuery({
  baseUrl: navoyGraphqlUrl,
  // prepareHeaders is used to configure the header of every request and gives access to getState which we use to include the token from the store
  prepareHeaders: (headers, { getState }) => {
    // By default, if we have a token in the store, let's use that for authenticated requests
    const token = (getState() as RootState).auth.user?.token
    if (token) {
      // Let's set the Authorization header
      headers.set('authorization', `Bearer ${token}`)
    }
    return headers
  },
})

const baseQueryWithReauth = async (args: any, api: any, extraOptions: any) => {
  let result = await baseQuery(args, api, extraOptions);
  
  if (result.error && result.error.status === 401) {
    // Try to get a new token
    const refreshResult: any = await baseQuery({
      url: '/',
      method: 'POST',
      body: {
        query: refreshToken,
        variables: { token: (api.getState() as RootState).auth.user?.refreshToken }
      }
    }, api, extraOptions)
    if (refreshResult.data) {
      if (refreshResult.data?.data?.refreshToken?.success) {
        const { accessToken, refreshToken } = refreshResult.data?.data?.refreshToken?.responseObject
        api.dispatch(login({ user: { ...api.getState().auth.user, token: accessToken, refreshToken } }));
        result = await baseQuery(args, api, extraOptions);
      } else {
        if (api.getState().auth.user) {
          api.dispatch(logout({}))
          toast.warn('Your session has expired')
        }
      }
    } else {
      // Handle refresh token failure (e.g., logout user)
      if (api.getState().auth.user) {
        api.dispatch(logout({}))
        toast.warn('Your session has expired')
      }
    }
  }
  return result;
};

export const apiSlice = createApi({
  // The cache reducer expects to be added at `state.api` (already default - this is optional)
  reducerPath: 'api',
  // All of our requests will have URLs starting with '/fakeApi'
  baseQuery: baseQueryWithReauth,
  // The "endpoints" represent operations and requests for this server
  endpoints: builder => ({})
})
