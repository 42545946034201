import { yupResolver } from '@hookform/resolvers/yup';
import { Box, FormHelperText, ModalClose } from '@mui/joy';
import Button from '@mui/joy/Button';
import DialogContent from '@mui/joy/DialogContent';
import DialogTitle from '@mui/joy/DialogTitle';
import FormControl from '@mui/joy/FormControl';
import FormLabel from '@mui/joy/FormLabel';
import Input from '@mui/joy/Input';
import Modal from '@mui/joy/Modal';
import ModalDialog from '@mui/joy/ModalDialog';
import Stack from '@mui/joy/Stack';
import * as React from 'react';
import { validationSchemaPassword } from '../../utils/formYupValidation';
import { Controller, useForm } from 'react-hook-form';
import VisibilityIcon from "@mui/icons-material/Visibility";
import VisibilityOffIcon from "@mui/icons-material/VisibilityOff";
import LockIcon from "@mui/icons-material/Lock";
import { useCancelUserSubscriptionMutation, useGetUserSubscriptionDataQuery } from '../../redux/features/users.api';
import { toast } from 'react-toastify';
import { useAppSelector } from '../../redux/hooks';
import { useGetUserSubscriptionStatusQuery } from '../../redux/features/authSlice';

type PasswordConfirmationModalProps = {
  open: boolean;
  setOpen: (open: boolean) => void;
}

const PasswordConfirmationModal: React.FC<PasswordConfirmationModalProps> = ({ open, setOpen }) => {
  const [visible, setVisible] = React.useState(true);
  const [cancelSubscription, { isLoading }] = useCancelUserSubscriptionMutation()
  const user = useAppSelector(state => state.auth.user)
  const { refetch } = useGetUserSubscriptionDataQuery({ id: '', subscription_id: user?.subscription?.currentSubscription.subscriptionId, user: { id: user?.id } })
  const { refetch: refetchSubscription } = useGetUserSubscriptionStatusQuery({ id: user?.id })

  const { handleSubmit, control, formState: { errors }, setError } = useForm({
    resolver: yupResolver(validationSchemaPassword),
    defaultValues: {
      password: ""
    },
  });

  const onSubmit = async (data: any) => {
    console.log(data)
    try {
      setError('password', { type: "manual", message: '' })
      const result = await cancelSubscription({ password: data.password, subscription_id: user?.subscription?.currentSubscription.subscriptionId, userId: user?.id }).unwrap()
      if (result.success) {
        if (result.responseObject.error) {
          toast.warn(result.responseObject.message)
          setError('password', { type: "manual", message: result.responseObject.message })
        } else {
          refetch()
          refetchSubscription()
          toast.success(result.responseObject.message)
          setOpen(false)
        }
      } else {
        setError('password', { type: "manual", message: result.responseObject.message })
        toast.error(result.responseObject.message)
      }
    } catch (error: any) {
      setError('password', { type: "manual", message: error.data.message })
    }
  }

  return (
    <Modal open={open} onClose={() => setOpen(false)}>
      <ModalDialog>
        <ModalClose variant="outlined" />
        <DialogTitle>Confirmation</DialogTitle>
        <DialogContent>Are you sure you want to cancel your subscription!</DialogContent>
        <DialogContent>If you confirm, you will not be able to create new trips or modify old trips</DialogContent>
        <form onSubmit={handleSubmit(onSubmit)}        >
          <Stack spacing={2}>
            <FormControl>
              <FormLabel
                sx={(theme) => ({
                  "--FormLabel-color": theme.vars.palette.neutral[700],
                })}
              >
                Password
              </FormLabel>
              <Controller
                name="password"
                control={control}
                rules={{ required: true }}
                render={({ field }) => (
                  <Input
                    {...field}
                    sx={(theme) => ({
                      fontSize: " 0.75rem",
                      "--Input-minHeight": "3rem",
                      "&:focus-within:before": {
                        "--Input-focused": 0,
                      },
                      // boxShadow: theme.shadow.lg,
                      background: "#fff",
                    })}
                    placeholder="Password"
                    autoComplete="current-password"
                    type={visible ? "password" : "text"}
                    error={!!errors.password?.message}
                    size="sm"
                    startDecorator={
                      <LockIcon
                        sx={(theme) => ({ color: theme.palette.navoy.blue })}
                        fontSize="small"
                      />
                    }
                    endDecorator={
                      !visible ? (
                        <VisibilityIcon
                          sx={(theme) => ({ color: "#999" })}
                          fontSize="small"
                          onClick={() => setVisible((prev) => !prev)}
                        />
                      ) : (
                        <VisibilityOffIcon
                          sx={(theme) => ({ color: "#999" })}
                          fontSize="small"
                          onClick={() => setVisible((prev) => !prev)}
                        />
                      )
                    }
                  />
                )}
              />
              {!!errors.password?.message && (
                <FormHelperText
                  sx={(theme) => ({
                    color: theme.vars.palette.danger[400],
                    fontSize: "xs",
                  })}
                >
                  {errors.password?.message}
                </FormHelperText>
              )}
            </FormControl>
            <Box className='flex justify-end gap-2'>
              <Button type="button" color='danger' onClick={() => setOpen(false)} disabled={isLoading}>Discard</Button>
              <Button type="submit" color='success' loading={isLoading}>Confirm</Button>
            </Box>
          </Stack>
        </form>
      </ModalDialog>
    </Modal>
  );
}

export default PasswordConfirmationModal;