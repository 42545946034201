export const userTripsInfo = `
query GetUserTripsInfo($data: GetUserTripInfoParams) {
  getUserTripsInfo(data: $data) {
    status
    data {
      total_trips
      total_destination
      total_cost
    }
  }
}
`

export const userTrips = `
query GetUserTrips($data: GetUserTripInfoParams) {
  getUserTrips(data: $data) {
    status
    data {
      id
      subscription_id
      name
      description
      destination
      creation_time
      start_date
      end_date
      given_budget
      modifications
      last_edited
    }
  }
}
`

export const getTripOfPlan = `
query GetTripOfPlan($data: GetTripOfPlanInput) {
  getTripOfPlan(data: $data) {
    status
    data
    trips
  }
}
`
