export const updateSearch = `
mutation UpdateSearch($source: String!, $startDate: String!, $endDate: String!, $target: String!, $budget: String, $mode: String!, $email: String!,$statement:String,$userId:String,$address:AddressInput,$requestId:String,$error:String){
  updateSearch(source: $source, startDate: $startDate, endDate: $endDate, target: $target, budget: $budget, mode: $mode, email: $email,statement:$statement,userId:$userId, address:$address,requestId:$requestId,error:$error) {
    message
    responseObject
    statusCode
    success
  }
}
`

export const getSearch = `
  query GetSearch {
    search{
      message
      responseObject {
        target
        source
        statement
        startDate
        endDate
        budget 
        budgetInUSD 
        mode
        email
        userId
        requestId
        date
        address {
          country
          city
          state
        }
        error
        countryInfo {
          name
          countyLockup {
            city
          }
        }
      }
      statusCode
      success
    }
  }
`