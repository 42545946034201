import Button from "@mui/joy/Button";
import DialogContent from "@mui/joy/DialogContent";
import DialogTitle from "@mui/joy/DialogTitle";
import FormControl from "@mui/joy/FormControl";
import Input from "@mui/joy/Input";
import Modal from "@mui/joy/Modal";
import ModalDialog from "@mui/joy/ModalDialog";
import logo from "../../assets/images/logo/logo red.svg";

import { yupResolver } from "@hookform/resolvers/yup";
import Stack from "@mui/joy/Stack";
import { Controller, useForm } from "react-hook-form";
import * as yup from "yup";
import { AlertSuccess } from "../alerts";
import { useAddNewsletterMutation } from "../../redux/features/homeSlice";
import { useCallback, useEffect } from "react";
const validationSchema = yup.object().shape({
  email: yup.string().email("Email is invalid").required("Email is required"),
});
type EmailModalProps = {
  open: boolean;
  setOpen: (email: string) => void;
  text: string;
  title: string;
  checkExist?: boolean;
};

const EmailModal: React.FC<EmailModalProps> = ({ open, setOpen, text, checkExist, title }) => {
  const [addNewsletter, { data: newsLetterData, isLoading, error }] = useAddNewsletterMutation();

  const { handleSubmit, control, formState: { errors }, getValues } = useForm<any>({
    resolver: yupResolver(validationSchema),
    defaultValues: {
      email: "",
    },
  });

  const closeModal = useCallback((email: string) => {
    localStorage.setItem("email", email);
    setOpen(email)
  }, [setOpen])

  const onSubmit = (data: any) => {
    if (data?.email) {
      addNewsletter({ email: data.email });
    }
  };

  const messageSuccess = useCallback(() => {
    if (newsLetterData?.message) {
      return newsLetterData?.message
    }
    return ''
  }, [newsLetterData?.message])

  const messageError = useCallback(() => {
    if ((error && 'data' in error)) {
      const message = (error?.data as any)?.message
      if (!['You are already subscribed!'].includes(message))
        return message
    }
    return ''
  }, [error])

  useEffect(() => {
    if (newsLetterData?.message && checkExist) {
      closeModal(getValues('email'))
    } else if ((error && 'data' in error)) {
      const message = (error?.data as any)?.message
      if (['You are already subscribed!'].includes(message) && checkExist) {
        closeModal(getValues('email'))
      }
    }
  }, [newsLetterData?.message, error])



  return (
    <Modal open={open} onClose={() => closeModal("")}>
      <ModalDialog sx={{ p: 4 }}>
        <img src={logo} alt="Logo" className="mx-auto w-16 " />
        <DialogTitle
          level="h3"
          sx={{ justifyContent: "center", textAlign: "center", mt: 2 }}
        >
          {title}
        </DialogTitle>
        <DialogContent sx={{ textAlign: "center", mb: 2 }}>
          {text}
        </DialogContent>
        <form onSubmit={handleSubmit(onSubmit)}>
          <Stack spacing={2}>
            <FormControl>
              <Controller
                key={"email"}
                name="email"
                control={control}
                rules={{ required: true }}
                render={({ field }) => (
                  <Input
                    {...field}
                    type="email"
                    className={"  px-3.5 py-2 ring  "}
                    placeholder="Email address"
                    autoFocus
                    size="md"
                    color={errors?.email?.message ? "danger" : "neutral"}
                    sx={{
                      "--Input-focusedThickness": "0",
                    }}
                  />
                )}
              />
              {errors?.email && (
                <span className="text-navoy-red text-xs block mt-2">
                  {errors.email?.message?.toString()}
                </span>
              )}
            </FormControl>
            {messageError() && (
              <span className="text-navoy-red text-xs block mt-2">{messageError() || "Oops! please try again later"}</span>
            )}
            {!messageSuccess() && (
              <Button
                sx={theme => ({
                  background: theme.palette.navoy.red,
                  "&:hover": { background: theme.palette.navoy_red[700] },
                })}
                type="submit"
                disabled={isLoading}
                loading={isLoading}
              >
                {isLoading ? "Loading..." : "Subscribe"}
              </Button>
            )}
            {messageSuccess() && <AlertSuccess message={messageSuccess()} />}
          </Stack>
        </form>
      </ModalDialog>
    </Modal>
  );
};

export default EmailModal;
