
import { yupResolver } from '@hookform/resolvers/yup';
import { Button, FormHelperText, Grid, Textarea } from '@mui/joy';
import { FormControl, FormLabel, LinearProgress } from '@mui/material';
import { useEffect, useMemo, useState } from 'react';
import { Controller, useForm } from 'react-hook-form';
import { useParams } from 'react-router-dom';
import { toast } from 'react-toastify';

import { addDays, addMonths, lastDayOfMonth } from 'date-fns';
import { ChoiceChips } from '../../../components/inputs/Checkbox';
import DateRange from '../../../components/inputs/dateRange/date';
import { InputReactNumber, InputReactNumberFormatUSD } from '../../../components/inputs/input';
import SearchGoogleMap from '../../../components/inputs/searchGoogleMap';
import { SelectInput } from '../../../components/inputs/select';
import { useLazyGetAllActivitiesQuery, useLazyGetOverviewQuery, useLazyRegenerateItineraryQuery } from '../../../redux/features/itineraryApi';
import { useGetOptionsQuery, useGetSearchParametersQuery, useLazyGetModificationByTripQuery, useLazyRegenerateOptionsQuery } from '../../../redux/features/tripApi';
import { useAppSelector } from '../../../redux/hooks';
import { maxAddDays, numberActivitiesList, travelerStyleList, tripPurpose } from '../../../utils/const';
import { validationSchemaFormTravel } from '../../../utils/formYupValidation';
import { formatDate } from '../../../utils/functions';

type RegenerateFormProps = {
  expanded: boolean;
  setExpanded: (b: boolean) => void;
}

interface ITripForm {
  tripPurpose: string;
  activities: string[];
  food: string[];
  dateRange: (Date | null)[];
  target: string;
  preferredAccommodation: string;
  budgetInUSD: string;
  nbrTravelers: string;
  travelerStyle: string;
  numberActivities: string;
  prompts: string;
}


const RegenerateForm: React.FC<RegenerateFormProps> = ({ expanded, setExpanded }) => {
  const user = useAppSelector((state) => state.auth.user)
  const params = useParams();
  const requestId = params.requestId;
  const subscriptionId = params.subscriptionId;
  const trip_id = useMemo(() => ({ id: requestId, subscription_id: subscriptionId, user: { id: user?.id } }), [requestId, subscriptionId, user?.id]);
  const { data: dataOptions, isLoading: isLoadingOption } = useGetOptionsQuery({ ...trip_id })

  const [getOverview, { isFetching: getOverviewLoading }] = useLazyGetOverviewQuery()
  const [regenerateItinerary, { isLoading }] = useLazyRegenerateItineraryQuery()
  const [regenerateOptions, { data: dataRegenerateOptions, isFetching: isFetchingRegenerateOption }] = useLazyRegenerateOptionsQuery()
  const [getModification] = useLazyGetModificationByTripQuery()
  const [getAllActivities] = useLazyGetAllActivitiesQuery();
  const { data: searchData, isLoading: searchIsLoading, refetch } = useGetSearchParametersQuery({ ...trip_id });
  const [isInitialRender, setIsInitialRender] = useState(true);

  const { handleSubmit, control, formState: { errors }, setValue, watch } = useForm<any>({
    resolver: yupResolver(validationSchemaFormTravel),
    defaultValues: {
      activities: [],
      food: [],
      dateRange: [null, null],
      preferredAccommodation: '',
      budgetInUSD: '',
      nbrTravelers: '',
      travelerStyle: '',
      numberActivities: '',
      prompts: '',
      tripPurpose: '',
      target: ''
    },
    mode: 'onTouched',
    reValidateMode: 'onChange',
    shouldUnregister: false,
    shouldFocusError: true,
  });

  useEffect(() => {
    if (searchData?.data) {
      setValue('activities', searchData.data.activities || [])
      setValue('food', searchData.data.food || [])
      setValue('dateRange', searchData.data.dateRange ? [new Date(searchData.data.dateRange[0] || ''), new Date(searchData.data.dateRange[1] || '')] : [null, null])
      setValue('preferredAccommodation', searchData.data.preferredAccommodation || '')
      setValue('budgetInUSD', searchData.data.budgetInUSD || '')
      setValue('nbrTravelers', searchData.data.nbrTravelers || '')
      setValue('travelerStyle', searchData.data.travelerStyle || '')
      setValue('numberActivities', searchData.data.numberActivities || '')
      setValue('prompts', searchData.data.prompts || '')
      setValue('tripPurpose', searchData.data.tripPurpose || '')
      setValue('target', searchData.data.target || '')
      setValue('prompts', searchData.data.prompts || '')
    }
  }, [searchData?.data, setValue, trip_id.subscription_id])


  useEffect(() => {
    if (!errors) return;
    Object.values(errors).forEach((error) => {
      if (error?.message) {
        toast.error(error?.message?.toString() || '');
      }
    });
  }, [errors]);



  const onSubmit = async (data: ITripForm) => {
    const { activities, food, preferredAccommodation, dateRange, budgetInUSD, nbrTravelers, travelerStyle, numberActivities, prompts } = data

    const regenerate_trip_input = {
      activities,
      food,
      dateRange: [formatDate(dateRange?.[0]?.toDateString() || ''), formatDate(dateRange?.[1]?.toDateString() || '')],
      preferredAccommodation,
      budgetInUSD,
      nbrTravelers: Number(nbrTravelers),
      travelerStyle,
      numberActivities,
      prompt: prompts
    }
    try {
      const data = await regenerateItinerary({ trip_id, regenerate_trip_input }).unwrap()
      if (data.status === 401) {
        toast.warn(data.message)
      } else {
        toast.success('Your trip is being regenerated!')
        getModification({ ...trip_id })
        getAllActivities({ ...trip_id })
        setExpanded(false)
        refetch()
        await getOverview({ ...trip_id }).unwrap()
      }

    } catch (error) {
      toast.warn('An error occurred. Please contact the administrator for assistance.')
    }
  };

  useEffect(() => {
    const subscription = watch((value: ITripForm, { name, type }) => {

      const { tripPurpose, dateRange, target, budgetInUSD, nbrTravelers, travelerStyle, numberActivities, prompts } = value
      if (tripPurpose && target && budgetInUSD && nbrTravelers && travelerStyle && numberActivities && !['prompts', 'preferredAccommodation', 'activities', 'food'].includes(name || "")) {
        const search_input = {
          tripPurpose,
          activities: [],
          food: [],
          dateRange: [formatDate(dateRange?.[0]?.toDateString() || ''), formatDate(dateRange?.[1]?.toDateString() || '')],
          target,
          preferredAccommodation: '',
          budgetInUSD,
          nbrTravelers: Number(nbrTravelers),
          travelerStyle,
          numberActivities,
          prompt: prompts,
          latitude: dataOptions?.data?.latitude,
          longitude: dataOptions?.data?.longitude
        }
        if (!isInitialRender && dataOptions?.data) {
          regenerateOptions({ trip_id, search_input })
        } else {
          setIsInitialRender(false);
        }
      }
    });
    return () => subscription.unsubscribe()

  }, [dataOptions?.data, isInitialRender, regenerateOptions, watch])


  return (
    <div>
      {searchIsLoading && <LinearProgress />}
      {/* <Typography sx={{ fontSize: '1.5rem', fontWeight: '700' }} level="h3">Regenerate trip</Typography> */}
      <form className="w-full flex flex-col gap-4" onSubmit={handleSubmit(onSubmit)}>
        <Grid container spacing={2} sx={{ flexGrow: 1 }}>
          <Grid lg={4} md={6} xs={12}>
            <Controller
              name='tripPurpose'
              control={control}
              render={({ field }) => (
                <SelectInput
                  id="tripPurpose"
                  field={field}
                  list={tripPurpose}
                  label='Trip purpose'
                  error={errors?.tripPurpose?.message?.toString() || ''}
                />
              )}
            />
          </Grid>
          <Grid lg={4} md={6} xs={12}>
            <Controller
              name="dateRange"
              control={control}
              rules={{ required: true }}
              render={({ field }) => (
                <DateRange
                  id="tripDates"
                  field={field}
                  setValue={(range) => setValue('dateRange', range)}
                  matches={watch('tripPurpose') === 'Travel'}
                  errors={errors?.dateRange || []}
                  label='Date'
                  minDate={!field.value?.[1] ? field.value?.[0] || new Date() : new Date()}
                  maxDate={!!field.value?.[0] && !field.value?.[1] ? addDays(new Date(field.value?.[0]), maxAddDays) : addMonths(lastDayOfMonth(new Date()), 10)}
                />
              )}
            />
          </Grid>
          <Grid lg={4} md={6} xs={12}>
            <Controller
              name="target"
              control={control}
              rules={{ required: true }}
              render={({ field }) => (
                <SearchGoogleMap
                  id="destination"
                  label="Destination"
                  placeholder="Where are you going?"
                  field={field}
                  error={errors?.target?.message?.toString() || ''}
                  disabled={true}
                />
              )}
            />
          </Grid>
          <Grid lg={4} md={6} xs={12}>
            <Controller
              name="budgetInUSD"
              control={control}
              render={({ field }) => (
                <InputReactNumberFormatUSD
                  field={field}
                  label='Budget in USD'
                  error={errors?.budgetInUSD?.message?.toString() || ''}
                  placeholder='Budget in USD'
                />
              )}
            />
          </Grid>
          <Grid lg={4} md={6} xs={12}>
            <Controller
              name="nbrTravelers"
              control={control}
              render={({ field }) => (
                <InputReactNumber
                  field={field}
                  label='Number of travelers '
                  error={errors?.nbrTravelers?.message?.toString() || ''}
                  placeholder='Number of travelers'
                />
              )}
            />
          </Grid>
          <Grid lg={4} md={6} xs={12}>
            <Controller
              name='travelerStyle'
              control={control}
              render={({ field }) => (
                <SelectInput
                  id="travelerStyle"
                  field={field}
                  list={travelerStyleList}
                  label='Traveler Style'
                  error={errors?.travelerStyle?.message?.toString() || ''}
                />
              )}
            />
          </Grid>
          <Grid lg={4} md={6} xs={12}>
            <Controller
              name='numberActivities'
              control={control}
              render={({ field }) => (
                <SelectInput
                  id="numberOfActivities"
                  field={field}
                  list={numberActivitiesList}
                  label='Number of activities per day'
                  error={errors?.numberActivities?.message?.toString() || ''}
                />
              )}
            />
          </Grid>

          <Grid lg={4} md={6} xs={12} >
            <Controller
              name='preferredAccommodation'
              control={control}
              render={({ field }) => (
                <SelectInput
                  id="preferredAccommodation"
                  field={field}
                  list={(dataRegenerateOptions?.data || dataOptions?.data)?.accommodation?.map((item: string) => { return { value: item, label: item } })}
                  label='Preferred Accommodation type'
                  error={errors?.preferredAccommodation?.message?.toString() || ''}
                />
              )}
            />
          </Grid>
          {(isFetchingRegenerateOption || isLoadingOption) && <Grid xs={12}><LinearProgress /> </Grid>}
          {(dataRegenerateOptions?.data || dataOptions?.data)?.activities?.length > 0 &&
            <Grid sm={12}>
              <Controller
                name='activities'
                control={control}
                render={({ field }) => (
                  <ChoiceChips
                    field={field}
                    list={(dataRegenerateOptions?.data || dataOptions?.data)?.activities}
                    label='Interests and Activities'
                  />
                )}
              />
              {errors?.activities?.message && <FormHelperText>{errors?.activities?.message?.toString()}</FormHelperText>}
            </Grid>
          }
          {(dataRegenerateOptions?.data || dataOptions?.data)?.food?.length > 0 &&
            <Grid sm={12}>
              <Controller
                name='food'
                control={control}
                render={({ field }) => (
                  <ChoiceChips
                    field={field}
                    list={(dataRegenerateOptions?.data || dataOptions?.data)?.food}
                    label='Foods'
                  />
                )}
              />
              {errors?.food?.message && <FormHelperText>{errors?.food?.message?.toString()}</FormHelperText>}
            </Grid>
          }
          <Grid xs={12}>
            <Controller
              name='prompts'
              control={control}
              render={({ field }) => (
                <FormControl fullWidth className='gap-2' >
                  <FormLabel >Type of activities</FormLabel>
                  <Textarea
                    {...field}
                    minRows={2}
                    variant="outlined"
                    error={!!errors?.prompts?.message?.toString()}
                    placeholder="I want the activities to be unique experiences and not mainstream"
                  />
                  {errors?.prompts?.message && <FormHelperText>{errors?.prompts?.message?.toString()}</FormHelperText>}
                </FormControl>
              )}
            />

          </Grid>
        </Grid>

        <div>
          <Button type="submit" loading={isLoading || getOverviewLoading} disabled={isFetchingRegenerateOption} >Submit</Button>
        </div>
      </form>
    </div>
  );
}


export default RegenerateForm;