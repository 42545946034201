import { Box, Button, Card, CardContent, Chip, LinearProgress } from "@mui/joy";
import { Grid } from "@mui/material";
import { format } from "date-fns";
import { useState } from "react";
import PasswordConfirmationModal from "../../components/modals/passwordConfirmationModal";
import { useGetUserSubscriptionDataQuery } from "../../redux/features/users.api";
import { useAppSelector } from "../../redux/hooks";



type SubscriptionProps = {}

const Subscription: React.FC<SubscriptionProps> = () => {
  const user = useAppSelector(state => state.auth.user)
  const { data, isFetching } = useGetUserSubscriptionDataQuery({ id: '', subscription_id: user?.subscription?.currentSubscription.subscriptionId, user: { id: user?.id } })
  
  const [openDialog, setOpenDialog] = useState(false)
  return (
    <Card variant="outlined" size="lg" className="max-w-screen-lg w-full">
      <CardContent >
        <div className=" mb-4   flex gap-4 items-center">
          <span className="text-2xl capitalize">{user?.subscription?.currentSubscription.plan}</span>
          <Chip color={data?.responseObject.status === 'active' ? 'success' : 'neutral'}>{data?.responseObject.status}</Chip>
        </div>
        <Box sx={{ flexGrow: 1, marginBottom: 4 }}>
          {isFetching && <LinearProgress />}
          <Grid container spacing={2}>
            <Grid item xs={12} sm={6} md={3}>
              <p>Created:</p>
            </Grid>
            <Grid item xs={12} sm={6} md={8}>
              <p>{data?.responseObject.created && format(data?.responseObject.created * 1000, 'MMM d, h:mm a')}</p>
            </Grid>
            <Grid item xs={12} sm={6} md={3}>
              <p>Current period:</p>
            </Grid>
            <Grid item xs={12} sm={6} md={8}>
              <p>{data?.responseObject.current_period_start && format(data?.responseObject.current_period_start * 1000, 'MMM d')} to {data?.responseObject.current_period_end && format(data?.responseObject.current_period_end * 1000, 'MMM d')}</p>
            </Grid>
            {data?.responseObject.canceled_at &&
              <>
                <Grid item xs={12} sm={6} md={3}>
                  <p>Canceled at:</p>
                </Grid>
                <Grid item xs={12} sm={6} md={8}>
                  <p>{format(data?.responseObject.canceled_at * 1000, 'MMM d')}</p>
                </Grid>
              </>
            }
            <Grid item xs={12} sm={6} md={3}>
              <p>Payment method:</p>
            </Grid>
            <Grid item xs={12} sm={6} md={8}>
              <p><span className="bg-navoy_blue-700 text-white py-1 px-2 text-xs rounded">{data?.responseObject.brand}</span> •••• {data?.responseObject.last4}</p>
            </Grid>
            {!data?.responseObject.canceled_at &&
              <>
                <Grid item xs={12} sm={6} md={3}>
                  <p>Next invoice:</p>
                </Grid>
                <Grid item xs={12} sm={6} md={8}>
                  <p>${data?.responseObject.amount && (data?.responseObject.amount / 100).toFixed(2)} on {data?.responseObject.current_period_end && format(data?.responseObject.current_period_end * 1000, 'MMM d')}</p>
                </Grid>
              </>
            }
          </Grid>
        </Box>
        {!data?.responseObject.canceled_at && <Box>
          <Button color="danger" onClick={() => setOpenDialog(true)}>Cancel subscription</Button>
        </Box>}
      </CardContent>
      {openDialog && <PasswordConfirmationModal open={openDialog} setOpen={setOpenDialog} />}
    </Card>
  );
}


export default Subscription;