import { Stack, Typography } from "@mui/joy";

type CardOverviewProps = {
  value: string | number;
  text: string;
  img: any;
  background: string
}

const CardOverview: React.FC<CardOverviewProps> = ({ value, text, img, background }) => {
  return (
    <Stack direction={'row'} justifyContent={'space-between'}
      sx={{
        flex: 1,
        minWidth: '266px',
        height: '151px',
        padding: '28px 20px 28px 20px',
        background,
        borderRadius: '10px',
      }}
    >
      <Stack>
        <Typography sx={{ fontSize: 32, color: 'white', fontWeight: '700' }} >{value}</Typography>
        <Typography sx={{ fontSize: 16, color: "white", fontWeight: '400' }} >{text}</Typography>
      </Stack>
      <Stack>
        <img src={img} alt="overview" style={{ width: 91, height: 91 }} />
      </Stack>
    </Stack>
  );
}


export default CardOverview;