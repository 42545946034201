import Check from '@mui/icons-material/Check';
import CloseIcon from '@mui/icons-material/Close';
import KeyboardArrowRight from '@mui/icons-material/KeyboardArrowRight';
import Button from '@mui/joy/Button';
import Card from '@mui/joy/Card';
import CardActions from '@mui/joy/CardActions';
import Divider from '@mui/joy/Divider';
import List from '@mui/joy/List';
import ListItem from '@mui/joy/ListItem';
import ListItemDecorator from '@mui/joy/ListItemDecorator';
import Typography from '@mui/joy/Typography';
import * as React from 'react';
import { useNavigate } from 'react-router-dom';
import { useAppSelector } from '../../../redux/hooks';
import { stripBasicMonth, stripBasicYear, stripPremiumMonth, stripPremiumYear, stripProMonth, stripProYear } from '../../../utils/env';
import { toast } from 'react-toastify';

type PricingCardProps = {
  chip: string;
  title: string;
  list: string[];
  price?: number;
  url: string;
  listNo?: string[];
  index: number;
  text?: string;
}

const PricingCard: React.FC<PricingCardProps> = ({ chip, list, price, title, url, listNo, index, text }) => {
  const user = useAppSelector((state) => state.auth.user)

  const navigate = useNavigate()
  const handelNavigate = () => {
    localStorage.setItem('plan', title)
    localStorage.setItem('type', index.toString())
    if (!price) {
      navigate('/enterprisePlan')
    } else if (title.toLocaleLowerCase() === 'basic plan') {
      if (user?.id) {
        if (title === 'Basic Plan') {
          index === 0 ? window.open(stripBasicMonth + `?client_reference_id=${user?.id}&prefilled_email=${user?.email}`, '_self') : window.open(stripBasicYear + `?client_reference_id=${user?.id}&prefilled_email=${user?.email}`, '_self')
        }
        if (title === 'Pro Plan') {
          index === 0 ? window.open(stripProMonth + `?client_reference_id=${user?.id}&prefilled_email=${user?.email}`, '_self') : window.open(stripProYear + `?client_reference_id=${user?.id}&prefilled_email=${user?.email}`, '_self')
        }
        if (title === 'Premium Plan') {
          index === 0 ? window.open(stripPremiumMonth + `?client_reference_id=${user?.id}&prefilled_email=${user?.email}`, '_self') : window.open(stripPremiumYear + `?client_reference_id=${user?.id}&prefilled_email=${user?.email}`, '_self')
        }
      } else navigate('/auth/login')
    } else {
      toast.info('This plan is coming soon')
    }
  }

  return (
    <Card size="lg" variant="outlined" className="h-full w-auto mx-auto tablet:!w-[440px] !min-h-[500px]" >
      <Typography level="h2">{title}</Typography>
      {chip && <Typography level="title-sm">{chip}</Typography>}
      <Divider inset="none" />
      {price && <List size="sm" sx={{ mx: 'calc(-1 * var(--ListItem-paddingX))' }}>
        {
          list.map(item => (
            <ListItem key={item}>
              <ListItemDecorator>
                <Check color='success' />
              </ListItemDecorator>
              {item}
            </ListItem>
          ))
        }
        {
          listNo?.map(item => (
            <ListItem key={item}>
              <ListItemDecorator>
                <CloseIcon color='error' />
              </ListItemDecorator>
              {item}
            </ListItem>
          ))
        }
      </List>}
      {
        // listNo?.length === 0 && <Typography level="h2" sx={{ mt: 2, mb: 'auto' }}>Coming soon</Typography>
      }
      {text && <Typography level="body-md" sx={{ mt: 2, mb: 'auto' }}>{text}</Typography>}
      <Divider inset="none" />
      <CardActions>
        {price && <Typography level="title-lg" sx={{ mr: 'auto' }}>
          ${index === 0 ? price : ((price * 10) / 12).toFixed(0)}
          <Typography fontSize="sm" textColor="text.tertiary">
            / month
          </Typography>
        </Typography>}
        <Button
          variant="soft"
          color="neutral"
          endDecorator={<KeyboardArrowRight />}
          onClick={handelNavigate}
        >
          {price ? 'Get started' : "Let's talk"}
        </Button>
      </CardActions>
    </Card>
  );
}
export default PricingCard;