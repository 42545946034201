import { Button, Stack } from '@mui/joy';
import Paper from '@mui/material/Paper';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TablePagination from '@mui/material/TablePagination';
import TableRow from '@mui/material/TableRow';
import * as React from 'react';
import { Link } from 'react-router-dom';
import { formatDateRange, timeSince } from '../../utils/functions';
import { TripSchema } from '../../utils/types';
import { tripGeneratorUrl } from '../../utils/env';
import { toast } from 'react-toastify';
import { useAppSelector } from '../../redux/hooks';
interface Column {
  id: 'id' | 'tripName' | 'date' | 'cost' | 'lastEdited' | 'action';
  label: string;
  minWidth?: number;
  align?: 'left' | 'right' | 'center';
}

interface Data {
  id: string;
  tripName: string;
  date: string;
  cost: string;
  lastEdited: string;
  action: string;
  subscriptionId: string;
}



const columns: readonly Column[] = [
  { id: 'tripName', label: 'Trip name', minWidth: 170 },
  { id: 'date', label: 'Date', minWidth: 100 },
  { id: 'cost', label: 'Estimated cost', minWidth: 170, },
  { id: 'lastEdited', label: 'Last edited', minWidth: 170, },
  { id: 'action', label: 'Action', minWidth: 230 },
];



function createData(id: string, tripName: string, date: string, cost: string, lastEdited: string, action: string, subscriptionId: string): Data {
  return { id, tripName, date, cost, lastEdited, action, subscriptionId };
}

type TableBookmarksProps = {
  filterTrip: TripSchema[]
}

const TableBookmarks: React.FC<TableBookmarksProps> = ({ filterTrip }) => {
  const user = useAppSelector((state) => state.auth.user)

  const [page, setPage] = React.useState(0);
  const [rowsPerPage, setRowsPerPage] = React.useState(5);

  const rows = React.useMemo(() => {
    return filterTrip.map((item) => createData(item.id, item.name, formatDateRange(item.start_date, item.end_date), "$" + item.given_budget, timeSince(item.last_edited), item.id, item.subscription_id))
  }, [filterTrip])

  const handleChangePage = (event: unknown, newPage: number) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event: React.ChangeEvent<HTMLInputElement>) => {
    setRowsPerPage(+event.target.value);
    setPage(0);
  };

  const handleExport = async (id: string, subscriptionId: string, title: string) => {

    try {
      const url = `${tripGeneratorUrl}/trip/export?trip_id=${id}&user_id=${user?.id}&format=pdf&subscription_id=${subscriptionId}`;
      const response = await fetch(url, { method: 'GET' });

      if (response.ok) {
        const blob = await response.blob();
        const downloadUrl = window.URL.createObjectURL(blob);
        const a = document.createElement('a');
        a.href = downloadUrl;
        a.download = `${title}.pdf`;
        document.body.appendChild(a);
        a.click();
        a.remove();
      } else {
        console.error('Failed to export trip');
        // toast.warn('Failed to export trip')
      }
    } catch (error) {
      console.error('Failed to export trip');
      // toast.warn('Failed to export trip')
    }

  };

  return (
    <Paper sx={{ width: '100%', overflow: 'hidden' }}>
      <TableContainer sx={{ maxHeight: 440 }}>
        <Table stickyHeader aria-label="sticky table">
          <TableHead>
            <TableRow>
              {columns.map((column) => (
                <TableCell
                  key={column.id}
                  align={column.align}
                  style={{ minWidth: column.minWidth }}
                >
                  {column.label}
                </TableCell>
              ))}
            </TableRow>
          </TableHead>
          <TableBody>
            {rows
              .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
              .map((row) => {
                return (
                  <TableRow hover role="checkbox" tabIndex={-1} key={row.id}>
                    {columns.map((column) => {
                      const value = row[column.id];
                      return (
                        <TableCell key={column.id} align={column.align}>
                          {column.id === 'action' ?
                            <Stack spacing={3} direction={'row'}>
                              <Link to={'/dashboard/travel/' + row.id + '/' + row.subscriptionId} className='MuiButton-root'>
                                <Button variant='soft' className='!rounded-full'>
                                  Open
                                </Button>
                              </Link>
                              <Button className={'!rounded-full !bg-navoy_blue-500 hover:!bg-navoy_blue-600 active:!bg-navoy_blue-700'}
                                onClick={() => handleExport(row.id, row.subscriptionId, row.tripName)}
                              >
                                Export
                              </Button>
                            </Stack>
                            : value}
                        </TableCell>
                      );
                    })}
                  </TableRow>
                );
              })}
          </TableBody>
        </Table>
      </TableContainer>
      <TablePagination
        rowsPerPageOptions={[5, 10, 25, 100]}
        component="div"
        count={rows.length}
        rowsPerPage={rowsPerPage}
        page={page}
        onPageChange={handleChangePage}
        onRowsPerPageChange={handleChangeRowsPerPage}
      />
    </Paper>
  );
}


export default TableBookmarks;