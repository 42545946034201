import CheckIcon from '@mui/icons-material/Check'
import ExpandMoreIcon from '@mui/icons-material/ExpandMore'
import { Accordion, AccordionDetails, AccordionSummary, Box, Step, StepContent, StepLabel, Stepper, Typography } from '@mui/material'
import { useEffect } from 'react'
import 'react-responsive-carousel/lib/styles/carousel.min.css'
import { Link, useParams } from 'react-router-dom'
import { ReactCarousel } from '../../../../components/carousel'
import { viatorUrl } from '../../../../utils/env'
import productData from '../viatorProduct/product_viator.json'


type IProductDetails = {

}

const ProductDetails: React.FC<IProductDetails> = () => {
  const params = useParams();
  const requestId = params.requestId;
  const subscriptionId = params.subscriptionId;
  const { productId } = useParams();
  useEffect(() => {
    const url = viatorUrl + '/products/' + productId

    const fetchProduct = async () => {
      try {
        const result = await fetch("https://api.sandbox.viator.com/partner/products/9866P59", {
          headers: {
            accept: "application/json;version=2.0",
            "accept-language": "en-US",
            "exp-api-key": "194bc457-2a3b-4bef-b5cc-39437d655702",
            "sec-ch-ua": '"Chromium";v="130", "Google Chrome";v="130", "Not?A_Brand";v="99"',
            "sec-ch-ua-mobile": "?0",
            "sec-ch-ua-platform": '"Windows"',
          },
          referrer: "http://localhost:3000/",
          referrerPolicy: "strict-origin-when-cross-origin",
          body: null,
          method: "GET",
          mode: "cors",
          credentials: "omit"
        });

        const data = await result.json();

        console.log('Product data:', data);
      } catch (error) {
        console.error('Error fetching product:', error);
      }
    };
    fetchProduct();

  }, [productId])


  const largeImages: string[] = productData.images
    .map(image => image.variants.find(variant => variant.width === 720)?.url)
    .filter((url): url is string => url !== undefined);
  return (
    <Box sx={{ maxWidth: '1200px', margin: '0 auto', padding: '20px' }}>
      <Link to={`/dashboard/travel/${requestId}/${subscriptionId}`} style={{ display: 'inline-block', marginBottom: '20px', textDecoration: 'none', color: 'primary.main' }}>
        ← Back to Trip
      </Link>

      <Typography variant="h4" sx={{ marginBottom: '20px' }}>
        {productData.title}
      </Typography>
      <ReactCarousel images={largeImages} />

      {/* Content Accordions */}
      <Box sx={{ marginTop: '20px' }}>
        {/* Overview */}
        <Accordion defaultExpanded>
          <AccordionSummary expandIcon={<ExpandMoreIcon />}>
            <Typography variant="h6">Overview</Typography>
          </AccordionSummary>
          <AccordionDetails>
            <Typography>
              {productData.description}
            </Typography>
          </AccordionDetails>
        </Accordion>

        {/* What's Included */}
        <Accordion>
          <AccordionSummary expandIcon={<ExpandMoreIcon />}>
            <Typography variant="h6">What's Included</Typography>
          </AccordionSummary>
          <AccordionDetails>
            <ul style={{ listStyle: 'none', padding: 0 }}>
              {productData.inclusions.map((item, index) => (
                <li key={index} style={{ display: 'flex', alignItems: 'center', marginBottom: '8px' }}>
                  <CheckIcon sx={{ color: 'green', marginRight: '8px' }} />
                  <Typography>{item.description || item.otherDescription}</Typography>
                </li>
              ))}
            </ul>
          </AccordionDetails>
        </Accordion>

        {/* Meeting and Pickup */}
        <Accordion>
          <AccordionSummary expandIcon={<ExpandMoreIcon />}>
            <Typography variant="h6">Meeting and Pickup</Typography>
          </AccordionSummary>
          <AccordionDetails>
            <Typography variant="subtitle1">Start point:</Typography>
            {productData.logistics.start.map((item, index) => (
              <Typography key={index}>
                {item.description}
              </Typography>
            ))}
          </AccordionDetails>
        </Accordion>

        {/* What to Expect */}
        <Accordion>
          <AccordionSummary expandIcon={<ExpandMoreIcon />}>
            <Typography variant="h6">What to Expect</Typography>
          </AccordionSummary>
          <AccordionDetails>
            <Typography variant="subtitle1">Itinerary:</Typography>
            <Stepper orientation="vertical" nonLinear>
              {productData.itinerary.itineraryItems.map((item, index) => {
                let stepNumber = 1
                const previousItems = productData.itinerary.itineraryItems.slice(0, index)
                const validStops = previousItems.filter(prevItem => !prevItem.passByWithoutStopping)
                stepNumber += validStops.length

                return (
                  <Step key={index} active={true}>
                    <StepLabel>
                      {!item.passByWithoutStopping ? `Stop ${stepNumber}:` : 'Pass by:'}
                    </StepLabel>
                    <StepContent>
                      <Typography>
                        {item.description}
                      </Typography>
                      {item.duration && (
                        <Typography variant="caption">
                          Duration: {item.duration.fixedDurationInMinutes} minutes
                        </Typography>
                      )}
                    </StepContent>
                  </Step>
                )
              })}
            </Stepper>
          </AccordionDetails>
        </Accordion>

        {/* Additional Info */}
        <Accordion>
          <AccordionSummary expandIcon={<ExpandMoreIcon />}>
            <Typography variant="h6">Additional Info</Typography>
          </AccordionSummary>
          <AccordionDetails>
            <Box >
              <ul style={{ listStyleType: 'disc', columnCount: 2, margin: 0, paddingLeft: '20px' }}>
                {productData.additionalInfo.map((info, index) => (
                  <li key={index}>
                    <Typography>{info.description}</Typography>
                  </li>
                ))}
              </ul>
            </Box>
          </AccordionDetails>
        </Accordion>
      </Box>
    </Box>
  )
}

export default ProductDetails
