import { useEffect, useState } from 'react';

/**
 * Debounce a value. This means that the value will only be updated after a certain delay.
 * This is useful for input fields where you don't want to update the state on every keystroke.
 */
const useDebounce = <T>(value: T, delay: number = 500): T => {
  const [debouncedValue, setDebouncedValue] = useState<T>(value);

  useEffect(() => {
    const handler = setTimeout(() => {
      setDebouncedValue(value);
    }, delay);

    return () => {
      clearTimeout(handler);
    };
  }, [value, delay]);

  return debouncedValue;
};

export default useDebounce;
