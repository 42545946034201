import AddIcon from '@mui/icons-material/Add';
import ExpandLessIcon from '@mui/icons-material/ExpandLess';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import GpsFixedIcon from '@mui/icons-material/GpsFixed';
import VisibilityIcon from '@mui/icons-material/Visibility';
import VisibilityOffIcon from '@mui/icons-material/VisibilityOff';
import { Box, Breadcrumbs, Button, Divider, LinearProgress, Typography } from '@mui/joy';
import { Checkbox, IconButton, Tooltip } from '@mui/material';
import Accordion from '@mui/material/Accordion';
import AccordionDetails from '@mui/material/AccordionDetails';
import AccordionSummary from '@mui/material/AccordionSummary';
import React, { useMemo, useState } from 'react';
import { useParams } from 'react-router-dom';
import { useLazyGetActivitiesByDayQuery } from '../../../redux/features/itineraryApi';
import { useAppSelector } from '../../../redux/hooks';
import { ActivityOfDay, ItineraryDay } from '../../../utils/types';
import { useMapActivitiesStore } from '../../../zustand';
import CardActivity from './cardActivity';
import CardAddActivity from './cardAddActivity';

import viatorProducts from './viatorProduct/products_viator.json';
import CardViator from './viatorProduct/cardViator';


type CardDayProps = {
  day: ItineraryDay;
};

const CardDay: React.FC<CardDayProps> = ({ day }) => {
  const params = useParams();
  const requestId = params.requestId;
  const subscriptionId = params.subscriptionId;
  const user = useAppSelector((state) => state.auth.user);
  const { mapActivities, toggleVisibility, locateOnMap } = useMapActivitiesStore();

  const [expanded, setExpanded] = useState<string | false>(false);
  const [showAddActivity, setShowAddActivity] = useState(false);

  /**
   * Whether the activities of the day should be shown on the map. This updates
   * as the user toggles the show on map checkbox.
   */
  const showOnMap = useMemo(
    () => mapActivities.some((activity) => activity.dayId === day.id && activity.visible),
    [mapActivities, day.id]
  );

  const [getActivityByDay, { data: dataActivityByDay, isLoading, isFetching }] = useLazyGetActivitiesByDayQuery();

  const handleChange = (panel: string) => (event: React.SyntheticEvent, isExpanded: boolean) => {
    setExpanded(isExpanded ? panel : false);
    if (isExpanded && user)
      getActivityByDay({
        id: requestId,
        user: { id: user.id },
        subscription_id: subscriptionId,
        number: (+day.id - 1).toString(),
      });
  };

  return (
    <Box className="rounded-lg border-[#E3E3E3] border-[1px] shadow-sm bg-white">
      <Box className="flex flex-row items-center gap-3 px-4 py-2">
        <Typography className="!text-sm md:!text-base lg:!text-2xl !font-semibold">Day {day.id}</Typography>
        <Typography className="!text-sm md:!text-base lg:!text-2xl !font-semibold">{day?.title}</Typography>
        <div className="flex ml-auto">
          <Tooltip title="Locate on map">
            <span>
              <IconButton color="primary" disabled={!showOnMap} onClick={() => locateOnMap(day.id)}>
                <GpsFixedIcon />
              </IconButton>
            </span>
          </Tooltip>
          <Tooltip title={showOnMap ? 'Hide on map' : 'Show on map'}>
            <Checkbox
              checked={showOnMap}
              onChange={() => toggleVisibility(day.id)}
              icon={<VisibilityOffIcon />}
              checkedIcon={<VisibilityIcon />}
              name="showOnMap"
            />
          </Tooltip>
        </div>
      </Box>
      <Typography className="!text-sm md:!text-base lg:!text-base px-4 pb-2">{day?.description}</Typography>
      <Divider orientation="horizontal" />
      <Accordion className="!mt-0" expanded={expanded === 'day' + day.id} onChange={handleChange('day' + day.id)}>
        <AccordionSummary
          expandIcon={<ExpandMoreIcon />}
          aria-controls="panel1-content"
          id="panel1-header"
          title="Expand"
          className="!ps-0"
          sx={{
            '& .Mui-expanded': { margin: '0 !important' },
          }}
        >
          <Breadcrumbs separator="|" aria-label="breadcrumbs" size="lg">
            {[
              // {
              //   title: 'Activities count: ',
              //   value: day?.number_of_activities || 0,
              // },
              // { title: 'Daily cost: $', value: day?.budget || 0 },
              { title: 'City: ', value: day?.city },
            ].map((item, index) => (
              <div className="flex gap-1" key={index}>
                <Typography color="neutral" className="!text-xs md:!text-sm">
                  {item.title}
                </Typography>
                <Typography color="neutral" className="!text-xs md:!text-sm !font-semibold">
                  {item.value}
                </Typography>
              </div>
            ))}
          </Breadcrumbs>
        </AccordionSummary>
        <AccordionDetails className="flex flex-col gap-4">
          {(isLoading || isFetching) && <LinearProgress />}
          {dataActivityByDay?.data?.map((activity: ActivityOfDay, rank: number) => (
            <CardActivity key={rank} activity={activity} number={day.id} rank={rank} />
          ))}

          {/* {
            viatorProducts.products.map(prod=>(
              <CardViator product={prod} key={prod.productCode} /> 
            ))
          } */}

          <Box className="flex justify-center">
            <Button onClick={() => setShowAddActivity(true)} startDecorator={<AddIcon />} className="!rounded-full">
              Add Activity
            </Button>
          </Box>
          {showAddActivity && (
            <CardAddActivity number={(+day.id - 1).toString()} setShowAddActivity={setShowAddActivity} />
          )}
          <Box className="flex justify-end">
            <Button startDecorator={<ExpandLessIcon />} variant="plain" onClick={() => setExpanded(false)}>
              Reduce
            </Button>
          </Box>
        </AccordionDetails>
      </Accordion>
    </Box>
  );
};

export default CardDay;
