export const addNewsletter = `
mutation AddNewsletter($email: String!) {
  addNewsletter(email: $email) {
    message
    responseObject {
      success
      message
    }
    statusCode
    success
  }
}
`