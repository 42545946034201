
import { AddRequestDemoResponse } from "../../utils/types";
import { addRequestDemo } from "../queries/requestDemo.query";
import { apiSlice } from "./apiSlice";

export const requestDemoApiSlice = apiSlice.injectEndpoints({
  endpoints: (builder) => ({
    addRequestDemo: builder.mutation({
      query: (params) => ({
        method: 'post',
        url: '/',
        body: {
          query: addRequestDemo,
          variables: { data: params },
        },
      }),
      transformResponse: (response: { data: { addRequestDemo: AddRequestDemoResponse } }) => response.data.addRequestDemo,
    }),
  }),
});

export const { useAddRequestDemoMutation } = requestDemoApiSlice;
