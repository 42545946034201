import AirlineSeatLegroomNormalIcon from '@mui/icons-material/AirlineSeatLegroomNormal';
import EnergySavingsLeafIcon from '@mui/icons-material/EnergySavingsLeaf';
import FlightLandIcon from '@mui/icons-material/FlightLand';
import FlightTakeoffIcon from '@mui/icons-material/FlightTakeoff';
import VideoIcon from '@mui/icons-material/OndemandVideo';
import UsbIcon from '@mui/icons-material/Usb';
import WifiIcon from '@mui/icons-material/Wifi';
import { Text, View } from '@react-pdf/renderer';
import React from 'react';
import { createTw } from 'react-pdf-tailwind';

import { MaterialIcon } from '../../shared/MaterialIcon';

const tw = createTw({});

interface FlightItemProps {
  name: string;
  time: string;
  duration: string;
  stops: string;
  type: string;
  costUsd: number;
  departureTime: string;
  departureAirport: string;
  arrivalTime: string;
  arrivalAirport: string;
  airline: string;
  amenities: string[];
  primaryColor: string;
  secondaryColor: string;
  isFirst: boolean;
}

const FlightItem: React.FC<FlightItemProps> = (props) => {
  return (
    <View wrap={false} style={props.isFirst ? tw('border-t') : undefined}>
      <View style={tw('flex flex-row')}>
        <Text style={tw('flex-1 text-xs p-2')}>{props.name}</Text>
        <Text style={tw('flex-1 text-xs p-2')}>{props.time}</Text>
        <Text style={tw('flex-1 text-xs p-2')}>{props.duration}</Text>
        <Text style={tw('flex-1 text-xs p-2')}>{props.stops}</Text>
        <Text style={tw('flex-1 text-xs p-2')}>{props.type}</Text>
        <Text style={tw('flex-1 text-xs p-2')}>
          {props.costUsd.toLocaleString('en-US', { style: 'currency', currency: 'USD' })}
        </Text>
      </View>
      <View style={[tw('p-2 border-t flex flex-col gap-y-2 mx-4'), { borderColor: props.secondaryColor }]}>
        <View style={tw('flex flex-row items-center mx-auto')}>
          <MaterialIcon icon={FlightTakeoffIcon} className="w-4 h-4 mr-2" style={{ color: props.primaryColor }} />
          <Text style={tw('text-xs')}>
            {props.departureTime} . {props.departureAirport}
          </Text>
          <View style={tw('border-dotted border-b border-black mx-2')} />
          <MaterialIcon icon={FlightLandIcon} className="w-4 h-4 mr-2" style={{ color: props.primaryColor }} />
          <Text style={tw('text-xs')}>
            {props.arrivalTime} . {props.arrivalAirport}
          </Text>
        </View>
        <View style={tw('mx-auto')}>
          <Text style={tw('text-xs')}>{props.airline}</Text>
        </View>
        <View style={tw('flex flex-row items-center gap-x-3 flex-wrap mx-auto')}>
          {props.amenities.includes('Wi-Fi') && (
            <View style={tw('flex flex-row')}>
              <MaterialIcon icon={WifiIcon} className="w-4 h-4 mr-1" style={{ color: props.primaryColor }} />
              <Text style={tw('text-xs')}>Wi-Fi for a fee</Text>
            </View>
          )}
          {props.amenities.includes('USB') && (
            <View style={tw('flex flex-row')}>
              <MaterialIcon icon={UsbIcon} className="w-4 h-4 mr-1" style={{ color: props.primaryColor }} />
              <Text style={tw('text-xs')}>In-seat USB outlet</Text>
            </View>
          )}
          {props.amenities.includes('Video') && (
            <View style={tw('flex flex-row')}>
              <MaterialIcon icon={VideoIcon} className="w-4 h-4 mr-1" style={{ color: props.primaryColor }} />
              <Text style={tw('text-xs')}>On-demand video</Text>
            </View>
          )}
          {props.amenities.includes('Legroom') && (
            <View style={tw('flex flex-row')}>
              <MaterialIcon
                icon={AirlineSeatLegroomNormalIcon}
                className="w-4 h-4 mr-1"
                style={{ color: props.primaryColor }}
              />
              <Text style={tw('text-xs')}>Average legroom (31 in)</Text>
            </View>
          )}
          {props.amenities.includes('Carbon') && (
            <View style={tw('flex flex-row')}>
              <MaterialIcon
                icon={EnergySavingsLeafIcon}
                className="w-4 h-4 mr-1"
                style={{ color: props.primaryColor }}
              />
              <Text style={tw('text-xs')}>Carbon emissions: 384 kg</Text>
            </View>
          )}
        </View>
      </View>
    </View>
  );
};

export default FlightItem;
