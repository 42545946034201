import { StyleSheet, Text, View } from '@react-pdf/renderer';
import React, { useMemo } from 'react';
import { createTw } from 'react-pdf-tailwind';
import { ReportAccommodation } from '../../../types';
import AccommodationItem from './AccommodationItem';

const tw = createTw({});

const styles = StyleSheet.create({
  bold: {
    fontFamily: 'Helvetica-Bold',
    fontWeight: 'bold',
  },
});

interface AccommodationDetailsProps {
  data: ReportAccommodation[];
  primaryColor: string;
  secondaryColor: string;
}

export const AccommodationDetails: React.FC<AccommodationDetailsProps> = (props) => {
  const totalCost = useMemo(() => {
    return props.data.reduce((total, acc) => total + acc.costPerNightUsd, 0);
  }, [props.data]);

  return (
    <View>
      <View style={[tw('p-3 border flex items-center rounded-sm'), { backgroundColor: props.primaryColor }]}>
        <Text style={[tw('text-lg leading-none'), styles.bold]}>Accommodation Details</Text>
      </View>
      <View style={[tw('my-2 border border-black')]}>
        {/* Header */}
        <View wrap={false} style={[tw('flex flex-row'), { backgroundColor: props.secondaryColor }]}>
          <Text style={[tw('flex-1 text-xs p-2'), styles.bold]}>Name</Text>
          <Text style={[tw('flex-1 text-xs p-2'), styles.bold]}>Type</Text>
          <Text style={[tw('flex-1 text-xs p-2'), styles.bold]}>Check-in</Text>
          <Text style={[tw('flex-1 text-xs p-2'), styles.bold]}>Check-out</Text>
          <Text style={[tw('flex-1 text-xs p-2'), styles.bold]}>Cost per Night</Text>
        </View>
        {/* Accommodation Items or No Accommodation Available */}
        {props.data.length === 0 ? (
          <View style={[tw('flex flex-row p-4')]}>
            <Text style={[tw('flex-1 text-xs text-center')]}>No accommodation available</Text>
          </View>
        ) : (
          props.data.map((accommodation, index) => (
            <AccommodationItem
              key={index}
              name={accommodation.name}
              type={accommodation.type}
              checkIn={accommodation.checkIn}
              checkOut={accommodation.checkOut}
              costPerNightUsd={accommodation.costPerNightUsd}
              address={accommodation.address}
              primaryColor={props.primaryColor}
              secondaryColor={props.secondaryColor}
              isFirst={index !== 0}
            />
          ))
        )}
      </View>
      {/* Total Cost Section */}
      {props.data.length > 0 && (
        <View
          wrap={false}
          style={[tw('border flex flex-row p-2 px-4 justify-between'), { backgroundColor: props.secondaryColor }]}
        >
          <Text style={[tw('text-xs leading-none'), styles.bold]}>Total Cost</Text>
          <Text style={[tw('text-xs leading-none'), styles.bold]}>
            {totalCost.toLocaleString('en-US', { style: 'currency', currency: 'USD' })}
          </Text>
        </View>
      )}
    </View>
  );
};
