export const getHotels = `
query GetHotels($data: DataGenerateId) {
  getHotels(data: $data) {
    status
    data {
      end_date
      start_date
      location
      suggestions {
        hotel_id
        type
        name
        gps_coordinates {
          latitude
          longitude
        }
        check_in_date
        check_in_time
        checkout_date
        check_out_time
        price_per_night
        total_price
        images {
          thumbnail
          original_image
        }
        overall_rating
        reviews
        essential_info
        amenities
        property_token
        hotel_class
        search_parameters {
          check_in_date
          check_out_date
          max_price
          min_price
          q
        }
        description
        prices {
          source
          link
          logo
          rate_per_night
          total_price
          free_cancellation
          free_cancellation_unit_date
          free_cancellation_unit_time
        }
        nearby_places {
          category
          name
          transportation {
            type
            duration
          }
          gps_coordinates {
            latitude
            longitude
          }
        }
      }
    }
  }
}
`;

export const getHotelDetails = `
query GetHotelDetails($data: HotelDetailsInput) {
  getHotelDetails(data: $data) {
    status
    data {
      hotel_id
      type
      name
      gps_coordinates {
        latitude
        longitude
      }
      check_in_date
      check_in_time
      checkout_date
      check_out_time
      price_per_night
      total_price
      images {
        thumbnail
        original_image
      }
      overall_rating
      reviews
      essential_info
      amenities
      property_token
      hotel_class
      search_parameters {
        check_in_date
        check_out_date
        max_price
        min_price
        q
      }
      description
      prices {
        source
        link
        logo
        rate_per_night
        total_price
        free_cancellation
        free_cancellation_unit_date
        free_cancellation_unit_time
      }
      nearby_places {
        category
        name
        transportation {
          type
          duration
        }
        gps_coordinates {
          latitude
          longitude
        }
      }
    }
  }
}
`;

export const persistHotels = `
mutation PersistHotels($data: PersistHotelsInput!) {
  persistHotels(data: $data) {
    status
    data
  }
}
`;

export const getPersistedHotels = `
query GetPersistedHotels($data: DataGenerateId) {
  getPersistedHotels(data: $data) {
    status
    data {
      type
      name
      gps_coordinates {
        latitude
        longitude
      }
      check_in_date
      check_in_time
      checkout_date
      check_out_time
      price_per_night
      total_price
      overall_rating
      reviews
      description
    }
  }
}
`;
