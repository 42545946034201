import { Skeleton, Typography, useTheme } from '@mui/joy';
import { Stack, useMediaQuery } from '@mui/material';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TablePagination from '@mui/material/TablePagination';
import TableRow from '@mui/material/TableRow';
import { ChangeEvent, useEffect, useState } from 'react';

import { columnsFlight } from '../../../utils/const';
import { convertMinutesToDuration } from '../../../utils/functions';
import { Flight, FlightSearchResult, RowTableFlight } from '../../../utils/types';
import Row from './row';

type TableFlightProps = { flightsData: FlightSearchResult }

const TableFlight: React.FC<TableFlightProps> = ({ flightsData }) => {
  const theme = useTheme();
  const matches = useMediaQuery(theme.breakpoints.down("laptop"));
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(10);
  const [rows, setRows] = useState<RowTableFlight[]>([])

  useEffect(() => {
    if (flightsData?.data?.suggestions) {
      mapFlights([...flightsData.data.suggestions]);
    }
  }, [flightsData.data.suggestions])


  const mapFlights = (flights: Flight[]) => {
    const rowFlights: RowTableFlight[] = flights.map((flight) => {
      const firstFlight = flight.flights?.[0];
      const lastFlight = flight.flights?.[flight.flights.length - 1];
      return {
        photo: flight.airline_logo,
        name: flight?.airline || firstFlight?.airline,
        time: firstFlight.departure_airport.time.split(' ')[1] + ' - ' + lastFlight.arrival_airport.time.split(' ')[1],
        duration: convertMinutesToDuration(flight.total_duration),
        stops: flight.flights.length > 1 ? `${flight.flights.length - 1} stop${flight.flights.length > 2 ? 's' : ''}` : 'Non - Stop',
        cost: '$' + flight.price,
        flights: flight.flights,
        type: flight.type,
        action: {
          departure_id: flight.search_parameters.departure_id,
          arrival_id: flight.search_parameters.arrival_id,
          outbound_date: flight.search_parameters.outbound_date,
          return_date: flight.search_parameters.return_date,
          token: flight.token
        }
      }
    })
    setRows(rowFlights)
  }

  const handleChangePage = (event: unknown, newPage: number) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event: ChangeEvent<HTMLInputElement>) => {
    setRowsPerPage(+event.target.value);
    setPage(0);
  };

  if (!flightsData?.data?.suggestions) return null

  return (
    <Stack sx={{ width: '100%', overflow: 'hidden' }}>
      {
        <TableContainer sx={{ maxHeight: 440 }}>
          <Table stickyHeader aria-label="sticky table"
            sx={(theme) => (theme.breakpoints.up("laptop") ? {
              '& thead th': { minWidth: '100px', border: 'none', backgroundColor: theme.palette.navoy.bg, padding: '12px', color: theme.palette.navoy.blue, fontSize: '14px', fontWeight: '500', fontFamily: 'Poppins' },
              '& thead th:first-of-type': { borderRadius: '8px 0 0 8px', paddingLeft: '16px' },
              '& thead th:last-child': { borderRadius: '0 8px 8px 0' },
              '& td': { padding: '16px', color: theme.palette.navoy_bg[500], fontFamily: 'Poppins', fontSize: '14px', fontWeight: '500', position: 'relative' },
              '& tbody tr > *:first-of-type': { color: theme.palette.navoy.blue, fontSize: '14px', fontWeight: '500', fontFamily: 'Poppins' },
            } : null)}
          >
            {!matches && <TableHead>
              <TableRow>
                {columnsFlight.map((column) => (
                  <TableCell
                    key={column.id}
                    align={column.align}
                    style={{ minWidth: column.minWidth }}
                  >
                    {column.label}
                  </TableCell>
                ))}
              </TableRow>
            </TableHead>}
            <TableBody>
              {rows.length ? rows
                .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                .map((row, index) => {
                  return (<Row key={index} row={row} index={index} indexFils={1} />);
                }) :
                !matches && Array.from({ length: 5 }).map((_, index) => (
                  <TableRow key={index}>
                    {columnsFlight.map((column) => (
                      <TableCell key={column.id} align={column.align} style={{ width: column.minWidth, height: 30, padding: 0 }}                  >
                        {column.label ?
                          <Typography level="body-sm" sx={{ mt: 1, mb: 1, position: 'relative', overflow: 'hidden' }}                    >
                            <Skeleton loading={true}>HISTORY, HISTORY</Skeleton>
                          </Typography> : null}
                      </TableCell>
                    ))}
                  </TableRow>
                ))
              }
            </TableBody>
          </Table>
        </TableContainer>
      }
      <TablePagination
        rowsPerPageOptions={[10, 25, 100]}
        component="div"
        count={rows.length}
        rowsPerPage={rowsPerPage}
        page={page}
        onPageChange={handleChangePage}
        onRowsPerPageChange={handleChangeRowsPerPage}
      />

    </Stack>
  );
}


export default TableFlight;