import FormControl from '@mui/joy/FormControl';
import Input from '@mui/joy/Input';
import { FormHelperText, FormLabel } from '@mui/material';
import * as React from 'react';
import { NumericFormat, NumericFormatProps } from 'react-number-format';
;

interface CustomProps {
  onChange: (event: { target: { name: string; value: string } }) => void;
  name: string;
}

const NumericFormatAdapter = React.forwardRef<NumericFormatProps, CustomProps>(
  function NumericFormatAdapter(props, ref) {
    const { onChange, ...other } = props;

    return (
      <NumericFormat
        {...other}
        getInputRef={ref}
        onValueChange={(values:any) => {
          onChange({
            target: {
              name: props.name,
              value: values.value,
            },
          });
        }}
        valueIsNumericString
        thousandSeparator={false}
        decimalScale={0}
        allowNegative={false}
      />
    );
  },
);

type InputReactNumberFormatUSDProps = {
  field: any;
  label: string;
  error: string;
  placeholder: string;
}

const InputReactNumber: React.FC<InputReactNumberFormatUSDProps> = ({ field, label, error, placeholder }) => {

  return (
    <FormControl error={!!error} className="gap-2">
      <FormLabel htmlFor={label}>{label}</FormLabel>
      <Input
        id={label}
        {...field}
        placeholder={placeholder}
        slotProps={{
          input: {
            component: NumericFormatAdapter,
          },
        }}
        sx={{
          // border: 'none', // remove the native input border
          minWidth: 0, // remove the native input width
          outline: 0, // remove the native input outline
          // padding: 0, // remove the native input padding
          // paddingTop: '1em',
          flex: 1,
          color: 'inherit',
          // backgroundColor: 'transparent',
          fontFamily: 'inherit',
          fontSize: 'inherit',
          fontStyle: 'inherit',
          fontWeight: 'inherit',
          lineHeight: 'inherit',
          textOverflow: 'ellipsis',
          '&::placeholder': {
            opacity: 0,
            transition: '0.1s ease-out',
          },
          '&:focus::placeholder': {
            opacity: 1,
          },
          '&:focus ~ label, &:not(:placeholder-shown) ~ label, &:-webkit-autofill ~ label': {
            top: '0.5rem',
            fontSize: '0.75rem',
          },
          '&:focus ~ label': {
            color: 'var(--Input-focusedHighlight)',
          },
          '&:-webkit-autofill': {
            alignSelf: 'stretch', // to fill the height of the root slot
          },
          '&:-webkit-autofill:not(* + &)': {
            marginInlineStart: 'calc(-1 * var(--Input-paddingInline))',
            // paddingInlineStart: 'var(--Input-paddingInline)',
            borderTopLeftRadius:
              'calc(var(--Input-radius) - var(--variant-borderWidth, 0px))',
            borderBottomLeftRadius:
              'calc(var(--Input-radius) - var(--variant-borderWidth, 0px))',
          },
          minHeight: '56px',
          backgroundColor: 'white',
          border: '1.5px solid var(--variant-outlinedBorder, var(--joy-palette-neutral-outlinedBorder, var(--joy-palette-neutral-300, #CDD7E1)));',
          borderRadius: '6px'
        }}
      />

      {error && <FormHelperText error={!!error}>{error}</FormHelperText>}
    </FormControl>
  );
}

export default InputReactNumber