import { Alert } from '@mui/material';
import { useParams } from 'react-router-dom';
import { useGetOverviewQuery } from '../../../redux/features/itineraryApi';
import { useGetModificationByTripQuery } from '../../../redux/features/tripApi';
import { useAppSelector } from '../../../redux/hooks';
import CardDay from './cardDay';
import ProductDetails from './productDetails';


type ItineraryContentProps = {};

const ItineraryContent: React.FC<ItineraryContentProps> = () => {
  const params = useParams();
  const requestId = params.requestId;
  const subscriptionId = params.subscriptionId;
  const user = useAppSelector((state) => state.auth.user);
  const { data: dataOverview, refetch } = useGetOverviewQuery({ id: requestId, subscription_id: subscriptionId, user: { id: user?.id } });

  const { data } = useGetModificationByTripQuery({ id: requestId, subscription_id: subscriptionId, user: { id: user?.id } })
  return (
    <div className="flex flex-col gap-y-4">
      <Alert severity="success" >
        You have used {data?.data?.total || 0}/ {data?.data?.modification} modifications of this itinerary.
      </Alert>

      {(dataOverview?.data?.days || []).map((day, index) => (
        <CardDay key={index} day={day} />
      ))}
    </div>
  );
};
export default ItineraryContent;
