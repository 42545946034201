import { styled } from '@mui/material/styles';

interface DateRangeProps {

}

const DateRangeStyle = styled('div')<DateRangeProps>(({ theme }) => ({
  height: '100%',
  '& .react-datepicker-wrapper': {
    width: '100%'
  },
  '& .react-datepicker-popper': {
    zIndex: 100
  },
  '& input': {
    fontSize:'1rem',
    height: '100%',
    minHeight: '56px',
    border: '1.5px solid var(--variant-outlinedBorder, var(--joy-palette-neutral-outlinedBorder, var(--joy-palette-neutral-300, #CDD7E1)));',
    borderRadius: 4,
    padding: '14.5px 14px',
    textOverflow: 'ellipsis',
    whiteSpace: 'nowrap',
    overflow: 'hidden',
    background: theme.palette.background.default,
    color: theme.palette.text.primary,
    width: '100%',
    '&:hover': {
      backgroundColor: theme.palette.background.default,
      cursor: 'pointer',
    },

    '&:focus-visible': {
      outline: 'none',
    }

  },
  // '& .error': {
  //   border: '1.5px solid red',
  //   '&:hover': {
  //     backgroundColor: theme.palette.background.default,
  //     border: `1.5px solid red`,
  //     cursor: 'pointer',
  //   },
  //   '&:focus': {
  //     border: `1.5px solid red`,
  //   },
  // },

}))

export default DateRangeStyle