export const generateTripIdQuery = `
  query GenerateTripId($data: GenerateIdInput) {
    generateTripId(data: $data) {
      status
      data {
        id
        subscription_id
        user {
          id
        }
      }
    }
  }
`

export const triggerTripQuery = `
  query TriggerTrip($data: TripTriggerInput) {
    triggerTrip(data: $data) {
      status
      message
    }
  }
`

export const getSearchParametersQuery = `
  query GetSearchParameters($data: GetSearchParametersInput) {
    getSearchParameters(data: $data) {
      data {
        tripPurpose
        activities
        food
        dateRange
        target
        preferredAccommodation
        budgetInUSD
        nbrTravelers
        travelerStyle
        numberActivities
        prompt
        longitude
        latitude
      }
      status
    }
  }
`

export const getTripStatusQuery = `
  query GetTripStatus($data: TripStatusInput) {
    getTripStatus(data: $data) {
      status
      data
    }
  }
`

export const generateOptionsQuery = `
  query GenerateOptions($data: GenerateOptions) {
    generateOptions(data: $data) {
      status
      data {
        activities
        food
        accommodation
      }
    }
  }
`

export const getOptionsQuery = `
  query GetOptions($data: GetOptionInput) {
    getOptions(data: $data) {
      data {
        accommodation
        activities
        food
      }
      status
    }
  }
`

export const regenerateOptionsQuery = `
  query RegenerateOptions($data: RegenerateOptionInput) {
    regenerateOptions(data: $data) {
      data {
        activities
        food
        accommodation
      }
      status
    }
  }
`

export const validatePromptQuery = `
  query ValidatePrompt($data: ValidatePromptInput) {
    validatePrompt(data: $data) {
      data
      status
    }
  }
`

export const validateBudgetQuery = `
  query ValidateBudget($data: ValidateBudgetInput) {
    validateBudget(data: $data) {
      data
      status
    }
  }
`

export const budgetQuery = `
  query Budget($data: BudgetInput) {
    budget(data: $data) {
      data
      status
    }
  }
`

export const getModificationByTrip = `
query GetModificationByTrip($data: DataGenerateId) {
  getModificationByTrip(data: $data) {
    status
    data {
      total
      modification
    }
  }
}
`