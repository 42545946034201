import Tab, { tabClasses } from '@mui/joy/Tab';
import TabList from '@mui/joy/TabList';
import Tabs from '@mui/joy/Tabs';

type TabsPriceProps = {
  index: number;
  setIndex: (index: any) => void
}

const TabsPrice: React.FC<TabsPriceProps> = ({ index = 0, setIndex }) => {

  return (
    <Tabs
      size="lg"
      aria-label="Bottom Navigation"
      value={index}
      onChange={(event, value) => setIndex(value)}
      sx={(theme) => ({
        p: 1,
        borderRadius: 16,
        maxWidth: 400,
        mx: 'auto',
        boxShadow: theme.shadow.sm,
        '--joy-shadowChannel': theme.vars.palette.navoy_blue,
        [`& .${tabClasses.root}`]: {
          py: 1,
          flex: 1,
          transition: '0.3s',
          fontWeight: 'md',
          fontSize: 'md',
          [`&:not(.${tabClasses.selected}):not(:hover)`]: {
            opacity: 0.7,
          },
        },
      })}
    >
      <TabList
        variant="plain"
        size="sm"
        disableUnderline
        sx={{ borderRadius: 'lg', p: 0 }}
      >
        <Tab
          disableIndicator
          orientation="vertical"
          sx={theme => ({ ...(index === 0 && { color: theme.vars.palette.navoy_blue }), flex: 1 })}
          className='!font-semibold !text-xl'
        >
          Monthly
        </Tab>
        <Tab
          disableIndicator
          orientation="vertical"
          sx={theme => ({ ...(index === 1 && { color: theme.vars.palette.navoy_blue }), flex: 1 })}
          className='!font-semibold !text-xl'
        >
          Yearly
        </Tab>
      </TabList>
    </Tabs>
  );
}


export default TabsPrice;