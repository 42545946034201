import { createSlice } from '@reduxjs/toolkit';
import { forgetPasswordQuery, getImage, getUserInfo, getUserSocialMediaInfo, getUserSubscriptionStatus, googleAuthQuery, loginQuery, registerQuery, resetPasswordQuery, updateSocialMedia, updateUserInfo, updateUserSubscription, uploadImage } from '../queries/auth.query';
import { apiSlice } from './apiSlice';
import { GetImageResponse, GetUserInfoResponse, GetUserSocialMediaInfoResponse, GetUserSubscriptionStatusResponse, GoogleAuthResponse, IUser, LoginResponse, MessageResponse, UpdateSocialMediaResponse, UpdateUserSubscriptionResponse, UploadImageResponse, UserInfoResponse } from '../../utils/types';
const userLocal = localStorage.getItem("user") || 'null';
const user: IUser = JSON.parse(userLocal);
const initialState = user ? { isLoggedIn: true, user } : { isLoggedIn: false, user: null };

const authSlice = createSlice({
  name: 'auth',
  initialState,
  reducers: {
    register(state, action) {
      state.isLoggedIn = false
    },
    login(state, { payload }) {
      state.isLoggedIn = true
      state.user = state.user ? { ...state.user, ...payload.user } : payload.user
      localStorage.setItem('user', JSON.stringify(payload.user) || '')
    },
    logout(state, action) {
      state.isLoggedIn = false
      state.user = null
      localStorage.removeItem('user')
    },
    subscription(state, { payload }) {
      state.user = {
        ...state.user, subscription: payload.subscription
      }
      localStorage.setItem('user', JSON.stringify(state.user) || '')
    }
  }
})

export const { register, login, logout, subscription } = authSlice.actions

export default authSlice.reducer

export const extendedApiSlice = apiSlice?.injectEndpoints({
  endpoints: builder => ({
    getUsers: builder.query({
      query: () => '/users'
    }),
    register: builder.mutation({
      query: (data) => ({
        url: `/`,
        method: 'post',
        body: {
          query: registerQuery,
          variables: { data }
        }
      }),
      transformResponse: (response: { data: { register: MessageResponse } }) => response.data.register,
    }),
    login: builder.mutation({
      query: (data) => ({
        url: ``,
        method: 'post',
        body: {
          query: loginQuery,
          variables: { data }
        }
      }),
      transformResponse: (response: { data: { login: LoginResponse } }) => response.data.login
    }),
    twitter: builder.mutation({
      query: (code) => ({
        url: `/auth/twitter`,
        method: 'post',
        body: { code }
      }),

    }),
    google: builder.mutation({
      query: (data) => ({
        url: ``,
        method: 'post',
        body: {
          query: googleAuthQuery,
          variables: { data }
        }
      }),
      transformResponse: (response: { data: { loginGoogle: GoogleAuthResponse } }) => response.data.loginGoogle
    }),
    updateUserInfo: builder.mutation({
      query: (data) => ({
        url: `/`,
        method: 'post',
        body: {
          query: updateUserInfo,
          variables: { data }
        }
      }),
      transformResponse: (response: { data: { userInfo: UserInfoResponse } }) => response.data.userInfo,
    }),
    getUserInfo: builder.query({
      query: data => ({
        url: '/',
        method: 'post',
        body: {
          query: getUserInfo,
          variables: { data }
        }
      }),
      transformResponse: (response: { data: { getUserInfo: GetUserInfoResponse } }) => response.data.getUserInfo
    }),
    updateSocialMedia: builder.mutation({
      query: (data) => ({
        url: `/`,
        method: 'post',
        body: {
          query: updateSocialMedia,
          variables: { data }
        }
      }),
      transformResponse: (response: { data: { updateSocialMedia: UpdateSocialMediaResponse } }) => response.data.updateSocialMedia,
    }),
    getSocialMedia: builder.query({
      query: data => ({
        url: '/',
        method: 'post',
        body: {
          query: getUserSocialMediaInfo,
          variables: { data }
        }
      }),
      transformResponse: (response: { data: { getUserSocialMediaInfo: GetUserSocialMediaInfoResponse } }) => response.data.getUserSocialMediaInfo,
    }),
    uploadFile: builder.mutation({
      query: ({ file, id }: { file: File, id: string }) => {
        const formData = new FormData();
        const operations = JSON.stringify({
          query: uploadImage,
          variables: { file: null, id },
        });
        formData.append('operations', operations);
        const map = JSON.stringify({ '0': ['variables.file'] });
        formData.append('map', map);
        formData.append('0', file);
        return {
          url: '/',
          method: 'POST',
          body: formData,
          headers: {
            'apollo-require-preflight': 'true',  // Custom header to prevent CSRF
          }
        };
      },
      transformResponse: (response: { data: { uploadFile: UploadImageResponse } }) => response.data.uploadFile,
    }),
    getImage: builder.query({
      query: data => ({
        url: '/',
        method: 'post',
        body: {
          query: getImage,
          variables: { data }
        }
      }),
      transformResponse: (response: { data: { getImage: GetImageResponse } }) => response.data.getImage,
    }),
    updateUserSubscription: builder.mutation({
      query: (data) => ({
        url: `/`,
        method: 'post',
        body: {
          query: updateUserSubscription,
          variables: { data }
        }
      }),
      transformResponse: (response: { data: { updateUserSubscription: UpdateUserSubscriptionResponse } }) => response.data.updateUserSubscription
    }),
    getUserSubscriptionStatus: builder.query({
      query: (data) => ({
        url: `/`,
        method: 'post',
        body: {
          query: getUserSubscriptionStatus,
          variables: { data }
        }
      }),
      transformResponse: (response: { data: { getUserSubscriptionStatus: GetUserSubscriptionStatusResponse } }) => response.data.getUserSubscriptionStatus
    }),
    forgetPassword: builder.mutation({
      query: (data) => ({
        url: '/',
        method: 'post',
        body: {
          query: forgetPasswordQuery,
          variables: { data }
        }
      }),
      transformResponse: (response: { data: { forgotPassword: MessageResponse } }) => response.data.forgotPassword
    }),
    resetPassword: builder.mutation({
      query: (data) => ({
        url: '/',
        method: 'post',
        body: {
          query: resetPasswordQuery,
          variables: { data }
        }
      }),
      transformResponse: (response: { data: { resetPassword: MessageResponse } }) => response.data.resetPassword
    })
  })
})

export const {
  useGetUsersQuery,
  useRegisterMutation,
  useLoginMutation,
  useTwitterMutation,
  useGoogleMutation,
  useUpdateUserInfoMutation,
  useGetUserInfoQuery,
  useUpdateSocialMediaMutation,
  useGetSocialMediaQuery,
  useUploadFileMutation,
  useGetImageQuery,
  useLazyGetUserSubscriptionStatusQuery,
  useGetUserSubscriptionStatusQuery,
  useUpdateUserSubscriptionMutation,
  useForgetPasswordMutation,
  useResetPasswordMutation
} = extendedApiSlice;
