import { AddNewsletterResponse } from "../../utils/types"
import { addNewsletter, updateSearch } from "../queries"
import { addFeedback } from "../queries/feedback.query"
import { apiSlice } from "./apiSlice"

export const homeApiSlice = apiSlice.injectEndpoints({
  endpoints: builder => ({
    addNewsletter: builder.mutation({
      query: (data) => ({
        url: `/`,
        method: 'post',
        body: {
          query: addNewsletter,
          variables : {...data}
        }
      }),
      transformResponse: (response: { data: { addNewsletter: AddNewsletterResponse } }) => response.data.addNewsletter,
    }),
    updateSearch: builder.mutation({
      query: data => ({
        url: `/`,
        method: 'post',
        body: {
          query: updateSearch,
          variables : {...data}
        }
      }),
      transformResponse: (response: { data: { updateSearch: any } }) => response.data.updateSearch,
    }),
    addFeedback: builder.mutation({
      query: data => ({
        url: `/`,
        method: 'post',
        body: {
          query: addFeedback,
          variables : {...data}
        }
      }),
      transformResponse: (response: { data: { addFeedback: any } }) => response.data.addFeedback,
    })
  }),

})

export const { useAddNewsletterMutation, useAddFeedbackMutation, useUpdateSearchMutation } = homeApiSlice