import EditIcon from '@mui/icons-material/Edit';
import { Avatar, Badge, Button, Card, CardContent } from "@mui/joy";
import { styled } from '@mui/material/styles';
import { useEffect, useState } from "react";
import { useGetImageQuery, useUploadFileMutation } from "../../redux/features/authSlice";
import { useAppSelector } from "../../redux/hooks";

const VisuallyHiddenInput = styled('input')({
  clip: 'rect(0 0 0 0)',
  clipPath: 'inset(50%)',
  height: 1,
  overflow: 'hidden',
  position: 'absolute',
  bottom: 0,
  left: 0,
  whiteSpace: 'nowrap',
  width: 1,
});


type CardPhotoProps = {}

const CardPhoto: React.FC<CardPhotoProps> = () => {

  const [preview, setPreview] = useState<any>(null)
  const user = useAppSelector(state => state.auth.user)
  const [updateImage, { isLoading }] = useUploadFileMutation()
  const { data: imageData, refetch } = useGetImageQuery({ id: user?.id })
  const [imageSrc, setImageSrc] = useState('');

  useEffect(() => {
    if (imageData?.responseObject?.picture)
      fetch(imageData?.responseObject?.picture)
        .then(response => response.blob())
        .then(blob => {
          const url = URL.createObjectURL(blob);
          setImageSrc(url);
        })
        .catch(error => console.error('Error fetching the image:', error));
    if (imageData?.responseObject?.error) setImageSrc('')
  }, [imageData?.responseObject]);

  const handleFileChange = async (e: any) => {
    const file = e.target.files[0];
    if (file) {
      const reader = new FileReader();
      reader.onloadend = () => {
        setPreview(reader.result);
      };
      reader.readAsDataURL(file);
    }
    if (user?.id) await updateImage({ file, id: user.id }).unwrap();
    refetch()
  };

  return (
    <Card variant="plain" size="lg" className="me-auto">
      <CardContent >
        <div className="flex flex-wrap gap-6">
          <Badge
            anchorOrigin={{ vertical: 'bottom', horizontal: 'right' }}
            variant="plain"
            badgeContent={
              <Button size="sm" color="success" startDecorator={<EditIcon fontSize="small" className='text-white' />}
                className="!rounded-full !py-[0.375rem] !ps-[0.375rem] !pe-0"
                loading={isLoading} disabled={isLoading} component="label" role={undefined}>
                <VisuallyHiddenInput type="file" onChange={handleFileChange} />
              </Button>
            }
            badgeInset="14%"
            sx={{ '--Badge-paddingX': '0px' }}
          >
            <Avatar variant="outlined" className='!w-28 !h-28' src={preview || imageSrc} />
          </Badge>
        </div>
      </CardContent>
    </Card>
  );
}


export default CardPhoto;