import { Container, Grid } from '@mui/joy';
import * as React from 'react';
import PricingCard from './cardPrice';
import TabsPrice from './tabsPrice';

type PriceSectionProps = {}

const PriceSection: React.FC<PriceSectionProps> = () => {
  const [index, setIndex] = React.useState(0);

  return (
    <div className=" pt-14 pb-10">
      <Container maxWidth='lg'>
        <div className='mb-14'><TabsPrice index={index} setIndex={setIndex} /> </div>
        <div className='flex justify-around flex-wrap gap-10'>
          <Grid container spacing={4} sx={{ flexGrow: 1 }}>
            <Grid md={4} sm={12} display={'flex'} >
              <PricingCard chip='Great for small agents' price={35} title='Basic Plan' url='' index={index}
                list={['10 Generated trips', '10 Modifications per trip', '10 Exports', '1 Standard forms']}
                listNo={['Forms customization', 'Automatic Synch for flights & hotels into itineraries', 'Real time discounts notifications', 'Live collaboration with clients']}
              />
            </Grid>
            <Grid md={4} sm={12} display={'flex'}>
              <PricingCard chip='Best Value Offer for active agents' price={99} title='Pro Plan' url='' index={index}
                list={['30 Generated trips', '15 Modifications per trip', '30 Exports', '7 Standard forms', 'Forms customization', 'Automatic Synch for flights & hotels into itineraries']}
                listNo={['Real time discounts notifications', 'Live collaboration with clients']}
              />
            </Grid>
            <Grid md={4} sm={12} display={'flex'} >
              <PricingCard chip='Great for agents focusing on hyper personalized trips' price={350} title='Premium Plan' url='' index={index}
                list={['70 Generated trips', '20 Modifications per trip', '70 Exports', '7 Editable forms', 'Forms customization', 'Automatic Synch for flights & hotels into itineraries', 'Real time discounts notifications', 'Live collaboration with clients']}
                listNo={[]}
              />
            </Grid>
            <Grid md={4} sm={12} display={'flex'} marginX={{ md: 'auto' }} >
              <PricingCard chip='' title='Enterprise Plan' url='' list={[]} index={index}
                text={`For big agencies having multiple agents and selling thousands of trips yearly.`}
              />
            </Grid>
          </Grid>
        </div>

      </Container>
    </div>
  );
}





export default PriceSection;

