import { createSlice } from '@reduxjs/toolkit';
import { SET_MESSAGE } from "../actions/types";

const initialState = { message: '' };

const messageSlice = createSlice({
  name: 'message',
  initialState,
  reducers: {
    setMessage(state, action) {
      const { type, payload } = action;
      if (type === SET_MESSAGE) {
        state.message = payload
      }
    },
    clearMessage(state, action) {
      state.message = ""
    }
  }
})

export default messageSlice.reducer