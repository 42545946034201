import { Box, Drawer } from "@mui/material";

import { drawerWidth } from "../../utils/const";

type SidebarProps = {
  setIsClosing: (isClosing: boolean) => void;
  setMobileOpen: (isClosing: boolean) => void;
  mobileOpen: boolean;
  drawer: JSX.Element;
  desktopOpen: boolean
};

const Sidebar: React.FC<SidebarProps> = ({ setIsClosing, setMobileOpen, mobileOpen, drawer, desktopOpen }) => {
  const container =
    window !== undefined ? () => window.document.body : undefined;

  const handleDrawerClose = () => {
    setIsClosing(true);
    setMobileOpen(false);
  };

  const handleDrawerTransitionEnd = () => {
    setIsClosing(false);
  };

  return (
    <Box
      component="nav"
      sx={{ width: { sm: desktopOpen ? drawerWidth : 0 }, flexShrink: { sm: 0 } }}
      aria-label="sidebar"
    >
      {/* The implementation can be swapped with js to avoid SEO duplication of links. */}
      <Drawer
        container={container}
        variant="temporary"
        open={mobileOpen}
        onTransitionEnd={handleDrawerTransitionEnd}
        onClose={handleDrawerClose}
        ModalProps={{ keepMounted: true }}
        sx={theme => ({
          display: { xs: "block" },
          "& .MuiDrawer-paper": {
            boxSizing: "border-box", width: drawerWidth,
            backgroundColor: theme.palette.navoy.blue
          },

        })}
      >
        {drawer}
      </Drawer>
      {
        desktopOpen && <Drawer
          variant="permanent"
          sx={(theme) => ({
            display: { xs: "none", sm: "block" },
            "& .MuiDrawer-paper": {
              boxSizing: "border-box", width: drawerWidth,
              backgroundColor: theme.palette.navoy.blue
            },
          })}
          open
        >
          {drawer}
        </Drawer>
      }
    </Box>
  );
};

export default Sidebar;
