import { Container, Grid, LinearProgress, Pagination, Typography } from '@mui/material';
import React, { useEffect, useState } from 'react';
import BlogCard from './blogCard';
import { Blog } from '../../utils/types';
import { useLazyGetBlogsQuery } from '../../redux/features/blog.api';

const Blogs: React.FC = () => {
  const [getBlogs, { isFetching }] = useLazyGetBlogsQuery()
  const [blogs, setBlogs] = useState<Blog[]>([])
  const [page, setPage] = useState(1);
  const [totalPages, setTotalPages] = useState(1);
  const blogsPerPage = 6;

  useEffect(() => {
    const fetchBlogs = async () => {
      try {
        const blogs = await getBlogs({ page: 1, pageSize: blogsPerPage }).unwrap()
        setBlogs(blogs?.data);
        setTotalPages(blogs.meta.total || 1);
      } catch (error) {
        console.error('Error fetching blogs:', error);
      }
    };

    fetchBlogs();
  }, [getBlogs, page]);

  const handlePageChange = async (event: React.ChangeEvent<unknown>, value: number) => {
    setPage(value);
    try {
      const blogs = await getBlogs({ page: value, pageSize: blogsPerPage }).unwrap()
      setBlogs(blogs?.data);
      setTotalPages(blogs.meta.total);
    } catch (error) {
      console.error('Error fetching blogs:', error);
    }
  };

  return (
    <Container maxWidth="lg" sx={{ py: 8 }}>
      <Typography variant="h2" component="h2" gutterBottom>
        Our Blog
      </Typography>
      {isFetching && <LinearProgress />}
      <Grid container spacing={4}>
        {blogs.map((blog) => (
          <Grid item key={blog.id} xs={12} sm={6} md={4}>
            <BlogCard blog={blog} />
          </Grid>
        ))}
      </Grid>
      <Pagination
        count={totalPages}
        page={page}
        onChange={handlePageChange}
        sx={{ mt: 4, display: 'flex', justifyContent: 'center' }}
      />
    </Container>
  );
};

export default Blogs;
