export const addFeedback = `
mutation AddFeedback($email: String
  $rating: String
  $improve: String
  $requestId: String
  $type: String
  $comment: String){
    addFeedback(email:$email, rating:$rating,improve:$improve,requestId:$requestId,type:$type,comment:$comment){
      message
      responseObject
      statusCode
      success
    }
  }
`

export const getFeedback = `
  query GetFeedback {
    getFeedback{
      message
      responseObject {
        email
        rating
        improve
        requestId
        type
        comment
      }
      statusCode
      success
    }
  }
`