import { useCallback, useEffect, useState } from 'react';

import { useParams } from 'react-router-dom';
import { toast } from 'react-toastify';
import usePopover from '../../../../components/popover/usePopover';
import { useGetAllActivitiesQuery } from '../../../../redux/features/itineraryApi';
import { useAppSelector } from '../../../../redux/hooks';
import { useMapActivitiesStore } from '../../../../zustand';
import Map from './Map';
import MapPopover from './MapPopover';
import { MapActivity } from './types';

interface MapContainerProps {
  /**
   * Default latitude of the map.
   */
  defaultLat: number;
  /**
   * Default longitude of the map.
   */
  defaultLng: number;
  /**
   * Default zoom level of the map.
   */
  defaultZoom: number;
}

/**
 * Entry point for using the MapBox map.
 *
 * Example usage:
 * ```tsx
 *  <Map defaultLng={122.4194} defaultLat={37.7749} defaultZoom={12} />
 * ```
 */
const MapContainer = ({ defaultLat, defaultLng, defaultZoom }: MapContainerProps) => {
  const user = useAppSelector((state) => state.auth.user);

  const { mapRef } = useMapActivitiesStore();
  const { anchorEl, open, close } = usePopover();
  const { requestId, subscriptionId } = useParams();

  // ========================= State =========================
  const { data } = useGetAllActivitiesQuery({
    id: requestId,
    subscription_id: subscriptionId,
    user: { id: user?.id },
  });

  /**
   * Store state for map activities. This is used to show markers on the map.
   */
  const { setMapActivities } = useMapActivitiesStore();

  /**
   * The selected activity to be shown in the popover.
   */
  const [selectedActivity, setSelectedActivity] = useState<MapActivity | null>(null);

  // ========================= Handlers =========================
  /**
   * Handles the marker click event.
   */
  const handleMarkerClick = useCallback(
    (e: React.MouseEvent<HTMLElement>, feature: MapActivity) => {
      setSelectedActivity(feature);
      open(e);
    },
    [open]
  );

  /**
   * Zooms to the selected activity.
   */
  const handleZoom = useCallback(() => {
    if (!mapRef || !mapRef.current || !selectedActivity) return;
    close();
    mapRef.current.flyTo({
      center: [selectedActivity.longitude, selectedActivity.latitude],
      zoom: 17,
    });
  }, [close, selectedActivity, mapRef]);

  /**
   * Opens the activity's web page.
   */
  const handleExplore = useCallback(() => {
    if (selectedActivity?.webUrl) {
      window.open(selectedActivity.webUrl, '_blank');
    }
  }, [selectedActivity]);

  // ========================= Effects =========================
  /**
   * Initialize the map activities store from the itinerary activities when available.
   *
   * FIXME: The additional unnecessary checks are because the backend is broken.
   * Remove unnecessary checks once backend is fixed.
   */
  useEffect(() => {
    if (!data || !data.data || data.data.length === 0) return;

    const activitiesWithCoordinates = data.data.filter((activity) => activity.longitude && activity.latitude);
    if (activitiesWithCoordinates.length === 0) {
      console.debug(data);
      toast.warn('No activities with coordinates found. Map will stay empty.', {
        autoClose: 8000,
      });
      return;
    }

    setMapActivities(
      activitiesWithCoordinates.map((activity, id) => ({
        id: id.toString(),
        dayId: activity.day_id.toString(),
        title: activity.title,
        description: activity.description,
        visible: true,
        longitude: parseFloat(activity.longitude as string),
        latitude: parseFloat(activity.latitude as string),
        zoneId: activity.zone_id,
        startTime: new Date(activity.start_time),
        endTime: new Date(activity.end_time),
        webUrl: activity.web_url,
        imageUrl: activity.image_urls && activity.image_urls.length > 0 ? activity.image_urls[0] : undefined,
      }))
    );

    return () => {
      setMapActivities([]);
    };
  }, [data, setMapActivities]);

  return (
    <>
      <Map
        defaultLng={defaultLng}
        defaultLat={defaultLat}
        defaultZoom={defaultZoom}
        onMarkerClick={handleMarkerClick}
      />
      <MapPopover
        anchorEl={anchorEl}
        feature={selectedActivity}
        handleClose={close}
        handleExplore={handleExplore}
        handleZoom={handleZoom}
      />
    </>
  );
};

export default MapContainer;
