import { yupResolver } from "@hookform/resolvers/yup";
import AutorenewIcon from '@mui/icons-material/Autorenew';
import { Box, Button, FormHelperText, Stack } from "@mui/joy";
import { FormControl, FormLabel, MenuItem, Select, TextField } from "@mui/material";
import { useEffect, useState } from "react";
import { Controller, useForm } from "react-hook-form";
import { useParams } from "react-router-dom";
import { toast } from "react-toastify";
import { ChoiceChips } from "../../../components/inputs/Checkbox";
import { InputReactNumberFormatUSD } from "../../../components/inputs/input";
import TimeSelector from "../../../components/inputs/time";
import { useGetActivitiesByDayQuery, useLazyGetAllActivitiesQuery, useLazyRegenerateActivityByDayActivityQuery } from "../../../redux/features/itineraryApi";
import { useGetModificationByTripQuery, useGetOptionsQuery } from "../../../redux/features/tripApi";
import { useAppSelector } from "../../../redux/hooks";
import { validationSchemaRegenerateActivity } from "../../../utils/formYupValidation";
import { convertTime } from "../../../utils/functions";
import { ActivityOfDay, SelectOption } from "../../../utils/types";

type FormRegenerateProps = {
  setShowRegenerate: (open: boolean) => void
  dayID: string;
  activityID: number
}

type Activity = {
  prompt?: string;
  type: string[];
  budget: string;
  scheduled_startTime?: string;
  scheduled_endTime?: string;
}


const FormRegenerate: React.FC<FormRegenerateProps> = ({ setShowRegenerate, dayID, activityID }) => {
  const user = useAppSelector((state) => state.auth.user)
  const params = useParams()
  const requestId = params.requestId
  const subscriptionId = params.subscriptionId;
  const [regenerateActivity, { isFetching: isFetchingRegenerate, isError, error }] = useLazyRegenerateActivityByDayActivityQuery()
  const { refetch: refetchModification } = useGetModificationByTripQuery({ id: requestId, subscription_id: subscriptionId, user: { id: user?.id } })
  const [getAllActivities] = useLazyGetAllActivitiesQuery();

  const { data: dataOptions } = useGetOptionsQuery({ id: requestId, subscription_id: subscriptionId, user: { id: user?.id } })
  const { data: dataActivityByDay, refetch } = useGetActivitiesByDayQuery({ id: requestId, subscription_id: subscriptionId, user: { id: user?.id }, number: dayID })
  const [startTime1, setStartTime] = useState<any>(null)
  const [type, setType] = useState('activities')

  const { handleSubmit, control, formState: { errors }, getValues, watch } = useForm({
    resolver: yupResolver(validationSchemaRegenerateActivity),
    defaultValues: {
      budget: '',
      scheduled_startTime: '',
      scheduled_endTime: '',
      prompt: '',
      type: [],
    },
  });

  useEffect(() => {
    if (watch('scheduled_startTime')) {
      setStartTime(watch('scheduled_startTime'))
    }

  }, [watch('scheduled_startTime')])

  const onSubmit = async (data: Activity) => {
    try {
      const user_input = {
        time: data.scheduled_startTime && data.scheduled_endTime ? convertTime(data.scheduled_startTime) + ' - ' + convertTime(data.scheduled_endTime) : '',
        prompt: data.prompt,
        type: data.type,
        cost: Number(data.budget),
      }

      const dataInput = {
        trip_id: { id: requestId, subscription_id: subscriptionId, user: { id: user?.id } },
        user_input,
        day_id: dayID,
        activity_id: activityID
      }

      const activityResponse = await regenerateActivity({ ...dataInput }).unwrap()
      const { success } = activityResponse?.data ?? {};
      const isSuccess = success === true;
      if (activityResponse?.status === 401) {
        toast.warn(activityResponse?.message)
      } else {
        if (isSuccess) {
          refetch()
          refetchModification()
          getAllActivities({ id: requestId, subscription_id: subscriptionId, user: { id: user?.id } })
          toast.success("Your activity was regenerated successfully");
        } else {
          toast.error("Failed to regenerate activity. Please try again later");
        }
      }

      if (isSuccess) {
        setShowRegenerate(false);
      }
    } catch (error) {
      console.error('Error in onSubmit function:', error);
      toast.error('An error occurred while regenerating the activity. Please try again later.');
    }
  }

  const excludedTimes = () => {
    return dataActivityByDay?.data?.map((item: ActivityOfDay) => {
      return { time: item.scheduled_time }
    }) || []
  }

  return (
    <form onSubmit={handleSubmit(onSubmit)} className="w-full">
      <Stack spacing={4} mt={3}>
        <Box className='flex gap-3 items-center flex-wrap'>
          <FormControl sx={{ minWidth: { md: 190, xs: '100%' } }}>
            <Controller
              name="budget"
              control={control}
              render={({ field }) => (
                <InputReactNumberFormatUSD
                  field={field}
                  label='Budget'
                  error={errors?.budget?.message?.toString() || ''}
                  placeholder='Activity cost'
                  sx={{ minWidth: { md: 190, xs: '100%' } }}
                />
              )}
            />
          </FormControl>
          <FormControl sx={{ minWidth: { md: 185, xs: '100%' } }} className="gap-2" >
            <FormLabel >Start time</FormLabel>
            <Controller
              name="scheduled_startTime"
              control={control}
              rules={{ required: true }}
              render={({ field }) => (
                <TimeSelector field={field} excludedTimes={excludedTimes()} />
                // <Input  {...field} type="time" placeholder="Start time" variant="outlined" sx={{ minWidth: { md: 170, xs: '100%' }, minHeight: 56 }} />
              )}
            />
            {!!errors.scheduled_startTime?.message && (
              <FormHelperText
                sx={(theme) => ({
                  color: theme.vars.palette.danger[400],
                  fontSize: "xs",
                })}
              >
                {errors.scheduled_startTime?.message}
              </FormHelperText>
            )}
          </FormControl>
          <FormControl sx={{ minWidth: { md: 185, xs: '100%' } }} className="gap-2" >
            <FormLabel >End time</FormLabel>
            <Controller
              name="scheduled_endTime"
              control={control}
              rules={{ required: true }}
              render={({ field }) => (
                <TimeSelector field={field} excludedTimes={excludedTimes()} startTime={getValues('scheduled_startTime')} />
                // <Input {...field} type="time" placeholder="Start time" variant="outlined" sx={{ minWidth: { md: 170, xs: '100%' }, minHeight: 56 }} />
              )}
            />
            {!!errors.scheduled_endTime?.message && (
              <FormHelperText
                sx={(theme) => ({
                  color: theme.vars.palette.danger[400],
                  fontSize: "xs",
                })}
              >
                {errors.scheduled_endTime?.message}
              </FormHelperText>
            )}
          </FormControl>
          <FormControl sx={{ minWidth: { md: 185, xs: '100%' } }} className="gap-2">
            <FormLabel >Type</FormLabel>
            <Select value={type} onChange={(e) => setType(e.target.value)} >
              {
                [{ value: 'activities', label: 'Activities' }, { value: 'food', label: 'Food' }]?.map((item: SelectOption, key: number) => (
                  <MenuItem key={key} value={item.value}>{item.label}</MenuItem>
                ))
              }
            </Select>
          </FormControl>
        </Box>
        <Box className='flex gap-3 items-center flex-wrap'>
          <FormControl sx={{ minWidth: { lg: 530, xs: '100%' } }}>
            <Controller
              name='type'
              control={control}
              render={({ field }) => (
                <ChoiceChips
                  field={field}
                  list={(type === 'activities' ? dataOptions?.data?.activities : dataOptions?.data?.food) || []}
                  label='Interests and Activities'
                />
              )}
            />
            {errors?.type?.message && <FormHelperText>{errors?.type?.message?.toString()}</FormHelperText>}
          </FormControl>
        </Box>
        <FormControl  >
          <Controller
            name='prompt'
            control={control}
            render={({ field }) => (
              <TextField multiline label="Prompt" variant="outlined" fullWidth {...field}
                placeholder="Example: Somewhere calm, I need somewhere to buy gifts from, hidden gems, .." />
            )}
          />
        </FormControl  >
        <Box className='flex justify-center gap-3'>
          <Button disabled={isFetchingRegenerate} variant="outlined" onClick={() => setShowRegenerate(false)}>Discard</Button>
          <Button loading={isFetchingRegenerate} type="submit" endDecorator={<AutorenewIcon />}>Regenerate</Button>
        </Box>
      </Stack>
    </form>
  );
}


export default FormRegenerate;