import { BedOutlined, PersonOutline, SquareFootOutlined } from '@mui/icons-material';
import { LinearProgress } from '@mui/joy';
import { Box, Button, Grid, Paper, Skeleton, Tooltip, Typography } from '@mui/material';
import React, { useState } from 'react';
import { HotelRate, Room, RoomTypes } from '../../../../../utils/types';
import BookingDialog from './BookingDialog';

interface RoomProps {
  room: Room;
  rates?: RoomTypes[];
  isLoading: boolean;
  onBookRoom?: ( offerId: string) => void;
}

const HotelRoom: React.FC<RoomProps> = ({ room, rates, isLoading, onBookRoom }) => {
  const [showFullDescription, setShowFullDescription] = useState(false);
  const [showAllRates, setShowAllRates] = useState(false);
  const [openBookingDialog, setOpenBookingDialog] = useState(false);
  const [selectedRate, setSelectedRate] = useState<HotelRate | null>(null);
  const [offerId, setOfferId] = useState('');

  if (isLoading) {
    return (
      <Paper elevation={2} sx={{ p: 2, mb: 2 }}>
        <LinearProgress />
        <Skeleton variant="rectangular" height={200} />
      </Paper>
    );
  }

  const truncateDescription = (desc: string) => {
    const tempDiv = document.createElement('div');
    tempDiv.innerHTML = desc;
    const textContent = tempDiv.textContent || tempDiv.innerText;
    const words = textContent.split(' ');
    if (words.length > 30) {
      return words.slice(0, 30).join(' ') + '...';
    }
    return textContent;
  };

  const handleBookNow = (rate: HotelRate, offerId: string) => {
    setSelectedRate(rate);
    setOfferId(offerId);
    setOpenBookingDialog(true);
  };

  const handleConfirmBooking = () => {
    if (onBookRoom && offerId) {
      onBookRoom( offerId);
    }
    setOpenBookingDialog(false);
  };

  return (
    <Paper elevation={2} sx={{ p: 3, mb: 3, borderRadius: 1 }}>
      <Grid container spacing={3}>
        {/* Room Image and Basic Info */}
        <Grid item xs={12} md={4}>
          {room.photos && room.photos.length > 0 && (
            <Box sx={{
              height: 220,
              borderRadius: 1,
              overflow: 'hidden',
              mb: 2
            }}>
              <img
                src={room.photos[0].url}
                alt={room.photos[0].imageDescription || room.roomName}
                style={{ width: '100%', height: '100%', objectFit: 'cover' }}
              />
            </Box>
          )}

          <Typography variant="h6" gutterBottom fontWeight="600">
            {room.roomName}
          </Typography>

          <Box sx={{ display: 'flex', gap: 2, flexWrap: 'wrap', mb: 2 }}>
            <Tooltip title="Room Size">
              <Box sx={{ display: 'flex', alignItems: 'center', gap: 0.5 }}>
                <SquareFootOutlined fontSize="small" color="action" />
                <Typography variant="body2">
                  {room.roomSizeSquare} {room.roomSizeUnit}
                </Typography>
              </Box>
            </Tooltip>

            <Tooltip title="Maximum Occupancy">
              <Box sx={{ display: 'flex', alignItems: 'center', gap: 0.5 }}>
                <PersonOutline fontSize="small" color="action" />
                <Typography variant="body2">
                  Max: {room.maxOccupancy}
                </Typography>
              </Box>
            </Tooltip>
          </Box>
        </Grid>

        {/* Room Details and Description */}
        <Grid item xs={12} md={5}>
          <Box sx={{ mb: 2 }}>
            <Typography variant="subtitle2" color="text.secondary" gutterBottom>
              Bed Configuration
            </Typography>
            {room.bedTypes.map((bed, index) => (
              <Box key={index} sx={{ display: 'flex', alignItems: 'center', gap: 1, mb: 0.5 }}>
                <BedOutlined fontSize="small" color="action" />
                <Typography variant="body2">
                  {bed.quantity}x {bed.bedType} ({bed.bedSize})
                </Typography>
              </Box>
            ))}
          </Box>

          {room.description && (
            <Box sx={{ mt: 2 }}>
              <Typography
                variant="body2"
                color="text.secondary"
                sx={{ lineHeight: 1.6 }}
                dangerouslySetInnerHTML={{
                  __html: showFullDescription ? room.description : truncateDescription(room.description)
                }}
              />
              {room.description.length > 180 && (
                <Button
                  size="small"
                  onClick={() => setShowFullDescription(!showFullDescription)}
                  sx={{ mt: 1, textTransform: 'none' }}
                >
                  {showFullDescription ? 'Show Less' : 'Read More'}
                </Button>
              )}
            </Box>
          )}
        </Grid>

        {/* Pricing */}
        <Grid item xs={12} md={3}>
          {rates && rates.length > 0 ? (
            <Box>
              {rates.flatMap(roomType => roomType.rates.map(rate => ({ ...rate, offerId: roomType.offerId })))
                .sort((a, b) => a.retailRate.total[0].amount - b.retailRate.total[0].amount)
                .slice(0, showAllRates ? undefined : 1)
                .map((rate, index) => (
                  <Box
                    key={index}
                    sx={{
                      bgcolor: index === 0 ? 'primary.main' : 'grey.100',
                      color: index === 0 ? 'white' : 'text.primary',
                      p: 2,
                      borderRadius: 1,
                      mb: 1,
                      position: 'relative'
                    }}
                  >
                    {rate.retailRate.suggestedSellingPrice &&
                      rate.retailRate.suggestedSellingPrice[0].amount < rate.retailRate.total[0].amount && (
                        <Box
                          sx={{
                            position: 'absolute',
                            top: 8,
                            right: 8,
                            bgcolor: 'success.main',
                            color: 'white',
                            px: 1,
                            py: 0.5,
                            borderRadius: 1,
                            fontSize: '0.75rem'
                          }}
                        >
                          {Math.round(((rate.retailRate.total[0].amount - rate.retailRate.suggestedSellingPrice[0].amount) / rate.retailRate.total[0].amount) * 100)}% OFF
                        </Box>
                      )}
                    <Typography variant="subtitle2" gutterBottom>
                      {index === 0 ? 'Best Available Rate' : 'Alternative Rate'}
                    </Typography>
                    <Typography variant="body2" sx={{ opacity: 0.9 }}>
                      {rate.boardName}
                    </Typography>
                    {rate.retailRate.suggestedSellingPrice &&
                      rate.retailRate.suggestedSellingPrice[0].amount < rate.retailRate.total[0].amount ? (
                      <>
                        <Typography variant="h5" sx={{ my: 1, fontWeight: 'bold' }}>
                          ${rate.retailRate.suggestedSellingPrice[0].amount}
                        </Typography>
                        <Typography
                          variant="body2"
                          sx={{
                            textDecoration: 'line-through',
                            color: index === 0 ? 'rgba(255,255,255,0.7)' : 'text.secondary'
                          }}
                        >
                          ${rate.retailRate.total[0].amount}
                        </Typography>
                      </>
                    ) : (
                      <Typography variant="h5" sx={{ my: 1, fontWeight: 'bold' }}>
                        ${rate.retailRate.total[0].amount}
                      </Typography>
                    )}
                    <Button
                      variant="contained"
                      fullWidth
                      size="small"
                      onClick={() => handleBookNow(rate, rate.offerId)}
                      sx={{
                        bgcolor: index === 0 ? 'white' : 'primary.main',
                        color: index === 0 ? 'primary.main' : 'white',
                        '&:hover': {
                          bgcolor: index === 0 ? 'grey.100' : 'primary.dark'
                        }
                      }}
                    >
                      Book Now
                    </Button>
                  </Box>
                ))}
              {rates.reduce((total, roomType) => total + roomType.rates.length, 0) > 1 && (
                <Button
                  size="small"
                  fullWidth
                  onClick={() => setShowAllRates(!showAllRates)}
                  sx={{ mt: 1 }}
                >
                  {showAllRates ? 'Show Less' : `Show ${rates.reduce((total, roomType) => total + roomType.rates.length, 0) - 1} More Rates`}
                </Button>
              )}
            </Box>
          ) : (
            <Box sx={{
              p: 2,
              bgcolor: '#FFF4F4',
              borderRadius: 1,
              textAlign: 'center'
            }}>
              <Typography color="error" variant="subtitle2">
                Not Available
              </Typography>
            </Box>
          )}
        </Grid>
        <BookingDialog
          open={openBookingDialog}
          onClose={() => setOpenBookingDialog(false)}
          room={room}
          selectedRate={selectedRate}
          onConfirm={handleConfirmBooking}
        />
      </Grid>
    </Paper>
  );
};

export default HotelRoom;
