import { BlogsResponse } from '../../utils/types';
import { getBlog, getBlogs } from '../queries/blog.query';
import { apiSlice } from './apiSlice';

export const blogApi = apiSlice.injectEndpoints({
  endpoints: (builder) => ({
    getBlogs: builder.query({
      query: (data) => ({
        url: '/',
        method: 'POST',
        body: {
          query: getBlogs,
          variables: { data },
        },
      }),
      transformResponse: (response: { data: { getBlogs: BlogsResponse } }) => response.data.getBlogs,
    }),
    getBlog: builder.query({
      query: (documentId) => ({
        url: '/',
        method: 'POST',
        body: {
          query: getBlog,
          variables: { documentId },
        },
      }),
      transformResponse: (response: { data: { getBlog: BlogsResponse } }) => response.data.getBlog,
    }),
  }),
});

export const { useGetBlogsQuery, useGetBlogQuery, useLazyGetBlogsQuery } = blogApi;
