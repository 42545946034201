import { Box, Divider } from "@mui/joy";
import { differenceInHours, differenceInMinutes, format } from "date-fns";
import { convertMinutesToDuration } from "../../../utils/functions";
import { FlightSegment } from "../../../utils/types";

type SubRowProps = {
  flights: FlightSegment[]
}

const SubRow: React.FC<SubRowProps> = ({ flights }) => {
  return (
    <Box className="lg:ml-16 flex flex-col gap-3">
      {flights?.map((flight, index) => (
        <div key={index}>
          <Box key={index} className="flex flex-row justify-between">
            <Box className="flex flex-col gap-2">
              <Box className="flex flex-row gap-2">
                <Box className="flex flex-col self-stretch grid-cols-2 grid-rows-1" sx={{}}>
                  <Box sx={theme => ({ width: 12, height: 12, borderRadius: 12, border: `2px solid ${theme.vars.palette.navoy_bg[300]}` })}></Box>
                  <Box sx={theme => ({ width: 9, borderRight: `6px dotted  ${theme.vars.palette.navoy_bg[300]}`, marginY: 0.5, flex: 1 })}></Box>
                  <Box sx={theme => ({ width: 12, height: 12, borderRadius: 12, border: `2px solid ${theme.vars.palette.navoy_bg[300]}` })}></Box>
                </Box>
                <Box className='flex flex-col gap-2'>
                  <Box>
                    <span className="poppins-light text-sm ">{format(new Date(flight.departure_airport.time), 'HH:mm')}</span>
                    <span className="poppins-light text-sm"> . {flight.departure_airport.name}</span>
                  </Box>
                  <Box>
                    <span className="poppins-medium text-xs text-navoy_bg-300 ">Travel time: {convertMinutesToDuration(flight.duration)}</span>
                  </Box>
                  <Box>
                    <span className="poppins-light text-sm ">{format(new Date(flight.arrival_airport.time), 'HH:mm')}</span>
                    <span className="poppins-light text-sm"> . {flight.arrival_airport.name}</span>
                  </Box>

                </Box>
              </Box>
              <Box className='mt-auto ps-6'>
                <span className="text-xs text-navoy_bg-300">{`${flight?.airline} . ${flight.travel_class} . ${flight.airplane} . ${flight.flight_number}`} </span>
              </Box>
            </Box>
            <Box className="flex flex-col" >
              {
                flight.extensions?.map((extension, index) => (
                  <Box className="flex flex-row gap-2" key={index}>
                    <span className="text-xs poppins-light text-navoy-blue">{extension}</span> 
                  </Box>
                ))
              }
            </Box>
          </Box>
          {
            flights.length > 1 && index < flights.length - 1 &&
            <Box className="flex flex-col pl-6 py-2 gap-4" >
              <Divider orientation="horizontal" />
              <span>
                {
                  `${differenceInHours(new Date(flights[index + 1].departure_airport.time), new Date(flights[index].arrival_airport.time)) > 0 ?
                    differenceInHours(new Date(flights[index + 1].departure_airport.time), new Date(flights[index].arrival_airport.time)) + ' hrs ' : ''}
                  ${differenceInMinutes(new Date(flights[index + 1].departure_airport.time), new Date(flights[index].arrival_airport.time))}
                    min layover . ${flights[index].arrival_airport.name.split(' ')[0]} (${flights[index].arrival_airport.id})`}
              </span>
              <Divider orientation="horizontal" />
            </Box>
          }
        </div>
      ))}
    </Box>
  );
}


export default SubRow;