import React from 'react';

import { Box, Typography, Paper, Table, TableBody, TableCell, TableContainer, TableHead, TableRow, Button } from '@mui/material';

interface QuotationProps {
  clientName: string;
  clientAddress: string;
  clientPostcode: string;
  siteAddress: string;
  sitePostcode: string;
  items: {
    title: string;
    description: string;
    subTotal: number;
    tax: number;
  }[];
}

const Quotation: React.FC<QuotationProps> = ({ items }) => {
  const total = React.useMemo(() => items.reduce((acc, item) => acc + item.subTotal + item.tax, 0), [items]);
  return (
    <Box>
      <Paper sx={{ bgcolor: 'primary.main', color: 'white', p: 4, mb: 2, display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
        <Box>
          <Typography variant="h4" fontWeight="bold">Total</Typography>
        </Box>
        <Box sx={{ display: 'flex', flexDirection: 'column', alignItems: 'center', gap: 2 }}>
          <Typography variant="h4" fontWeight="bold">£{total}</Typography>
          <Button variant="contained" >Book Now</Button>
        </Box>
      </Paper>
      <Box sx={{ mb: 2, borderBottom: 1, borderColor: 'grey.300' }}>
        <Typography variant="h6" fontWeight="bold">Notes</Typography>
      </Box>
      <Typography color="text.secondary" sx={{ mb: 3 }}>Use this space to introduce the quote to your client.</Typography>
      {items.map((item, index) => (
        <Box key={index} sx={{ pt: 2 }}>
          <Box sx={{ borderBottom: 1, borderColor: 'grey.300', py: 2 }}>
            <Box sx={{ display: 'flex', justifyContent: 'space-between', mt: 1 }}>
              <Box>
                <Typography fontWeight="bold">{item.title}</Typography>
                <Typography color="text.secondary">{item.description}</Typography>
              </Box>
              <Box sx={{ display: 'flex', gap: 4, textAlign: 'right' }}>
                <Box>
                  <Typography color="text.secondary">Sub-total</Typography>
                  <Typography color="text.secondary">Tax</Typography>
                  <Typography fontWeight="bold">Total</Typography>
                </Box>
                <Box>
                  <Typography color="text.secondary">£{item.subTotal}</Typography>
                  <Typography color="text.secondary">£{item.tax}</Typography>
                  <Box sx={{ display: 'flex', flexDirection: 'column', gap: 2 }}>
                    <Typography color="primary" fontWeight="bold">£{item.subTotal + item.tax}</Typography>
                    <Button variant="contained" size="small">Book</Button>
                  </Box>
                </Box>
              </Box>
            </Box>
          </Box>
        </Box>
      ))}
    </Box>
  );
}; export default Quotation;