import * as yup from "yup";


const validationSchemaSearchTravel = yup.object({
  dateRange: yup.array().of(yup.date().nullable().required('Date Range is required')),
  target: yup.string().required('Destination is required'),
  source: yup.string().required('Source is required'),
  mode: yup.string().required('Mode is required'),
  budgetInUSD: yup.mixed()
    .test('Budget must be a number', 'Budget  >= 100 and Budget  <= 1000000', (value: any) => {
      return (!isNaN(value) && Number(value) >= 100 && Number(value) <= 1000000);
    })
    .required('Budget is required'),
  statement: yup.string()
}).required()

const validationSchemaFormTravel = yup.object({
  tripPurpose: yup.string().required('Trip purpose is required'),
  activities: yup.array().of(yup.string()),
  food: yup.array().of(yup.string()),
  dateRange: yup.array()
    .of(yup.date().nullable())
    .length(2, 'Date range must have exactly two dates'),
  target: yup.string().required('Target is required'),
  preferredAccommodation: yup.string(),
  budgetInUSD: yup.mixed()
    .test('Budget must be a number', 'Budget  >= 100 and Budget  <= 1000000', (value: any) => {
      return (!isNaN(value) && Number(value) >= 100 && Number(value) <= 1000000);
    })
    .required('Budget is required'),
  nbrTravelers: yup.string().required('Number of travelers is required'),
  travelerStyle: yup.string().required('Traveler Style is required'),
  numberActivities: yup.string().required('Number activities is required'),
  prompts: yup.string(),
}).required()

const validationSchemaPassword = yup.object({
  password: yup
    .string()
    .min(8, "Password must be at least 8 characters")
    .matches(
      /^(?=.*[A-Za-z])(?=.*\d)[A-Za-z\d@$!%*#?&]/,
      "Password must contain at least one letter and one number"
    )
    .required("Password is required"),
});

const validationSchemaLogin = yup.object().shape({
  email: yup.string().email("Email is invalid").required("Email is required"),
  password: yup
    .string()
    .min(8, "Password must be at least 8 characters")
    .matches(
      /^(?=.*[A-Za-z])(?=.*\d)[A-Za-z\d@$!%*#?&]/,
      "Password must contain at least one letter and one number"
    )
    .required("Password is required"),
  remember: yup.boolean(),
});


const validationSchemaSignup = yup.object().shape({
  email: yup.string().email("Email is invalid").required("Email is required"),
  password: yup
    .string()
    .min(8, "Password must be at least 8 characters")
    .matches(
      /^(?=.*[A-Za-z])(?=.*\d)[A-Za-z\d@$!%*#?&]/,
      "Password must contain at least one letter and one number"
    )
    .required("Password is required"),
  name: yup.string().required('Name is required'),
  surName: yup.string().required('Surname is required'),
  agencyName: yup.string(),
  website: yup.string(),
  remember: yup.boolean(),
});

const validationSchemaCardInfo = yup.object().shape({
  name: yup.string().required('Name is required'),
  surName: yup.string().required('Surname is required'),
  agencyName: yup.string(),
  website: yup.string().url('Invalid URL'),
  description: yup.string()
})

const validationSchemaCardSocialMedia = yup.object().shape({
  x: yup.string(),
  pinterest: yup.string(),
  instagram: yup.string(),
  tiktok: yup.string(),
  linkedin: yup.string()
})

const validationSchemaGetInTouch = yup.object().shape({
  firstName: yup.string().required('First Name is required'),
  lastName: yup.string().required('Last Name is required'),
  email: yup.string().email("Email is invalid").required("Email is required"),
  message: yup.string().required('Message is required'),
})

const validationSchemaAddActivity = yup.object({
  budget: yup.string().required('Budget is required'),
  description: yup.string(),
  scheduled_startTime: yup.string().required('Scheduled time is required'),
  // .matches(/^([0-1]?[0-9]|2[0-3]):[0-5][0-9] (AM|PM)$/, 'Invalid time format'),
  scheduled_endTime: yup.string().required('Scheduled time is required')
    .test('is-greater', 'The end time must be greater than start time', function (endTime) {
      const startTime = this.parent.scheduled_startTime;
      if (startTime && endTime) {
        return new Date(endTime) > new Date(startTime);
      }
      return false;
    }),
  // .matches(/^([0-1]?[0-9]|2[0-3]):[0-5][0-9] (AM|PM)$/, 'Invalid time format'),
  title: yup.string().required('Title is required'),
  type: yup.string().required('Type is required'),
  web_url: yup.string().url('Invalid URL format')
}).required();

const validationSchemaRegenerateActivity = yup.object({
  budget: yup.string().required('Budget is required'),
  scheduled_startTime: yup.string(),
  scheduled_endTime: yup.string(),
  type: yup.array().of(yup.string().required()).required('Type is requires'),
  prompt: yup.string()
}).required()

const validationSchemaSource = yup.object().shape({
  source: yup.string().required('Source is required')
})

const validationSchemaRequestDemo = yup.object().shape({
  firstName: yup.string().required('First Name is required'),
  lastName: yup.string().required('Last Name is required'),
  email: yup.string().email("Email is invalid").required("Email is required"),
  country: yup.string().required('Country is required'),
  city: yup.string().required('City is required'),
  additionalComments: yup.string(),
});

const validationSchemaEnterprise = yup.object().shape({
  firstName: yup.string().required('First Name is required'),
  lastName: yup.string().required('Last Name is required'),
  email: yup.string().email("Email is invalid").required("Email is required"),
  agentName: yup.string().required('Agent name is required'),
  website: yup.string().required('Website is required'),
  additionalComments: yup.string(),
});

export {
  validationSchemaSearchTravel,
  validationSchemaFormTravel,
  validationSchemaSignup,
  validationSchemaCardInfo,
  validationSchemaLogin,
  validationSchemaCardSocialMedia,
  validationSchemaGetInTouch,
  validationSchemaAddActivity,
  validationSchemaRegenerateActivity,
  validationSchemaSource,
  validationSchemaRequestDemo,
  validationSchemaPassword,
  validationSchemaEnterprise
};