import { compareAsc, format, formatDistanceToNow, isValid, parse } from "date-fns";
import { toZonedTime } from "date-fns-tz";
import { apiKey, geocodeJson, xClientId, xRedirectUri, xRootUrl, xState } from "./env";

const convertDateToZoneTime = (value: string | Date) => {
  const date = new Date(value);
  const timeZone = Intl.DateTimeFormat().resolvedOptions().timeZone;
  const zonedDate = toZonedTime(date, timeZone);
  return zonedDate
}

export const getTwitterOauthUrl = () => {
  const rootUrl = xRootUrl;
  const options = {
    redirect_uri: xRedirectUri,
    client_id: xClientId,
    state: xState,
    response_type: "code",
    code_challenge: "challenge",
    code_challenge_method: "plain",
    scope: ["users.read", "tweet.read", "follows.read", "follows.write"].join(" "), // add/remove scopes as needed
  };
  const qs = new URLSearchParams(options).toString();
  return `${rootUrl}?${qs}`;
}

export const formatDate = (date: string) => {
  date = date || '';
  return isValid(new Date(date)) ? format(new Date(date), 'yyyy-MM-dd') : ''
}
export const formatDateTime = (date: string) => {
  date = date || '';
  return isValid(new Date(date)) ? format(new Date(date), 'yyyy-MM-dd HH:mm') : ''
}

export const loadAsyncScript = async (src: any) => {
  return new Promise((resolve) => {
    const script = document.createElement("script");
    Object.assign(script, {
      type: "text/javascript",
      async: true,
      src,
    });
    script.addEventListener("load", () => resolve(script));
    document.head.appendChild(script);
  });
}

export const extractAddress = () => {
  const address = {
    city: "",
    state: "",
    zip: "",
    country: "",
  };
  if (navigator.geolocation) {
    navigator.geolocation.getCurrentPosition(async (position) => {
      try {
        const { latitude, longitude } = position.coords
        const url = `${geocodeJson}?key=${apiKey}&latlng=${latitude},${longitude}`;

        const response = await fetch(url);
        const location = await response.json();
        const place = location.results[0];

        if (!Array.isArray(place?.address_components)) {
          return address;
        }

        place.address_components.forEach((component: any) => {
          const types = component.types;
          const value = component.long_name;

          if (types.includes("locality")) {
            address.city = value;
          }

          if (types.includes("administrative_area_level_2")) {
            address.state = value;
          }

          if (types.includes("postal_code")) {
            address.zip = value;
          }

          if (types.includes("country")) {
            address.country = value;
          }
        });
      } catch (error) {
        console.log('error: ', error);
      }
    });
  }
  return address;
};

export const convertMinutesToDuration = (minutes: number) => {
  let hours = Math.floor(minutes / 60);
  let remainingMinutes = minutes % 60;

  return hours + " hr " + remainingMinutes + " min";
}

export const dateOfInput = (startDate: string, date: string) => {
  if (!date) return null
  return isValid(new Date(date)) && compareAsc(new Date(startDate), new Date()) === 1 ? new Date(date) : null
}


export const formatDateRange = (startDate: string, endDate: string) => {


  const s = format(convertDateToZoneTime(startDate), 'yyyy-MM-dd HH:mm:ss')
  const e = format(convertDateToZoneTime(endDate), 'yyyy-MM-dd HH:mm:ss')
  const start = parse(s, 'yyyy-MM-dd HH:mm:ss', new Date());
  const end = parse(e, 'yyyy-MM-dd HH:mm:ss', new Date());

  const startMonth = format(start, 'MMMM');
  const endMonth = format(end, 'MMMM');

  const startDay = format(start, 'd');
  const endDay = format(end, 'd');

  const startYear = format(start, 'yyyy');
  const endYear = format(end, 'yyyy');


  if (startYear !== endYear) {
    return `${startDay} ${startMonth} ${startYear} - ${endDay} ${endMonth} ${endYear}`;
  } else if (startMonth === endMonth) {
    return `${startDay} - ${endDay} ${startMonth} ${startYear}`;
  } else {
    return `${startDay} ${startMonth} - ${endDay} ${endMonth} ${startYear}`;
  }
}

export const timeSince = (date: Date | string) => {
  const gmtDate = new Date(date + ' GMT+0000');
  const localDate = convertDateToZoneTime(gmtDate.toUTCString());
  return formatDistanceToNow(localDate, { addSuffix: true });
}

export const convertTime = (timeString: string): string => {
  return format(new Date(timeString), 'hh:mm a');
}