import { StyleSheet, Text, View } from '@react-pdf/renderer';
import React, { useMemo } from 'react';
import { createTw } from 'react-pdf-tailwind';

import { ReportFlight } from '../../../types';
import FlightItem from './FlightItem';

const tw = createTw({});

const styles = StyleSheet.create({
  bold: {
    fontFamily: 'Helvetica-Bold',
    fontWeight: 'bold',
  },
});

interface FlightDetailsProps {
  data: ReportFlight[];
  primaryColor: string;
  secondaryColor: string;
}

const FlightDetails: React.FC<FlightDetailsProps> = (props) => {
  const totalCost = useMemo(() => {
    return props.data.reduce((total, acc) => total + acc.costUsd, 0);
  }, [props.data]);

  return (
    <View>
      <View style={[tw('p-3 border flex items-center rounded-sm'), { backgroundColor: props.primaryColor }]}>
        <Text style={[tw('text-lg leading-none'), styles.bold]}>Flight Details</Text>
      </View>
      <View style={[tw('my-2 border border-black')]}>
        {/* Header */}
        <View wrap={false} style={[tw('flex flex-row'), { backgroundColor: props.secondaryColor }]}>
          <Text style={[tw('flex-1 text-xs p-2'), styles.bold]}>Name</Text>
          <Text style={[tw('flex-1 text-xs p-2'), styles.bold]}>Time</Text>
          <Text style={[tw('flex-1 text-xs p-2'), styles.bold]}>Duration</Text>
          <Text style={[tw('flex-1 text-xs p-2'), styles.bold]}>Stops</Text>
          <Text style={[tw('flex-1 text-xs p-2'), styles.bold]}>Type</Text>
          <Text style={[tw('flex-1 text-xs p-2'), styles.bold]}>Cost</Text>
        </View>
        {/* Flight Items or No Flights Available */}
        {props.data.length === 0 ? (
          <View style={[tw('flex flex-row p-4')]}>
            <Text style={[tw('flex-1 text-xs text-center')]}>No flights available</Text>
          </View>
        ) : (
          props.data.map((flight, index) => (
            <FlightItem
              key={index}
              name={flight.name}
              time={flight.time}
              duration={flight.duration}
              stops={flight.stops}
              type={flight.type}
              costUsd={flight.costUsd}
              departureTime={flight.departureTime}
              departureAirport={flight.departureAirport}
              arrivalTime={flight.arrivalTime}
              arrivalAirport={flight.arrivalAirport}
              airline={flight.airline}
              amenities={flight.amenities}
              primaryColor={props.primaryColor}
              secondaryColor={props.secondaryColor}
              isFirst={index === 0}
            />
          ))
        )}
      </View>
      {/* Total Cost Section */}
      {props.data.length > 0 && (
        <View
          wrap={false}
          style={[tw('border flex flex-row p-2 px-4 justify-between'), { backgroundColor: props.secondaryColor }]}
        >
          <Text style={[tw('text-xs leading-none'), styles.bold]}>Total Cost</Text>
          <Text style={[tw('text-xs leading-none'), styles.bold]}>
            {totalCost.toLocaleString('en-US', { style: 'currency', currency: 'USD' })}
          </Text>
        </View>
      )}
    </View>
  );
};

export default FlightDetails;
