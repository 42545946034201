export const getOverviewStatusQuery = `
  query GetOverviewStatus {
  getOverviewStatus {
    status
    data
  }
}
`
export const getOverviewQuery = `
  query GetOverview($data: DataGenerateId) {
    getOverview(data: $data) {
      data {
        days {
          id
          title
          description
          date
          city
          budget
          number_of_activities
          attraction_key_words
          food_key_words
        }
        description
        title
      }
    }
  }
`

export const getActivitiesByDayQuery = `
query GetActivitiesByDay($data: GetActivityByDayInput) {
  getActivitiesByDay(data: $data) {
    status
    data {
      location_id
      scheduled_time
      title
      description
      type
      web_url
      budget
      longitude
      latitude
      image_urls
      image_urls_original
      image_urls_large
    }
  }
}
`

export const deleteActivityByDayRankQuery = `
mutation DeleteActivityByDayRank($data: GetActivityByDayRankInput) {
  deleteActivityByDayRank(data: $data) {
    status
    data {
      message
      success
    }
    message
  }
}
`

export const addActivityByDayQuery = `
mutation AddActivityByDay($data: AddActivityInput) {
  addActivityByDay(data: $data) {
    status
    data {
      success
      message
    }
    message
  }
}
`

export const regenerateActivityByDayActivityQuery = `
query RegenerateActivityByDayActivity($data: RegenerateActivityByDayActivityInput) {
  regenerateActivityByDayActivity(data: $data) {
    status
    data {
      message
      success
    }
    message
  }
}
`
export const regenerateItineraryQuery = `
  query RegenerateItinerary($data: RegenerateItineraryInput) {
    regenerateItinerary(data: $data) {
      status
      message
    }
  }
`

export const getAllActivities = `
query GetAllActivities($data: DataGenerateId) {
  getAllActivities(data: $data) {
    status
    data {
      day_id
      location_id
      title
      scheduled_time
      description
      budget
      zone_id
      start_time
      end_time
      web_url
      longitude
      latitude
      image_urls
    }
  }
}
`