import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import RefreshIcon from '@mui/icons-material/Refresh';
import {
  Button,
  CircularProgress,
  FormControl,
  FormControlLabel,
  IconButton,
  InputLabel,
  MenuItem,
  Select,
  Switch,
  TextField,
  Tooltip,
} from '@mui/material';
import { PDFViewer } from '@react-pdf/renderer';
import React from 'react';
import { ChromePicker } from 'react-color';
import { Link } from 'react-router-dom';

import { TemplateKey, Templates } from '../templates';
import { ReportAccommodation, ReportActivity, ReportFlight } from '../types';

interface ExportComponentProps {
  requestId: string;
  subscriptionId: string;
  refreshKey: number;
  isLoading: boolean;
  useDummyData: boolean;
  title: string;
  description: string;
  subject: string;
  flights: ReportFlight[];
  accommodations: ReportAccommodation[];
  activities: ReportActivity[];
  primaryColor: { value: string; debouncedValue: string; setValue(value: string): void };
  secondaryColor: { value: string; debouncedValue: string; setValue(value: string): void };
  template: { value: TemplateKey; debouncedValue: TemplateKey; setValue(value: TemplateKey): void };
  agentName: { value: string; debouncedValue: string; setValue(value: string): void };
  agentEmail: { value: string; debouncedValue: string; setValue(value: string): void };
  agentPhone: { value: string; debouncedValue: string; setValue(value: string): void };
  clientName: { value: string; debouncedValue: string; setValue(value: string): void };
  clientEmail: { value: string; debouncedValue: string; setValue(value: string): void };
  clientPhone: { value: string; debouncedValue: string; setValue(value: string): void };
  displayPrimaryColorPicker: boolean;
  displaySecondaryColorPicker: boolean;
  handleRefresh: () => void;
  setDisplayPrimaryColorPicker: (display: boolean) => void;
  handlePrimaryColorClick: () => void;
  setDisplaySecondaryColorPicker: (display: boolean) => void;
  handleSecondaryColorClick: () => void;
  handlePrimaryColorClose: () => void;
  handleSecondaryColorClose: () => void;
  handleToggleDummyData: () => void;
}

export const ExportComponent: React.FC<ExportComponentProps> = (props) => {
  return (
    <div className="flex flex-wrap flex-grow h-full bg-gray-200 rounded-lg shadow-lg">
      {/* Editor */}
      <section className="flex flex-col p-2 md:w-1/2 gap-y-2">
        {/* Add a back button with MUI */}
        <div className="flex justify-between">
          <article>
            <Link to={`/dashboard/travel/${props.requestId}/${props.subscriptionId}`}>
              <Button className="p-2 mb-4 text-white rounded-lg" startIcon={<ArrowBackIcon />} color="primary">
                Back to trip
              </Button>
            </Link>
          </article>
          <article className="flex items-center">
          <FormControlLabel
              control={
                <Switch
                  checked={!props.useDummyData}
                  onChange={props.handleToggleDummyData}
                  color="primary"
                />
              }
              label={`Using ${props.useDummyData ? 'sample' : 'real'} data`}
            />
            <Tooltip title="Manually refresh PDF">
              <IconButton color="primary" onClick={props.handleRefresh}>
                <RefreshIcon />
              </IconButton>
            </Tooltip>
          </article>
        </div>

        <div className="flex flex-col flex-grow rounded-lg">
          {/* Indicator for Sample Data */}
          {props.useDummyData && <div className="mb-2 text-sm text-red-500">Sample data is currently in use.</div>}

          {/* Agent Details */}
          <article className="p-4 mb-4 rounded-md shadow-lg bg-gray-50">
            <h2 className="mb-4 text-xl font-bold">Agent Details</h2>
            <TextField
              label="Agent Name"
              variant="outlined"
              fullWidth
              margin="normal"
              value={props.agentName.value}
              onChange={(e) => props.agentName.setValue(e.target.value)}
            />
            <TextField
              label="Agent Email"
              variant="outlined"
              fullWidth
              margin="normal"
              value={props.agentEmail.value}
              onChange={(e) => props.agentEmail.setValue(e.target.value)}
            />
            <TextField
              label="Agent Phone"
              variant="outlined"
              fullWidth
              margin="normal"
              value={props.agentPhone.value}
              onChange={(e) => props.agentPhone.setValue(e.target.value)}
            />
          </article>

          {/* Client Details */}
          <article className="p-4 mb-4 rounded-md shadow-lg bg-gray-50">
            <h2 className="mb-4 text-xl font-bold">Client Details</h2>
            <TextField
              label="Client Name"
              variant="outlined"
              fullWidth
              margin="normal"
              value={props.clientName.value}
              onChange={(e) => props.clientName.setValue(e.target.value)}
            />
            <TextField
              label="Client Email"
              variant="outlined"
              fullWidth
              margin="normal"
              value={props.clientEmail.value}
              onChange={(e) => props.clientEmail.setValue(e.target.value)}
            />
            <TextField
              label="Client Phone"
              variant="outlined"
              fullWidth
              margin="normal"
              value={props.clientPhone.value}
              onChange={(e) => props.clientPhone.setValue(e.target.value)}
            />
          </article>

          {/* General */}
          <article className="p-4 mb-4 rounded-md shadow-lg bg-gray-50">
            <h2 className="mb-4 text-xl font-bold">Theme</h2>
            <div className="flex justify-around mb-4">
              <section className="flex flex-col items-center">
                <h3 className="mb-2 font-semibold text-gray-800">Primary Color</h3>
                <div
                  className="w-10 h-10 border border-gray-800 rounded-full cursor-pointer"
                  style={{ backgroundColor: props.primaryColor.value }}
                  onClick={props.handlePrimaryColorClick}
                />
                {props.displayPrimaryColorPicker ? (
                  <div className="absolute z-10">
                    <div className="fixed inset-0" onClick={props.handlePrimaryColorClose} />
                    <ChromePicker
                      color={props.primaryColor.value}
                      onChange={(color) => props.primaryColor.setValue(color.hex)}
                    />
                  </div>
                ) : null}
              </section>
              <section className="flex flex-col items-center">
                <h3 className="mb-2 font-semibold text-gray-800">Secondary Color</h3>
                <div
                  className="w-10 h-10 border border-gray-800 rounded-full cursor-pointer"
                  style={{ backgroundColor: props.secondaryColor.value }}
                  onClick={props.handleSecondaryColorClick}
                />
                {props.displaySecondaryColorPicker ? (
                  <div className="absolute z-10">
                    <div className="fixed inset-0" onClick={props.handleSecondaryColorClose} />
                    <ChromePicker
                      color={props.secondaryColor.value}
                      onChange={(color) => props.secondaryColor.setValue(color.hex)}
                    />
                  </div>
                ) : null}
              </section>
            </div>
            <FormControl fullWidth margin="normal">
              <InputLabel id="template-label">Template</InputLabel>
              <Select
                labelId="template-label"
                id="template"
                value={props.template.value}
                onChange={(e) => props.template.setValue(e.target.value as TemplateKey)}
                label="Template"
              >
                {Object.keys(Templates).map((key) => (
                  <MenuItem key={key} value={key}>
                    {key}
                  </MenuItem>
                ))}
              </Select>
            </FormControl>
          </article>
        </div>
      </section>

      {/* PDF Preview */}
      <section className="sticky flex flex-col w-full p-2 top-16 md:w-1/2 h-[calc(100vh-7rem)]">
        <div className="flex flex-col flex-grow rounded-lg">
          {props.isLoading ? (
            <div className="flex items-center justify-center h-full bg-gray-300">
              <CircularProgress /> {/* Loading indicator */}
            </div>
          ) : (
            <PDFViewer className="w-full h-full" key={props.refreshKey}>
              {React.createElement(Templates[props.template.debouncedValue], {
                title: props.title,
                description: props.description,
                subject: props.subject,
                author: props.agentName.debouncedValue,
                primaryColor: props.primaryColor.debouncedValue,
                secondaryColor: props.secondaryColor.debouncedValue,
                agentName: props.agentName.debouncedValue,
                agentEmail: props.agentEmail.debouncedValue,
                agentPhone: props.agentPhone.debouncedValue,
                clientName: props.clientName.debouncedValue,
                clientEmail: props.clientEmail.debouncedValue,
                clientPhone: props.clientPhone.debouncedValue,
                flights: props.flights,
                accommodations: props.accommodations,
                activities: props.activities,
              })}
            </PDFViewer>
          )}
        </div>
      </section>
    </div>
  );
};
