import * as React from 'react';
import Checkbox from '@mui/joy/Checkbox';
import List from '@mui/joy/List';
import ListItem from '@mui/joy/ListItem';
import Typography from '@mui/joy/Typography';
import Sheet from '@mui/joy/Sheet';
import Done from '@mui/icons-material/Done';

type ChoiceChipsProps = {
  list: string[];
  field: any;
  label: string
}

const ChoiceChips: React.FC<ChoiceChipsProps> = ({ list, field, label }) => {
  return (
    <Sheet variant="outlined" sx={{ p: 2, borderRadius: 'sm' }}>
      <Typography id="rank" level="body-sm" fontWeight="lg" sx={{ mb: 1.5 }}>
        {label}
      </Typography>
      <div role="group" aria-labelledby="rank">
        <List
          orientation="horizontal"
          wrap
          sx={{
            '--List-gap': '8px',
            '--ListItem-radius': '20px',
            '--ListItem-minHeight': '32px',
            '--ListItem-gap': '4px',
          }}
        >
          {list.map((item, index) => (
            <ListItem key={item}>
              {field?.value.includes(item) && (
                <Done
                  fontSize="medium"
                  color="primary"
                  sx={{ ml: -0.5, zIndex: 2, pointerEvents: 'none' }}
                />
              )}
              <Checkbox
                size="sm"
                // disabled={index === 0}
                disableIcon
                overlay
                label={item}
                checked={field?.value.includes(item)}
                variant={field?.value.includes(item) ? 'soft' : 'outlined'}
                onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
                  if (event.target.checked) {
                    field.onChange([...field?.value, item]);
                  } else {
                    field.onChange(field?.value.filter((text: string) => text !== item));
                  }
                }}
                slotProps={{
                  action: ({ checked }) => ({
                    sx: checked
                      ? {
                        border: '1px solid',
                        borderColor: 'primary.500',
                      }
                      : {},
                  }),
                }}
              />
            </ListItem>
          ),
          )}
        </List>
      </div>
    </Sheet>
  );
}


export default ChoiceChips;