import { GoogleOAuthProvider } from '@react-oauth/google';
import ReactDOM from "react-dom/client";
import "./index.css";
import App from "./App";
import { googleClientId } from "./utils/env";
import 'react-toastify/dist/ReactToastify.css';

// import reportWebVitals from './reportWebVitals';

const root = ReactDOM.createRoot(
  document.getElementById("root") as HTMLElement
);
root.render(
  <GoogleOAuthProvider clientId={googleClientId}>
    <App />
  </GoogleOAuthProvider>
);

// reportWebVitals(console.log);