import { CancelUserSubscription, GetUserSubscription, UpdateTerms } from "../../utils/types";
import { cancelUserSubscription, getEnterpriseLink, getUserSubscriptionData, updateTerms } from "../queries/users.query";
import { apiSlice } from "./apiSlice";

export const UsersApiSlice = apiSlice.injectEndpoints({

  endpoints: builder => ({
    getUserSubscriptionData: builder.query({
      query: params => ({
        method: 'post',
        url: `/`,
        body: {
          query: getUserSubscriptionData,
          variables: { data: params }
        }
      }),
      transformResponse: (response: { data: { getUserSubscriptionData: GetUserSubscription } }) => response.data.getUserSubscriptionData
    }),
    cancelUserSubscription: builder.mutation({
      query: params => ({
        method: 'post',
        url: `/`,
        body: {
          query: cancelUserSubscription,
          variables: { data: params }
        }
      }),
      transformResponse: (response: { data: { cancelUserSubscription: CancelUserSubscription } }) => response.data.cancelUserSubscription
    }),
    updateTerms: builder.mutation({
      query: params => ({
        method: 'post',
        url: `/`,
        body: {
          query: updateTerms,
          variables: { data: params }
        }
      }),
      transformResponse: (response: { data: { updateUserTerms: UpdateTerms } }) => response.data.updateUserTerms
    }),
    getEnterpriseLink: builder.query({
      query: link => ({
        method: 'post',
        url: `/`,
        body: {
          query: getEnterpriseLink,
          variables: { link }
        }
      }),
      transformResponse: (response: { data: { getEnterpriseLink: any } }) => response.data.getEnterpriseLink
    }),
  })
})

export const {
  useGetUserSubscriptionDataQuery,
  useCancelUserSubscriptionMutation,
  useUpdateTermsMutation,
  useLazyGetEnterpriseLinkQuery
} = UsersApiSlice