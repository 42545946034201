import { Box, Button, Container } from "@mui/joy";
import FeedbackTable from "./table";
import { useGetFeedbackQuery } from "../../../redux/features/adminSlice";

type FeedbackProps = {};

const Feedback: React.FC<FeedbackProps> = () => {

  const { refetch, isLoading } = useGetFeedbackQuery({});
  // function to refetch
  const handleRefetch = () => refetch();
  return (
    <Box
      component="section"
      sx={theme => ({ paddingTop: 16, paddingBottom: 16, background: theme.vars.palette.navoy.bg, minHeight: "100vh", })}
    >
      <Container maxWidth="lg">
        <Box className="flex justify-between items-center mb-4">
          <h2 className="text-2xl font-bold mb-2">Users Feedback</h2>
          <Button loading={isLoading} className="bg-navoy-red" size="sm" onClick={handleRefetch}>
            Refetch
          </Button>
        </Box>
        <FeedbackTable />
      </Container>
    </Box>
  );
};

export default Feedback;
