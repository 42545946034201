
// create itinerary api slice

import { GetOverviewResponse, ItineraryActivity } from "../../utils/types";
import { addActivityByDayQuery, deleteActivityByDayRankQuery, getActivitiesByDayQuery, getAllActivities, getOverviewQuery, getOverviewStatusQuery, regenerateActivityByDayActivityQuery, regenerateItineraryQuery } from "../queries/itineraty.query";
import { apiSlice } from "./apiSlice";

export const itineraryApiSlice = apiSlice.injectEndpoints({

  endpoints: builder => ({
    getOverviewStatus: builder.query({
      query: params => ({
        method: 'post',
        url: `/`,
        body: {
          query: getOverviewStatusQuery,
          variables: { data: params }
        }
      }),
      transformResponse: (response: { data: { getOverviewStatus: any } }) => response.data.getOverviewStatus
    }),
    getOverview: builder.query({
      query: params => ({
        method: 'post',
        url: `/`,
        body: {
          query: getOverviewQuery,
          variables: { data: params }
        }
      }),
      transformResponse: (response: { data: { getOverview: GetOverviewResponse } }) => response.data.getOverview
    }),
    getActivitiesByDay: builder.query({
      query: params => ({
        method: 'post',
        url: `/`,
        body: {
          query: getActivitiesByDayQuery,
          variables: { data: params }
        }
      }),
      transformResponse: (response: { data: { getActivitiesByDay: any } }) => response.data.getActivitiesByDay
    }),
    deleteActivityByDayRank: builder.mutation({
      query: params => ({
        method: 'post',
        url: `/`,
        body: {
          query: deleteActivityByDayRankQuery,
          variables: { data: params }
        }
      }),
      transformResponse: (response: { data: { deleteActivityByDayRank: any } }) => response.data.deleteActivityByDayRank
    }),
    addActivityByDay: builder.mutation({
      query: data => ({
        method: 'post',
        url: `/`,
        body: {
          query: addActivityByDayQuery,
          variables: { data }
        }
      }),
      transformResponse: (response: { data: { addActivityByDay: any } }) => response.data.addActivityByDay
    }),
    regenerateActivityByDayActivity: builder.query({
      query: params => ({
        method: 'post',
        url: `/`,
        body: {
          query: regenerateActivityByDayActivityQuery,
          variables: { data: params }
        }
      }),
      transformResponse: (response: { data: { regenerateActivityByDayActivity: any } }) => response.data.regenerateActivityByDayActivity,
      transformErrorResponse: (response: { status: number, data: { error: any } }) => response.data.error,
    }),
    regenerateItinerary: builder.query({
      query: data => ({
        method: 'post',
        url: `/`,
        body: {
          query: regenerateItineraryQuery,
          variables: { data }
        }
      }),
      transformResponse: (response: { data: { regenerateItinerary: any } }) => response.data.regenerateItinerary
    }),
    getAllActivities: builder.query({
      query: data => ({
        method: 'post',
        url: `/`,
        body: {
          query: getAllActivities,
          variables: { data }
        }
      }),
      transformResponse: (response: { data: { getAllActivities: { status: number, data: ItineraryActivity[] } | undefined } }) => response.data.getAllActivities
    }),

  })
})

export const {
  useGetActivitiesByDayQuery,
  useLazyGetActivitiesByDayQuery,
  useGetOverviewQuery,
  useLazyGetOverviewQuery,
  useDeleteActivityByDayRankMutation,
  useLazyRegenerateActivityByDayActivityQuery,
  useAddActivityByDayMutation,
  useLazyRegenerateItineraryQuery,
  useGetAllActivitiesQuery,
  useLazyGetAllActivitiesQuery
} = itineraryApiSlice;