import { ItineraryActivity } from '../../../utils/types';

export interface ReportActivity {
  name: string;
  date: string;
  time: string;
  costUsd: number;
  description: string;
}

export function mapActivity(itineraryActivity: ItineraryActivity): ReportActivity {
  return {
    name: itineraryActivity.title,
    date: itineraryActivity.start_time.split('T')[0], // ISO format parsing
    time: itineraryActivity.start_time.split('T')[1], // ISO format parsing
    costUsd: itineraryActivity.budget,
    description: itineraryActivity.description,
  };
}
