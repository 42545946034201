import { PersistedHotelResponse } from "../../utils/types"
import { getHotelDetails, getHotels, getPersistedHotels, persistHotels } from "../queries/hotels.query"
import { apiSlice } from "./apiSlice"

export const hotelsApi = apiSlice.injectEndpoints({
  endpoints: builder => ({
    getHotels: builder.query({
      query: data => ({
        url: `/`,
        method: 'post',
        body: {
          query: getHotels,
          variables: { data }
        }
      }),
      transformResponse: (response: { data: { getHotels: any } }) => response.data.getHotels
    }),
    getHotelDetails: builder.query({
      query: ({ q, check_in_date, check_out_date, min_price, max_price, token }) => ({
        url: `/`,
        method: 'post',
        body: {
          query: getHotelDetails,
          variables: { data: { q, check_in_date, check_out_date, min_price, max_price, token } }
        }
      }),
      transformResponse: (response: { data: { getHotelDetails: any } }) => response.data.getHotelDetails
    }),
    persistHotels: builder.mutation({
      query: data => ({
        url: `/`,
        method: 'post',
        body: {
          query: persistHotels,
          variables: { data }
        }
      }),
      transformResponse: (response: { data: { persistHotels: any } }) => response.data.persistHotels
    }),
    getPersistedHotels: builder.query({
      query: data => ({
        url: `/`,
        method: 'post',
        body: {
          query: getPersistedHotels,
          variables: { data }
        }
      }),
      transformResponse: (response: { data: { getPersistedHotels: PersistedHotelResponse } }) => response.data.getPersistedHotels
    })
  }),
})

export const {
  useGetHotelsQuery,
  useLazyGetHotelDetailsQuery,
  usePersistHotelsMutation,
  useGetPersistedHotelsQuery
} = hotelsApi
