import {
  Button,
  Card,
  CardActions,
  CardContent,
  CardMedia,
} from '@mui/material';
import CustomPopover from '../../../../components/popover/CustomPopover';
import { MapActivity } from './types';

interface MapPopoverProps {
  anchorEl: HTMLElement | null;
  feature: MapActivity | null;
  handleClose: () => void;
  handleExplore: () => void;
  handleZoom: () => void;
}

const MapPopover = ({
  anchorEl,
  feature,
  handleClose,
  handleExplore,
  handleZoom,
}: MapPopoverProps) => {
  return (
    <CustomPopover
      anchorEl={anchorEl}
      anchorOrigin={{
        horizontal: 'center',
        vertical: 'bottom',
      }}
      handleClose={handleClose}
    >
      <Card sx={{ width: 300 }}>
        <CardMedia
          sx={{ height: 90 }}
          image={feature?.imageUrl || '/static/no-image.jpg'}
          title="green iguana"
        />
        <CardContent className="!px-4 !pt-3 !pb-2">
          <h5 className="mb-2 text-base font-semibold line-clamp-1">
            {feature?.title}
          </h5>
          <p className="text-sm text-gray-700 line-clamp-2">
            {feature?.description}
          </p>
        </CardContent>
        <CardActions className="flex justify-end">
          <Button
            size="small"
            onClick={handleExplore}
            disabled={!feature?.webUrl}
          >
            Explore
          </Button>
          <Button size="small" variant="contained" onClick={handleZoom}>
            Zoom
          </Button>
        </CardActions>
      </Card>
    </CustomPopover>
  );
};

export default MapPopover;
