import { lazy, Suspense } from 'react';
import { Helmet } from 'react-helmet';
import LazyLoad from 'react-lazyload';
import { Element } from 'react-scroll';
const Blog = lazy(() => import('./blog'));
const Loading = lazy(() => import("../../components/loading"));
const WelcomeSection = lazy(() => import("./welcome"));
const OurCoreValues = lazy(() => import("./ourServices/ourCoreValues"));
const PriceSection = lazy(() => import("./price"));
const FAQ = lazy(() => import("./faq"));
const GetInTouch = lazy(() => import("./getInTouch"));
const Newsletter = lazy(() => import("./newsletter"));
const Footer = lazy(() => import("../../components/footer"));

const Home = () => {

  const plan = localStorage.getItem('plan')
  if (plan) {
    localStorage.removeItem('plan')
    localStorage.removeItem('type')
  }

  return (
    <>
      <Helmet>
        <title>Navoy | Tailored Travel, Just for You</title>
        <meta name="description"
          content="Navoy: Your personalized travel companion. Seamlessly plan, book, and experience tailored journeys with our AI-powered platform. Elevate your travel endeavors today" />
        <meta name="keywords" content="travel, ai, navoy, assistant" />
        <meta name="author" content="Navoy" />
        <script type="application/ld+json">
          {`
            {
              "@context": "https://schema.org",
              "@type": "WebSite",
              "name": "Travel-AI",
              "alternateName" : ["Travel-AI","Navoy","Tailored Travel","Navigation","Voyage"],
              "url": "https://navoy.io",
              "publisher": {
                "@type": "Organization",
                "name": "Navoy",
                "logo": {
                  "@type": "ImageObject",
                  "url": "https://navoy.io/logo192.png"
                }
              }
            }
          `}
        </script>
        <script type="application/ld+json">
          {
          `{
            "@context": "https://schema.org",
            "@type": "Organization",
            "name": "Navoy",
            "url": "https://navoy.io",
            "logo": "https://navoy.io/logo512.png"
           }`
          }
        </script>

      </Helmet>
      <h1 style={{ display: 'none' }} >Navoy</h1>
      <LazyLoad offset={0} once >
        <Suspense fallback={<Loading style={{ minHeight: '100vh' }} />} >
          <Element name="welcome">
            <WelcomeSection />
          </Element>
        </Suspense>
      </LazyLoad>
      <LazyLoad offset={1000} once >
        <Suspense fallback={<Loading style={{ minHeight: '100vh' }} />} >
          <Element name="ourservice">
            <OurCoreValues />
          </Element>
        </Suspense>
      </LazyLoad>
      <LazyLoad offset={2000} once >
        <Suspense fallback={<Loading style={{ minHeight: '100vh' }} />} >
          <Element name="pricing">
            <PriceSection />
          </Element>
        </Suspense>
      </LazyLoad>
      {/* <LazyLoad offset={3000} once >
        <Suspense fallback={<Loading style={{ minHeight: '100vh' }} />} >
          <Element name="blog">
            <Blog />
          </Element>
        </Suspense>
      </LazyLoad> */}
      <LazyLoad offset={4000} once >
        <Suspense fallback={<Loading style={{ minHeight: '100vh' }} />} >
          <Element name="faq">
            <FAQ />
          </Element>
        </Suspense>
      </LazyLoad>
      <LazyLoad offset={5000} once >
        <Suspense fallback={<Loading style={{ minHeight: '100vh' }} />} >
          <Element name="getintouch">
            <GetInTouch />
          </Element>
        </Suspense>
      </LazyLoad>
      <LazyLoad offset={6000} once >
        <Suspense fallback={<Loading style={{ minHeight: '100vh' }} />} >
          <Newsletter />
        </Suspense>
      </LazyLoad>
      <LazyLoad offset={7000} once >
        <Suspense fallback={<Loading style={{ minHeight: '100vh' }} />} >
          <Footer />
        </Suspense>
      </LazyLoad>
    </>
  );
};

export default Home 