import { yupResolver } from "@hookform/resolvers/yup";
import LockIcon from "@mui/icons-material/Lock";
import MailIcon from "@mui/icons-material/Mail";
import PersonIcon from '@mui/icons-material/Person';
import VisibilityIcon from "@mui/icons-material/Visibility";
import VisibilityOffIcon from "@mui/icons-material/VisibilityOff";
import WorkIcon from '@mui/icons-material/Work';
import LanguageIcon from '@mui/icons-material/Language';
import { Box, Button, Checkbox, Chip, Divider, FormControl, FormHelperText, FormLabel, Grid, Input, Typography, } from "@mui/joy";
import { useEffect, useState } from "react";
import { Controller, useForm } from "react-hook-form";
import { Link, useNavigate, useSearchParams } from "react-router-dom";
import { logout, useRegisterMutation } from "../../redux/features/authSlice";
import { validationSchemaSignup } from "../../utils/formYupValidation";
import Logo from "./components/logo";
import SocialMedia from "./components/socialMedia";
import { toast } from "react-toastify";
import { useLazyGetEnterpriseLinkQuery } from "../../redux/features/users.api";
import { useAppDispatch } from "../../redux/hooks";

type SignupProps = {};

const Signup: React.FC<SignupProps> = () => {
  const { handleSubmit, control, formState: { errors }, setError, setValue } = useForm({
    resolver: yupResolver(validationSchemaSignup),
    defaultValues: {
      password: "",
      email: "",
      name: '',
      surName: '',
      agencyName: '',
      website: '',
      remember: false,
    },
  });
  const [terms, setTerms] = useState(false)
  const [messageTerms, setMessageTerms] = useState("")
  const dispatch = useAppDispatch()

  let [searchParams] = useSearchParams();
  const link = searchParams.get('link');
  const [getEnterpriseLink, { data: dataLink }] = useLazyGetEnterpriseLinkQuery()

  useEffect(() => {
    if (link) {
      dispatch(logout({}))
      getEnterpriseLink(link);
    }
  }, [link, getEnterpriseLink, dispatch]);

  useEffect(() => {
    if (dataLink?.responseObject?.email) {
      setValue('email', dataLink?.responseObject.email)
      setValue('agencyName', dataLink?.responseObject.agencyName)
    }
  }, [dataLink?.responseObject.agencyName, dataLink?.responseObject.email, setValue])

  const [visible, setVisible] = useState(true);
  const [register, { data, isLoading, error }] = useRegisterMutation()
  const navigate = useNavigate()
  const onSubmit = async (data: any) => {
    if (!isLoading) {
      try {
        if (terms) {
          setMessageTerms("")
          await register({ ...data, terms, link: dataLink?.responseObject.id || '' }).unwrap()
        } else {
          setMessageTerms("You must accept the terms and conditions")
        }
      } catch (error: any) {
        if (error?.data)
          setError('password', {
            type: "manual",
            message: error?.data?.message || '',
          })
      }
    }
  };

  useEffect(() => {
    if (data?.success) {
      toast.success(data.message)
      navigate('/auth/login')
    } else if (data?.message)
      setError('email', { type: "manual", message: data?.message })
  }, [data, navigate, setError])


  useEffect(() => {
    if (error) {

    }
  }, [error])


  return (
    <Box className="flex flex-col flex-1 justify-center items-center  gap-2 md:gap-10 px-6">
      <Logo />
      <Box className="min-w-[70%] flex flex-col gap-4 w-full md:w-auto">
        <form onSubmit={handleSubmit(onSubmit)} className="flex flex-col gap-3">
          <Grid container spacing={2} sx={{ flexGrow: 1 }}>
            <Grid md={6} xs={12}>
              <FormControl>
                <FormLabel
                  sx={(theme) => ({
                    "--FormLabel-color": theme.vars.palette.neutral[700],
                  })}
                >
                  Email <span className="text-navoy-red">*</span>
                </FormLabel>
                <Controller
                  name="email"
                  control={control}
                  rules={{ required: true }}
                  defaultValue=""
                  render={({ field }) => (
                    <Input
                      {...field}
                      sx={(theme) => ({
                        fontSize: " 0.75rem",
                        "--Input-minHeight": "3rem",
                        "&:focus-within:before": {
                          "--Input-focused": 0,
                        },
                        background: "#fff",
                      })}
                      placeholder="Enter your email"
                      type="email"
                      error={!!errors.email?.message}
                      size="sm"
                      startDecorator={
                        <MailIcon
                          sx={(theme) => ({ color: theme.palette.navoy.blue })}
                          fontSize="small"
                        />
                      }
                    />
                  )}
                />
                {!!errors.email?.message && (
                  <FormHelperText
                    sx={(theme) => ({
                      color: theme.vars.palette.danger[400],
                      fontSize: "xs",
                    })}
                  >
                    {errors.email?.message}
                  </FormHelperText>
                )}
              </FormControl>
            </Grid>
            <Grid md={6} xs={12}>
              <FormControl>
                <FormLabel
                  sx={(theme) => ({
                    "--FormLabel-color": theme.vars.palette.neutral[700],
                  })}
                >
                  Password <span className="text-navoy-red">*</span>
                </FormLabel>
                <Controller
                  name="password"
                  control={control}
                  rules={{ required: true }}
                  render={({ field }) => (
                    <Input
                      {...field}
                      sx={(theme) => ({
                        fontSize: " 0.75rem",
                        "--Input-minHeight": "3rem",
                        "&:focus-within:before": {
                          "--Input-focused": 0,
                        },
                        background: "#fff",
                      })}
                      placeholder="Password"
                      type={visible ? "password" : "text"}
                      error={!!errors.password?.message}
                      size="sm"
                      startDecorator={
                        <LockIcon
                          sx={(theme) => ({ color: theme.palette.navoy.blue })}
                          fontSize="small"
                        />
                      }
                      endDecorator={
                        !visible ? (
                          <VisibilityIcon
                            sx={(theme) => ({ color: "#999" })}
                            fontSize="small"
                            onClick={() => setVisible((prev) => !prev)}
                          />
                        ) : (
                          <VisibilityOffIcon
                            sx={(theme) => ({ color: "#999" })}
                            fontSize="small"
                            onClick={() => setVisible((prev) => !prev)}
                          />
                        )
                      }
                    />
                  )}
                />
                {!!errors.password?.message && (
                  <FormHelperText
                    sx={(theme) => ({
                      color: theme.vars.palette.danger[400],
                      fontSize: "xs",
                    })}
                  >
                    {errors.password?.message}
                  </FormHelperText>
                )}
              </FormControl>
            </Grid>
            <Grid md={6} xs={12}>
              <FormControl>
                <FormLabel
                  sx={(theme) => ({
                    "--FormLabel-color": theme.vars.palette.neutral[700],
                  })}
                >
                  Name <span className="text-navoy-red">*</span>
                </FormLabel>
                <Controller
                  name="name"
                  control={control}
                  rules={{ required: true }}
                  render={({ field }) => (
                    <Input
                      {...field}
                      sx={(theme) => ({
                        fontSize: " 0.75rem",
                        "--Input-minHeight": "3rem",
                        "&:focus-within:before": {
                          "--Input-focused": 0,
                        },
                      })}
                      placeholder="Name"
                      type={"text"}
                      error={!!errors.name?.message}
                      size="sm"
                      startDecorator={
                        <PersonIcon
                          sx={(theme) => ({ color: theme.palette.navoy.blue })}
                          fontSize="small"
                        />
                      }
                    />
                  )}
                />
                {!!errors.name?.message && (
                  <FormHelperText
                    sx={(theme) => ({
                      color: theme.vars.palette.danger[400],
                      fontSize: "xs",
                    })}
                  >
                    {errors.name?.message}
                  </FormHelperText>
                )}
              </FormControl>
            </Grid>
            <Grid md={6} xs={12}>
              <FormControl>
                <FormLabel
                  sx={(theme) => ({
                    "--FormLabel-color": theme.vars.palette.neutral[700],
                  })}
                >
                  Surname <span className="text-navoy-red">*</span>
                </FormLabel>
                <Controller
                  name="surName"
                  control={control}
                  rules={{ required: true }}
                  render={({ field }) => (
                    <Input
                      {...field}
                      sx={(theme) => ({
                        fontSize: " 0.75rem",
                        "--Input-minHeight": "3rem",
                        "&:focus-within:before": {
                          "--Input-focused": 0,
                        },
                      })}
                      placeholder="Surname"
                      type={"text"}
                      error={!!errors.surName?.message}
                      size="sm"
                      startDecorator={
                        <PersonIcon
                          sx={(theme) => ({ color: theme.palette.navoy.blue })}
                          fontSize="small"
                        />
                      }
                    />
                  )}
                />
                {!!errors.surName?.message && (
                  <FormHelperText
                    sx={(theme) => ({
                      color: theme.vars.palette.danger[400],
                      fontSize: "xs",
                    })}
                  >
                    {errors.surName?.message}
                  </FormHelperText>
                )}
              </FormControl>
            </Grid>
            <Grid md={6} xs={12}>
              <FormControl>
                <FormLabel
                  sx={(theme) => ({
                    "--FormLabel-color": theme.vars.palette.neutral[700],
                  })}
                >
                  Agency name
                </FormLabel>
                <Controller
                  name="agencyName"
                  control={control}
                  rules={{ required: true }}
                  render={({ field }) => (
                    <Input
                      {...field}
                      sx={(theme) => ({
                        fontSize: " 0.75rem",
                        "--Input-minHeight": "3rem",
                        "&:focus-within:before": {
                          "--Input-focused": 0,
                        },
                      })}
                      placeholder="Agency name"
                      type={"text"}
                      error={!!errors.agencyName?.message}
                      size="sm"
                      startDecorator={
                        <WorkIcon
                          sx={(theme) => ({ color: theme.palette.navoy.blue })}
                          fontSize="small"
                        />
                      }
                    />
                  )}
                />
                {!!errors.agencyName?.message && (
                  <FormHelperText
                    sx={(theme) => ({
                      color: theme.vars.palette.danger[400],
                      fontSize: "xs",
                    })}
                  >
                    {errors.agencyName?.message}
                  </FormHelperText>
                )}
              </FormControl>
            </Grid>
            <Grid md={6} xs={12}>
              <FormControl>
                <FormLabel
                  sx={(theme) => ({
                    "--FormLabel-color": theme.vars.palette.neutral[700],
                  })}
                >
                  Web site
                </FormLabel>
                <Controller
                  name="website"
                  control={control}
                  rules={{ required: true }}
                  render={({ field }) => (
                    <Input
                      {...field}
                      sx={(theme) => ({
                        fontSize: " 0.75rem",
                        "--Input-minHeight": "3rem",
                        "&:focus-within:before": {
                          "--Input-focused": 0,
                        },
                      })}
                      placeholder="Website"
                      type={"text"}
                      error={!!errors.website?.message}
                      size="sm"
                      startDecorator={
                        <LanguageIcon
                          sx={(theme) => ({ color: theme.palette.navoy.blue })}
                          fontSize="small"
                        />
                      }
                    />
                  )}
                />
                {!!errors.website?.message && (
                  <FormHelperText
                    sx={(theme) => ({
                      color: theme.vars.palette.danger[400],
                      fontSize: "xs",
                    })}
                  >
                    {errors.website?.message}
                  </FormHelperText>
                )}
              </FormControl>
            </Grid>

          </Grid>
          <Box className="flex justify-between">
            <Controller
              name="remember"
              control={control}
              rules={{ required: true }}
              render={({ field }) => (
                <Checkbox
                  size="sm"
                  onChange={field.onChange}
                  label="Remember me"
                  color="neutral"
                  sx={{ fontSize: "sm" }}
                />
              )}
            />
            <Link to={"/auth/forgot-password"} className="text-sm text-navoy-blue font-medium">
              Forgot Password!
            </Link>
          </Box>
          <Box className='flex items-center gap-2'>
            <Checkbox
              size="sm"
              onChange={(e) => setTerms(e.target.checked)}
              // label="Remember me"
              color="neutral"
              sx={{ fontSize: "sm" }}
            />
            <span className="text-sm  font-medium">I agree to </span>
            <Link to={"/terms"} className="text-sm text-navoy-blue font-medium underline">Navoy Terms</Link>
          </Box>
          {messageTerms && <Box>
            <FormHelperText
              sx={(theme) => ({
                color: theme.vars.palette.danger[400],
                fontSize: "xs",
              })}
            >
              {messageTerms}
            </FormHelperText>
          </Box>}
          <Box className="mt-4">
            <Button
              fullWidth
              sx={(theme) => ({
                background: theme.vars.palette.navoy.blue,
                "&:hover": { background: theme.vars.palette.navoy.blue },
                paddingY: 2,
              })}
              type="submit"
              loading={isLoading}
            >
              Signup
            </Button>
          </Box>
        </form>
        <Divider>
          <Chip variant="soft" color="neutral" size="sm">
            or
          </Chip>
        </Divider>
        <SocialMedia terms={terms} setMessage={setMessageTerms} />
        <Typography className="text-center  pt-[3px]" fontSize="sm">
          Already have an account?{" "}
          <Link to="/auth/login" className=" text-navoy-blue font-bold">
            Login
          </Link>
        </Typography>
      </Box>
    </Box>
  );
};

export default Signup;
