import { GenerateTripId, ITripFormResponse } from "../../utils/types";
import { budgetQuery, generateOptionsQuery, generateTripIdQuery, getModificationByTrip, getOptionsQuery, getSearchParametersQuery, getTripStatusQuery, regenerateOptionsQuery, triggerTripQuery, validateBudgetQuery, validatePromptQuery } from "../queries/trip.query"
import { apiSlice } from "./apiSlice"

export const tripApiSlice = apiSlice.injectEndpoints({

  endpoints: builder => ({
    generateTripId: builder.query({
      query: params => ({
        method: 'post',
        url: `/`,
        body: {
          query: generateTripIdQuery,
          variables: { data: params }
        }
      }),
      transformResponse: (response: { data: { generateTripId: GenerateTripId } }) => response.data.generateTripId
    }),
    triggerTrip: builder.query({
      query: params => ({
        method: 'post',
        url: `/`,
        body: {
          query: triggerTripQuery,
          variables: { data: params }
        }
      }),
      transformResponse: (response: { data: { triggerTrip: any } }) => response.data.triggerTrip
    }),
    getSearchParameters: builder.query({
      query: params => ({
        method: 'post',
        url: `/`,
        body: {
          query: getSearchParametersQuery,
          variables: { data: params }
        }
      }),
      transformResponse: (response: { data: { getSearchParameters: ITripFormResponse } }) => response.data.getSearchParameters
    }),
    getTripStatus: builder.query({
      query: params => ({
        method: 'post',
        url: `/`,
        body: {
          query: getTripStatusQuery,
          variables: { data: params }
        }
      }),
      transformResponse: (response: { data: { getTripStatus: any } }) => response.data.getTripStatus
    }),
    generateOptions: builder.query({
      query: params => ({
        method: 'post',
        url: `/`,
        body: {
          query: generateOptionsQuery,
          variables: { data: params }
        }
      }),
      transformResponse: (response: { data: { generateOptions: any } }) => response.data.generateOptions
    }),
    getOptions: builder.query({
      query: params => ({
        method: 'post',
        url: `/`,
        body: {
          query: getOptionsQuery,
          variables: { data: params }
        }
      }),
      transformResponse: (response: { data: { getOptions: any } }) => response.data.getOptions
    }),
    regenerateOptions: builder.query({
      query: params => ({
        method: 'post',
        url: `/`,
        body: {
          query: regenerateOptionsQuery,
          variables: { data: params }
        }
      }),
      transformResponse: (response: { data: { regenerateOptions: any } }) => response.data.regenerateOptions
    }),
    validatePrompt: builder.query({
      query: params => ({
        method: 'post',
        url: `/`,
        body: {
          query: validatePromptQuery,
          variables: { data: params }
        }
      }),
      transformResponse: (response: { data: { validatePrompt: any } }) => response.data.validatePrompt
    }),
    validateBudget: builder.query({
      query: params => ({
        method: 'post',
        url: `/`,
        body: {
          query: validateBudgetQuery,
          variables: { data: params }
        }
      }),
      transformResponse: (response: { data: { validateBudget: any } }) => response.data.validateBudget
    }),
    budget: builder.query({
      query: params => ({
        method: 'post',
        url: `/`,
        body: {
          query: budgetQuery,
          variables: { data: params }
        }
      }),
      transformResponse: (response: { data: { budget: any } }) => response.data.budget
    }),
    getModificationByTrip: builder.query({
      query: params => ({
        method: 'post',
        url: `/`,
        body: {
          query: getModificationByTrip,
          variables: { data: params }
        }
      }),
      transformResponse: (response: { data: { getModificationByTrip: any } }) => response.data.getModificationByTrip
    }),
  }),


})

export const {
  useGenerateTripIdQuery,
  useLazyGenerateOptionsQuery,
  useLazyTriggerTripQuery,
  useLazyRegenerateOptionsQuery,
  useGetSearchParametersQuery,
  useGetOptionsQuery,
  useGetModificationByTripQuery,
  useLazyGetModificationByTripQuery
} = tripApiSlice;