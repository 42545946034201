import { FormHelperText, FormLabel } from '@mui/material';
import FormControl from '@mui/material/FormControl';
import MenuItem from '@mui/material/MenuItem';
import Select from '@mui/material/Select';
import { SelectOption } from '../../../utils/types';

interface SelectInputProps {
  label: string;
  field: any;
  error: string;
  list: SelectOption[];
  disabled?: boolean;
  id?: string;
}

const SelectInput: React.FC<SelectInputProps> = ({ id, label, field, error, list, disabled, ...rest }) => {
  return (
    <FormControl error={!!error} fullWidth className="gap-2">
      <FormLabel htmlFor={id || label}>{label}</FormLabel>
      <Select
        {...field}
        id={id || label}
        {...rest}
        disabled={disabled}
      >
        {
          list?.map((item: SelectOption, key: number) => (
            <MenuItem key={key} value={item.value}>{item.label}</MenuItem>
          ))
        }
      </Select>
      {error && <FormHelperText>{error}</FormHelperText>}
    </FormControl>
  );
}

export default SelectInput;
