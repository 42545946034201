import ArrowDropDownIcon from '@mui/icons-material/ArrowDropDown';
import AutorenewIcon from '@mui/icons-material/Autorenew';
import DeleteOutlineOutlinedIcon from '@mui/icons-material/DeleteOutlineOutlined';
import { Box, Button, Card, CardContent, IconButton, Typography, useTheme } from "@mui/joy";
import { useMediaQuery } from '@mui/material';
import React, { useMemo, useState } from 'react';
import { useParams } from 'react-router-dom';
import { toast } from 'react-toastify';
import { ReactCarousel } from '../../../components/carousel';
import { AlertDialogModal } from '../../../components/modals';
import { useDeleteActivityByDayRankMutation, useLazyGetActivitiesByDayQuery, useLazyGetAllActivitiesQuery } from '../../../redux/features/itineraryApi';
import { useAppSelector } from '../../../redux/hooks';
import { ActivityOfDay } from '../../../utils/types';
import FormRegenerate from './formRegenerate';

type CardActivityProps = {
  activity: ActivityOfDay
  number: string
  rank: number
}

const CardActivity: React.FC<CardActivityProps> = ({ activity, number, rank }) => {
  const theme = useTheme()
  const matche = useMediaQuery(theme.breakpoints.up("laptop"))
  const params = useParams()
  const requestId = params.requestId
  const subscriptionId = params.subscriptionId;
  const user = useAppSelector((state) => state.auth.user)
  const [showRegenerate, setShowRegenerate] = useState(false)
  const [open, setOpen] = useState(false)
  const [showImages, setShowImages] = useState(false)
  const [deleteActivity, { isLoading }] = useDeleteActivityByDayRankMutation()
  const [getActivityByDay] = useLazyGetActivitiesByDayQuery()

  const [getAllActivities] = useLazyGetAllActivitiesQuery();

  const imagesList = useMemo(() => {
    return activity?.image_urls_large?.length > 0
      ? activity?.image_urls_original
      : activity?.image_urls_original?.length > 0
        ? activity?.image_urls_large
        : activity?.image_urls?.length > 0
          ? activity?.image_urls
          : []
  }, [activity.image_urls_original, activity.image_urls_large, activity.image_urls])

  const handleClickOpen = async (open: boolean, status: string) => {
    if (status === 'confirm' && user) {
      // confirm delete
      const deleted = await deleteActivity({ id: requestId, subscription_id: subscriptionId, user: { id: user.id }, number: (+number - 1).toString(), rank: rank.toString() }).unwrap()
      const { success } = deleted?.data ?? {};
      const isSuccess = success === true;

      if (deleted?.status === 401) {
        toast.warn(deleted?.message)
        return;
      }

      if (isSuccess) {
        await getActivityByDay({ id: requestId, subscription_id: subscriptionId, user: { id: user.id }, number: (+number - 1).toString() }).unwrap()
        setTimeout(() => {
          getAllActivities({ id: requestId, subscription_id: subscriptionId, user: { id: user?.id } })
        }, 500);
        toast.success("Your activity was deleted successfully");
      } else {
        toast.error("Failed to delete activity. Please try again later");
      }
    }
    setOpen(open)
  }

  return (
    <Card variant="outlined">
      <CardContent >
        <Box className='flex justify-between' >
          <Box className='flex flex-row flex-wrap items-center gap-2'>
            {/* <MuseumIcon className="!text-base md:!text-xl" /> */}
            <Typography level="h4" className="!text-sm sm:!text-base md:!text-xl">
              <a href={activity.web_url} target='_blank' rel="noreferrer">{activity.title}</a>
            </Typography>
            <Typography level="h4" className="!text-sm sm:!text-base md:!text-xl">{activity.scheduled_time}</Typography>
            <Typography level="h4" className="!text-sm sm:!text-base md:!text-xl">${activity.budget}</Typography>
            {/* <CreateIcon className="!text-base md:!text-xl" /> */}
          </Box>
          {
            matche ?
              <Button onClick={() => setOpen(true)} className="!text-base" color="neutral" variant="plain" endDecorator={<DeleteOutlineOutlinedIcon />}>Delete</Button> :
              <IconButton aria-label="delete" color="neutral" onClick={() => setOpen(true)}>
                <DeleteOutlineOutlinedIcon />
              </IconButton>
          }
        </Box>
        <Typography level="body-lg" className="!text-sm sm:!text-base md:!text-xl">
          {activity.description}
        </Typography>
        <Box className="mt-3 flex justify-between mb-2">
          {!showRegenerate &&
            <Button endDecorator={<AutorenewIcon />} onClick={() => setShowRegenerate(true)}>Regenerate</Button>
          }
          <Button variant='plain' endDecorator={<ArrowDropDownIcon fontSize='medium' style={{ transform: showImages ? 'rotate(180deg)' : 'rotate(0deg)', marginLeft: 'auto' }} />} onClick={() => setShowImages((prev: boolean) => !prev)}>Explore</Button>
        </Box>
        {showImages &&
          (imagesList?.length > 0 ?
            <ReactCarousel images={imagesList} /> :
            <Typography level="body-md" className="!text-sm sm:!text-base md:!text-xl">No images available</Typography>
          )}
        {showRegenerate && <FormRegenerate setShowRegenerate={setShowRegenerate} dayID={(+number - 1).toString()} activityID={rank} />}
      </CardContent>
      <AlertDialogModal isLoading={isLoading} open={open} setOpen={handleClickOpen} text={'Are you sure you want to delete this activity?'} />
    </Card>
  );
}

export default CardActivity;
