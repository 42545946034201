import GoogleIcon from "@mui/icons-material/Google";
import { Box, Button, Checkbox } from "@mui/joy";
import { TokenResponse, googleLogout, useGoogleLogin } from '@react-oauth/google';
import { useCallback, useEffect, useState } from "react";
import { Link, useNavigate, useSearchParams } from "react-router-dom";
import { toast } from "react-toastify";
import { login, useGoogleMutation, useTwitterMutation } from "../../../redux/features/authSlice";
import { useAppDispatch, useAppSelector } from "../../../redux/hooks";
import { stripBasicMonth, stripBasicYear, stripFreeTrial, stripPremiumMonth, stripPremiumYear, stripProMonth, stripProYear } from "../../../utils/env";
import { GoogleAuthResponse } from "../../../utils/types";
import { useLazyGetEnterpriseLinkQuery, useUpdateTermsMutation } from "../../../redux/features/users.api";

type SocialMediaProps = {
  terms?: boolean;
  setMessage?: (text: string) => void
}

const SocialMedia: React.FC<SocialMediaProps> = ({ terms, setMessage }) => {
  let [searchParams] = useSearchParams();
  const code = searchParams.get("code");
  const user = useAppSelector(state => state.auth.user)
  const [getTwitter, { data: twitter }] = useTwitterMutation();
  const [getGoogle, { data: googleData, isLoading: googleLoading }] = useGoogleMutation()
  const [updateTerms, { isLoading }] = useUpdateTermsMutation()
  const navigate = useNavigate()
  const dispatch = useAppDispatch()
  const [termsLogin, setTerms] = useState(false)
  const [showTerms, setShowTerms] = useState(false)
  const link = searchParams.get('link');

  const [getEnterpriseLink, { data: dataLink }] = useLazyGetEnterpriseLinkQuery()
  
  useEffect(() => {
    if (link) {
      getEnterpriseLink(link);
    }
  }, [link, getEnterpriseLink]);

  useEffect(() => {
    if (code) {
      // getTwitter(code);
    }
  }, [code, getTwitter])

  useEffect(() => {
    if (twitter) {
      console.log('twitter: ', twitter.responseObject);
      try {
        localStorage.setItem('user', JSON.stringify(twitter.responseObject) || '')
        navigate('/')
      } catch (error: any) {
        console.log('error: ', error);
      }
    }

  }, [navigate, twitter])

  const checkSubscription = useCallback(async () => {
    const handelNavigate = (user: GoogleAuthResponse) => {
      const plan = localStorage.getItem('plan')
      const type = localStorage.getItem('type')
      const duration = type === '0' ? '30' : '365'

      if (plan === 'Basic Plan') {
        localStorage.removeItem('plan')
        localStorage.removeItem('type')
        duration === "30" ? window.open(stripBasicMonth + `?client_reference_id=${user?.responseObject?.id}&prefilled_email=${user?.responseObject.email}`, '_self') : window.open(stripBasicYear + `?client_reference_id=${user?.responseObject?.id}&prefilled_email=${user?.responseObject.email}`, '_self')
      } else if (plan === 'Pro Plan') {
        localStorage.removeItem('plan')
        localStorage.removeItem('type')
        duration === "30" ? window.open(stripProMonth + `?client_reference_id=${user?.responseObject?.id}&prefilled_email=${user?.responseObject.email}`, '_self') : window.open(stripProYear + `?client_reference_id=${user?.responseObject?.id}&prefilled_email=${user?.responseObject.email}`, '_self')
      } else if (plan === 'Premium Plan') {
        localStorage.removeItem('plan')
        localStorage.removeItem('type')
        duration === "30" ? window.open(stripPremiumMonth + `?client_reference_id=${user?.responseObject?.id}&prefilled_email=${user?.responseObject.email}`, '_self') : window.open(stripPremiumYear + `?client_reference_id=${user?.responseObject?.id}&prefilled_email=${user?.responseObject.email}`, '_self')
      } else {
        localStorage.removeItem('plan')
        localStorage.removeItem('type')
        navigate('/dashboard');
      }
    }

    if (googleData?.responseObject?.id) {
      const plan = localStorage.getItem('plan')
      const daysRemaining = googleData?.responseObject?.subscription?.daysRemaining;
      const isActive = googleData?.responseObject?.subscription?.isActive;

      const handleLogin = (googleData: GoogleAuthResponse) => {
        dispatch(login({ user: googleData.responseObject }));
        localStorage.removeItem('plan');
        localStorage.removeItem('type');
      };

      if (!plan || plan === 'Free trial') {
        if (daysRemaining === null) {
          handleLogin(googleData);
          window.open(stripFreeTrial + `?client_reference_id=${googleData?.responseObject?.id}`, '_self');
        } else if (typeof daysRemaining === "number") {
          handleLogin(googleData);
          navigate('/dashboard');
        }
      } else {
        handelNavigate(googleData);
      }

      if (
        (plan === 'Free trial' && typeof daysRemaining === 'number' && daysRemaining >= 0) ||
        (isActive && daysRemaining && daysRemaining > 0)
      ) {
        if (plan === 'Free trial') {
          toast.warn(`You already have a ${googleData?.responseObject?.subscription?.currentSubscription.plan}, can't start a new free trial!`);
        }
        handleLogin(googleData);
        navigate('/dashboard');
      }
    }
    if (googleData?.responseObject?.error) {
      toast.warn(googleData?.responseObject?.message)
    }
  }, [dispatch, googleData, navigate])
  useEffect(() => {
    if (!user && googleData) {
      try {
        if (googleData?.responseObject.terms) {
          checkSubscription()
        } else {
          toast.warn('Please read and accept the terms')
          setShowTerms(true)
        }
      } catch (error: any) {
        console.log('error: ', error);
      }
    }
  }, [checkSubscription, googleData, user])


  const getGoogleProfile = async (codeResponse: TokenResponse) => {
    try {
      if (setMessage) {
        if (terms) {
          setMessage('')
          getGoogle({ access_token: codeResponse.access_token, link: dataLink?.responseObject.id || '' })
        } else {
          setMessage("You must accept the terms and conditions")
        }
      } else {
        getGoogle({ access_token: codeResponse.access_token, link: dataLink?.responseObject.id || '' })
      }
    } catch (error) {
      console.log('error: ', error);
    }
  }

  const signin = useGoogleLogin({
    onSuccess: (codeResponse) => {
      getGoogleProfile(codeResponse)
    },
    onError: (error) => {
      console.log('Login Failed:', error)
    }
  });

  // log out function to log the user out of google and set the profile array to null
  const logOut = () => {
    googleLogout();
  };

  const checkTerms = async (check: boolean) => {
    if (check) {
      setTerms(check)
      if (!user && googleData?.responseObject) {
        try {
          const result = await updateTerms({ id: googleData.responseObject?.id, terms: check }).unwrap()
          if (result.success) {
            if (result.responseObject.error) {
              toast.warn(result.responseObject.message)
            } else {
              checkSubscription()
            }
          } else {
            toast.error(result.responseObject.message)
          }
        } catch (error: any) {
          console.log('error: ', error);
        }
      }
    }
  }

  return (
    <Box className="flex justify-center items-center gap-3 flex-col">
      <Button
        className="  shadow flex gap-2"
        sx={(theme) => ({
          background: "#fff",
          color: theme.vars.palette.navoy.blue,
          "&:hover": { background: "white" },
          paddingY: 2,
          fontSize: 'lg'
        })}
        fullWidth
        onClick={() => signin()}
        loading={googleLoading || isLoading}
      >
        <GoogleIcon />
        Continue with google
      </Button>
      {showTerms && <Box className='flex items-center gap-2'>
        <Checkbox
          size="sm"
          onChange={(e) => checkTerms(e.target.checked)}
          // label="Remember me"
          color="neutral"
          sx={{ fontSize: "sm" }}
        />
        <span className="text-sm  font-medium">I agree to </span>
        <Link to={"/terms"} className="text-sm text-navoy-blue font-medium underline">Navoy Terms</Link>
      </Box>}
      {/* <Button
        className=" shadow-md "
        sx={(theme) => ({
          background: "#fff",
          color: theme.vars.palette.navoy.red,
          "&:hover": { background: "white" },
          paddingY: 2
        })}
        loading={isLoading}
      >
        <a className="a-button row-container" href={getTwitterOauthUrl()}>
          <XIcon />
        </a>
      </Button> */}
    </Box>
  );
}

export default SocialMedia;