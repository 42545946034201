import { getFeedback } from "../queries/feedback.query";
import { getSearch } from "../queries/search.query";
import { apiSlice } from "./apiSlice";

export const adminApiSlice = apiSlice.injectEndpoints({
  endpoints: (builder) => ({
    getFeedback: builder.query({
      query: () => ({
        url: `/`,
        method: 'post',
        body: {
          query: getFeedback,
        }
      }),
      transformResponse: (response: { data: { getFeedback: any } }) => response.data.getFeedback,
    }),
    getSearch: builder.query({
      query: () => ({
        url: `/`,
        method: 'post',
        body: {
          query: getSearch,
        }
      }),
      transformResponse: (response: { data: { search: any } }) => response.data.search,
    })
  }),
});

export const { useGetFeedbackQuery, useGetSearchQuery } = adminApiSlice;
