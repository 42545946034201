import { useMediaQuery, useTheme } from '@mui/material';

/**
 * Returns the height of the app bar
 *
 * @see https://github.com/mui/material-ui/issues/10739
 *
 * @returns {number} The height of the app bar
 */
export default function useAppBarHeight(): number {
  const {
    mixins: { toolbar },
    breakpoints,
  } = useTheme();

  const queryDesktop = breakpoints.up('sm');
  const queryLandscape = `${breakpoints.up('xs')} and (orientation: landscape)`;

  const isDesktop = useMediaQuery(queryDesktop);
  const isLandscape = useMediaQuery(queryLandscape);

  const cssToolbar =
    toolbar[isDesktop ? queryDesktop : isLandscape ? queryLandscape : ''];

  return ((cssToolbar ?? toolbar) as { minHeight: number })?.minHeight ?? 0;
}
