import { LinearProgress } from "@mui/joy";
import { CssVarsProvider as JoyCssVarsProvider, extendTheme } from "@mui/joy/styles";
import CssBaseline from "@mui/material/CssBaseline";
import {
  THEME_ID as MATERIAL_THEME_ID,
  Experimental_CssVarsProvider as MaterialCssVarsProvider,
  experimental_extendTheme as materialExtendTheme,
} from "@mui/material/styles";
import React, { Suspense, useEffect } from "react";
import { Provider } from "react-redux";
import { RouterProvider } from "react-router-dom";
import { ToastContainer } from 'react-toastify';
import "./App.css";
import store from "./redux/store";
import router from "./routers";

const materialTheme = materialExtendTheme({
  breakpoints: {
    values: {
      xs: 0,
      sm: 640,
      md: 768,
      lg: 1280,
      xl: 1536,
      mobile: 0,
      tablet: 640,
      laptop: 1024,
      desktop: 1200,
    },
  },
  colorSchemes: {
    light: {
      palette: {
        navoy: {
          blue: "#0c2d45",
          // blue: "#103D5A",
          red: "#DB3A34",
          beg: "#E5C581",
          bg: "#f3f4f6"
        },
        'navoy_blue': {
          '50': '#f1f9fe',
          '100': '#e2f1fc',
          '200': '#bee4f9',
          '300': '#85cdf4',
          '400': '#45b4eb',
          '500': '#1c9bdb',
          '600': '#0f7cba',
          '700': '#0d6397',
          '800': '#0f547d',
          '900': '#103d5a',
          '950': '#0c2d45',
        },
        'navoy_red': {
          '50': '#fef2f2',
          '100': '#fee3e2',
          '200': '#fdcdcb',
          '300': '#fba9a6',
          '400': '#f67873',
          '500': '#ec4d47',
          '600': '#db3a34',
          '700': '#b6241f',
          '800': '#97211d',
          '900': '#7d221f',
          '950': '#440d0b',
        },
        'navoy_beg': {
          '50': '#fcf9f0',
          '100': '#f8f1dc',
          '200': '#f0dfb8',
          '300': '#e5c581',
          '400': '#dcab5b',
          '500': '#d4943b',
          '600': '#c67c30',
          '700': '#a56229',
          '800': '#844f28',
          '900': '#6b4123',
          '950': '#392011',
        },
        'navoy_bg': {
          '50': '#f3f4f6',
          '100': '#edeef1',
          '200': '#d7dbe0',
          '300': '#b3bbc6',
          '400': '#8a96a6',
          '500': '#6c798b',
          '600': '#566173',
          '700': '#474f5d',
          '800': '#3d444f',
          '900': '#363b44',
          '950': '#24272d',
        }
      },
    },
  },
  components: {
    MuiInputBase: {
      styleOverrides: {
        root: {
          backgroundColor: 'white',
        },
      },
    },
    MuiCssBaseline: {
      styleOverrides: `
        @font-face {
          font-family: 'Poppins';
          font-style: normal;
          font-display: swap;
          font-weight: 500;
        }
      `,
    },
  },
  typography: {
    fontFamily: "Poppins",
  },
});

const theme = extendTheme({
  colorSchemes: {
    light: {
      palette: {
        navoy: {
          blue: "#0c2d45",
          // blue: "#103D5A",
          red: "#DB3A34",
          beg: "#E5C581",
          bg: "#f3f4f6",
        },
        'navoy_blue': {
          '50': '#f1f9fe',
          '100': '#e2f1fc',
          '200': '#bee4f9',
          '300': '#85cdf4',
          '400': '#45b4eb',
          '500': '#1c9bdb',
          '600': '#0f7cba',
          '700': '#0d6397',
          '800': '#0f547d',
          '900': '#103d5a',
          '950': '#0c2d45',
        },
        'navoy_red': {
          '50': '#fef2f2',
          '100': '#fee3e2',
          '200': '#fdcdcb',
          '300': '#fba9a6',
          '400': '#f67873',
          '500': '#ec4d47',
          '600': '#db3a34',
          '700': '#b6241f',
          '800': '#97211d',
          '900': '#7d221f',
          '950': '#440d0b',
        },
        'navoy_beg': {
          '50': '#fcf9f0',
          '100': '#f8f1dc',
          '200': '#f0dfb8',
          '300': '#e5c581',
          '400': '#dcab5b',
          '500': '#d4943b',
          '600': '#c67c30',
          '700': '#a56229',
          '800': '#844f28',
          '900': '#6b4123',
          '950': '#392011',
        },
        'navoy_bg': {
          '50': '#f3f4f6',
          '100': '#edeef1',
          '200': '#d7dbe0',
          '300': '#b3bbc6',
          '400': '#8a96a6',
          '500': '#6c798b',
          '600': '#566173',
          '700': '#474f5d',
          '800': '#3d444f',
          '900': '#363b44',
          '950': '#24272d',
        }
      },
    },
  },
  breakpoints: {
    values: {
      xs: 0,
      sm: 640,
      md: 768,
      lg: 1280,
      xl: 1536,
      mobile: 0,
      tablet: 640,
      laptop: 1024,
      desktop: 1200,
    },
  },
  components: {
    JoySkeleton: {
      defaultProps: {
        animation: 'wave',
      },
    },
    JoyInput: {
      styleOverrides: {
        root: {
          backgroundColor: 'white',
        }
      }
    },
    JoyTextarea: {
      styleOverrides: {
        root: {
          backgroundColor: 'white'
        }
      }
    }
  },
  fontFamily: {
    display: 'Poppins',
    body: 'Poppins'
  }
});



const App: React.FC = () => {
  useEffect(() => {
    window.onerror = function (message, source, lineno, colno, error) {
      // Log the error details or send them to a logging service
      console.error('Error message:', message);
      console.error('Source:', source);
      console.error('Line Number:', lineno);
      console.error('Column Number:', colno);
      console.error('Error Object:', error);

      // Return true to prevent the default browser error handling
      return true;
    };
  }, [])
  return (
    <Provider store={store}>
      <MaterialCssVarsProvider theme={{ [MATERIAL_THEME_ID]: materialTheme }}>
        <JoyCssVarsProvider theme={theme}>
          <CssBaseline enableColorScheme />
          <Suspense fallback={<LinearProgress />}>
            <RouterProvider router={router} />
          </Suspense>
          <ToastContainer />
          {/* <NetworkStatusIndicator /> */}
        </JoyCssVarsProvider>
      </MaterialCssVarsProvider>
    </Provider>
  );
};

export default App;
