import { Document, Page } from '@react-pdf/renderer';
import { createTw } from 'react-pdf-tailwind';
import { TemplateProps } from '../../types';
import { AccommodationDetails } from './components/AccommodationDetails';
import { Activities } from './components/Activities';
import FlightDetails from './components/FlightDetails';
import GeneralDetails from './components/GeneralDetails';
import Overview from './components/Overview';
import { TotalCosts } from './components/TotalCosts';

const tw = createTw({});

/**
 * First variant of the PDF document.
 *
 * TODO: Fix tailwind's `bold`, `semibold`, etc. classes. The key is likely
 * in the [font registration](https://react-pdf.org/fonts) of `react-pdf`
 */
export const Variant1 = (props: TemplateProps) => (
  <Document
    title={props.title}
    subject={props.subject}
    author={props.author}
    producer={window.location.origin}
    creator={window.location.origin}
    keywords="report, export, travel, itinerary, hotels, flights"
  >
    <Page size="A4" style={tw('flex flex-col p-4 gap-y-4')}>
      <GeneralDetails
        agentName={props.agentName}
        agentEmail={props.agentEmail}
        agentPhone={props.agentPhone}
        clientName={props.clientName}
        clientEmail={props.clientEmail}
        clientPhone={props.clientPhone}
      />
      <Overview title={props.title} description={props.description} primaryColor={props.primaryColor} />
      <FlightDetails data={props.flights} primaryColor={props.primaryColor} secondaryColor={props.secondaryColor} />
      <AccommodationDetails
        data={props.accommodations}
        primaryColor={props.primaryColor}
        secondaryColor={props.secondaryColor}
      />
      <Activities data={props.activities} primaryColor={props.primaryColor} secondaryColor={props.secondaryColor} />
      <TotalCosts
        flights={props.flights}
        accommodations={props.accommodations}
        activities={props.activities}
        primaryColor={props.primaryColor}
        secondaryColor={props.secondaryColor}
      />
    </Page>
  </Document>
);
