import { useMemo, useState } from 'react';
import { useParams } from 'react-router-dom';
import useDebouncedState from '../../../hooks/useDebouncedState';
import { useGetPersistedFlightsQuery } from '../../../redux/features/flights.api';
import { useGetPersistedHotelsQuery } from '../../../redux/features/hotels.api';
import { useGetAllActivitiesQuery, useGetOverviewQuery } from '../../../redux/features/itineraryApi';
import { useAppSelector } from '../../../redux/hooks';
import { DUMMY_DATA } from '../data/dummy';
import { TemplateKey } from '../templates';
import { ExportPageParams, ReportAccommodation, ReportActivity, ReportFlight } from '../types';
import { mapAccommodation } from '../types/accommodation.type';
import { mapActivity } from '../types/activity.type';
import { mapFlight } from '../types/flight.type';
import { ExportComponent } from './ExportComponent';

export const ExportContainer = () => {
  let { requestId, subscriptionId } = useParams<ExportPageParams>();
  if (!requestId || !subscriptionId) throw new Error('Missing requestId or subscriptionId');

  const user = useAppSelector((state) => state.auth.user);
  if (!user) throw new Error('There is no user');

  // =============================== State ===============================
  const [refreshKey, setRefreshKey] = useState(0);
  const [useDummyData, setUseDummyData] = useState(false);
  const [primaryColor, setPrimaryColor, debouncedPrimaryColor] = useDebouncedState('#ffc952', 500);
  const [secondaryColor, setSecondaryColor, debouncedSecondaryColor] = useDebouncedState('#DCDCDC', 500);
  const [template, setTemplate, debouncedTemplate] = useDebouncedState<TemplateKey>('Variant1', 500);
  const [agentName, setAgentName, debouncedAgentName] = useDebouncedState(`${user.name} ${user.surName}` || '', 500);
  const [agentEmail, setAgentEmail, debouncedAgentEmail] = useDebouncedState(user.email || '', 500);
  const [agentPhone, setAgentPhone, debouncedAgentPhone] = useDebouncedState('+1234567890', 500);
  const [clientName, setClientName, debouncedClientName] = useDebouncedState('Client Name', 500);
  const [clientEmail, setClientEmail, debouncedClientEmail] = useDebouncedState('client@example.com', 500);
  const [clientPhone, setClientPhone, debouncedClientPhone] = useDebouncedState('+0987654321', 500);

  const [displayPrimaryColorPicker, setDisplayPrimaryColorPicker] = useState(false);
  const [displaySecondaryColorPicker, setDisplaySecondaryColorPicker] = useState(false);

  // =============================== API Calls ===============================
  // Includes the title and description of the trip
  const { data: rawOverview, isLoading: isOverviewLoading } = useGetOverviewQuery({
    id: requestId,
    subscription_id: subscriptionId,
    user: { id: user.id },
  });

  const { data: rawFlights, isLoading: isFlightsLoading } = useGetPersistedFlightsQuery({
    id: requestId,
    subscription_id: subscriptionId,
    user: { id: user.id },
  });

  const { data: rawActivities, isLoading: isActivitiesLoading } = useGetAllActivitiesQuery({
    id: requestId,
    subscription_id: subscriptionId,
    user: { id: user.id },
  });

  const { data: rawAccommodations, isLoading: isAccommodationsLoading } = useGetPersistedHotelsQuery({
    id: requestId,
    subscription_id: subscriptionId,
    user: { id: user.id },
  });

  // =============================== Memoized ===============================
  const flights: ReportFlight[] = useMemo(() => {
    if (!rawFlights || !rawFlights.data) return [];
    return rawFlights.data.map((flight) => mapFlight(flight));
  }, [rawFlights]);

  const accommodations: ReportAccommodation[] = useMemo(() => {
    if (!rawAccommodations || !rawAccommodations.data) return [];
    return rawAccommodations.data.map((accommodation) => mapAccommodation(accommodation));
  }, [rawAccommodations]);

  const activities: ReportActivity[] = useMemo(() => {
    if (!rawActivities || !rawActivities.data) return [];
    return rawActivities.data.map((activity) => mapActivity(activity));
  }, [rawActivities]);

  const isLoading = useMemo(
    () => isOverviewLoading || isActivitiesLoading || isFlightsLoading || isAccommodationsLoading,
    [isOverviewLoading, isActivitiesLoading, isFlightsLoading, isAccommodationsLoading]
  );

  // =============================== Handlers ===============================
  const handlePrimaryColorClick = () => {
    setDisplayPrimaryColorPicker(!displayPrimaryColorPicker);
  };
  const handleSecondaryColorClick = () => {
    setDisplaySecondaryColorPicker(!displaySecondaryColorPicker);
  };
  const handlePrimaryColorClose = () => {
    setDisplayPrimaryColorPicker(false);
  };
  const handleSecondaryColorClose = () => {
    setDisplaySecondaryColorPicker(false);
  };
  const handleRefresh = () => {
    setRefreshKey((prevKey) => prevKey + 1);
  };
  const handleToggleDummyData = () => {
    setUseDummyData((prev) => !prev);
  };

  // =============================== Render ===============================
  return (
    <ExportComponent
      requestId={requestId}
      subscriptionId={subscriptionId}
      refreshKey={refreshKey}
      isLoading={isLoading}
      useDummyData={useDummyData}
      title={useDummyData ? DUMMY_DATA.title : rawOverview?.data.title || ''}
      description={useDummyData ? DUMMY_DATA.description : rawOverview?.data.description || ''}
      subject={useDummyData ? DUMMY_DATA.title : rawOverview?.data.title || ''}
      flights={useDummyData ? DUMMY_DATA.flights : flights}
      accommodations={useDummyData ? DUMMY_DATA.accommodations : accommodations}
      activities={useDummyData ? DUMMY_DATA.activities : activities}
      primaryColor={{ value: primaryColor, debouncedValue: debouncedPrimaryColor, setValue: setPrimaryColor }}
      secondaryColor={{ value: secondaryColor, debouncedValue: debouncedSecondaryColor, setValue: setSecondaryColor }}
      template={{ value: template, debouncedValue: debouncedTemplate, setValue: setTemplate }}
      agentName={{ value: agentName, debouncedValue: debouncedAgentName, setValue: setAgentName }}
      agentEmail={{ value: agentEmail, debouncedValue: debouncedAgentEmail, setValue: setAgentEmail }}
      agentPhone={{ value: agentPhone, debouncedValue: debouncedAgentPhone, setValue: setAgentPhone }}
      clientName={{ value: clientName, debouncedValue: debouncedClientName, setValue: setClientName }}
      clientEmail={{ value: clientEmail, debouncedValue: debouncedClientEmail, setValue: setClientEmail }}
      clientPhone={{ value: clientPhone, debouncedValue: debouncedClientPhone, setValue: setClientPhone }}
      displayPrimaryColorPicker={displayPrimaryColorPicker}
      displaySecondaryColorPicker={displaySecondaryColorPicker}
      handleRefresh={handleRefresh}
      setDisplayPrimaryColorPicker={setDisplayPrimaryColorPicker}
      setDisplaySecondaryColorPicker={setDisplaySecondaryColorPicker}
      handlePrimaryColorClick={handlePrimaryColorClick}
      handleSecondaryColorClick={handleSecondaryColorClick}
      handlePrimaryColorClose={handlePrimaryColorClose}
      handleSecondaryColorClose={handleSecondaryColorClose}
      handleToggleDummyData={handleToggleDummyData}
    />
  );
};
