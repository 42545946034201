export const addRequestDemo = `
mutation AddRequestDemo($data: RequestDemoInput) {
  addRequestDemo(data: $data) {
    success
    message
    responseObject {
      success
      message
    }
    statusCode
  }
}
`