import { yupResolver } from '@hookform/resolvers/yup';
import { Button, LinearProgress, Stack } from "@mui/joy";
import { Controller, useForm } from 'react-hook-form';
import { useParams } from "react-router-dom";
import SearchGoogleMap from "../../../components/inputs/searchGoogleMap";
import { useGetFlightQuery, useLazyGetFlightsQuery } from "../../../redux/features/flights.api";
import { validationSchemaSource } from "../../../utils/formYupValidation";
import Body from "./body";
import { useAppSelector } from '../../../redux/hooks';
type SearchFlights = { source: string }
type FlightProps = {}

const Flight: React.FC<FlightProps> = () => {
  const params = useParams();
  const user = useAppSelector((state) => state.auth.user);
  const requestId = params.requestId;
  const subscriptionId = params.subscriptionId;
  const { data: dataFlights, isLoading: isLoadingFlights } = useGetFlightQuery({ id: requestId, subscription_id: subscriptionId, user: { id: user?.id } })
  const [searchFlights, { data: flightsData, isLoading: flightsIsLoading }] = useLazyGetFlightsQuery();
  const { handleSubmit, control, formState: { errors }, } = useForm<any>({
    resolver: yupResolver(validationSchemaSource),
    defaultValues: {
      source: '',
    },
  });

  const onSubmit = (data: SearchFlights) => {
    sessionStorage.setItem('source', data.source)
    searchFlights({ id: requestId, subscription_id: subscriptionId, user: { id: user?.id }, source: data.source })
  };

  return (
    <Stack spacing={4} >
      <form className="flex flex-col w-full gap-4" onSubmit={handleSubmit(onSubmit)}>
        <Controller
          name="source"
          control={control}
          rules={{ required: true }}
          render={({ field }) => (
            <SearchGoogleMap
              id="origin"
              label="Enter the city you're flying from"
              placeholder="Enter origin city"
              field={field}
              error={errors?.target?.message?.toString() || ''}
            />
          )}
        />
        <Button type="submit" loading={flightsIsLoading}>Submit</Button>
      </form>
      {isLoadingFlights && <LinearProgress />}
      <Body flightsData={flightsData || dataFlights} />
    </Stack>
  );
}


export default Flight;