import SvgIcon from '@mui/material/SvgIcon';
import { Circle, Path, Svg } from '@react-pdf/renderer';
import { Style } from '@react-pdf/types';
import React, { useMemo } from 'react';
import ReactDOMServer from 'react-dom/server';
import { createTw } from 'react-pdf-tailwind';

const tw = createTw({});

interface MaterialIconProps {
  icon: typeof SvgIcon;
  style?: Style;
  className?: string;
}

/**
 * MaterialIcon component that renders an SVG icon from the Material UI library
 * in a format that can be used with @react-pdf/renderer.
 *
 * @example
 * ```tsx
 * import WifiIcon from '@mui/icons-material/Wifi';
 * import { MaterialIcon } from '../shared/MaterialIcon';
 *
 * <MaterialIcon icon={WifiIcon} className="w-4 h-4 mr-1" style={{ color: 'red' }} />
 * ```
 */
export const MaterialIcon = ({ icon: Icon, style = {}, className = '' }: MaterialIconProps) => {
  const { paths } = useMemo(() => {
    // Render the icon to a string
    const iconString = ReactDOMServer.renderToString(<Icon />);

    // Create a temporary DOM element to parse the string
    const tempDiv = document.createElement('div');
    tempDiv.className = className;
    Object.assign(tempDiv.style, style);
    document.body.appendChild(tempDiv);

    const computedStyle = getComputedStyle(tempDiv);
    const computedColor = computedStyle.color;

    const parser = new DOMParser();
    const doc = parser.parseFromString(iconString, 'image/svg+xml');
    const svgElement = doc.querySelector('svg');

    let svgPaths: React.ReactNode[] = [];
    if (svgElement) {
      svgPaths = [
        ...Array.from(svgElement.querySelectorAll('path')).map((path, index) => (
          <Path key={index} d={path.getAttribute('d') || ''} fill={computedColor} />
        )),
        ...Array.from(svgElement.querySelectorAll('circle')).map((circle, index) => (
          <Circle
            key={index}
            cx={circle.getAttribute('cx') || ''}
            cy={circle.getAttribute('cy') || ''}
            r={circle.getAttribute('r') || ''}
            fill={computedColor}
          />
        )),
      ];
    }

    // Clean up the temporary DOM element
    document.body.removeChild(tempDiv);

    return { paths: svgPaths };
  }, [Icon, className, style]);

  return (
    <Svg viewBox="0 0 24 24" style={[tw(className), style]}>
      {/* fill='currentColor' does not work for the Svg from @react-pdf/renderer */}
      {/* hence why the colors are also computed at the memo function */}
      {paths}
    </Svg>
  );
};
