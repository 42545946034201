import { addMinutes, parse, setHours, setMinutes } from 'date-fns';
import DatePicker from 'react-datepicker';
import 'react-datepicker/dist/react-datepicker.css';
import { ExcludedTime } from '../../../utils/types';
import DateRangeStyle from '../dateRange/dateRange.styles';


interface TimeSelectorProps {
  excludedTimes: ExcludedTime[];
  field: any
  startTime?: string
}
const TimeSelector = ({ excludedTimes, field, startTime }: TimeSelectorProps) => {

  // Convert excluded times to Date objects
  const excludedDateTimes = excludedTimes.flatMap(({ time }) => {
    const [start, end] = time.includes(' - ') ? time.split(' - ') : time.split(' to ');
    if (!start) return []

    return [
      parse(start, 'hh:mm aa', new Date()),
      end ? parse(end, 'hh:mm aa', new Date()) : parse(start, 'hh:mm aa', new Date())
    ]
  });

  // Filter out times that fall within excluded ranges
  const filterTime = (time: Date) => {
    // 
    return !excludedDateTimes.some((excludedTime, index) => {
      if (index % 2 === 0) {
        const endTime = excludedDateTimes[index + 1];
        return time >= excludedTime && time < endTime;
      }
      return false;
    });
  };
  // return null
  return (
    <DateRangeStyle>
      <DatePicker
        placeholderText="hh:mm aa"
        className="MuiInput-input"
        selected={field.value}
        onChange={(time) => field.onChange(time)}
        showTimeSelect
        showTimeSelectOnly
        timeIntervals={15}
        timeCaption="Time"
        dateFormat="h:mm aa"
        filterTime={filterTime}
        minTime={startTime ? addMinutes(new Date(startTime), 15) : setHours(setMinutes(new Date(), 0), 0)}
        maxTime={setHours(setMinutes(new Date(), 45), 23)}
      />
    </DateRangeStyle>
  );
};

export default TimeSelector;
