import { Flight } from '../../../utils/types';

export interface ReportFlight {
  name: string;
  time: string;
  duration: string;
  stops: string;
  type: string;
  costUsd: number;
  departureTime: string;
  departureAirport: string;
  arrivalTime: string;
  arrivalAirport: string;
  airline: string;
  amenities: string[];
}

export function mapFlight(flight: Flight): ReportFlight {
  const firstSegment = flight.flights[0];
  const lastSegment = flight.flights[flight.flights.length - 1];

  return {
    name: flight.airline,
    time: firstSegment.departure_airport.time,
    duration: `${flight.total_duration} minutes`,
    stops: `${flight.flights.length - 1} stops`,
    type: flight.type,
    costUsd: flight.price || 0,
    departureTime: firstSegment.departure_airport.time,
    departureAirport: firstSegment.departure_airport.name,
    arrivalTime: lastSegment.arrival_airport.time,
    arrivalAirport: lastSegment.arrival_airport.name,
    airline: flight.airline,
    amenities: flight.flights.flatMap((segment) => segment.extensions),
  };
}
