import { Alert, Box, Button, Card, CardContent, Grid, Typography } from '@mui/joy';
import { Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle, Paper } from '@mui/material';
import { useState } from 'react';
import { toast } from 'react-toastify';
import { useCancelBookingMutation, useGetHotelDetailsNuiteeQuery } from "../../../../../redux/features/nuiteeHotel.api";
import { BookingsDetails } from "../../../../../utils/types";

type CardBookProps = {
  booking: BookingsDetails;
  refetch: () => void;
}

const CardBook: React.FC<CardBookProps> = ({ booking, refetch }) => {
  const { data: hotel } = useGetHotelDetailsNuiteeQuery(booking.hotel.hotelId);
  const [cancelBooking, { isLoading: isCancelling }] = useCancelBookingMutation();
  const [openDialog, setOpenDialog] = useState(false);

  const formatDate = (dateString: string) => {
    return new Date(dateString).toLocaleDateString('en-US', {
      weekday: 'long',
      year: 'numeric',
      month: 'long',
      day: 'numeric'
    });
  };

  const hotelDetails = hotel?.responseObject?.data;

  const handleCancelBooking = async () => {
    const response = await cancelBooking(booking.bookingId).unwrap();
    console.log(response);
    if (response.responseObject.data.status === 'CANCELLED') {
      toast.success('Booking cancelled successfully');
      refetch();
    } else {
      toast.error('Failed to cancel booking');
    }
    setOpenDialog(false);
  };

  return (
    <Grid xs={12} key={booking.bookingId}>
      <Paper elevation={3} sx={{ p: 4, borderRadius: 2 }}>
        <Box sx={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center', mb: 3 }}>
          <Typography level="h3">{hotelDetails?.name || 'Hotel Name Not Available'}</Typography>
          <Typography
            level="body-lg"
            sx={{
              px: 2,
              py: 1,
              borderRadius: 'sm',
              bgcolor: booking.status === 'CONFIRMED' ? 'success.softBg' : 'warning.softBg',
              color: booking.status === 'CONFIRMED' ? 'success.solidBg' : 'warning.solidBg'
            }}
          >
            {booking.status}
          </Typography>
        </Box>

        <Grid container spacing={4}>
          {/* Hotel Information */}
          <Grid xs={12} md={6}>
            <Card>
              <CardContent>
                <Typography level="body-lg" sx={{ mb: 2 }}>Hotel Details</Typography>
                <Box sx={{ display: 'flex', flexDirection: 'column', gap: 1.5 }}>
                  <Typography level="body-lg">
                    <strong>Hotel:</strong> {hotelDetails?.name}
                  </Typography>
                  <Typography level="body-lg">
                    <strong>Address:</strong> {hotelDetails?.address}
                  </Typography>
                  <Typography level="body-lg">
                    <strong>Check-in:</strong> {formatDate(booking.checkin)}
                  </Typography>
                  <Typography level="body-lg">
                    <strong>Check-out:</strong> {formatDate(booking.checkout)}
                  </Typography>
                  <Typography level="body-lg">
                    <strong>Confirmation Code:</strong> {booking.hotelConfirmationCode}
                  </Typography>
                </Box>
              </CardContent>
            </Card>
          </Grid>

          {/* Guest Information */}
          <Grid xs={12} md={6}>
            <Card>
              <CardContent>
                <Typography level="body-lg" sx={{ mb: 2 }}>Guest Information</Typography>
                <Box sx={{ display: 'flex', flexDirection: 'column', gap: 1.5 }}>
                  <Typography level="body-lg">
                    <strong>Guest Name:</strong> {booking.holder.firstName} {booking.holder.lastName}
                  </Typography>
                  <Typography level="body-lg">
                    <strong>Email:</strong> {booking.holder.email}
                  </Typography>
                  <Typography level="body-lg">
                    <strong>Phone:</strong> {booking.holder.phone || 'Not provided'}
                  </Typography>
                  <Typography level="body-lg">
                    <strong>Booking Date:</strong> {formatDate(booking.createdAt)}
                  </Typography>
                </Box>
              </CardContent>
            </Card>
          </Grid>

          {/* Room Details */}
          <Grid xs={12} md={6}>
            <Card>
              <CardContent>
                <Typography level="body-lg" sx={{ mb: 2 }}>Room Details</Typography>
                <Box sx={{ display: 'flex', flexDirection: 'column', gap: 1.5 }}>
                  <Typography level="body-lg">
                    <strong>Room Type:</strong> {booking.bookedRooms[0]?.roomType?.name}
                  </Typography>
                  <Typography level="body-lg">
                    <strong>Adults:</strong> {booking.bookedRooms[0]?.adults}
                  </Typography>
                  <Typography level="body-lg">
                    <strong>Children:</strong> {booking.bookedRooms[0]?.children}
                  </Typography>
                </Box>
              </CardContent>
            </Card>
          </Grid>

          {/* Payment Information */}
          <Grid xs={12} md={6}>
            <Card>
              <CardContent>
                <Typography level="body-lg" sx={{ mb: 2 }}>Payment Details</Typography>
                <Box sx={{ display: 'flex', flexDirection: 'column', gap: 1.5 }}>
                  <Typography level="body-lg">
                    <strong>Total Amount:</strong> {booking.currency} {booking.price}
                  </Typography>
                  <Typography level="body-lg">
                    <strong>Booking ID:</strong> {booking.bookingId}
                  </Typography>
                  <Typography level="body-lg">
                    <strong>Supplier Reference:</strong> {booking.supplierBookingId}
                  </Typography>
                </Box>
              </CardContent>
            </Card>
          </Grid>

          {/* Cancellation Policy */}
          <Grid xs={12}>
            <Card>
              <CardContent>
                <Typography level="body-lg" sx={{ mb: 2 }}>Cancellation Policy</Typography>
                {booking.cancellationPolicies?.refundableTag === 'NRFN' ? (
                  <Alert color="warning" sx={{ mb: 2 }}>
                    This booking is non-refundable
                  </Alert>
                ) : (
                  <Box sx={{ display: 'flex', flexDirection: 'column', gap: 2 }}>
                    {booking.cancellationPolicies?.cancelPolicyInfos?.map((policy, index) => (
                      <Box key={index}>
                        <Typography level="body-lg">
                          <strong>{policy.type === 'FREE_CANCELLATION' ? 'Free cancellation until:' : 'Cancellation fee after:'}</strong>
                          {' '}{formatDate(policy.cancelTime)}
                        </Typography>
                        {policy.amount > 0 && (
                          <Typography level="body-lg">
                            Fee: ${policy.amount}
                          </Typography>
                        )}
                      </Box>
                    ))}
                  </Box>
                )}
                {booking.cancellationPolicies?.hotelRemarks?.map((remark, index) => (
                  <Typography key={index} level="body-sm" sx={{ mt: 1, color: 'neutral.500' }}>
                    {remark}
                  </Typography>
                ))}
              </CardContent>
            </Card>
          </Grid>
        </Grid>

        {booking.status === 'CONFIRMED' && <Box sx={{ mt: 3, display: 'flex', justifyContent: 'flex-end' }}>
          <Button
            onClick={() => setOpenDialog(true)}
            sx={{ textTransform: 'none' }}
            color='danger'
            variant="outlined"
            loading={isCancelling}
          >
            Cancel Booking
          </Button>
        </Box>}
      </Paper>

      <Dialog
        open={openDialog}
        onClose={() => setOpenDialog(false)}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogTitle id="alert-dialog-title">
          {"Cancel Booking Confirmation"}
        </DialogTitle>
        <DialogContent>
          <DialogContentText id="alert-dialog-description">
            Are you sure you want to cancel this booking?
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={() => setOpenDialog(false)} variant='outlined' color="neutral" disabled={isCancelling}>
            No
          </Button>
          <Button onClick={handleCancelBooking}  variant='outlined' color="danger" loading={isCancelling}>
            Yes
          </Button>
        </DialogActions>
      </Dialog>
    </Grid>
  );
}

export default CardBook;