import { lazy } from "react";
import { createBrowserRouter } from "react-router-dom";
import RequestDemoContainer from "../containers/requestDemo";
import EnterpriseForm from "../pages/enterpriseForm";


// lazy loading
const AdminContent = lazy(() => import('../containers/admin'))
const Auth = lazy(() => import('../containers/auth'))
const Dashboard = lazy(() => import('../containers/dashboard'))
const ErrorContainer = lazy(() => import('../containers/error'))
const HomeContainer = lazy(() => import('../containers/home'))
const Feedback = lazy(() => import('../pages/admin/feedback/feedback'))
const Search = lazy(() => import('../pages/admin/search/search'))
const Login = lazy(() => import('../pages/auth/login'))
const ForgotPassword = lazy(() => import('../pages/auth/forgotPassword'))
const ResetPassword = lazy(() => import('../pages/auth/resetPassword'))
const Signup = lazy(() => import('../pages/auth/signup'))
const Book = lazy(() => import('../pages/book'))
const Bookmarks = lazy(() => import('../pages/bookmarks'))
const DashboardPage = lazy(() => import('../pages/dashboard'))
const Error404 = lazy(() => import('../pages/error/404'))
const FormSearch = lazy(() => import('../pages/form'))
const Home = lazy(() => import('../pages/home'))
const Profile = lazy(() => import('../pages/profile'))
const TravelTabs = lazy(() => import('../pages/travelTabs'))
const AuthRoute = lazy(() => import('./authRoute'))
const PrivetRoute = lazy(() => import('./privetRoute'))
const PaymentContainer = lazy(() => import("../containers/payment"));
const PaymentPage = lazy(() => import("../pages/payment"));
const DashboardRoute = lazy(() => import("./dashboardRouter"));
const PaymentSuccess = lazy(() => import("../pages/paymentSuccess"));
const RequestDemo = lazy(() => import("../pages/requestDemo"));
const TermsPage = lazy(() => import("../pages/terms"));

const router = createBrowserRouter([
  {
    path: "/",
    element: <HomeContainer />,
    children: [
      {
        path: "",
        element: <Home />,
      },
      
    ],
    errorElement: <ErrorContainer />
  },
  {
    path: "/",
    element: <RequestDemoContainer />,
    children: [
      {
        path: "/requestDemo",
        element: <RequestDemo />
      },
      {
        path: "/enterprisePlan",
        element: <EnterpriseForm />
      },
      {
        path: "/terms",
        element: <TermsPage />
      }
    ]
  },
  {
    path: "/404",
    element: <Error404 />,
  },
  {
    path: '/dashboard',
    element: <PrivetRoute><Dashboard /></PrivetRoute>,
    errorElement: <ErrorContainer />,
    children: [
      {
        path: "/dashboard",
        element: <DashboardPage />
      },
      {
        path: "/dashboard/profile",
        element: <Profile />
      },
      {
        path: "/dashboard/form",
        element: <FormSearch />
      },
      {
        path: "/dashboard/bookmarks",
        element: <Bookmarks />,
      },
      {
        path: "/dashboard/book/:requestId",
        element: <Book />
      },
      {
        path: "/dashboard/travel/:requestId/:subscriptionId",
        element: <TravelTabs />
      }
    ]
  },
  {
    path: "/auth",
    element: <Auth />,
    children: [
      {
        path: "/auth/login",
        element: <Login />,
      },
      {
        path: "/auth/signup",
        element: <AuthRoute><Signup /></AuthRoute>,
      },
      {
        path: "/auth/forgot-password",
        element: <AuthRoute><ForgotPassword /></AuthRoute>,
      },
      {
        path: "/auth/reset-password/:token",
        element: <AuthRoute><ResetPassword /></AuthRoute>,
      },
    ],
    errorElement: <ErrorContainer />
  },
  {
    path: "/admin",
    element: <AdminContent />,
    children: [
      {
        path: "/admin/userssearch",
        element: <Search />,
      },
      {
        path: "/admin/usersfeedback",
        element: <Feedback />,
      },
    ],
    errorElement: <ErrorContainer />
  },
  {
    path: "/payment",
    element: <DashboardRoute><PaymentContainer /></DashboardRoute>,
    children: [
      {
        path: "/payment",
        element: <PaymentPage />
      },
      {
        path: "/payment/success",
        element: <PaymentSuccess />
      },
    ],
    errorElement: <ErrorContainer />
  }
]);

export default router;