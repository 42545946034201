import { yupResolver } from "@hookform/resolvers/yup";
import LanguageIcon from '@mui/icons-material/Language';
import PersonIcon from '@mui/icons-material/Person';
import WorkIcon from '@mui/icons-material/Work';
import { Button, Card, CardContent, FormControl, FormHelperText, FormLabel, Grid, Input, LinearProgress, Textarea } from "@mui/joy";
import { useEffect } from "react";
import { Controller, useForm } from "react-hook-form";
import { toast } from "react-toastify";
import { useGetUserInfoQuery, useUpdateUserInfoMutation } from "../../redux/features/authSlice";
import { useAppSelector } from "../../redux/hooks";
import { validationSchemaCardInfo } from "../../utils/formYupValidation";


type CardInfoProps = {}

const CardInfo: React.FC<CardInfoProps> = () => {

  const user = useAppSelector((state) => state.auth.user)
  const { data, error, isFetching } = useGetUserInfoQuery({ id: user?.id })

  const { handleSubmit, control, formState: { errors }, setValue } = useForm({
    resolver: yupResolver(validationSchemaCardInfo),
    defaultValues: {
      name: '',
      surName: '',
      agencyName: '',
      website: '',
      description: ''
    },
  });

  useEffect(() => {
    if (data?.responseObject) {
      setValue('name', data?.responseObject?.name || '')
      setValue('surName', data?.responseObject?.surName || '')
      setValue('agencyName', data?.responseObject?.agencyName)
      setValue('website', data?.responseObject?.website)
      setValue('description', data?.responseObject?.description)
    }

  }, [data?.responseObject, setValue])

  const [updateUserInfo, { isLoading }] = useUpdateUserInfoMutation()

  const onSubmit = async (data: any) => {
    const userInfo = await updateUserInfo({ ...data, id: user?.id }).unwrap()
    toast.success(userInfo.message)
  };


  return (
    <Card variant="outlined" size="lg" className="me-auto">
      <CardContent >
        <form onSubmit={handleSubmit(onSubmit)} className="flex flex-col gap-4">
          <Grid container spacing={2} sx={{ flexGrow: 1 }}>
            {isFetching && <Grid xs={12}> <LinearProgress /></Grid>}
            <Grid md={6} xs={12}>
              <FormControl>
                <FormLabel
                  sx={(theme) => ({
                    "--FormLabel-color": theme.vars.palette.neutral[700],
                  })}
                >
                  Name
                </FormLabel>
                <Controller
                  name="name"
                  control={control}
                  rules={{ required: true }}
                  render={({ field }) => (
                    <Input
                      {...field}
                      sx={(theme) => ({
                        fontSize: " 0.75rem",
                        "--Input-minHeight": "3rem",
                        "&:focus-within:before": {
                          "--Input-focused": 0,
                        },

                      })}
                      placeholder="Name"
                      type={"text"}
                      error={!!errors.name?.message}
                      size="sm"
                      startDecorator={
                        <PersonIcon
                          sx={(theme) => ({ color: theme.palette.navoy.blue })}
                          fontSize="small"
                        />
                      }
                    />
                  )}
                />
                {!!errors.name?.message && (
                  <FormHelperText
                    sx={(theme) => ({
                      color: theme.vars.palette.danger[400],
                      fontSize: "xs",
                    })}
                  >
                    {errors.name?.message}
                  </FormHelperText>
                )}
              </FormControl>
            </Grid>
            <Grid md={6} xs={12}>
              <FormControl>
                <FormLabel
                  sx={(theme) => ({
                    "--FormLabel-color": theme.vars.palette.neutral[700],
                  })}
                >
                  Surname
                </FormLabel>
                <Controller
                  name="surName"
                  control={control}
                  rules={{ required: true }}
                  render={({ field }) => (
                    <Input
                      {...field}
                      sx={(theme) => ({
                        fontSize: " 0.75rem",
                        "--Input-minHeight": "3rem",
                        "&:focus-within:before": {
                          "--Input-focused": 0,
                        },
                      })}
                      placeholder="Surname"
                      type={"text"}
                      error={!!errors.surName?.message}
                      size="sm"
                      startDecorator={
                        <PersonIcon
                          sx={(theme) => ({ color: theme.palette.navoy.blue })}
                          fontSize="small"
                        />
                      }
                    />
                  )}
                />
                {!!errors.surName?.message && (
                  <FormHelperText
                    sx={(theme) => ({
                      color: theme.vars.palette.danger[400],
                      fontSize: "xs",
                    })}
                  >
                    {errors.surName?.message}
                  </FormHelperText>
                )}
              </FormControl>
            </Grid>
            <Grid md={6} xs={12}>
              <FormControl>
                <FormLabel
                  sx={(theme) => ({
                    "--FormLabel-color": theme.vars.palette.neutral[700],
                  })}
                >
                  Agency name
                </FormLabel>
                <Controller
                  name="agencyName"
                  control={control}
                  rules={{ required: true }}
                  render={({ field }) => (
                    <Input
                      {...field}
                      sx={(theme) => ({
                        fontSize: " 0.75rem",
                        "--Input-minHeight": "3rem",
                        "&:focus-within:before": {
                          "--Input-focused": 0,
                        },
                      })}
                      placeholder="Agency name"
                      type={"text"}
                      error={!!errors.agencyName?.message}
                      size="sm"
                      startDecorator={
                        <WorkIcon
                          sx={(theme) => ({ color: theme.palette.navoy.blue })}
                          fontSize="small"
                        />
                      }
                    />
                  )}
                />
                {!!errors.agencyName?.message && (
                  <FormHelperText
                    sx={(theme) => ({
                      color: theme.vars.palette.danger[400],
                      fontSize: "xs",
                    })}
                  >
                    {errors.agencyName?.message}
                  </FormHelperText>
                )}
              </FormControl>
            </Grid>
            <Grid md={6} xs={12}>
              <FormControl>
                <FormLabel
                  sx={(theme) => ({
                    "--FormLabel-color": theme.vars.palette.neutral[700],
                  })}
                >
                  Web site
                </FormLabel>
                <Controller
                  name="website"
                  control={control}
                  rules={{ required: true }}
                  render={({ field }) => (
                    <Input
                      {...field}
                      sx={(theme) => ({
                        fontSize: " 0.75rem",
                        "--Input-minHeight": "3rem",
                        "&:focus-within:before": {
                          "--Input-focused": 0,
                        },
                      })}
                      placeholder="Website"
                      type={"text"}
                      error={!!errors.website?.message}
                      size="sm"
                      startDecorator={
                        <LanguageIcon
                          sx={(theme) => ({ color: theme.palette.navoy.blue })}
                          fontSize="small"
                        />
                      }
                    />
                  )}
                />
                {!!errors.website?.message && (
                  <FormHelperText
                    sx={(theme) => ({
                      color: theme.vars.palette.danger[400],
                      fontSize: "xs",
                    })}
                  >
                    {errors.website?.message}
                  </FormHelperText>
                )}
              </FormControl>
            </Grid>
            <Grid xs={12}>
              <FormControl>
                <FormLabel
                  sx={(theme) => ({
                    "--FormLabel-color": theme.vars.palette.neutral[700],
                  })}
                >
                  Description of services
                </FormLabel>
                <Controller
                  name="description"
                  control={control}
                  rules={{ required: true }}
                  render={({ field }) => (
                    <Textarea
                      {...field}
                      minRows={3}
                      sx={(theme) => ({
                        fontSize: " 0.75rem",
                        "&:focus-within:before": {
                          "--Input-focused": 0,
                        },
                      })}
                      placeholder="Type you description"
                      error={!!errors.description?.message}
                    />
                  )}
                />
                {!!errors.description?.message && (
                  <FormHelperText
                    sx={(theme) => ({
                      color: theme.vars.palette.danger[400],
                      fontSize: "xs",
                    })}
                  >
                    {errors.description?.message}
                  </FormHelperText>
                )}
              </FormControl>
            </Grid>
          </Grid>
          <div>
            <Button type="submit" loading={isLoading} >Submit</Button>
          </div>
        </form>
      </CardContent>
    </Card>
  );
}

export default CardInfo;