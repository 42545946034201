import React from 'react';
import { StyleSheet, Text, View, Image } from '@react-pdf/renderer';
import { createTw } from 'react-pdf-tailwind';

const tw = createTw({});

const styles = StyleSheet.create({
  bold: {
    fontFamily: 'Helvetica-Bold',
    fontWeight: 'bold',
  },
});

interface GeneralDetailsProps {
  agentName: string;
  agentEmail: string;
  agentPhone: string;
  clientName: string;
  clientEmail: string;
  clientPhone: string;
}

const GeneralDetails: React.FC<GeneralDetailsProps> = (props) => {
  return (
    <View style={tw('flex flex-row justify-between')}>
      <View style={tw('flex flex-row items-start flex-1 mr-2')}>
        <Image
          style={tw('w-12 h-12 rounded-full mr-2')}
          src="https://avatar.iran.liara.run/public/boy" // Placeholder for agent avatar
        />
        <View>
          <Text style={tw('text-xs mb-1')}>
            <Text style={styles.bold}>By:</Text> {props.agentName}
          </Text>
          <Text style={tw('text-xs mb-1')}>
            <Text style={styles.bold}>Email:</Text> {props.agentEmail}
          </Text>
          <Text style={tw('text-xs mb-1')}>
            <Text style={styles.bold}>Phone number:</Text> {props.agentPhone}
          </Text>
        </View>
      </View>
      <View style={tw('flex-1 ml-2')}>
        <Text style={tw('text-xs mb-1')}>
          <Text style={styles.bold}>For:</Text> {props.clientName}
        </Text>
        <Text style={tw('text-xs mb-1')}>
          <Text style={styles.bold}>Email:</Text> {props.clientEmail}
        </Text>
        <Text style={tw('text-xs mb-1')}>
          <Text style={styles.bold}>Phone number:</Text> {props.clientPhone}
        </Text>
      </View>
    </View>
  );
};

export default GeneralDetails;