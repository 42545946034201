import AutoAwesomeIcon from '@mui/icons-material/AutoAwesome';
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
import KeyboardArrowUpIcon from '@mui/icons-material/KeyboardArrowUp';
import { Box, Button } from "@mui/joy";
import { Collapse, IconButton, Stack, Table, TableBody, TableCell, TableRow, useMediaQuery, useTheme } from "@mui/material";
import { useEffect, useState } from "react";
import { useParams } from 'react-router-dom';
import { toast } from 'react-toastify';
import DialogVerticalScroll from '../../../components/modals/dialogVerticalScroll';
import { useLazyGetFlightBookingOptionsQuery, useLazyGetReturnFlightsQuery, usePersistFlightsMutation } from '../../../redux/features/flights.api';
import { useAppSelector } from '../../../redux/hooks';
import { columnsFlight } from '../../../utils/const';
import { convertMinutesToDuration } from "../../../utils/functions";
import { Flight, RowTableFlight } from "../../../utils/types";
import BookingFlight from './bookingFlight';
import FlightCard from './flightCard';
import SubRow from "./subRow";

type RowProps = {
  row: RowTableFlight;
  subFlight?: boolean;
  index: number
  indexFils: number
}

const Row: React.FC<RowProps> = ({ row, subFlight, index, indexFils }) => {
  const params = useParams()
  const requestId = params.requestId
  const subscriptionId = params.subscriptionId;
  const user = useAppSelector((state) => state.auth.user)

  const theme = useTheme();
  const matches = useMediaQuery(theme.breakpoints.down("laptop"));
  const [open, setOpen] = useState<boolean>(false);
  const [openSelectFlight, setOpenSelectFlight] = useState(false)
  const [openBooking, setOpenBooking] = useState(false)
  const [getSelectFlight, { data: returnFlight, error: errorSelectFlight, isLoading }] = useLazyGetReturnFlightsQuery()
  const [getBookingFlight, { data: bookingFlight, error: errorBookingFlight, isLoading: loadingBooking }] = useLazyGetFlightBookingOptionsQuery()
  const [persistFlights] = usePersistFlightsMutation();
  const [rows, setRows] = useState<RowTableFlight[]>([])
  const [select, setSelect] = useState("");

  const handleSelectFlight = (params: any) => {
    if (openSelectFlight) {
      setOpenSelectFlight(false)
    } else {
      getSelectFlight({ ...params })
      if (returnFlight?.status === 200) {
        if (returnFlight.data) {
          mapFlights([...returnFlight.data]);
          setOpenSelectFlight(true)
        }
      }
    }
  }

  const selectFlightBook = async (book_with: string) => {
    setSelect(book_with)
    if (book_with) {
      try {
        await persistFlights({
          trip_id: { id: requestId, subscription_id: subscriptionId, user: { id: user?.id } },
          selected_flights: bookingFlight?.data.selected_flights,
        }).unwrap();
        toast.success('Flights saved successfully');
      } catch (error) {
        toast.error('An error occurred while saving the flights');
      }
    } else {
      // TODO: Remove from backend
      // Unsupported for now due to time constraints
    }
  }

  useEffect(() => {
    if (errorSelectFlight) {
      const message = (errorSelectFlight && 'data' in errorSelectFlight && (errorSelectFlight.data as any)?.message) || ''
      toast.warn(message || 'Something went wrong in the select flight')
    }
    if (errorBookingFlight) {
      const message = (errorBookingFlight && 'data' in errorBookingFlight && (errorBookingFlight.data as any)?.message) || ''
      toast.warn(message || 'Something went wrong in the book flight')
    }
  }, [errorBookingFlight, errorSelectFlight])

  useEffect(() => {
    if (returnFlight?.status === 200) {
      if (returnFlight.data) {
        mapFlights([...returnFlight.data]);
        setOpenSelectFlight(true)
      }
    }
  }, [returnFlight])

  const mapFlights = (flights: Flight[]) => {
    const rowFlights: RowTableFlight[] = flights.map((flight) => {
      const firstFlight = flight.flights?.[0];
      const lastFlight = flight.flights?.[flight.flights.length - 1];
      return {
        photo: flight.airline_logo,
        name: flight?.airline || firstFlight?.airline,
        time: firstFlight.departure_airport.time.split(' ')[1] + ' - ' + lastFlight.arrival_airport.time.split(' ')[1],
        duration: convertMinutesToDuration(flight.total_duration),
        stops: flight.flights.length > 1 ? `${flight.flights.length - 1} stop${flight.flights.length > 2 ? 's' : ''}` : 'Non - Stop',
        cost: '$' + flight.price,
        flights: flight.flights,
        type: flight.type,
        action: {
          departure_id: flight.search_parameters.departure_id,
          arrival_id: flight.search_parameters.arrival_id,
          outbound_date: flight.search_parameters.outbound_date,
          return_date: flight.search_parameters.return_date,
          token: flight.token
        }
      }
    })
    setRows(rowFlights)
  }

  const handleBookingFlight = (params: any) => {
    getBookingFlight({ ...params })
    if (bookingFlight?.data?.booking_options?.length) {
      setOpenBooking(true)
    }
  }

  
  useEffect(() => {
    if (bookingFlight?.data?.booking_options?.length) {
      setOpenBooking(true)
    }
  }, [bookingFlight])


  return (
    <>
      {
        matches ?
          <FlightCard
            row={row}
            open={open}
            setOpen={setOpen}
            openBooking={openBooking}
            openSelectFlight={openSelectFlight}
            setOpenBooking={setOpenBooking}
            setOpenSelectFlight={setOpenSelectFlight}
            subFlight={subFlight}
            loadingBooking={loadingBooking}
            isLoading={isLoading}
            handleBookingFlight={(a: any) => handleBookingFlight(a)}
            handleSelectFlight={(a: any) => handleSelectFlight(a)}
            index={index}
            indexFils={indexFils}
          /> :

          <TableRow role="checkbox" tabIndex={-1} className={index === 0 && (subFlight ? indexFils === 0 : true) ? "bg-green-50" : ""} >
            {columnsFlight.map((column, key) => (
              <TableCell key={key} align={column.align} width={column.id === 'icon' ? column.minWidth : ''} sx={{ minWidth: column.minWidth }}  >
                {index === 0 && key === 0 && (subFlight ? indexFils === 0 : true) && <div className='absolute top-1 left-1 text-yellow-600 z-10'>
                  <AutoAwesomeIcon  />
                </div>}
                {column.id === 'name' ?
                  <Stack direction={'row'} alignItems={'center'} gap={1} >
                    <img src={row.photo} alt="" width={44} /> {row.name}
                  </Stack> :
                  column.id === 'icon' ?
                    <IconButton aria-label="expand row" size="small" onClick={() => setOpen(!open)}>
                      {open ? <KeyboardArrowUpIcon /> : <KeyboardArrowDownIcon />}
                    </IconButton> :
                    column.id === 'action' ?
                      subFlight ?
                        <Button loading={loadingBooking} disabled={loadingBooking} onClick={() => handleBookingFlight(row['action'])} variant="outlined" color='neutral' className="rounded-lg" >Book flight</Button> :
                        <Button loading={isLoading} disabled={isLoading} onClick={() => handleSelectFlight(row['action'])} variant="outlined" color='neutral' className="rounded-lg" >{openSelectFlight ? 'Unselect' : 'Select flight'}</Button> :
                      column.id === 'type' ? subFlight ? 'Return flight' : 'Departure flight' :
                        row[column.id]
                }
              </TableCell>
            ))}
          </TableRow >}
      {open &&
        <TableRow>
          <TableCell style={{ paddingBottom: 0, paddingTop: 0 }} colSpan={8}>
            <Collapse in={open} timeout="auto" unmountOnExit>
              <Box sx={{ margin: 1 }}>
                <SubRow flights={row.flights} />
              </Box>
            </Collapse>
          </TableCell>
        </TableRow>
      }
      {
        !subFlight &&
        <TableRow>
          <TableCell style={{ paddingBottom: 0, paddingTop: 0 }} colSpan={8}>
            <Collapse in={openSelectFlight} timeout="auto" unmountOnExit>
              <Table sx={theme => ({ backgroundColor: theme.palette.navoy_blue[50] })}>
                <TableBody className="w-full">
                  {rows.map((row, key) => <Row key={key} row={row} subFlight indexFils={key} index={index} />)}
                </TableBody>
              </Table>
            </Collapse>
          </TableCell>
        </TableRow>
      }
      {
        bookingFlight?.data?.booking_options &&
        <DialogVerticalScroll
          open={openBooking}
          setOpen={setOpenBooking}
          title="Flight prices"
          text="Tick the website you want to book from"
          subText="(This will be added to your quote)"
          option={bookingFlight?.data?.booking_options}
          Content={BookingFlight}
          select={select}
          setSelect={(s) => selectFlightBook(s)}
        />
      }
    </>
  );
}

export default Row;
