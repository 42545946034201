
import { yupResolver } from '@hookform/resolvers/yup';
import LanguageIcon from '@mui/icons-material/Language';
import WorkIcon from '@mui/icons-material/Work';
import { Container, FormHelperText, Textarea } from '@mui/joy';
import Button from '@mui/joy/Button';
import Card from '@mui/joy/Card';
import CardActions from '@mui/joy/CardActions';
import CardContent from '@mui/joy/CardContent';
import FormControl from '@mui/joy/FormControl';
import FormLabel from '@mui/joy/FormLabel';
import Input from '@mui/joy/Input';
import Typography from '@mui/joy/Typography';
import * as React from 'react';
import { Controller, useForm } from "react-hook-form";
import { toast } from 'react-toastify';
import { v4 as uuidv4 } from 'uuid';
import { useAddEnterpriseMutation } from '../../redux/features/enterprise.api';
import { validationSchemaEnterprise } from '../../utils/formYupValidation';

interface FormData {
  firstName: string;
  lastName: string;
  email: string;
  agentName: string;
  website: string;
  additionalComments?: string;
}

const EnterpriseForm: React.FC = () => {
  const userId = localStorage.getItem("userId") || uuidv4();
  localStorage.setItem("userId", userId);
  const { handleSubmit, control, formState: { errors }, setError } = useForm<FormData>({
    resolver: yupResolver(validationSchemaEnterprise),
    defaultValues: {
      firstName: '',
      lastName: '',
      email: '',
      agentName: '',
      website: '',
      additionalComments: ''
    },
  });
  const [createEnterprise, { isLoading }] = useAddEnterpriseMutation();

  const onSubmit = async (data: FormData) => {
    try {
      const result = await createEnterprise({ ...data }).unwrap();
      if (result?.success) {
        if (!result?.responseObject.success) {
          toast.warn(result?.responseObject?.message)
          if (result?.responseObject.message?.includes('email'))
            setError('email', { type: "manual", message: result?.responseObject.message })
        } else {
          toast.info(result?.responseObject?.message)
        }
      } else {
        toast.error(result?.responseObject?.message)
      }
    } catch (error) {
      toast.error("An error occurred. Please try again.");
    }
  };

  return (
    <Container maxWidth="lg" className="min-h-screen pt-36 laptop:pt-40 pb-8 relative">
      <Card
        variant="outlined"
        sx={{
          maxHeight: 'max-content',
          maxWidth: '100%',
          mx: 'auto',
        }}
      >
        <Typography level="h4" component="h1" sx={{ mb: 2 }}>
          Enterprise Form
        </Typography>
        <form onSubmit={handleSubmit(onSubmit)}>
          <CardContent
            sx={{
              display: 'grid',
              gridTemplateColumns: 'repeat(2, minmax(80px, 1fr))',
              gap: 1.5,
            }}
          >
            <FormControl>
              <FormLabel>First Name</FormLabel>
              <Controller
                name="firstName"
                control={control}
                rules={{ required: true }}
                render={({ field }) => (
                  <Input placeholder='First name' {...field} />
                )}
              />
              {!!errors.firstName?.message && (
                <FormHelperText
                  sx={(theme) => ({
                    color: theme.vars.palette.danger[400],
                    fontSize: "xs",
                  })}
                >
                  {errors.firstName?.message}
                </FormHelperText>
              )}
            </FormControl>
            <FormControl>
              <FormLabel>Last Name</FormLabel>
              <Controller
                name="lastName"
                control={control}
                rules={{ required: true }}
                render={({ field }) => (
                  <Input placeholder='Last name' {...field} />
                )}
              />
              {!!errors.lastName?.message && (
                <FormHelperText
                  sx={(theme) => ({
                    color: theme.vars.palette.danger[400],
                    fontSize: "xs",
                  })}
                >
                  {errors.lastName?.message}
                </FormHelperText>
              )}
            </FormControl>
            <FormControl sx={{ gridColumn: '1/-1' }}>
              <FormLabel>Email Address</FormLabel>
              <Controller
                name="email"
                control={control}
                rules={{ required: true }}
                render={({ field }) => (
                  <Input placeholder='Email' {...field} />
                )}
              />
              {!!errors.email?.message && (
                <FormHelperText
                  sx={(theme) => ({
                    color: theme.vars.palette.danger[400],
                    fontSize: "xs",
                  })}
                >
                  {errors.email?.message}
                </FormHelperText>
              )}
            </FormControl>
            <FormControl>
              <FormLabel>Enterprise Name</FormLabel>
              <Controller
                name="agentName"
                control={control}
                rules={{ required: true }}
                render={({ field }) => (
                  <Input
                    {...field}
                    placeholder="Enterprise name"
                    startDecorator={
                      <WorkIcon
                        sx={(theme) => ({ color: theme.palette.navoy.blue })}
                        fontSize="small"
                      />
                    }
                  />
                )}
              />
              {!!errors.agentName?.message && (
                <FormHelperText
                  sx={(theme) => ({
                    color: theme.vars.palette.danger[400],
                    fontSize: "xs",
                  })}
                >
                  {errors.agentName?.message}
                </FormHelperText>
              )}
            </FormControl>
            <FormControl>
              <FormLabel>Website</FormLabel>
              <Controller
                name="website"
                control={control}
                rules={{ required: true }}
                render={({ field }) => (
                  <Input
                    {...field}
                    placeholder="Website"
                    startDecorator={
                      <LanguageIcon
                        sx={(theme) => ({ color: theme.palette.navoy.blue })}
                        fontSize="small"
                      />
                    }
                  />
                )}
              />
              {!!errors.website?.message && (
                <FormHelperText
                  sx={(theme) => ({
                    color: theme.vars.palette.danger[400],
                    fontSize: "xs",
                  })}
                >
                  {errors.website?.message}
                </FormHelperText>
              )}
            </FormControl>
            <FormControl sx={{ gridColumn: '1/-1' }}>
              <FormLabel>Additional Comments</FormLabel>
              <Controller
                name="additionalComments"
                control={control}
                render={({ field }) => (
                  <Textarea placeholder="Type anything…" minRows={4} {...field} />
                )}
              />
              {!!errors.additionalComments?.message && (
                <FormHelperText
                  sx={(theme) => ({
                    color: theme.vars.palette.danger[400],
                    fontSize: "xs",
                  })}
                >
                  {errors.additionalComments?.message}
                </FormHelperText>
              )}
            </FormControl>
            <CardActions sx={{ gridColumn: '1/-1' }}>
              <Button loading={isLoading} type='submit' variant="solid" color="primary" className='!bg-navoy_blue-950'>
                Submit
              </Button>
            </CardActions>
          </CardContent>
        </form>
      </Card>
    </Container>
  );
}

export default EnterpriseForm;
