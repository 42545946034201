import { yupResolver } from '@hookform/resolvers/yup';
import { Button, FormHelperText, Grid, Stack, Textarea, Typography } from '@mui/joy';
import { FormControl, FormLabel, LinearProgress } from '@mui/material';
import { useEffect } from 'react';
import { Controller, useForm } from 'react-hook-form';
import { useNavigate } from 'react-router-dom';
import { toast } from 'react-toastify';
import { ChoiceChips } from '../../components/inputs/Checkbox';
import DateRange from '../../components/inputs/dateRange/date';
import { InputReactNumber, InputReactNumberFormatUSD } from '../../components/inputs/input';
import SearchGoogleMap from '../../components/inputs/searchGoogleMap';
import { SelectInput } from '../../components/inputs/select';
import { useGenerateTripIdQuery, useLazyGenerateOptionsQuery, useLazyRegenerateOptionsQuery, useLazyTriggerTripQuery } from '../../redux/features/tripApi';
import { useAppSelector } from '../../redux/hooks';
import { maxAddDays, numberActivitiesList, travelerStyleList, tripPurpose } from '../../utils/const';
import { validationSchemaFormTravel } from '../../utils/formYupValidation';
import { formatDate } from '../../utils/functions';
import useDebounce from '../../hooks/useDebounce';
import { addDays, addMonths, lastDayOfMonth } from 'date-fns';

type FormSearchProps = {}

interface ITripForm {
  tripPurpose: string;
  activities: string[];
  food: string[];
  dateRange: (Date | null)[];
  target: string;
  preferredAccommodation: string;
  budgetInUSD: string;
  nbrTravelers: string;
  travelerStyle: string;
  numberActivities: string;
  prompts: string;

  latitude?: number;
  longitude?: number;
}
let generateOption = true

// Separate the search input creation logic
const createSearchInput = (formData: ITripForm) => {
  const { 
    tripPurpose, 
    dateRange, 
    target, 
    budgetInUSD, 
    nbrTravelers, 
    travelerStyle, 
    numberActivities, 
    prompts, 
    latitude, 
    longitude 
  } = formData;

  return {
    tripPurpose,
    activities: [],
    food: [],
    dateRange: [
      formatDate(dateRange?.[0]?.toDateString() || ''), 
      formatDate(dateRange?.[1]?.toDateString() || '')
    ],
    target,
    preferredAccommodation: '',
    budgetInUSD,
    nbrTravelers: Number(nbrTravelers),
    travelerStyle,
    numberActivities,
    prompt: prompts,
    latitude,
    longitude
  };
};

// Check if required fields are filled
const hasRequiredFields = (value: ITripForm) => {
  return !!(
    value.tripPurpose &&
    value.target &&
    value.budgetInUSD &&
    value.nbrTravelers &&
    value.travelerStyle &&
    value.numberActivities
  );
};

const FormSearch: React.FC<FormSearchProps> = () => {
  const { handleSubmit, control, formState: { errors }, setValue, getValues, watch } = useForm<any>({
    resolver: yupResolver(validationSchemaFormTravel),
    defaultValues: {
      tripPurpose: '',
      activities: [],
      food: [],
      dateRange: [null, null],
      target: '',
      preferredAccommodation: '',
      budgetInUSD: '',
      nbrTravelers: '',
      travelerStyle: '',
      numberActivities: '',
      prompts: '',
      latitude: undefined,
      longitude: undefined,
    },
    mode: 'onTouched', // Consider adding validation mode to improve user experience
    reValidateMode: 'onChange', // Improves re-validation upon change, can help catch errors early
    shouldUnregister: false, // Prevents unregistered inputs from losing their data
    shouldFocusError: true, // Automatically focuses the first field with an error, useful for accessibility
  });

  const debouncedTarget = useDebounce(watch('target'), 500);
  
  const user = useAppSelector((state) => state.auth.user)
  const navigate = useNavigate()
  const { data: dataGenerateId } = useGenerateTripIdQuery({ id: user?.id, subscription_id: user?.subscription?.currentSubscription?.eventId })
  const [generateOptions, { data: dataOptions, isFetching: isFetchingOption }] = useLazyGenerateOptionsQuery()
  const [regenerateOptions, { data: dataRegenerateOptions, isFetching: isFetchingRegenerateOption }] = useLazyRegenerateOptionsQuery()
  const [triggerTrip, { data: dataTriggerTrip, isFetching: isFetchingTriggerTrip }] = useLazyTriggerTripQuery()

  const handleLocationChange = (location: { lat: number; lng: number }) => {
    setValue('latitude', location.lat);
    setValue('longitude', location.lng);
  };

  const onSubmit = (data: ITripForm) => {
    const { tripPurpose, activities, food, preferredAccommodation, dateRange, target, budgetInUSD, nbrTravelers, travelerStyle, numberActivities, prompts, latitude, longitude } = data

    const search_input = {
      tripPurpose,
      activities,
      food,
      dateRange: [formatDate(dateRange?.[0]?.toDateString() || ''), formatDate(dateRange?.[1]?.toDateString() || '')],
      target,
      preferredAccommodation,
      budgetInUSD,
      nbrTravelers: Number(nbrTravelers),
      travelerStyle,
      numberActivities,
      prompt: prompts,
      latitude,
      longitude
    }
    triggerTrip({ trip_id: dataGenerateId?.data, search_input })
  };
  useEffect(() => {
    const subscription = watch((value: ITripForm, { name }) => {
      // Skip if it's a dateRange update with the same values
      if (name === 'dateRange' && 
          JSON.stringify(value.dateRange) === JSON.stringify(getValues('dateRange'))) {
        return;
      }

      // Skip if it's one of these fields or required fields are missing
      const skipFields = ['prompts', 'preferredAccommodation', 'activities', 'food'];
      if (skipFields.includes(name || '') || !hasRequiredFields(value)) {
        return;
      }

      const search_input = createSearchInput(value);
      const trip_id = dataGenerateId?.data;

      if (!trip_id) return;

      // Handle initial generation
      if (generateOption) {
        generateOptions({ trip_id, search_input });
        generateOption = false;
        return;
      }

      // Handle regeneration (skip if target field changes)
      if (name !== 'target') {
        regenerateOptions({ trip_id, search_input });
      }
    });

    return () => subscription.unsubscribe();
  }, [dataGenerateId?.data, generateOptions, getValues, regenerateOptions, watch]);

  useEffect(() => {
    if (getValues('tripPurpose') && debouncedTarget && getValues('budgetInUSD') && getValues('nbrTravelers') && getValues('travelerStyle') && getValues('numberActivities')) {
      const search_input = {
        tripPurpose: getValues('tripPurpose'),
        activities: [],
        food: [],
        dateRange: [formatDate(getValues('dateRange')?.[0]?.toDateString() || ''), formatDate(getValues('dateRange')?.[1]?.toDateString() || '')],
        target: getValues('target'),
        preferredAccommodation: '',
        budgetInUSD: getValues('budgetInUSD'),
        nbrTravelers: Number(getValues('nbrTravelers')),
        travelerStyle: getValues('travelerStyle'),
        numberActivities: getValues('numberActivities'),
        prompt: getValues('prompts'),
        latitude: getValues('latitude'),
        longitude: getValues('longitude')
      }
      regenerateOptions({ trip_id: dataGenerateId?.data, search_input })
    }
  }, [dataGenerateId?.data, debouncedTarget, getValues, regenerateOptions]);

  useEffect(() => {
    if (dataTriggerTrip?.status === 200) {
      toast.success('Your trip is being generated!')
      navigate('/dashboard/travel/' + dataGenerateId?.data.id + '/' + user?.subscription?.currentSubscription?.eventId)
    }
    if (dataTriggerTrip?.status === 401) toast.warn(dataTriggerTrip?.message || 'Something went wrong')
  }, [dataGenerateId?.data.id, dataTriggerTrip?.message, dataTriggerTrip?.status, navigate, user?.subscription?.currentSubscription?.eventId])


  return (
    <Stack spacing={4} sx={{ padding: { lg: 4, xs: 1 } }}>
      <Typography sx={{ fontSize: '2rem', fontWeight: '700' }} level="h2">Create a new trip</Typography>
      <form className="w-full flex flex-col gap-4" onSubmit={handleSubmit(onSubmit)}>
        <Grid container spacing={2} sx={{ flexGrow: 1 }}>
          <Grid lg={4} md={6} xs={12}>
            <Controller
              name='tripPurpose'
              control={control}
              render={({ field }) => (
                <SelectInput
                  id="tripPurpose"
                  field={field}
                  list={tripPurpose}
                  label='Trip purpose'
                  error={errors?.tripPurpose?.message?.toString() || ''}
                />
              )}
            />
          </Grid>
          <Grid lg={4} md={6} xs={12}>
            <Controller
              name="dateRange"
              control={control}
              rules={{ required: true }}
              render={({ field }) => (
                <DateRange
                  id="tripDates"
                  field={field}
                  setValue={(range) => setValue('dateRange', range)}
                  matches={watch('tripPurpose') === 'Travel'}
                  errors={errors?.dateRange || []}
                  label='Date'
                  minDate={!field.value?.[1] ? field.value?.[0] || new Date() : new Date()}
                  maxDate={!!field.value?.[0] && !field.value?.[1] ? addDays(new Date(field.value?.[0]), maxAddDays) : addMonths(lastDayOfMonth(new Date()), 10)}
                />
              )}
            />
          </Grid>
          <Grid lg={4} md={6} xs={12}>
            <Controller
              name="target"
              control={control}
              rules={{ required: true }}
              render={({ field }) => (
                <SearchGoogleMap
                  id="destination"
                  label="Destination"
                  placeholder="Where are you going?"
                  field={field}
                  error={errors?.target?.message?.toString() || ''}
                  onLocationChange={handleLocationChange}
                />
              )}
            />
          </Grid>
          <Grid lg={4} md={6} xs={12}>
            <Controller
              name="budgetInUSD"
              control={control}
              render={({ field }) => (
                <InputReactNumberFormatUSD
                  field={field}
                  label='Budget in USD'
                  error={errors?.budgetInUSD?.message?.toString() || ''}
                  placeholder='Budget in USD'
                />
              )}
            />
          </Grid>
          <Grid lg={4} md={6} xs={12}>
            <Controller
              name="nbrTravelers"
              control={control}
              render={({ field }) => (
                <InputReactNumber
                  field={field}
                  label='Number of travelers '
                  error={errors?.nbrTravelers?.message?.toString() || ''}
                  placeholder='Number of travelers'
                />
              )}
            />
          </Grid>
          <Grid lg={4} md={6} xs={12}>
            <Controller
              name='travelerStyle'
              control={control}
              render={({ field }) => (
                <SelectInput
                  id="travelerStyle"
                  field={field}
                  list={travelerStyleList}
                  label='Traveler Style'
                  error={errors?.travelerStyle?.message?.toString() || ''}
                />
              )}
            />
          </Grid>
          <Grid lg={4} md={6} xs={12}>
            <Controller
              name='numberActivities'
              control={control}
              render={({ field }) => (
                <SelectInput
                  id="numberOfActivities"
                  field={field}
                  list={numberActivitiesList}
                  label='Number of activities per day'
                  error={errors?.numberActivities?.message?.toString() || ''}
                />
              )}
            />
          </Grid>

          <Grid lg={4} md={6} xs={12} >
            <Controller
              name='preferredAccommodation'
              control={control}
              render={({ field }) => (
                <SelectInput
                  id="preferredAccommodation"
                  disabled={!dataOptions?.data?.accommodation?.length && !dataRegenerateOptions?.data?.accommodation?.length}
                  field={field}
                  list={(dataRegenerateOptions?.data || dataOptions?.data)?.accommodation?.map((item: string) => { return { value: item, label: item } })}
                  label='Preferred Accommodation type'
                  error={errors?.preferredAccommodation?.message?.toString() || ''}
                />
              )}
            />
          </Grid>
          {(isFetchingRegenerateOption || isFetchingOption) && <Grid xs={12}><LinearProgress /> </Grid>}
          {(dataRegenerateOptions?.data || dataOptions?.data)?.activities?.length > 0 &&
            <Grid sm={12}>
              <Controller
                name='activities'
                control={control}
                render={({ field }) => (
                  <ChoiceChips
                    field={field}
                    list={(dataRegenerateOptions?.data || dataOptions?.data)?.activities}
                    label='Interests and Activities'
                  />
                )}
              />
              {errors?.activities?.message && <FormHelperText>{errors?.activities?.message?.toString()}</FormHelperText>}
            </Grid>
          }
          {(dataRegenerateOptions?.data || dataOptions?.data)?.food?.length > 0 &&
            <Grid sm={12}>
              <Controller
                name='food'
                control={control}
                render={({ field }) => (
                  <ChoiceChips
                    field={field}
                    list={(dataRegenerateOptions?.data || dataOptions?.data)?.food}
                    label='Foods'
                  />
                )}
              />
              {errors?.food?.message && <FormHelperText>{errors?.food?.message?.toString()}</FormHelperText>}
            </Grid>
          }
          <Grid xs={12}>
            <Controller
              name='prompts'
              control={control}
              render={({ field }) => (
                <FormControl fullWidth className='gap-2' >
                  <FormLabel >Type of activities</FormLabel>
                  <Textarea
                    {...field}
                    minRows={2}
                    variant="outlined"
                    error={!!errors?.prompts?.message?.toString()}
                    placeholder="I want the activities to be unique experiences and not mainstream"
                  />
                  {errors?.prompts?.message && <FormHelperText>{errors?.prompts?.message?.toString()}</FormHelperText>}
                </FormControl>
              )}
            />

          </Grid>
        </Grid>

        <div>
          <Button type="submit" loading={isFetchingTriggerTrip} disabled={isFetchingTriggerTrip || isFetchingRegenerateOption || isFetchingOption} >Submit</Button>
        </div>
      </form>
    </Stack >
  );
}


export default FormSearch;