import { Button, LinearProgress } from '@mui/joy';
import Box from '@mui/material/Box';
import Tab from '@mui/material/Tab';
import Tabs from '@mui/material/Tabs';
import * as React from 'react';
import { Link, Outlet, useParams, useNavigate, useLocation } from 'react-router-dom';

import { yupResolver } from '@hookform/resolvers/yup';
import { Controller, useForm } from 'react-hook-form';
import SearchGoogleMap from '../../components/inputs/searchGoogleMap';
import useAppBarHeight from '../../hooks/useAppBarHeight';
import { useGetFlightQuery, useLazyGetFlightsQuery } from '../../redux/features/flights.api';
import { useGetOverviewQuery } from '../../redux/features/itineraryApi';
import { useGetSearchParametersQuery } from '../../redux/features/tripApi';
import { useAppSelector } from '../../redux/hooks';
import { validationSchemaSource } from '../../utils/formYupValidation';
import Body from './flight/body';
import BodyHotel from './hotel/main';
import ItineraryContent from './itinerary';
import { Map } from './itinerary/map';
import Overview from './itinerary/overview';
import NuiteeHotels from './hotel/nuitee';

interface TabPanelProps {
  children?: React.ReactNode;
  index: number;
  value: number;
}

function CustomTabPanel(props: TabPanelProps) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && <Box>{children}</Box>}
    </div>
  );
}

function a11yProps(index: number) {
  return {
    id: `simple-tab-${index}`,
    'aria-controls': `simple-tabpanel-${index}`,
  };
}

type TravelTabsProps = {};

type SearchFlights = { source: string }

const TravelTabs: React.FC<TravelTabsProps> = () => {
  const navigate = useNavigate();
  const location = useLocation();
  const [searchFlights, { data: flightsData, isLoading: flightsIsLoading }] = useLazyGetFlightsQuery();
  const { handleSubmit, control, formState: { errors }, } = useForm<any>({
    resolver: yupResolver(validationSchemaSource),
    defaultValues: {
      source: '',
    },
  });

  const appBarHeight = useAppBarHeight();
  const params = useParams();
  const requestId = params.requestId;
  const subscriptionId = params.subscriptionId;
  const [expanded, setExpanded] = React.useState(false);
  const user = useAppSelector((state) => state.auth.user);
  const { data: dataOverview, isFetching: isLoadingOverview } = useGetOverviewQuery({ id: requestId, subscription_id: subscriptionId, user: { id: user?.id } });

  const { data: searchData, isLoading: searchIsLoading } = useGetSearchParametersQuery<{
    data: {
      status: number;
      data: {
        target: string;
        latitude: number;
        longitude: number;
      };
    };
    isLoading: boolean;
  }>({ id: requestId, subscription_id: subscriptionId, user: { id: user?.id } });

  // Determine active tab based on URL path
  const getActiveTab = () => {
    if (location.pathname.includes('/hotel')) return 1;
    if (location.pathname.includes('/flight')) return 2;
    return 0; // Default to itinerary
  };

  const handleChange = (event: React.SyntheticEvent, newValue: number) => {
    const paths = {
      0: `/dashboard/travel/${requestId}/${subscriptionId}`,
      1: `/dashboard/travel/${requestId}/${subscriptionId}/hotel`,
      2: `/dashboard/travel/${requestId}/${subscriptionId}/flight`
    };
    navigate(paths[newValue as keyof typeof paths]);
  };

  const onSubmit = (data: SearchFlights) => {
    sessionStorage.setItem('source', data.source)
    searchFlights({ id: requestId, subscription_id: subscriptionId, user: { id: user?.id }, source: data.source })
  };

  if (isLoadingOverview) return <LinearProgress />;

  return (
    <article className="space-y-4">
      <Overview
        target={searchData?.data?.target?.split(',')[0] || ''}
        title={dataOverview?.data?.title || ''}
        description={dataOverview?.data?.description || 'No description.'}
        expanded={expanded}
        setExpanded={(b) => setExpanded(b)}
      />

      <section className="space-y-4">
        <Tabs value={getActiveTab()} onChange={handleChange} aria-label="travel tabs">
          <Tab label="Itinerary" {...a11yProps(0)} />
          <Tab label="Hotels" {...a11yProps(1)} />
          <Tab label="Flights" {...a11yProps(2)} />
        </Tabs>

        <section className="flex flex-col-reverse lg:flex-row gap-x-6 gap-y-2">
          <article className="w-full lg:w-2/3">
            <Outlet />
          </article>

          {(searchIsLoading) && <LinearProgress />}
          <Box
            className="static w-full lg:w-1/3 lg:sticky"
            sx={{
              height: {
                xs: '50vh',
                // TODO: Make this dynamic
                // 15 is the padding of the main container
                // 21 is the padding of the tab container
                md: `calc(100vh - ${appBarHeight + 15 + 21}px)`,
                lg: `calc(100vh - ${appBarHeight + 15 + 21}px)`,
              },
              top: `${appBarHeight + 15}px`,
            }}
          >
            {/* <article className="w-full h-full bg-blue-500" /> */}
            {searchData?.data?.target && (
              <Map
                defaultLng={searchData?.data?.longitude || 0}
                defaultLat={searchData?.data?.latitude || 0}
                defaultZoom={10}
              />
            )}
          </Box>
        </section>
      </section>
    </article>
  );
};
export default TravelTabs;
