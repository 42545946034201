import { Stack, Typography } from "@mui/joy";
import CardPhoto from "./cardPhoto";
import CardInfo from "./cardInfo";
import CardSocialMedia from "./cardSocialMedia";
import Subscription from "./cardSubscription";

type ProfileProps = {}

const Profile: React.FC<ProfileProps> = () => {
  return (
    <Stack spacing={4} sx={{ padding: { lg: 4, xs: 1 } }}>
      <Typography sx={{ fontSize: '2rem', fontWeight: '700' }} level="h2">My profile</Typography>
      <div className="flex flex-col  flex-wrap gap-5">
        <CardPhoto />
        <CardInfo />
        <Typography sx={{ fontSize: '1.5rem', fontWeight: '700' }} level="h3">Subscription</Typography>
        <Subscription />
        <Typography sx={{ fontSize: '1.5rem', fontWeight: '700' }} level="h3">Social media</Typography>
        <CardSocialMedia />
      </div>
    </Stack>
  );
}


export default Profile;