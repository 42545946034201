import { yupResolver } from "@hookform/resolvers/yup";
import LockIcon from "@mui/icons-material/Lock";
import MailIcon from "@mui/icons-material/Mail";
import VisibilityIcon from "@mui/icons-material/Visibility";
import VisibilityOffIcon from "@mui/icons-material/VisibilityOff";
import { Box, Button, Checkbox, Chip, Divider, FormControl, FormHelperText, FormLabel, Input, Typography, } from "@mui/joy";
import { useEffect, useState } from "react";
import { Controller, useForm } from "react-hook-form";
import { Link, useNavigate } from "react-router-dom";
import { toast } from "react-toastify";
import { login, logout, useLoginMutation } from "../../redux/features/authSlice";
import { useAppDispatch, useAppSelector } from "../../redux/hooks";
import { stripBasicMonth, stripBasicYear, stripFreeTrial, stripPremiumMonth, stripPremiumYear, stripProMonth, stripProYear } from "../../utils/env";
import { validationSchemaLogin } from "../../utils/formYupValidation";
import { LoginResponse } from "../../utils/types";
import Logo from "./components/logo";
import SocialMedia from "./components/socialMedia";

type LoginProps = {};

const Login: React.FC<LoginProps> = () => {
  const userAuth = useAppSelector((state) => state.auth.user)
  const { handleSubmit, control, formState: { errors }, setError } = useForm({
    resolver: yupResolver(validationSchemaLogin),
    defaultValues: {
      password: "",
      email: "",
      remember: false,
    },
  });

  const [visible, setVisible] = useState(true);

  const [signin, { isLoading }] = useLoginMutation()

  const dispatch = useAppDispatch()
  const navigate = useNavigate()
  const onSubmit = async (data: any) => {
    if (!isLoading) {
      try {
        setError('password', { type: "manual", message: '' })
        const user = await signin(data).unwrap()
        if (user?.responseObject?.id) {
          const plan = localStorage.getItem('plan')
          const daysRemaining = user?.responseObject?.subscription?.daysRemaining;
          const isActive = user?.responseObject?.subscription?.isActive;

          const handleLogin = (user: LoginResponse) => {
            dispatch(login({ user: user.responseObject }));
            localStorage.removeItem('plan');
            localStorage.removeItem('type');
          };

          if (!plan || plan === 'Free trial') {
            if (daysRemaining === null) {
              handleLogin(user);
              window.open(stripFreeTrial + `?client_reference_id=${user?.responseObject?.id}&prefilled_email=${user?.responseObject.email}`, '_self');
            } else if (typeof daysRemaining === "number") {
              handleLogin(user);
              navigate('/dashboard');
            }
          } else {
            handelNavigate(user);
          }

          if (
            (plan === 'Free trial' && typeof daysRemaining === 'number' && daysRemaining >= 0) ||
            (isActive && daysRemaining && daysRemaining > 0)
          ) {
            if (plan === 'Free trial') {
              toast.warn(`You already have a ${user?.responseObject?.subscription?.currentSubscription.plan}, can't start a new free trial!`);
            }
            handleLogin(user);
            navigate('/dashboard');
          }
        }

        if (user?.responseObject?.error) {
          setError('password', {
            type: "manual",
            message: user?.responseObject?.message || '',
          })
        }
      } catch (error: any) {
        if (error?.data)
          setError('password', {
            type: "manual",
            message: error?.data?.message || '',
          })
      }
    }
  };

  const handelNavigate = (user: LoginResponse) => {
    const plan = localStorage.getItem('plan')
    const type = localStorage.getItem('type')
    const duration = type === '0' ? '30' : '365'
    dispatch(login({ user: user.responseObject }));
    if (plan === 'Basic Plan') {
      localStorage.removeItem('plan')
      localStorage.removeItem('type')
      duration === "30" ? window.open(stripBasicMonth + `?client_reference_id=${user?.responseObject?.id}&prefilled_email=${user?.responseObject.email}`, '_self') : window.open(stripBasicYear + `?client_reference_id=${user?.responseObject?.id}&prefilled_email=${user?.responseObject.email}`, '_self')
    } else if (plan === 'Pro Plan') {
      localStorage.removeItem('plan')
      localStorage.removeItem('type')
      duration === "30" ? window.open(stripProMonth + `?client_reference_id=${user?.responseObject?.id}&prefilled_email=${user?.responseObject.email}`, '_self') : window.open(stripProYear + `?client_reference_id=${user?.responseObject?.id}&prefilled_email=${user?.responseObject.email}`, '_self')
    } else if (plan === 'Premium Plan') {
      localStorage.removeItem('plan')
      localStorage.removeItem('type')
      duration === "30" ? window.open(stripPremiumMonth + `?client_reference_id=${user?.responseObject?.id}&prefilled_email=${user?.responseObject.email}`, '_self') : window.open(stripPremiumYear + `?client_reference_id=${user?.responseObject?.id}&prefilled_email=${user?.responseObject.email}`, '_self')
    } else {
      localStorage.removeItem('plan')
      localStorage.removeItem('type')
      navigate('/dashboard');
    }
  }

  useEffect(() => {
    const plan = localStorage.getItem('plan')
    if(!plan && userAuth?.id){
      const daysRemaining = userAuth?.subscription?.daysRemaining;
      if (daysRemaining === null) {
        dispatch(logout({}))
      } else if (typeof daysRemaining === "number") {
        navigate('/dashboard');
      }
    }
  }, [dispatch, navigate, userAuth?.id, userAuth?.subscription?.daysRemaining])


  return (
    <Box className="flex flex-col flex-1 justify-center items-center gap-2 md:gap-10">
      <Logo />
      <Box className="min-w-[70%] flex flex-col gap-4  w-full md:w-auto">
        <form onSubmit={handleSubmit(onSubmit)} className="flex flex-col gap-3">
          <FormControl>
            <FormLabel
              sx={(theme) => ({
                "--FormLabel-color": theme.vars.palette.neutral[700],
              })}
            >
              Email
            </FormLabel>
            <Controller
              name="email"
              control={control}
              rules={{ required: true }}
              defaultValue=""
              render={({ field }) => (
                <Input
                  {...field}
                  sx={(theme) => ({
                    fontSize: " 0.75rem",
                    "--Input-minHeight": "3rem",
                    "&:focus-within:before": {
                      "--Input-focused": 0,
                    },
                    // boxShadow: theme.shadow.lg,
                    background: "#fff",
                  })}
                  placeholder="Enter your email"
                  autoComplete="username"
                  type="email"
                  error={!!errors.email?.message}
                  size="sm"
                  startDecorator={
                    <MailIcon
                      sx={(theme) => ({ color: theme.palette.navoy.blue })}
                      fontSize="small"
                    />
                  }
                />
              )}
            />
            {!!errors.email?.message && (
              <FormHelperText
                sx={(theme) => ({
                  color: theme.vars.palette.danger[400],
                  fontSize: "xs",
                })}
              >
                {errors.email?.message}
              </FormHelperText>
            )}
          </FormControl>
          <FormControl>
            <FormLabel
              sx={(theme) => ({
                "--FormLabel-color": theme.vars.palette.neutral[700],
              })}
            >
              Password
            </FormLabel>
            <Controller
              name="password"
              control={control}
              rules={{ required: true }}
              render={({ field }) => (
                <Input
                  {...field}
                  sx={(theme) => ({
                    fontSize: " 0.75rem",
                    "--Input-minHeight": "3rem",
                    "&:focus-within:before": {
                      "--Input-focused": 0,
                    },
                    // boxShadow: theme.shadow.lg,
                    background: "#fff",
                  })}
                  placeholder="Password"
                  autoComplete="current-password"
                  type={visible ? "password" : "text"}
                  error={!!errors.password?.message}
                  size="sm"
                  startDecorator={
                    <LockIcon
                      sx={(theme) => ({ color: theme.palette.navoy.blue })}
                      fontSize="small"
                    />
                  }
                  endDecorator={
                    !visible ? (
                      <VisibilityIcon
                        sx={(theme) => ({ color: "#999" })}
                        fontSize="small"
                        onClick={() => setVisible((prev) => !prev)}
                      />
                    ) : (
                      <VisibilityOffIcon
                        sx={(theme) => ({ color: "#999" })}
                        fontSize="small"
                        onClick={() => setVisible((prev) => !prev)}
                      />
                    )
                  }
                />
              )}
            />
            {!!errors.password?.message && (
              <FormHelperText
                sx={(theme) => ({
                  color: theme.vars.palette.danger[400],
                  fontSize: "xs",
                })}
              >
                {errors.password?.message}
              </FormHelperText>
            )}
          </FormControl>
          <Box className="flex justify-between">
            <Controller
              name="remember"
              control={control}
              rules={{ required: true }}
              render={({ field }) => (
                <Checkbox
                  size="sm"
                  onChange={field.onChange}
                  label="Remember me"
                  color="neutral"
                  sx={{ fontSize: "sm" }}
                />
              )}
            />
            <Link to={"/auth/forgot-password"} className="text-sm text-navoy-blue font-medium">
              Forgot Password!
            </Link>
          </Box>
          <Box className="mt-4">
            <Button
              fullWidth
              sx={(theme) => ({
                background: theme.vars.palette.navoy.blue,
                "&:hover": { background: theme.vars.palette.navoy.blue },
                paddingY: 2,
              })}
              type="submit"
              loading={isLoading}
              disabled={isLoading}
            >
              Login
            </Button>
          </Box>
        </form>
        <Divider>
          <Chip variant="soft" color="neutral" size="sm">
            or
          </Chip>
        </Divider>
        <SocialMedia />
        <Typography className="text-center pt-[3px]" fontSize="sm">
          Don't Have an account?{" "}
          <Link to="/auth/signup" className=" text-navoy-blue font-bold">
            Create Account
          </Link>
        </Typography>
      </Box>
    </Box>
  );
};

export default Login;
