import { Link } from "react-router-dom";

type Error404Props = {}

const Error404: React.FC<Error404Props> = () => {
  return (
    <main className="grid min-h-screen place-items-center bg-white px-6 py-24 sm:py-32 lg:px-8">
      <div className="text-center">
        <p className="text-7xl font-semibold text-navoy-red">404</p>
        <h2 className="mt-4 text-3xl font-bold tracking-tight text-navoy-blue sm:text-5xl">Page not found</h2>
        <p className="mt-6 text-base leading-7 text-gray-600">Sorry, we couldn’t find the page you’re looking for.</p>
        <div className="mt-10 flex items-center justify-center gap-x-6">
          <Link to={'/'} className="rounded-md bg-navoy-red px-3.5 py-2.5 text-sm font-semibold text-white shadow-sm hover:bg-navoy_red-700 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-600">Go back home</Link>
        </div>
      </div>
    </main>
  );
}


export default Error404;