import WarningRoundedIcon from '@mui/icons-material/WarningRounded';
import { Textarea, Typography } from '@mui/joy';
import Button from '@mui/joy/Button';
import DialogActions from '@mui/joy/DialogActions';
import DialogContent from '@mui/joy/DialogContent';
import DialogTitle from '@mui/joy/DialogTitle';
import Divider from '@mui/joy/Divider';
import Modal from '@mui/joy/Modal';
import ModalDialog from '@mui/joy/ModalDialog';
import * as React from 'react';

type AlertDialogModalProps = {
  open: boolean;
  setOpen: (open: boolean, status: string) => void;
  text: string;
  isLoading?: boolean
  prompt?: string
  setPrompt?: (prompt: string) => void
}


const AlertDialogModal: React.FC<AlertDialogModalProps> = ({ open, setOpen, text, isLoading, prompt, setPrompt }) => {
  return (
    <Modal open={open} onClose={() => setOpen(false, 'cancel')}>
      <ModalDialog variant="outlined" role="alertdialog">
        <DialogTitle>
          <WarningRoundedIcon />
          Confirmation
        </DialogTitle>
        <Divider />
        <DialogContent>
          <Typography fontWeight={'500'} >{text || 'Are you sure you want to discard all of your notes?'}</Typography>
          {prompt !== undefined && !!setPrompt &&
            <div>
              <Typography>Prompt:</Typography>
              <Textarea value={prompt} onChange={(e) => setPrompt(e.target.value)} minRows={2}
                placeholder="I want the activities to be unique experiences and not mainstream" />
            </div>
          }
        </DialogContent>
        <DialogActions>
          <Button loading={isLoading} variant="solid" color="success" onClick={() => setOpen(false, 'confirm')}>
            Confirm
          </Button>
          <Button variant="outlined" color="neutral" onClick={() => setOpen(false, 'cancel')} disabled={isLoading}>
            Cancel
          </Button>
        </DialogActions>
      </ModalDialog>
    </Modal>
  );
}

export default AlertDialogModal;